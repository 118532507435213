import { useState } from 'react';

import { Editor } from 'slate';

import { useOnToolbarRefresh } from '../../../hooks';
import { DocEditorRef } from '../../../types';

export const useMatchMark = (editor: DocEditorRef | undefined, format: string) => {
  const [mark, setMark] = useState<boolean | string | number | null>(null);
  useOnToolbarRefresh(() => {
    const curEditor = editor?.current?.editor;
    if (!curEditor) setMark(null);
    else {
      const marks = Editor.marks(curEditor);
      // @ts-ignore
      setMark(marks?.[format] ?? null);
    }
  });
  return mark;
};
