import { forwardRef, useContext, useEffect, useRef, useState } from "react"
import OptionBarBtn from "../../../components/option-bar-btn/OptionBarBtn";
import CustomPopover from "../../../components/popover/CustomPopover";
import CustomSelect from "../../../components/select/CustomSelect";
import { checkIsTempAccount, fontSizeTemplateList, getAZcode, getAZindex, handleStopPropagation, makeClassNameList, toastShort } from "../../../utils";
import { CellStyleConfig, DocConfig, HorizontalAlignType, InlineStyleType, SelectOption, VerticalAlignType } from "../../../utils/types";
import { TableDataCell } from "../../../types";
import CellBorderPopover from "../../../components/popover/CellBorderPopover";
import ExcelThemePopover from "../../../components/popover/ExcelThemePopover";
import ExcelGridSettingPopover from "../../../components/popover/ExcelGridSettingPopover";
import comDocContext from '../../../context/DocContext';
import SymbolPopover from "../../../components/popover/SymbolPopover";
import useReSize from "../../../hooks/useResize";
import { commonPermissionsMsg } from "../../../components/smart-map/utils";

interface Props {
    currentCellStyleConfig?: CellStyleConfig
    onDispathCellStyle: Function
    focusTableCellList: TableDataCell[]
    updateCurrentCellStyleConfig: Function
    onDispathExcelConfig: Function
    onDispathCellBorderStyle: Function
}

const ExcelCellConfigOptionBar = (props: Props, ref: any) => {

    const {
        comDocConfig,
    } = useContext(comDocContext);

    const {
        onDispathCellStyle,
        onDispathCellBorderStyle,
        currentCellStyleConfig,
        focusTableCellList,
        updateCurrentCellStyleConfig,
        onDispathExcelConfig
    } = props;

    const excelThemePopoverRef = useRef(null);

    const excelGridSettingPopoverRef = useRef(null);

    const cellFrozenSelectRef = useRef(null);

    const cellBorderPopoverRef = useRef(null);

    const [topLeftCellPosition, setTopLeftCellPosition] = useState([0, 0]);

    const [cellFrozenOptions, setCellFrozenOptions] = useState<SelectOption[]>([]);

    const symbolBtnRef = useRef(null);
    const symbolPopoverRef = useRef(null);

    const [symbolPopoverPosition, setSybolPopoverPosition] = useState({ clientX: 0, clientY: 0 });

    const [cellTextWrapperModeOptions, setCellTextWrapperModeOptions] = useState<SelectOption[]>([
        {
            label: '截断',
            value: '0',
            icon: <i className="iconfont-1 ghzs-gray-jieduan" />
        },
        {
            label: '溢出',
            value: '1',
            icon: <i className="iconfont-1 ghzs-gray-yichu" />
        },
        {
            label: '自动换行',
            value: '2',
            icon: <i className="iconfont-1 ghzs-gray-zidonghuanhang" />
        },
    ])

    const cellTextWrapperModeSelectRef = useRef(null);
    const cellVerticalAlignSelectRef = useRef(null);
    const cellHorizontalAlignSelectRef = useRef(null);

    useEffect(() => {
        // console.log("focusTableCellList--->变动监听", focusTableCellList);
        if (focusTableCellList.length) {
            const bound = focusTableCellList[0].bound;
            setTopLeftCellPosition(focusTableCellList[0].bound);
            let cellFrozenOptions: SelectOption[] = [
                {
                    icon: <i style={{ fontSize: 15 }} className="iconfont-2 ghzs-gray-gongjulantubiao3" />,
                    label: '冻结首行',
                    value: 'row-0-0'
                },
                {
                    icon: <i style={{ fontSize: 15 }} className="iconfont-2 ghzs-gray-gongjulantubiao4" />,
                    label: '冻结首列',
                    value: 'column-0-0'
                },
            ]
            //如果列不为0
            if (bound[0]) {
                cellFrozenOptions.unshift({
                    icon: <i style={{ fontSize: 15 }} className="iconfont-2 ghzs-gray-gongjulantubiao2" />,
                    label: `冻结至第${getAZcode(bound[0])}列`,
                    value: `rangeColumn-0-${bound[0]}`,
                })
            }
            if (bound[1]) {
                cellFrozenOptions.unshift({
                    icon: <i style={{ fontSize: 15 }} className="iconfont-2 ghzs-gray-gongjulantubiao1" />,
                    label: `冻结至第${bound[1] + 1}行`,
                    value: `rangeRow-${bound[1]}-${bound[0]}`
                })
            }
            if (bound[0] && bound[1]) {
                cellFrozenOptions.unshift({
                    icon: <i style={{ fontSize: 15 }} className="iconfont-2 ghzs-gray-lengdongchuangge" />,
                    label: `冻结至第${bound[1] + 1}行第${getAZcode(bound[0])}列`,
                    value: `rangeBoth-${bound[1]}-${bound[0]}`
                })
            }
            const { docExtraConfigInfo } = comDocConfig;
            const { frozenInfo } = docExtraConfigInfo;
            if (frozenInfo && frozenInfo.type !== 'cancel') {
                cellFrozenOptions.unshift({
                    icon: <i style={{ fontSize: 15 }} className="iconfont-2 ghzs-gray-lengdongchuangge" />,
                    label: `取消冻结`,
                    value: `cancel-0-0`
                })
            }
            setCellFrozenOptions(cellFrozenOptions)
        } else {
            let cellFrozenOptions: SelectOption[] = [
                {
                    label: '冻结首行',
                    value: 'row-0-0'
                },
                {
                    label: '冻结首列',
                    value: 'row-0-0'
                },
            ]
            setCellFrozenOptions(cellFrozenOptions);
        }
    }, [focusTableCellList, comDocConfig]);

    const showTextWrapModeSelect = () => {
        cellTextWrapperModeSelectRef.current.show();
    }

    const showExcelGridSettingPopover = () => {
        excelGridSettingPopoverRef.current.openPopover();
    }

    const onCellVerticalAlignSelect = (e) => {

    }

    const showCellHorizontalAlignSelect = () => {
        cellHorizontalAlignSelectRef.current.show();
    }

    const showCellVerticalAlignSelect = () => {
        cellVerticalAlignSelectRef.current.show();
    }

    const showCellBorderSelect = () => {
        cellBorderPopoverRef.current.show();
    }

    const showCellForzenSelect = () => {
        cellFrozenSelectRef.current.show();
    }

    const handleCloseCellHorizontalAlignSelect = () => {

    };

    const handleCloseCellTextWrapperModeSelect = () => {
        cellTextWrapperModeSelectRef.current._handleClose();
    };


    const checkCellHorizontalAlignActive = (horizontalAligntype: HorizontalAlignType) => {
        return currentCellStyleConfig.cellHorizontalAlign == horizontalAligntype;
    }

    const checkCellVerticalAlignActive = (verticalAligntype: VerticalAlignType) => {
        return currentCellStyleConfig.cellVerticalAlign == verticalAligntype;
    }

    const onTextWrapModeSelect = (e) => {
        console.log("onTextWrapModeSelect--->", e)
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.cellTextWrapMode = e;
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        dispathCellStyle('cellTextWrapMode', e)
    }

    const toggleTextWrapMode = () => {
        let tempInlineStyle = currentCellStyleConfig;
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        dispathCellStyle('cellTextWrapMode', tempInlineStyle.cellTextWrapMode)
    }

    const handleSetCurrentCellStyleConfig = (newCellStyleConfig: CellStyleConfig) => {
        updateCurrentCellStyleConfig && updateCurrentCellStyleConfig(newCellStyleConfig);
    }

    const dispathCellStyle = (styleType: InlineStyleType, styleValue: number | string) => {
        console.log("dispathCellStyle--->", styleType, styleValue);
        onDispathCellStyle && onDispathCellStyle({ styleType, styleValue });
    }

    const adaptTextWrapModeClassName = () => {
        // console.log("currentCellStyleConfig--->", currentCellStyleConfig)
        let textWrapModeIconClassName = 'ghzs-gray-jieduan';
        switch (currentCellStyleConfig.cellTextWrapMode) {
            case '0':
                textWrapModeIconClassName = 'ghzs-gray-jieduan';
                break;
            case '1':
                textWrapModeIconClassName = 'ghzs-gray-yichu';
                break;
            case '2':
                textWrapModeIconClassName = 'ghzs-gray-zidonghuanhang';
                break;
            default:
                break;
        }
        return textWrapModeIconClassName;
    }

    const showExcelThemePopover = () => {
        return toastShort('warning', commonPermissionsMsg)
        excelThemePopoverRef.current.openPopover();
    }

    const toggleCellVerticalAlign = (alignType: VerticalAlignType) => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.cellVerticalAlign = alignType;
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        dispathCellStyle('cellVerticalAlign', alignType);
    }

    const toggleCellHorizontalAlign = (alignType: HorizontalAlignType) => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.cellHorizontalAlign = alignType;
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        dispathCellStyle('cellHorizontalAlign', alignType);
    }

    const onExcelFrozenSelect = (e) => {
        console.log("onExcelFrozenSelect---->", e)
        onDispathExcelConfig({ configType: 'frozenInfo', configValue: e })
    }

    const handleCloseExcelFrozenSelect = () => {
        cellFrozenSelectRef.current._handleClose();
    }

    const checkFrozenActive = () => {
        let isFrozen = false;
        if (
            comDocConfig &&
            comDocConfig.docExtraConfigInfo &&
            comDocConfig.docExtraConfigInfo.frozenInfo &&
            comDocConfig.docExtraConfigInfo.frozenInfo.type &&
            comDocConfig.docExtraConfigInfo.frozenInfo.type !== 'cancel'
        ) {
            isFrozen = true;
        }
        return isFrozen;
    }

    const cancelExcelFrozen = () => {
        if (checkFrozenActive()) {
            onDispathExcelConfig({ configType: 'frozenInfo', configValue: 'cancel-0-0' })
        } else {
            toastShort('warning', '请选择窗格的冻结方式')
        }
    }

    const onApplayExcelGridConfig = (newComDocConfig: DocConfig) => {
        onDispathExcelConfig({ configType: 'gridInfo', configValue: newComDocConfig })
    }

    const handleOpenSymbolPopover = () => {
        symbolPopoverRef.current.openPopover();
    }

    const containerRef = useRef(null);

    const containerSize = useReSize(containerRef);

    useEffect(() => {
        const clientRect = symbolBtnRef?.current.getBoundingClientRect();
        const { top, left } = clientRect;
        setSybolPopoverPosition({ clientX: left - 24, clientY: top + 32 })
    }, [containerSize])

    return (
        <div
            ref={containerRef}
            className='edit-page-option-row-item flex-col'
            style={{ width: '260px', paddingLeft: '10px' }}
            onMouseDown={handleStopPropagation}
        >
            <div className='edit-page-option-row-half text-edit-row-1 flex-row'>
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-a-11' style={{ fontWeight: '500', fontSize: 17 }} />}
                    size={'small'}
                    tooltipTitle={"顶部对齐"}
                    tooltipDesc={"调整单元格内容垂直方向-顶部对齐"}
                    active={checkCellVerticalAlignActive('top')}
                    onClick={() => toggleCellVerticalAlign('top')}
                />
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-a-2' style={{ fontWeight: '500', fontSize: 17, marginTop: -3 }} />}
                    size={'small'}
                    tooltipTitle={"居中对齐"}
                    tooltipDesc={"调整单元格内容垂直方向-居中对齐"}
                    active={checkCellVerticalAlignActive('center')}
                    onClick={() => toggleCellVerticalAlign('center')}
                />
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-a-3' style={{ fontWeight: '500', fontSize: 17 }} />}
                    size={'small'}
                    tooltipTitle={"底部对齐"}
                    tooltipDesc={"调整单元格内容垂直方向-底部对齐"}
                    active={checkCellVerticalAlignActive('bottom')}
                    onClick={() => toggleCellVerticalAlign('bottom')}
                />
                {
                    !checkIsTempAccount() ?
                        <OptionBarBtn
                            icon={<i className={makeClassNameList([
                                'iconfont-1',
                                adaptTextWrapModeClassName()
                            ])} style={{ fontWeight: '500', fontSize: 16 }} />}
                            size={'small'}
                            tooltipTitle={"文本换行"}
                            tooltipDesc={'调整单元格中文本过长后的展示方式'}
                            onClickArrow={showTextWrapModeSelect}
                            onClick={toggleTextWrapMode}
                            selectEnable
                        />
                        :
                        null
                }
                <div ref={symbolBtnRef}>
                    <OptionBarBtn
                        icon={<i style={{ fontSize: 18 }} className='iconfont-1 ghzs-gray-fuhao' />}
                        size={'small'}
                        tooltipTitle={"符号"}
                        // tooltipDesc={'将选中的多个单元格合并为一个单元格，且合并后的单元格内容默认居中显示'}
                        onClick={handleOpenSymbolPopover}
                        onClickArrow={handleOpenSymbolPopover}
                        selectEnable
                    />
                </div>
                <OptionBarBtn
                    icon={<i style={{ fontSize: 20 }} className='iconfont ghzs-color-lengdongchuangge1' />}
                    size={'small'}
                    tooltipTitle={checkFrozenActive() ? "取消冻结窗格" : "冻结窗格"}
                    tooltipDesc={'锁定表格窗口中的指定行和列，使其在表格滚动中始终保持固定可见（取消所有行和列的锁定，以滚动整个表格）'}
                    selectEnable
                    onClick={cancelExcelFrozen}
                    onClickArrow={showCellForzenSelect}
                    active={checkFrozenActive()}
                />
                
            </div>
            <div className='edit-page-option-row-half text-edit-row-1 flex-row'>
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-juzuoduiqi' style={{ fontWeight: '500', fontSize: 17 }} />}
                    size={'small'}
                    tooltipTitle={"居左对齐"}
                    tooltipDesc={"调整单元格内容水平方向-居左对齐"}
                    active={checkCellHorizontalAlignActive('left')}
                    onClick={() => toggleCellHorizontalAlign('left')}
                />
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-juzhongduiqi' style={{ fontWeight: '500', fontSize: 17 }} />}
                    size={'small'}
                    tooltipTitle={"居中对齐"}
                    tooltipDesc={"调整单元格内容水平方向-居中对齐"}
                    active={checkCellHorizontalAlignActive('center')}
                    onClick={() => toggleCellHorizontalAlign('center')}
                />
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-juyouduiqi' style={{ fontWeight: '500', fontSize: 17 }} />}
                    size={'small'}
                    tooltipTitle={"居右对齐"}
                    tooltipDesc={"调整单元格内容水平方向-居右对齐"}
                    active={checkCellHorizontalAlignActive('right')}
                    onClick={() => toggleCellHorizontalAlign('right')}
                />
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-quxiaodongjie' style={{ fontWeight: '500', fontSize: 17 }} />}
                    size={'small'}
                    tooltipTitle={"单元格边框"}
                    tooltipDesc={'选择不同样式的单元格边框'}
                    onClickArrow={showCellBorderSelect}
                    selectEnable
                />
                <OptionBarBtn
                    icon={<i style={{ fontSize: 20 }} className='iconfont ghzs-color-hanghelie' />}
                    size={'small'}
                    tooltipTitle={"行和列"}
                    tooltipDesc={'调整工作表中行和列的默认宽度'}
                    onClickArrow={showExcelGridSettingPopover}
                    selectEnable
                />
                <OptionBarBtn
                    icon={<i style={{ fontSize: 20 }} className='iconfont ghzs-color-biaogeyangshi' />}
                    size={'small'}
                    tooltipTitle={"表格样式"}
                    // tooltipDesc={'快速套用已有的表格样式'}
                    // onClickArrow={showExcelThemePopover}
                    // onClick={showExcelThemePopover}
                    style={{opacity: 0, pointerEvents: 'none'}}
                    selectEnable
                />
            </div>
            <CustomPopover
                ref={cellTextWrapperModeSelectRef}
                clientX={829}
                clientY={96}
                //@ts-ignore
                contentRender={(props) =>
                    <CustomSelect
                        options={cellTextWrapperModeOptions}
                        value={currentCellStyleConfig.cellTextWrapMode}
                        onSelect={onTextWrapModeSelect}
                        handleClose={handleCloseCellTextWrapperModeSelect}
                        selectTarget={'value'}
                    />
                }
            />
            <CustomPopover
                ref={cellFrozenSelectRef}
                clientX={929}
                // clientY={126}
                clientY={96}
                //@ts-ignore
                contentRender={(props) =>
                    <CustomSelect
                        options={cellFrozenOptions}
                        value={currentCellStyleConfig.cellTextWrapMode}
                        onSelect={onExcelFrozenSelect}
                        handleClose={handleCloseExcelFrozenSelect}
                        selectTarget={'value'}
                    />
                }
            />
            <CellBorderPopover
                ref={cellBorderPopoverRef}
                onDispathCellBorderStyle={onDispathCellBorderStyle}
                clientX={829}
                clientY={126}
            />
            <ExcelThemePopover
                ref={excelThemePopoverRef}
                clientX={929}
                clientY={96}
            />
            <ExcelGridSettingPopover
                ref={excelGridSettingPopoverRef}
                clientX={879}
                clientY={126}
                onApplayExcelGridConfig={onApplayExcelGridConfig}
            />
            <SymbolPopover
                ref={symbolPopoverRef}
                clientX={symbolPopoverPosition.clientX}
                clientY={symbolPopoverPosition.clientY}
            />
        </div>
    )
}


export default forwardRef(ExcelCellConfigOptionBar);