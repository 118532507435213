import React from 'react';

import { DocEditorRef } from '../../types';
import { createClassNameMark } from '../../utils';

import Style from './main.module.scss';

const classNames = createClassNameMark(Style);
import {
  AlignItem,
  BgColorItem,
  BoldItem,
  ColorItem,
  DeleteItem,
  FontFamilyItem,
  FontSizeItem,
  FontSizeOperationItem,
  FormatEraseItem,
  HeadingItem,
  ItalicItem,
  SubItem,
  SupItem,
  UnderlineItem,
} from './components';

interface Props {
  docEditorRef?: DocEditorRef;
}

export const Toolbar = React.memo(function Toolbar({ docEditorRef }: Props) {
  return (
    <div className={classNames('doc-toolbar')}>
      <BoldItem editor={docEditorRef} />
      <ItalicItem editor={docEditorRef} />
      <DeleteItem editor={docEditorRef} />
      <UnderlineItem editor={docEditorRef} />
      <SubItem editor={docEditorRef} />
      <SupItem editor={docEditorRef} />
      <ColorItem editor={docEditorRef} />
      <BgColorItem editor={docEditorRef} />
      <FontSizeItem editor={docEditorRef} />
      <FontSizeOperationItem isIncrease={true} editor={docEditorRef} />
      <FontSizeOperationItem isIncrease={false} editor={docEditorRef} />
      <FontFamilyItem editor={docEditorRef} />
      <AlignItem editor={docEditorRef} />
      <HeadingItem editor={docEditorRef} level={0} />
      <HeadingItem editor={docEditorRef} level={1} />
      <HeadingItem editor={docEditorRef} level={2} />
      <HeadingItem editor={docEditorRef} level={3} />
      <HeadingItem editor={docEditorRef} level={4} />
      <HeadingItem editor={docEditorRef} level={5} />
      <FormatEraseItem editor={docEditorRef} />
    </div>
  );
});
