import React, { CSSProperties } from 'react';

import { Element, Text } from 'slate';
import { RenderElementProps } from 'slate-react';

import { DocItemExternalData, DocItemType } from './doc';
import { AlignEnum } from '../constants';

export enum BaseSlateItemType {
  Input = 'input',
  Tag = 'tag',
}

export type SlateItemType = BaseSlateItemType | DocItemType;

/**
 * slate 元素属性
 */
export interface SlateElementProps extends Omit<RenderElementProps, 'children' | 'element'> {
  children?: React.ReactNode;
  style?: CSSProperties;
  element: RenderElementProps['element'] & {
    initialVal?: string | number;
    id?: string | number;
    mark?: string;
    type?: SlateItemType;
    scene?: string;
    externalData?: DocItemExternalData;
    align?: AlignEnum;
  };
}

/**
 * slate 基本构成单位
 */
export type SlateDescendant =
  | (Element & {
      initialVal?: string | number;
      id?: string | number;
      mark?: string;
      type?: SlateItemType;
      scene?: string;
      align?: AlignEnum;
    })
  | Text;
