import React, { forwardRef, useState, useImperativeHandle, ChangeEvent, useRef, useEffect } from 'react';
import { Button, Modal,Form, Image, TreeSelect, Switch, Tabs } from 'antd';
import { UserType } from '../../utils/types';


interface Props {
    onFinish?: Function
};

const UserServiceModal = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openModal: openModal
    }));

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [form] = Form.useForm<UserType>();
    const [formData, setFormData] = useState<UserType>({});

    const handleConfirm = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const openModal = () => {
        setIsModalOpen(true);
    }

    return (
        <Modal
            // title={'人工客服'}
            open={isModalOpen}
            onOk={handleConfirm}
            onCancel={handleCancel}
            okText={'确 认'}
            cancelText={'取 消'}
            width={'800px'}
            maskClosable={false}
            confirmLoading={submitLoading}
            centered
        >
            <div style={{fontSize: 20,justifyContent: 'center'}} className='flex-col'>人工客服</div>
            <div style={{ alignItems: 'center', paddingTop: 42, paddingBottom: 52}} className='flex-col custom-modal-container'>
                <div 
                    className='flex-col'
                    style={{
                        width: 260, 
                        height: 260, 
                        borderRadius: 12, 
                        marginBottom: 12,
                        justifyContent: 'center',
                        border:  `1px solid #d3d3d3`
                    }}
                >
                <Image preview={false} style={{width: 240, height: 240}} src={require('./../../assets/image/service.jpg')} />
                </div>
                <div style={{fontSize: 20, paddingBottom: 20}}>微信扫码联系客服</div>
                <div style={{fontSize: 20}}>联系电话：18613878897</div>
            </div>
        </Modal>
    )
}

export default forwardRef(UserServiceModal)