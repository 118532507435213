import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import EditorStateOptionBar from "./EditorStateOptionBar";
import ExcelInlineStyleOptionBar from "./ExcelInlineStyleOptionBar";
import ExcelCellStyleOptionBar from "./ExcelCellStyleOptionBar";
import ExcelCellConfigOptionBar from "./ExcelCellConfigOptionBar";
import ExcelDocToolsOptionBar from "./ExcelDocToolsOptionBar";
import ExcelExtraToolsOptionBar from "./ExcelExtraToolsOptionBar";
import ExcelEditorClipboard from "./ExcelEditorClipboard";
import { TableDataCell } from "../../../types";
import { deepCopy, defaultExcelCellStyleConfig, mergeCellConfigList } from "../../../utils";
import { CellStyleConfig, DocConfig } from "../../../utils/types";
import comDocContext from '../../../context/DocContext';
import { isEmpty } from "lodash";

interface Props {
    getExcelExportParams?: Function
    getWordExportParams?: Function
    onDispathCellStyle: Function
    onDispathCellBorderStyle: Function
    onDispathExcelConfig: Function
    handleRecommandMainProjectDevice: Function
    _handleClearCellStyle: Function
}

const ExcelEditorOptionBar = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        toggleLayoutVisible,
        handleSetFocusTableCellList
    }))

    const {
        comDocConfig,
        _setComDocConfig,
        comDocInstance,
        comCellStyleConfig
    } = useContext(comDocContext);

    const {
        onDispathCellStyle,
        onDispathCellBorderStyle,
        getExcelExportParams,
        getWordExportParams,
        onDispathExcelConfig,
        handleRecommandMainProjectDevice,
        _handleClearCellStyle
    } = props;

    const containerRef = useRef<any>(null);

    const tempDocConfig = useRef<DocConfig>(null);

    const excelCellStyleOptionBarRef = useRef<any>(null);
    const excelInlineStyleOptionBarRef = useRef<any>(null);

    const [excelCellStyleConfig, setExcelCellStyleConfig] = useState<CellStyleConfig>({ ...defaultExcelCellStyleConfig });

    const [focusTableCellList, setFocusTableCellList] = useState<TableDataCell[]>([]);


    useEffect(() => {
        let initExcelCellStyle = localStorage.getItem('excelCellStyle') ? JSON.parse(localStorage.getItem('excelCellStyle')) : { ...defaultExcelCellStyleConfig };
        setExcelCellStyleConfig(initExcelCellStyle);
    }, []);

    useEffect(() => {
        tempDocConfig.current = comDocConfig;
    }, [comDocConfig]);

    const toggleLayoutVisible = (visible: boolean) => {
        if (visible) {
            containerRef.current.style.zIndex = 1;
            containerRef.current.style.display = 'flex';
            containerRef.current.style.width = '100%';
        } else {
            containerRef.current.style.zIndex = -999;
            containerRef.current.style.display = 'none';
            containerRef.current.style.width = 0;
        }
    };

    const handleSetFocusTableCellList = (newFocusTableCellList: TableDataCell[]) => {
        // console.log("表格工具栏接收到的FocusTableCellList--->", newFocusTableCellList)
        let {
            cellConfigList
        } = comDocConfig;
        cellConfigList = deepCopy(cellConfigList);
        // console.log("当前的cellConfigList---->", deepCopy(cellConfigList));
        //
        const findCellStyleConfig = cellConfigList.find(cellConfigItem => {
            return cellConfigItem.cellId == `${newFocusTableCellList[0].id}-${newFocusTableCellList[0].bound[0]}`;
        })
        // console.log("findCellStyleConfig--->", findCellStyleConfig);
        let currentCellStyleConfig = defaultExcelCellStyleConfig;
        if (findCellStyleConfig) {
            currentCellStyleConfig = findCellStyleConfig;
        }
        // delete currentCellStyleConfig.inlineBackgroundColor;
        // delete currentCellStyleConfig.inlineFontColor;
        const newCellStyleConfig = {
            ...defaultExcelCellStyleConfig,
            ...currentCellStyleConfig
        }
        if(findCellStyleConfig){
            if(findCellStyleConfig.inlineFontColor){
                newCellStyleConfig.inlineFontColor = findCellStyleConfig.inlineFontColor
            }
            if(findCellStyleConfig.inlineBackgroundColor){
                newCellStyleConfig.inlineBackgroundColor = findCellStyleConfig.inlineBackgroundColor;
            }
        }
        // newCellStyleConfig.inlineBackgroundColor = excelCellStyleConfig.inlineBackgroundColor;
        // newCellStyleConfig.inlineFontColor = excelCellStyleConfig.inlineFontColor;
        // console.log("defaultExcelCellStyleConfig---->", newCellStyleConfig);
        // console.log("currentCellStyleConfig---->", newCellStyleConfig);
        // console.log("工具栏刷新应用上的样式配置---->", newCellStyleConfig);
        setExcelCellStyleConfig({ ...newCellStyleConfig });
        localStorage.setItem('excelCellStyle', JSON.stringify(newCellStyleConfig));
        setFocusTableCellList(newFocusTableCellList);
    }

    const _getExcelExportParams = () => {
        return getExcelExportParams();
    }

    const _getWordExportParams = () => {
        return getExcelExportParams();
    }

    const updateTableCellConfigList = (newInlineStyle: CellStyleConfig) => {
        if (isEmpty(focusTableCellList) || focusTableCellList.length == 0) {
            return false;
        }
        // console.log("focusTableCellList---->", focusTableCellList)
        let newCellConfigList = mergeCellConfigList(
            focusTableCellList.filter(cell => cell.text),
            newInlineStyle,
            comDocConfig.cellConfigList,
            'cellId'
        );
        // console.log("合并后的newCellConfigList---->", newCellConfigList)
        let _tempDocConfig = tempDocConfig.current;
        _tempDocConfig.cellConfigList = [...newCellConfigList];
        _tempDocConfig.updateComponentName = 'EditPageNav';
        _setComDocConfig(deepCopy(_tempDocConfig));
    }

    const handleSetExcelCellStyleConfig = (newCellStyleConfig: CellStyleConfig) => {
        console.log("handleSetExcelCellStyleConfig的是---->", newCellStyleConfig);
        // delete newCellStyleConfig.inlineBackgroundColor;
        // delete newCellStyleConfig.inlineFontColor;
        setExcelCellStyleConfig({ ...newCellStyleConfig });
        localStorage.setItem('excelCellStyle', JSON.stringify(newCellStyleConfig));
        updateTableCellConfigList(newCellStyleConfig);
    };
    

    return (
        <div
            ref={containerRef}
            className='edit-page-option-row content-border content-border-radius flex-row'
            style={{ display: 'flex' }}
        >
            <EditorStateOptionBar
                type={'excel'}
                getExcelExportParams={_getExcelExportParams}
                getWordExportParams={_getWordExportParams}
            />
            <div className='edit-page-option-row-border' style={{ marginRight: 12 }}></div>
            {/* 粘贴剪切复制格式刷等 */}
            <ExcelEditorClipboard 
                focusTableCellList={focusTableCellList}
                _handleClearCellStyle={_handleClearCellStyle}
            />
            <div className='edit-page-option-row-border'></div>
            {/* 字体、斜体 */}
            <ExcelInlineStyleOptionBar
                ref={excelInlineStyleOptionBarRef}
                currentCellStyleConfig={excelCellStyleConfig}
                updateCurrentCellStyleConfig={handleSetExcelCellStyleConfig}
                onDispathCellStyle={onDispathCellStyle}
                focusTableCellList={focusTableCellList}
            />
            {/* 上标下标、背景、格式擦、颜色 */}
            <ExcelCellStyleOptionBar
                ref={excelCellStyleOptionBarRef}
                currentCellStyleConfig={excelCellStyleConfig}
                updateCurrentCellStyleConfig={handleSetExcelCellStyleConfig}
                onDispathCellStyle={onDispathCellStyle}
                focusTableCellList={focusTableCellList}
            />
            <div className='edit-page-option-row-border' style={{ marginLeft: 12 }}></div>
            {/* 对齐方式、单元格调整 */}
            <ExcelCellConfigOptionBar 
                currentCellStyleConfig={excelCellStyleConfig}
                updateCurrentCellStyleConfig={handleSetExcelCellStyleConfig}
                onDispathCellStyle={onDispathCellStyle}
                onDispathCellBorderStyle={onDispathCellBorderStyle}
                focusTableCellList={focusTableCellList}
                onDispathExcelConfig={onDispathExcelConfig}
            />
            <div className='edit-page-option-row-border' style={{ marginLeft: 12, marginRight: 12 }}></div>
            <ExcelDocToolsOptionBar 
                handleRecommandMainProjectDevice={handleRecommandMainProjectDevice}
            />
        </div>
    )
}


export default forwardRef(ExcelEditorOptionBar);