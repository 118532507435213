import { CloseOutlined } from "@ant-design/icons";
import { Empty, Tabs, TabsProps, Tooltip } from "antd";
import { useContext, useState } from "react";
import comDocContext from '../../../context/DocContext';
import DocVersionItemKit from "./DocVersionItemKit";
import { DocVersionItem } from "../../../types";

const DocVersionHistroy = () => {

    const {
        _setBaseMode,
        comDocInstance,
        _setComDocInstance,
        comDocConfig,
        currentFocusedDocItem,
        _setComFocusTopicNode,
    } = useContext(comDocContext);

    const [allVersionHistroy, setAllVersionHistroy] = useState<DocVersionItem[]>([
        {
            title: "第12版",
            type: "edit-topic-property",
            topicId: "xxxx",
        },
        {
            title: "第11版",
            type: "edit-topic-property",
            topicId: "xxxx",
        },
        {
            title: "第10版",
            type: "edit-topic-property",
            topicId: "xxxx",
        },
        {
            title: "第9版",
            type: "edit-topic-property",
            topicId: "xxxx",
        },
        {
            title: "第8版",
            type: "edit-topic-property",
            topicId: "xxxx",
        },
    ]);
    const [finalVersionHistory, setFinalVersionHistory] = useState<DocVersionItem[]>([
        {
            title: "第12版",
            type: "edit-topic-property",
            topicId: "xxxx",
            isFixed: true,
        },
        {
            title: "第11版",
            type: "edit-topic-property",
            topicId: "xxxx",
            isFixed: true,
        },
    ]);

    const renderVersionList = (type: 'all' | 'final') => {
        if(type == 'all'){
            return (
                // allVersionHistroy.map((version, index) => {
                //     return (
                //         <DocVersionItemKit 
                //             key={index + '-all'} 
                //             version={version}
                //             index={index}
                //         />
                //     )
                // })
                <Empty/>
            )
        }else if(type == 'final'){
            return <Empty/>
            // return (
            //     finalVersionHistory.map((version, index) => {
            //         return (
            //             <DocVersionItemKit 
            //                 key={index + '-final'} 
            //                 version={version}
            //                 index={index}
            //             />
            //         )
            //     })
            // )
        }
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '历史',
            children: renderVersionList('all'),
        },
        {
            key: '2',
            label: '定稿',
            children: renderVersionList('final'),
        },
    ]

    const quitVersionHistoryMode = () => {
        _setBaseMode('Editor')
    }

    return (
        <div
            className="edit-page-with-history-side"
        >
            <div className="edit-page-with-history-side-content">
                <div className="flex-row edit-page-with-history-side-header">
                    <div className="history-side-header-title">
                        版本记录
                    </div>
                    <Tooltip
                        title={"退出版本回顾模式"}
                    >
                        <div 
                            className="history-side-header-btn"
                            onClick={quitVersionHistoryMode}
                        >
                            <CloseOutlined />
                        </div>
                    </Tooltip>
                </div>
                <Tabs
                    defaultActiveKey="1"
                    items={items}
                />
            </div>
        </div>
    )
}

export default DocVersionHistroy;