import { Button, Tabs, Empty, Tooltip } from "antd";
import React, { ReactNode, forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import comDocContext from '../../../context/DocContext';
import { generateDeviceParagraphSlice, replaceDocInfo } from "../word-editor-deprecated/WordEditorHelper";
import { deepCloneV2, getTopicMaxBackgroundIndex, isEmpty } from "../../../utils";
import { DocInstance, TopicType } from "../../../utils/types";
import { PlusCircleOutlined, SnippetsOutlined } from "@ant-design/icons";
import { WordParagraphBlock } from "../../../utils/types";
import { DocItem, DocItemType } from "../../slate-editor";
import useModal from "antd/lib/modal/useModal";
import AddBackgrounParagraphModal from "./modal/AddBackgrounParagraphModal";
import { getReplacedDocInfoPlainText } from "../word-editor/editor-doc-item-generate";

interface Props {
    changeBackgroundParagraph: Function
}

interface BackgroundParagraphTab {
    key: string
    label: string
    children: ReactNode
}

const TextTopicBackgroundParagraphOld = (props: Props, ref: any) => {

    const {
        comDocInstance,
        _setComDocInstance,
        currentFocusedDocItem
    } = useContext(comDocContext);

    const {
        changeBackgroundParagraph
    } = props;

    const tempComDocInstance = useRef<DocInstance>(null);
    const tempFocusedDocItem = useRef<DocItem>(null);
    const tempTextTopicId = useRef<string>(null);

    const [isNotSupport, setIsNotSupport] = useState<boolean>(false);
    const [backgroundParagraphList, setBackgroundParagraphList] = useState<string[]>([]);
    const [currentUseBackgroundParagraphIndex, setCurrentUseBackgroundParagraphIndex] = useState(-1);

    const [modal, contextHolder] = useModal();

    const addBackgrounParagraphModalRef = useRef<any>(null);

    const [currentTopic, setCurrentTopic] = useState<TopicType>(null);

    useEffect(() => {
        if (isEmpty(comDocInstance)) {
            return;
        }
        tempComDocInstance.current = comDocInstance;
    }, [comDocInstance])

    useEffect(() => {
        if (isEmpty(currentFocusedDocItem)) {
            return;
        }
        try {
            // console.log("检查文档用到的tempFocusedDocItem---->", currentFocusedDocItem)
            tempFocusedDocItem.current = currentFocusedDocItem;
            if (currentFocusedDocItem.type == DocItemType.P) {
                const currentTopicId = String(currentFocusedDocItem.id).split('-')[0];
                tempTextTopicId.current = currentTopicId;
                const _tempComDocInstance = tempComDocInstance.current;
                const findTextTopic = _tempComDocInstance.topicList.find(topic => {
                    return topic.id == currentTopicId
                })
                // console.log("检查背景文本用到的findTextTopic---->", findTextTopic)
                if (findTextTopic) {
                    let tempBackgroundParagraphList = [];
                    Object.keys(findTextTopic).forEach(key => {
                        if (key.includes('backgroundParagraph') && !isEmpty(findTextTopic[key])) {
                            tempBackgroundParagraphList.push(findTextTopic[key])
                        }
                    })
                    let currentUseBackgroundParagraphIndex = -1;
                    const findTargetDocItem = findTextTopic.topicSlateDocItemList.find(ele => ele.id.toString().includes('-p'));
                    let currentBackgroundParagraphPlainText = '';
                    if (findTargetDocItem && findTargetDocItem.plainText) {
                        currentBackgroundParagraphPlainText = findTargetDocItem.plainText;
                    }
                    // console.log("currentBackgroundParagraphPlainText--->", currentBackgroundParagraphPlainText)
                    if (!isEmpty(findTextTopic.currentInUseBackgroundParagraphIndex) && findTextTopic.currentInUseBackgroundParagraphIndex != -1) {
                        // console.log("下标是---->", findTextTopic.currentInUseBackgroundParagraphIndex)
                        currentUseBackgroundParagraphIndex = findTextTopic.currentInUseBackgroundParagraphIndex;
                    } else {
                        // console.log("走文本匹配", tempBackgroundParagraphList)
                        for (let i = 0; i < tempBackgroundParagraphList.length; i++) {
                            const tempBackgroundParagraphPlainText = getReplacedDocInfoPlainText(tempBackgroundParagraphList[i], comDocInstance).replace(/#(.*?)#/g, "\$1");
                            // const tempBackgroundParagraphPlainText = getReplacedDocInfoPlainText(backgroundParagraphList[i], comDocInstance).replace(/#/g, "\$1");
                            // console.log("tempBackgroundParagraphPlainText--->", tempBackgroundParagraphPlainText)
                            if (currentBackgroundParagraphPlainText == tempBackgroundParagraphPlainText) {
                                currentUseBackgroundParagraphIndex = i;
                                break;
                            }
                        }
                    }
                    // console.log("currentUseBackgroundParagraphIndex--->", currentUseBackgroundParagraphIndex)
                    setCurrentUseBackgroundParagraphIndex(currentUseBackgroundParagraphIndex)
                    setBackgroundParagraphList(tempBackgroundParagraphList);
                    setIsNotSupport(false);
                    setCurrentTopic(findTextTopic);
                }
            } else {
                setIsNotSupport(true);
                setBackgroundParagraphList([]);
            }
        } catch (e) {
            console.log("寻找背景文本失败---->", e)
            setIsNotSupport(true);
            setBackgroundParagraphList([]);
        }
    }, [currentFocusedDocItem]);

    const handleChangeBackgroundParagraph = (backgroundText: string, index: number) => {
        backgroundText = replaceDocInfo(backgroundText, comDocInstance)
        changeBackgroundParagraph &&
            changeBackgroundParagraph({
                backgroundText,
                index: index,
                textTopicId: tempTextTopicId.current
            })
    }

    const renderBackgroundParagraphText = (backgroundText: string, index: number) => {
        const backgroundTextPagragraphs: string[] = backgroundText.split(/[(\r\n)\r\n]+/);
        let backgroundTextPagragraphElementList = [];
        backgroundTextPagragraphs.forEach((str, index) => {
            const strSplitList = generateDeviceParagraphSlice(str);
            let strSplitElementList = []
            strSplitList.forEach((strSplit, strSplitIndex) => {
                if (
                    (strSplit.includes('[') && strSplit.includes(']')) ||
                    (strSplit.includes('YS') || strSplit.includes('YF'))
                ) {
                    const replacedText = replaceDocInfo(strSplit, tempComDocInstance.current)
                    strSplitElementList.push(
                        <span style={{ backgroundColor: '#e6f4ff' }} key={index + strSplitIndex}>{replacedText}</span>
                    )
                } else {
                    strSplitElementList.push(
                        <span>{strSplit}</span>
                    )
                }
            })
            backgroundTextPagragraphElementList.push(strSplitElementList);
        })
        return (
            <div className="background-paragraph-text-container">
                <div className="background-paragraph-text-content">
                    {
                        backgroundTextPagragraphElementList.map((strSplitElementList, strSplitElementListIndex) => {
                            return (
                                <p
                                    className="background-paragraph-text-template"
                                    key={strSplitElementListIndex + ''}
                                >
                                    {
                                        strSplitElementList.map((textSlice, textSliceIndex) => {
                                            return (
                                                <React.Fragment
                                                    key={textSliceIndex + strSplitElementListIndex}
                                                >{textSlice}
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </p>
                            )
                        })
                    }
                </div>
                <div className="flex-col background-paragraph-bottom-overlay">
                    <Button
                        type={'primary'}
                        icon={<SnippetsOutlined />}
                        disabled={currentUseBackgroundParagraphIndex == index}
                        onClick={() => handleChangeBackgroundParagraph(backgroundText, index)}
                    >
                        {
                            currentUseBackgroundParagraphIndex == index ?
                                '已在使用中'
                                :
                                '使用此范文'
                        }
                    </Button>
                </div>
            </div>
        )
    }

    const backgroundParagraphTabList = useMemo<BackgroundParagraphTab[]>(() => {
        let tabs: BackgroundParagraphTab[] = [];
        backgroundParagraphList.forEach((backgroundParagraph, index) => {
            tabs.push({
                key: `background-paragraph-${index}`,
                label: `范文-${index + 1}`,
                children: renderBackgroundParagraphText(backgroundParagraph, index)
            })
        })
        return tabs;
    }, [backgroundParagraphList, currentUseBackgroundParagraphIndex]);

    const handleOpenAddBackgroundParagraphModal = () => {
        addBackgrounParagraphModalRef.current.openModal(currentTopic)
    };

    const onAddBackgrounParagraph = (paragraphText: string) => {
        let paragraphTextList = backgroundParagraphList;
        paragraphTextList.push(paragraphText);
        let tempComDocInstance = comDocInstance;
        let tempTopicList = comDocInstance.topicList;
        tempTopicList.forEach(topic => {
            if (topic.id == currentTopic.id) {
                topic[`backgroundParagraph${getTopicMaxBackgroundIndex(topic) + 1}`] = paragraphText;
            }
        })
        tempComDocInstance.topicList = [...tempTopicList];
        setBackgroundParagraphList([...paragraphTextList]);
        _setComDocInstance(deepCloneV2(tempComDocInstance));
    };

    return (
        <div className="word-editor-plugins-content">
            {
                backgroundParagraphList.length ?
                    <Tabs
                        type={'card'}
                        defaultActiveKey="currentTopic"
                        items={backgroundParagraphTabList}
                        tabPosition='top'
                        className='word-editor-plugin-content-tabs disable-drag'
                        size={'small'}
                        tabBarExtraContent={{
                            right: (
                                <Tooltip title={"添加范文"}>
                                    <Button
                                        type={"text"}
                                        onClick={handleOpenAddBackgroundParagraphModal}
                                    >
                                        <PlusCircleOutlined />
                                    </Button>
                                </Tooltip>
                            )
                        }}
                    />
                    :
                    <div className="flex-col word-editor-plugins-empty">
                        <Empty
                            description={(
                                <div>
                                    <div style={{ fontSize: 15 }}>本节无参考范文</div>
                                    {
                                        isNotSupport ?
                                            <span style={{ fontSize: 14, color: '#7f7f7f' }}>光标所处段落不支持使用范文插件</span>
                                            :
                                            <span style={{ fontSize: 14, color: '#7f7f7f' }}>可将光标聚焦在需要查看的段落中，或选中指定段落文本，即可查看系统内置的参考范文</span>
                                    }
                                </div>
                            )}
                        />
                    </div>
            }
            <AddBackgrounParagraphModal
                ref={addBackgrounParagraphModalRef}
                onFinish={onAddBackgrounParagraph}
            />
            {contextHolder}
        </div>
    )
}

export default forwardRef(TextTopicBackgroundParagraphOld);