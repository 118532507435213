import React, { useCallback, useEffect, useState } from 'react';

import { Select } from 'antd';
import { Editor } from 'slate';

import { FontFamilyConfig, FontFamilyOptions } from '../../../constants';
import { useMatchMark, useMatchSlateItem } from '../hooks';
import { ItemProps } from '../types';
import SelectWidthToolip from '../../../../../components/select/SelectWidthToolip';
import { sortOptions } from '../../../../../utils';

/**
 * 文字字体工具项
 */
export const FontFamilyItem = React.memo(function FontFamilyItem({ editor }: ItemProps) {
  const format = 'fontFamily';
  const [value, setValue] = useState<string>(FontFamilyConfig.default);
  const changeFont = useCallback(
    (value: string) => {
      const curEditor = editor?.current?.editor;
      if (!curEditor) return;
      Editor.addMark(curEditor, format, value);
    },
    [editor],
  );
  const slateItemType = useMatchSlateItem(editor)?.type || 'default';
  const mark = (useMatchMark(editor, format) as string) || FontFamilyConfig[slateItemType];
  useEffect(() => {
    setValue(mark);
  }, [mark]);
  // return <Select popupMatchSelectWidth={false} value={`${value}`} options={FontFamilyOptions} onChange={changeFont} />;
  return (
    <SelectWidthToolip
      size={'small'}
      value={`${value}`}
      options={sortOptions(FontFamilyOptions)} 
      onChange={changeFont}
      tooltipTitle={'字体样式'}
      selectStyle={{ width: '94px', marginRight: '10px' }}
      selectClassName={'option-bar-select'}
      selectTarget={'value'}
    />
  )
});
