import { DocItemType } from '../types';

import { AlignEnum } from './align';
import { FontFamilyEnum } from './font-family';
import { FontSizeEnum } from './font-size';

export const FontSizeConfig = {
  default: FontSizeEnum.fourSmall,
  [DocItemType.CP]: FontSizeEnum.fourSmall,
  [DocItemType.P]: FontSizeEnum.fourSmall,
  [DocItemType.H1]: FontSizeEnum.three,
  [DocItemType.H2]: FontSizeEnum.three,
  [DocItemType.H3]: FontSizeEnum.four,
  [DocItemType.H4]: FontSizeEnum.four,
  [DocItemType.H5]: FontSizeEnum.fourSmall,
  [DocItemType.H6]: FontSizeEnum.fourSmall,
  [DocItemType.TABLE_DESC]: FontSizeEnum.five,
  [DocItemType.TABLE_NAME]: FontSizeEnum.five,
};
export const FontFamilyConfig = {
  default: FontFamilyEnum.simHei,
  [DocItemType.CP]: FontFamilyEnum.simSun,
  [DocItemType.P]: FontFamilyEnum.simSun,
  [DocItemType.H1]: FontFamilyEnum.simHei,
  [DocItemType.H2]: FontFamilyEnum.simHei,
  [DocItemType.H3]: FontFamilyEnum.simHei,
  [DocItemType.H4]: FontFamilyEnum.simHei,
  [DocItemType.H5]: FontFamilyEnum.simHei,
  [DocItemType.H6]: FontFamilyEnum.simHei,
  [DocItemType.TABLE_DESC]: FontFamilyEnum.simSun,
  [DocItemType.TABLE_NAME]: FontFamilyEnum.simSun,
};
export const BoldConfig = {
  default: false,
  [DocItemType.CP]: false,
  [DocItemType.P]: false,
  [DocItemType.H1]: true,
  [DocItemType.H2]: true,
  [DocItemType.H3]: true,
  [DocItemType.H4]: true,
  [DocItemType.H5]: true,
  [DocItemType.H6]: true,
  [DocItemType.TABLE_DESC]: true,
  [DocItemType.TABLE_NAME]: true,
};
export const AlignConfig = {
  default: AlignEnum.left,
  [DocItemType.CP]: AlignEnum.left,
  [DocItemType.P]: AlignEnum.left,
  [DocItemType.H1]: AlignEnum.center,
  [DocItemType.H2]: AlignEnum.center,
  [DocItemType.H3]: AlignEnum.left,
  [DocItemType.H4]: AlignEnum.left,
  [DocItemType.H5]: AlignEnum.left,
  [DocItemType.H6]: AlignEnum.left,
  [DocItemType.TABLE_DESC]: AlignEnum.center,
  [DocItemType.TABLE_NAME]: AlignEnum.right,
};

export const ColorConfig = {
  default: '#000000',
};

export const BgColorConfig = {
  default: '#ffffff',
};
