import { CSSProperties, useState } from 'react';
import redux from './../../redux/store';
import { UserType } from '../../utils/types';
import { Image } from 'antd';
import { defaultAvatar } from '../../utils';

interface Props {
    size?: number
    style?: CSSProperties
    avatar?: string
    onClick?: any
}

const UserAvatar = (props: Props) => {

    const [userInfo, setuserInfo] = useState<UserType>(redux.getState().systemConfig.userInfo);
    const {
        size = 36,
        avatar = userInfo.avatar,
        onClick = () => {},
        style = {}
    } = props;

    return (
        // <div
        //     className='flex-row'
        //     style={{
        //         width: size + 4,
        //         height: size + 4,
        //         borderRadius: '50%',
        //         display: 'flex',
        //         border: '1px solid #d9d9d9',
        //         justifyContent: 'center!important',
        //         alignItems: 'center!important',
        //         boxSizing: 'border-box',
        //         padding: 0,
        //         alignContent: 'flex-start'
        //     }}
        // >
            <Image
                src={avatar == 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023032051978eacda-828c-4121-838e-2c0c1439ba6a30.png' ? defaultAvatar : avatar}
                fallback={defaultAvatar}
                onClick={onClick}
                // width={size}
                // height={size}
                style={{
                    ...{
                        border: '1px solid #d9d9d9',
                        flexShrink: 0,
                        borderRadius: '50%',
                        width: size,
                        height: size,
                        margin: 0,
                        padding: 0,
                        cursor: onClick ? 'pointer' : 'auto'
                    },
                    ...style
                }}
                preview={false}
            />
        // </div>
    )
}

export default UserAvatar;