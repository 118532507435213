import React from 'react';

import { useEmitSlateItemClick } from '../../hooks';
import { SlateElementProps } from '../../types';
import { createClassNameMark } from '../../utils';

import Style from './main.module.scss';

const classNames = createClassNameMark(Style);
export const TableNameParagraph = React.memo(function TableNameParagraph({ attributes, children, element }: SlateElementProps) {
  const onClick = useEmitSlateItemClick(element);
  return (
    <p {...attributes} data-doc-item-id={element.id} className={classNames('table-name-paragraph')} onClick={onClick}>
      {children}
    </p>
  );
});
