import React, { forwardRef, ReactElement, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { makeClassNameList } from '../../utils';

interface Props {
    contentRender: (props: { isLayoutShow: boolean, handleClose: Function }) => ReactNode
    clientY: number
    clientX: number
    disableBackground?: boolean
    animatedDirection?: 'TopBotom' | 'leftRight'
    onShow?: Function
    onHide?: Function
}


const CustomPopover = (props: Props, ref: any) => {

    useImperativeHandle(ref, () => ({
        show: show,
        hide: _handleClose,
        _handleClose: _handleClose,
        handleClosePopover: _handleClose,
    }))

    const delayTimer = useRef<any>(null);
    const customPopoverRef = useRef<any>(null);
    const customPopoverOverlayRef = useRef<any>(null);

    const [isLayoutShow, setIsLayoutShow] = useState<boolean>(false);

    const {
        onShow,
        onHide,
        contentRender,
        clientX,
        clientY,
        disableBackground = false,
        animatedDirection = 'TopBotom'
    } = props;

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current)
        }
    }, [])

    const show = () => {

        customPopoverOverlayRef.current.style.zIndex = 999999;
        customPopoverOverlayRef.current.style.width = '100%';
        customPopoverOverlayRef.current.style.height = '100%';
        customPopoverRef.current.style.zIndex = 999999
        setIsLayoutShow(true);
        onShow && onShow();
    }

    const hide = () => {
        handleClosePopover();
    };

    const handleClosePopover = () => {
        requestAnimationFrame(() => {
            setIsLayoutShow(false);
        })
        onHide && onHide();
        delayTimer.current = setTimeout(() => {
            customPopoverOverlayRef.current.style.zIndex = -1;
            customPopoverOverlayRef.current.style.width = '0%';
            customPopoverOverlayRef.current.style.height = '0%';
        }, 200);
    };

    const onClickPopoverContainer = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const onMouseDown = (e: any) => {
        // e.preventDefault();
        // e.stopPropagation();
    };

    const _handleClose = () => {
        handleClosePopover();
    };

    return (
        <div
            className='custom-popover-overlay'
            ref={customPopoverOverlayRef}
            onClick={handleClosePopover}
            onMouseDown={onMouseDown}
        >
            <div
                ref={customPopoverRef}
                style={{
                    top: clientY + 'px',
                    left: clientX + 'px',
                    'pointerEvents': 'all',
                    userSelect: 'auto',
                }}
                className={makeClassNameList([
                    'custom-popover',
                    isLayoutShow ? 'show' : null,
                    disableBackground ? 'custom-popover-no-background' : null,
                    animatedDirection == 'TopBotom' ? null : 'left-right-popover'
                ])}
                onClick={onClickPopoverContainer.bind(this)}
            >
                {contentRender && contentRender({ isLayoutShow, handleClose: _handleClose })}
            </div>
        </div>
    )
}


export default forwardRef(CustomPopover);