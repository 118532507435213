import React from 'react';

interface SlateEditorConext {
    onSlateInputDataChanged: Function
}


const slateEditorConext = React.createContext<SlateEditorConext>(null);


export default slateEditorConext;