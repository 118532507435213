import { useState } from 'react';

import { Editor, Element as SlateElement } from 'slate';

import { useOnToolbarRefresh } from '../../../hooks';
import { DocEditorRef, DocItemType, SlateDescendant } from '../../../types';

export const useMatchSlateItem = (editor?: DocEditorRef) => {
  const [slateItem, setSlateItem] = useState<SlateDescendant | null>(null);
  useOnToolbarRefresh(() => {
    const curEditor = editor?.current?.editor;
    if (!curEditor) setSlateItem(null);
    else {
      const { selection } = curEditor;
      if (!selection) return setSlateItem(null);
      const [match] = Array.from(
        Editor.nodes(curEditor, {
          at: Editor.unhangRange(curEditor, selection),
          match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n),
        }),
      );
      if (!match) return;
      const [, path] = match;
      const slateItem = curEditor.children[path?.[0]];
      setSlateItem(slateItem ?? null);
    }
  });
  if (slateItem && !('text' in slateItem)) return slateItem as Omit<SlateDescendant, 'type'> & { type: DocItemType };
  return null;
};
