import { TableDataCell } from "../../../types";
import { adaptDocMoneyCardinalNumberName, deepCloneV2, defaultProjectSubTableList, dfsRecursive, isEmpty, isNumber, parsePrice, protectlandProblemTableData, toastShort } from "../../../utils";
import { ColSpanInfo, DocConfig, DocInstance, PlanningBasisGroupType, RowSpanInfo, TopicType } from "../../../utils/types"

import { DocItem, DocItemType } from "../../slate-editor"
import { AlignEnum } from "../../slate-editor/constants";
import { getDeviceParagraphBlockList } from "../word-editor-deprecated/WordEditorHelper";
import { getRadomParagraphPlainText, getReplacedDocInfoPlainText } from "./editor-doc-item-generate";

const replaceLastCharWithPeriod = (text: string) => {
    if (text.length === 0) {
        return text; // 如果文本为空，直接返回
    }
    if (text.length > 0 && (text[text.length - 1] === '、' || text[text.length - 1] === '，' || text[text.length - 1] === ',')) {
        return text.substring(0, text.length - 1) + '。';
    }
    return text;
}

const getRandomThree = <T>(originList: T[]): T[] => {
    if (originList.length <= 3) {
        return originList;
    }
    let copyArr = [...originList];
    let result: T[] = [];
    for (let i = 0; i < 3; i++) {
        let randomIndex = Math.floor(Math.random() * copyArr.length);
        result.push(copyArr[randomIndex]);
        copyArr.splice(randomIndex, 1);
    }
    return result;
}

const generatePlanningYearsTopic = (topic: TopicType, comDocInstance: DocInstance): string => {
    const {
        protectland: {
            type,
            subType,
        },
        protectlandBaseInfo: {
            protectlandArea,
            protectlandAreaUnit = '公顷',
            protectlandLevel,
            protectlandName,
            protectlandProvince,
            protectlandTypeId,
            startYear,
            endYear
        }
    } = comDocInstance;
    if (startYear && endYear) {
        const startYearStr = new Date(startYear).getFullYear();
        const endYearStr = new Date(endYear).getFullYear();
        let newTopicName = `规划期限（${startYearStr}年—${endYearStr}年）`;
        const yearLen = Number(endYearStr) - Number(startYearStr) + 1;
        const centerYearStr = (((Number(endYearStr) - Number(startYearStr)) / 2) + Number(startYearStr)).toFixed(0);
        let yearRange1 = `${startYearStr}年—${centerYearStr}年`;
        let yearRange2 = `${Number(centerYearStr) + 1}年—${endYearStr}年`;
        let topicBackgroundPargraphText = `根据${getProtectlandTypeText(comDocInstance)}建设发展的现实需要，按照规划原则，确定${protectlandName}总体规划的期限为${yearLen}年，即${startYearStr}年—${endYearStr}年。分两期：其中，近期：${yearRange1}；远期：${yearRange2}。`
        return topicBackgroundPargraphText;
    }
    return '';
}

/**
 * 深度遍历指定树节点下级路径
 * @param topicTreeData 
 * @param childeDeviceType 
 * @returns 
 */
const findCheckedChildTopicList = (topicTreeData: TopicType[], childeDeviceType?: 'all' | 'S' | 'X'): TopicType[] => {
    let childTopicList: TopicType[] = [];
    topicTreeData.forEach(topic => {
        if (topic.topicType == 'text' && topic.children) {
            childTopicList = childTopicList.concat(findCheckedChildTopicList(topic.children, childeDeviceType))
        } else if (topic.topicType == 'device' && topic.checked) {
            if (
                childeDeviceType == 'all' || topic.deviceType == childeDeviceType
            ) {
                childTopicList.push(topic)
            }
        }
    })
    return childTopicList;
}

const findTargetTextTopicChildDeviceList = (
    topciTreeData: TopicType[],
    targetTextTopicName: string,
    childeDeviceType?: 'all' | 'S' | 'X'
): TopicType[] => {
    if (isEmpty(childeDeviceType)) {
        childeDeviceType = 'all'
    }
    let childDeviceList: TopicType[] = [];
    let hasFindTargetTopic = false;

    dfsRecursive(topciTreeData, (topic: TopicType, level: number) => {
        if (topic.topicType == 'text' && !hasFindTargetTopic) {
            if (topic.topicName === targetTextTopicName) {
                childDeviceList = findCheckedChildTopicList(topic.children, childeDeviceType);
                hasFindTargetTopic = true;
            }
        }
    })
    return childDeviceList;
};

function padNumberWithZeros(number, targetLength) {
    // 将数字转换为字符串
    let numberStr: string = number.toString();
    // 使用padEnd方法补零
    return numberStr.padEnd(targetLength, '0');
}


const getPercent = (value1: number, value2: number): string => {
    return (Number(value1) / Number(value2) * 100).toFixed(2) + '%'
}

/**
 * 
 * @param tempTopicTreeData 
 * @param newComDocInstance 
 * @param newComDocConfig 
 * @returns 
 */
const getInvestmentArrangementContent = (
    topic: TopicType,
    docInstance: DocInstance,
    docConfig: DocConfig,
    includeTableNameInfo: boolean,
    gridTableData: TableDataCell[][]
): {
    tableData: string[][],
    rowSpanInfoList: RowSpanInfo[],
    colSpanInfoList: ColSpanInfo[],
    tableName: string,
    paragraphTextList: string[],
    tableHeaderEndRowIndex: number
} => {
    try {
        console.log("getInvestmentArrangementContent--->")
        const paragraphTextList: string[] = [];
        const priceUnit = adaptDocMoneyCardinalNumberName(docConfig);
        const investmentArrangementTable: string[][] = [
            ["序号", "建设项目", "合计", "占建设投资的比例", "分项投资估算", "", "", "分期投资估算", ""],
            ["", "", "", "", "建筑", "设备", "其它", "近期", "中长期"],
        ];
        const colSpanInfoList: ColSpanInfo[] = [
            {
                rowIndex: 0,
                colIndex: 4,
                colSpan: 3,
            },
            {
                rowIndex: 0,
                colIndex: 7,
                colSpan: 2,
            },
        ]
        const rowSpanInfoList: RowSpanInfo[] = [
            {
                rowIndex: 0,
                colIndex: 0,
                rowSpan: 2,
            },
            {
                rowIndex: 0,
                colIndex: 1,
                rowSpan: 2,
            },
            {
                rowIndex: 0,
                colIndex: 2,
                rowSpan: 2,
            },
            {
                rowIndex: 0,
                colIndex: 3,
                rowSpan: 2,
            }
        ]
        let newGridTableDataContent = [...gridTableData].splice(5, gridTableData.length - 11);
        newGridTableDataContent = newGridTableDataContent.filter(cellRowList => {
            return cellRowList[0].serialNumber && cellRowList[0].serialNumber.split('.').length == 1
        })
        // console.log("gridTableData---->", gridTableData)
        if (gridTableData.length >= 6) {
            const totalRowData = [];
            const totalCellRowData = gridTableData[3];
            const constructionCostCellRowData = gridTableData[4];
            totalRowData.push('');
            totalRowData.push('合计');
            totalRowData.push(totalCellRowData[5] ? totalCellRowData[5].text : '');
            totalRowData.push('100%');
            //
            totalRowData.push(totalCellRowData[6].text);
            totalRowData.push(totalCellRowData[7].text);
            totalRowData.push(totalCellRowData[8].text);
            //
            totalRowData.push(totalCellRowData[9].text);
            totalRowData.push(totalCellRowData[10].text);
            investmentArrangementTable.push(totalRowData);
            newGridTableDataContent.forEach(cellRowList => {
                const rowData = [];
                rowData.push(cellRowList[0].serialNumber);
                rowData.push(cellRowList[1].text);
                rowData.push(cellRowList[5].text);
                rowData.push(getPercent(cellRowList[5].text, totalCellRowData[5].text));
                //
                rowData.push(cellRowList[6].text);
                rowData.push(cellRowList[7].text);
                rowData.push(cellRowList[8].text);
                //
                rowData.push(cellRowList[9].text);
                rowData.push(cellRowList[10].text);
                investmentArrangementTable.push(rowData)
            })
            const otherExpensesCellRowData = gridTableData[gridTableData.length - 6];
            const reserveFeeRowCellData = gridTableData[gridTableData.length - 1];
            const otherExpensesRowData = [];
            const reserveFeeRowData = [];
            otherExpensesRowData.push(otherExpensesCellRowData[0].text);
            otherExpensesRowData.push(otherExpensesCellRowData[1].text);
            otherExpensesRowData.push(otherExpensesCellRowData[5] ? otherExpensesCellRowData[5].text : '');
            otherExpensesRowData.push(getPercent(otherExpensesCellRowData[5].text, totalCellRowData[5].text));
            //
            otherExpensesRowData.push(otherExpensesCellRowData[6].text);
            otherExpensesRowData.push(otherExpensesCellRowData[7].text);
            otherExpensesRowData.push(otherExpensesCellRowData[8].text);
            //
            otherExpensesRowData.push(otherExpensesCellRowData[9].text);
            otherExpensesRowData.push(otherExpensesCellRowData[10].text);
            investmentArrangementTable.push(otherExpensesRowData);
            //预备费
            reserveFeeRowData.push(reserveFeeRowCellData[0].text);
            reserveFeeRowData.push(reserveFeeRowCellData[1].text);
            reserveFeeRowData.push(reserveFeeRowCellData[5] ? reserveFeeRowCellData[5].text : '');
            reserveFeeRowData.push(getPercent(reserveFeeRowCellData[5].text, totalCellRowData[5].text));
            //
            reserveFeeRowData.push(reserveFeeRowCellData[6].text);
            reserveFeeRowData.push(reserveFeeRowCellData[7].text);
            reserveFeeRowData.push(reserveFeeRowCellData[8].text);
            //
            reserveFeeRowData.push(reserveFeeRowCellData[9].text);
            reserveFeeRowData.push(reserveFeeRowCellData[10].text);
            investmentArrangementTable.push(reserveFeeRowData);
            const paragraphText1 = `经估算，项目建设投资额为${Number(investmentArrangementTable[2][2]).toFixed(2)}${priceUnit}，其中，工程建设费用${Number(investmentArrangementTable[3][2]).toFixed(2)}${priceUnit}，工程建设其他费用${Number(investmentArrangementTable[investmentArrangementTable.length - 2][2]).toFixed(2)}${priceUnit}，工程预备费${Number(investmentArrangementTable[investmentArrangementTable.length - 1][2]).toFixed(2)}${priceUnit}。`;
            paragraphTextList.push(paragraphText1);
            let paragraphText2 = '工程建设费用中，包括';
            newGridTableDataContent.forEach((rowDataList, rowIndex) => {
                const percent = parsePrice(rowDataList[5].text / constructionCostCellRowData[5].text) + '%';
                paragraphText2 += rowDataList[1].text + `${rowDataList[5].text}${priceUnit}，占投资的${percent}`;
                if (rowIndex < newGridTableDataContent.length - 1) {
                    paragraphText2 += '；';
                } else {
                    paragraphText2 += '。'
                }
            })
            const constructionCostPrcent = parsePrice((totalCellRowData[6].text / totalCellRowData[5].text).toFixed(2)) + '%';
            const equipmentInstallationPercent = parsePrice((totalCellRowData[7].text / totalCellRowData[5].text).toFixed(2)) + '%';
            const otherExpensesPercent = parsePrice((otherExpensesCellRowData[5].text / totalCellRowData[5].text).toFixed(2)) + '%';
            const paragraphText3 = `按资金用途分，建筑费${(totalCellRowData[6].text).toFixed(2)}${priceUnit}，占投资的${constructionCostPrcent}；设备及安装费${(totalCellRowData[7].text).toFixed(2)}${priceUnit}，占投资的${equipmentInstallationPercent}；其它费用${(otherExpensesCellRowData[5].text).toFixed(2)}${priceUnit}，占投资的${otherExpensesPercent}。`;
            const constructionCostPercent1 = parsePrice(constructionCostCellRowData[9].text / constructionCostCellRowData[5].text) + '%';
            const constructionCostPercent2 = parsePrice(constructionCostCellRowData[10].text / constructionCostCellRowData[5].text) + '%';
            let paragraphText4 = `按建设期限分，近期投资${(constructionCostCellRowData[9].text).toFixed(2)}${priceUnit}，占投资的${constructionCostPercent1}；中长期投资${(constructionCostCellRowData[10].text).toFixed(2)}${priceUnit}，占投资的${constructionCostPercent2} 。`;
            if (includeTableNameInfo) {
                paragraphText4 += `具体情况见表${topic.serialNumber.split('.')[0]}-1。`;
            }
            paragraphTextList.push(paragraphText2);
            paragraphTextList.push(paragraphText3);
            paragraphTextList.push(paragraphText4);
        }

        const investmentArrangementTableBefore: any[][] = [];
        const investmentArrangementTableAfter: any[][] = [];
        const totalRowListForBuilding: any[] = ["一", "工程费用", 0, 0, 0, 0, 0, 0, 0];

        investmentArrangementTable.forEach((rowList, rowIndex) => {
            if (rowIndex < 3) {
                investmentArrangementTableBefore.push(rowList);
            } else {
                investmentArrangementTableAfter.push(rowList);
            }
            if (rowIndex > 2 && rowIndex < investmentArrangementTable.length - 2) {
                totalRowListForBuilding[2] += Number(rowList[2]);
                totalRowListForBuilding[3] += Number(rowList[3].replace('%', ''));
                // totalRowListForBuilding[3] += Number(padNumberWithZeros(Number(rowList[3].replace('%', '')).toFixed(2), 2));
                totalRowListForBuilding[4] += Number(rowList[4]);
                totalRowListForBuilding[5] += Number(rowList[5]);
                totalRowListForBuilding[6] += Number(rowList[6]);
                totalRowListForBuilding[7] += Number(rowList[7]);
                totalRowListForBuilding[8] += Number(rowList[8]);
            }
        })
        // totalRowListForBuilding[3] = totalRowListForBuilding[3] + '%';
        totalRowListForBuilding[3] = padNumberWithZeros(Number(totalRowListForBuilding[3]).toFixed(2), 2) + '%';
        let _investmentArrangementTable: any[][] = investmentArrangementTableBefore;
        _investmentArrangementTable.push(totalRowListForBuilding);
        _investmentArrangementTable = _investmentArrangementTable.concat(investmentArrangementTableAfter);
        const newInvestmentArrangementTable = _investmentArrangementTable.map(row => {
            const rowList: string[] = row.map((cellText, colIndex) => {
                if (isNumber(cellText) && colIndex > 0) {
                    return padNumberWithZeros(Number(cellText).toFixed(2), 2)
                }
                //  else if(cellText.toString().include('%')){
                //     let numberStr = cellText.split('%')[0];
                //     return padNumberWithZeros(Number(numberStr).toFixed(2), 2) + '%';
                // }
                else {
                    return cellText;
                }
            })
            return rowList;
        })

        const investmentArrangementTableName = '表 投资项目构成汇总表';
        return {
            tableData: newInvestmentArrangementTable,
            rowSpanInfoList: rowSpanInfoList,
            colSpanInfoList: colSpanInfoList,
            tableName: investmentArrangementTableName,
            paragraphTextList: paragraphTextList,
            tableHeaderEndRowIndex: 1,
        }
    } catch (e) {
        console.log("getInvestmentArrangementContent--->err", e)
    }
}

/**
 * 生成投资计划安排章节
 * @param topic 
 * @param docInstance 
 * @param docConfig 
 * @returns 
 */
const generateInvestmentArrangementChapter = (
    topic: TopicType,
    docInstance: DocInstance,
    docConfig: DocConfig,
    gridTableData: TableDataCell[][]
): DocItem[] => {
    try {
        let tempSlateDocItemList: DocItem[] = [];
        const {
            tableData,
            rowSpanInfoList,
            colSpanInfoList,
            tableName,
            paragraphTextList,
            tableHeaderEndRowIndex
        } = getInvestmentArrangementContent(
            topic,
            docInstance,
            docConfig,
            true,
            gridTableData
        )
        paragraphTextList.forEach((paragraphText, index) => {
            tempSlateDocItemList.push({
                id: `${topic.id}-SP-0-${index}`,
                type: DocItemType.P,
                text: paragraphText
            })
        })
        tempSlateDocItemList.push({
            id: `${topic.id}-SP-1-1`,
            type: DocItemType.TABLE_NAME,
            text: `$$[0]`,
            textStyleMap: {
                "$$[0]": {
                    text: tableName,
                    fontFamily: 'SimSun',
                    bold: true,
                    fontSize: '10.5pt'
                }
            }
        })
        tempSlateDocItemList.push({
            id: `${topic.id}-SP-1-2`,
            type: DocItemType.TABLE_DESC,
            text: `$$[0]`,
            style: {
                align: AlignEnum.right,
            },
            textStyleMap: {
                "$$[0]": {
                    text: `单位：${adaptDocMoneyCardinalNumberName(docConfig)}`,
                    fontFamily: 'SimSun',
                    bold: true,
                    fontSize: '10.5pt'
                }
            }
        })
        tempSlateDocItemList.push({
            id: `${topic.id}-SP-1-3`,
            type: DocItemType.TABLE,
            text: tableName,
            data: {},
            externalData: {
                tableData,
                rowSpanInfoList,
                colSpanInfoList,
                tableHeaderEndRowIndex: tableHeaderEndRowIndex,
            }
        })
        return tempSlateDocItemList;
    } catch (e) {
        console.log("generateInvestmentArrangementChapter--->", e)
        return []
    }
}


const getProtectlandTypeText = (docInstance: DocInstance) => {
    try{
        const {
            protectland: {
                type,
                subType,
                subTypeStr
            }
        } = docInstance;
        return type;
    }catch(e){
        return '自然保护区'
    }
};

/**
 * 生成规划主要建设内容章节
 * (2024-02-20修改SlateDocItem格式)
 * @param topic 
 * @param _tempTopicTreeData 
 * @param topicTitleTagStatusConfig 
 * @returns 
 */
const generateMainContentsOfThePlanningChapter = (
    topic: TopicType,
    topicTreeData: TopicType[],
    docInstance: DocInstance,
    docConfig: DocConfig,
    gridTableData: TableDataCell[][]
): DocItem[] => {
    const protectLandType = getProtectlandTypeText(docInstance);
    let tempSlateDocItemList: DocItem[] = [];
    let newGridTableDataContent = [...gridTableData].splice(5, gridTableData.length - 11);
    newGridTableDataContent = newGridTableDataContent.filter(cellRowList => {
        return cellRowList[0].serialNumber && cellRowList[0].serialNumber.split('.').length == 1
    })
    newGridTableDataContent.forEach((tableCellRowGroup, tableCellRowGroupIndex) => {
        const tempSubTitleSerialNumber = topic.serialNumber + '.' + (tableCellRowGroupIndex + 1);
        const topLevelTopicName = tableCellRowGroup[1].text;
        if (['保护管理工程', '保护管理规划'].includes(topLevelTopicName)) {
            tempSlateDocItemList.push({
                id: `${topic.id}-SP-1`,
                text: `${tempSubTitleSerialNumber} ${topLevelTopicName}`,
                //@ts-ignore
                type: `h${topic.treeLevel < 6 ? topic.treeLevel + 1 : 6}`,
            });
            let descriptionParagraphText = `严格执行《${protectLandType}管理条例》；加强保护管理，在${protectLandType}边界、功能区界、周边社区人为活动较为频繁的交通道口、重点保护的野生动植物分布地段以及生态旅游区，设立标识系统。`;
            //开始生成确标定界章节切片
            const exactDemarcationDeviceTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "确标定界", 'S');
            if (exactDemarcationDeviceTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '开展确标定界，包括新建';
                exactDemarcationDeviceTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < exactDemarcationDeviceTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //开始生成保护管理体系章节切片
            const protectionAndManagementSystemTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "保护管理体系", 'S');
            if (protectionAndManagementSystemTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '完善保护管理体系，包括新建';
                protectionAndManagementSystemTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < protectionAndManagementSystemTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //开始生成野生植物保护规划章节切片
            const wildPlantConservationPlanningTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "野生植物保护规划", 'S');
            if (wildPlantConservationPlanningTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '加强国家重点保护动植物保护，进行植被恢复与栖息地恢复，开展';
                wildPlantConservationPlanningTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < wildPlantConservationPlanningTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //开始生成野生动物保护规划章节切片
            const wildPlantConservationAnimalTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "野生动物保护规划", 'S');
            if (wildPlantConservationAnimalTopicList.length) {
                if (wildPlantConservationPlanningTopicList.length === 0) {
                    descriptionParagraphText = descriptionParagraphText + '加强国家重点保护动植物保护，进行植被恢复与栖息地恢复，开展';
                }
                wildPlantConservationAnimalTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < wildPlantConservationAnimalTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //生态系统保护与修复规划
            const ecosystemProtectionAndRestorationPlanningTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "生态系统保护与修复规划", 'S');
            if (ecosystemProtectionAndRestorationPlanningTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '开展生态系统保护与修复，包括进行';
                ecosystemProtectionAndRestorationPlanningTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < ecosystemProtectionAndRestorationPlanningTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //防火规划-设施
            const firePreventionPlanningTopicListS = findTargetTextTopicChildDeviceList(topicTreeData, "防火规划", 'S');
            if (firePreventionPlanningTopicListS.length) {
                descriptionParagraphText = descriptionParagraphText + `提升${protectLandType}防火能力建设，新建`;
                firePreventionPlanningTopicListS.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < firePreventionPlanningTopicListS.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //防火规划-设备
            const firePreventionPlanningTopicListX = findTargetTextTopicChildDeviceList(topicTreeData, "防火规划", 'X');
            if (firePreventionPlanningTopicListX.length) {
                descriptionParagraphText = descriptionParagraphText + '购置';
                const tempRandomList = getRandomThree(firePreventionPlanningTopicListX);
                tempRandomList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < tempRandomList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //有害生物防治-设施
            const pestControlTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "有害生物防治", 'S');
            if (pestControlTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '开展有害生物防治，包括新建';
                pestControlTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < pestControlTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，';
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。';
                    }
                })
            }
            //环境保护规划
            const environmentalProtectionPlanningTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "有害生物防治", 'S');
            if (environmentalProtectionPlanningTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + `提升${protectLandType}环境质量，开展`;
                environmentalProtectionPlanningTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < environmentalProtectionPlanningTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，';
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。';
                    }
                })
            }
            tempSlateDocItemList.push({
                id: `${topic.id}-SP-2`,
                text: descriptionParagraphText,
                type: DocItemType.P,
            });
        }
        else if (['科研监测工程', '科研监测规划'].includes(topLevelTopicName)) {
            console.log("科研监测规划----->")
            tempSlateDocItemList.push({
                id: `${topic.id}-SP-3`,
                text: `${tempSubTitleSerialNumber} ${topLevelTopicName}`,
                //@ts-ignore
                type: `h${topic.treeLevel < 6 ? topic.treeLevel + 1 : 6}`,
            });
            let descriptionParagraphText = '';
            //科研研究规划
            const scientificResearchPlanningTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "科研研究规划", 'all');
            console.log("scientificResearchPlanningTopicList---->", scientificResearchPlanningTopicList)
            if (scientificResearchPlanningTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '开展';
                const tempRandomList = getRandomThree(scientificResearchPlanningTopicList);
                tempRandomList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < tempRandomList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '等科学研究；'
                    }
                })
            }
            //监测项目规划
            const monitoringProjectPlanningTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "监测项目规划", 'all');
            if (monitoringProjectPlanningTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '开展';
                const tempRandomList = getRandomThree(monitoringProjectPlanningTopicList);
                tempRandomList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < tempRandomList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '等监测项目，包括动植物的种类、生境调查等；'
                    }
                })
            }
            //监测项目规划
            let scientificResearchMonitoringFacilitiesAndEquipmentTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "监测项目规划", 'all');
            if(scientificResearchMonitoringFacilitiesAndEquipmentTopicList.length === 0){
                scientificResearchMonitoringFacilitiesAndEquipmentTopicList =  findTargetTextTopicChildDeviceList(topicTreeData, "调查监测项目规划", 'all');
            }
            if (scientificResearchMonitoringFacilitiesAndEquipmentTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + `提升${protectLandType}科研监测能力，新建`;
                scientificResearchMonitoringFacilitiesAndEquipmentTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < scientificResearchMonitoringFacilitiesAndEquipmentTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '；'
                    }
                })
                descriptionParagraphText += '加强科研队伍建设，包括引进科研人才，加强科研培训和交流，制定激励措施，优化科研环境等。';
            }
            // console.log("测试生成的descriptionParagraphText---->", descriptionParagraphText)
            tempSlateDocItemList.push({
                id: `${topic.id}-SP-4`,
                text: descriptionParagraphText,
                type: DocItemType.P,
            });
        }
        else if (['宣传教育工程', '宣传教育规划'].includes(topLevelTopicName)) {
            tempSlateDocItemList.push({
                id: `${topic.id}-SP-5`,
                text: `${tempSubTitleSerialNumber} ${topLevelTopicName}`,
                //@ts-ignore
                type: `h${topic.treeLevel < 6 ? topic.treeLevel + 1 : 6}`,
            });
            let descriptionParagraphText = '';
            const missionaryFacilitiesAndEquipmentTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "宣教设施设备", 'S');
            if (missionaryFacilitiesAndEquipmentTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + `提供${protectLandType}宣传教育能力，`;
                missionaryFacilitiesAndEquipmentTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < missionaryFacilitiesAndEquipmentTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '；'
                    }
                })
                descriptionParagraphText += '建立一批野外宣教点，健全教学和科研实践基地；制作专题宣传材料；开展社区居民的自然环境保护教育活动；加强职业培训教育等。';
            }
            tempSlateDocItemList.push({
                id: `${topic.id}-SP-6`,
                text: descriptionParagraphText,
                type: DocItemType.P,
            });
        }
        else if (['可持续发展工程', '可持续发展规划'].includes(topLevelTopicName)) {
            tempSlateDocItemList.push({
                id: `${topic.id}-SP-7`,
                text: `${tempSubTitleSerialNumber} ${topLevelTopicName}`,
                //@ts-ignore
                type: `h${topic.treeLevel < 6 ? topic.treeLevel + 1 : 6}`,
            });
            let descriptionParagraphText = '';
            const ecoTourismPlanningTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "生态旅游规划", 'S');
            if (ecoTourismPlanningTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + `完善社区共管机制，制定社区经济发展项目扶持计划，推动${protectLandType}原住居民生产生活方式转型，引导社区健康、稳定发展。引导${protectLandType}周边群众兴办生态旅游配套设施，发展生态旅游，规划建设`;
                ecoTourismPlanningTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < ecoTourismPlanningTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
                descriptionParagraphText += '开展资源合理利用，优化社区产业结构模式。';
            }
            tempSlateDocItemList.push({
                id: `${topic.id}-SP-8`,
                text: descriptionParagraphText,
                type: DocItemType.P,
            });
        }
        else if (['基础设施工程', '基础设施规划'].includes(topLevelTopicName)) {
            tempSlateDocItemList.push({
                id: `${topic.id}-SP-9`,
                text: `${tempSubTitleSerialNumber} ${topLevelTopicName}`,
                //@ts-ignore
                type: `h${topic.treeLevel < 6 ? topic.treeLevel + 1 : 6}`,
            });
            let descriptionParagraphText = '';
            //局站址建设规划
            const constructionPlanningOfBureauStationSiteTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "局站址建设规划", 'S');
            if (constructionPlanningOfBureauStationSiteTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + `${protectLandType}的基础设施工程主要包括`;
                constructionPlanningOfBureauStationSiteTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < constructionPlanningOfBureauStationSiteTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //绿化美化规划
            const greeningAndBeautificationPlanningTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "绿化美化规划", 'S');
            if (greeningAndBeautificationPlanningTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '进行绿化美化，开展';
                greeningAndBeautificationPlanningTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < greeningAndBeautificationPlanningTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //局站址建设规划-设备
            const constructionPlanningOfBureauStationSiteTopicListX = findTargetTextTopicChildDeviceList(topicTreeData, "局站址建设规划", 'X');
            if (constructionPlanningOfBureauStationSiteTopicListX.length) {
                descriptionParagraphText = descriptionParagraphText + '购置基础办公设备、生活设备，包括';
                const tempRandomList = getRandomThree(constructionPlanningOfBureauStationSiteTopicListX);
                tempRandomList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < tempRandomList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //医疗救护规划
            const disasterPreventionAndMitigationPlanningTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "防灾减灾规划", 'S');
            if (disasterPreventionAndMitigationPlanningTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '提升防灾减灾能力，建设';
                disasterPreventionAndMitigationPlanningTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < disasterPreventionAndMitigationPlanningTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //排水规划
            const drainagePlanningTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "排水规划", 'S');
            if (drainagePlanningTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '建立';
                const tempRandomList = getRandomThree(drainagePlanningTopicList);
                tempRandomList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < tempRandomList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //供电通讯规划
            const powerSupplyCommunicationPlanningTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "供电通讯规划", 'S');
            if (powerSupplyCommunicationPlanningTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '建立';
                const tempRandomList = getRandomThree(powerSupplyCommunicationPlanningTopicList);
                tempRandomList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < tempRandomList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //道路建设规划
            const roadConstructionPlanningTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "道路建设规划", 'S');
            if (roadConstructionPlanningTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + '建立';
                const tempRandomList = getRandomThree(roadConstructionPlanningTopicList);
                tempRandomList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < tempRandomList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            //智慧保护区
            const wisdomReserveTopicList = findTargetTextTopicChildDeviceList(topicTreeData, "智慧保护区", 'S');
            if (wisdomReserveTopicList.length) {
                descriptionParagraphText = descriptionParagraphText + `提升${protectLandType}智慧化、数字化建设，建立`;
                wisdomReserveTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    descriptionParagraphText = descriptionParagraphText + `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`
                    if (deviceTopicIndex < wisdomReserveTopicList.length - 1) {
                        descriptionParagraphText = descriptionParagraphText + '，'
                    } else {
                        descriptionParagraphText = descriptionParagraphText + '。'
                    }
                })
            }
            tempSlateDocItemList.push({
                id: `${topic.id}-SP-999`,
                text: descriptionParagraphText,
                type: DocItemType.P,
            });
        }
    })
    return tempSlateDocItemList;
}

/**
 * 生成规划目标章节
 * @param topic 
 * @param newComDocInstance 
 * @returns 
 */
const generatePlanningGoalChapter = (
    topic: TopicType,
    docInstance: DocInstance
): DocItem[] => {
    const protectLandType = getProtectlandTypeText(docInstance);
    const {
        topicList,
        protectlandBaseInfo: {
            protectlandName
        }
    } = docInstance;
    let tempDocItemList: DocItem[] = [];
    if (topic.topicName == '总体目标') {
        let descriptionParagraphText = `以生态环境建设和可持续发展的国家基本战略为指导，遵循自然规律，以科技为支撑，以资金投入为保障，以保护管理、科研监测、宣传教育设施建设为重点，加强森林生态系统保护，保持区内自然生态系统和自然景观资源的完整性，维护生态环境系统平衡；妥善处理自然保护与当地社区经济建设、居民生产生活的关系，促进区域社会经济的繁荣与稳定，充分发挥${protectLandType}的多功能效益。把${protectlandName}逐步建设成为功能区划合理、基础设施完备、管理科学高效、科研监测先进、社区经济协调发展，并具中国特色和国际影响力的${protectLandType}。`;
        tempDocItemList.push({
            id: `${topic.id}-SP-0-1`,
            type: DocItemType.P,
            text: descriptionParagraphText
        })
    } else if (topic.topicName === '近期目标') {
        tempDocItemList.push({
            id: `${topic.id}-SP-0-2`,
            type: DocItemType.P,
            text: `近期着重进行保护工程、科研监测工程以及基础设施工程，用较短时间建立其较为完备的自然保护体系，建成森林生态系统完整、生态环境稳定的${protectLandType}。具体要实现以下几点：`
        })
        tempDocItemList.push({
            id: `${topic.id}-SP-0-3`,
            type: DocItemType.P,
            text: `1)逐步开展植被恢复，提高水源涵养能力，扩大生物生存空间。`
        })
        tempDocItemList.push({
            id: `${topic.id}-SP-0-4`,
            type: DocItemType.P,
            text: `2)加强科研队伍建设，作好科研监测工作，为珍稀、濒危物种的保存和繁衍创造条件。`
        })
        tempDocItemList.push({
            id: `${topic.id}-SP-0-5`,
            type: DocItemType.P,
            text: `3)加快${protectLandType}机构建设和职工队伍建设步伐，尽快形成较为完善的保护管理体系。`
        })
        tempDocItemList.push({
            id: `${topic.id}-SP-0-6`,
            type: DocItemType.P,
            text: `4)搞好${protectLandType}基础设施建设，使保护工程系统畅通无阻顺利进展。`
        })
        tempDocItemList.push({
            id: `${topic.id}-SP-0-7`,
            type: DocItemType.P,
            text: `5)在保护的前提下，积极探索${protectLandType}自然资源合理利用的途径。`
        })
        let descriptionParagraphText = `近期开展的工程包括`;
        const nearFutureDeviceTopicList = topicList.filter(topic => {
            return (
                topic.topicType == 'device' &&
                topic.isMainProjectChcked &&
                // Number(topic.nearFutureMoneyRateOfValue) >= Number(topic.mediumAndLongTermMoneyRateOfValue)
                Number(topic.nearFutureMoneyRate) >= Number(topic.mediumAndLongTermMoneyRate)
            )
        })
        nearFutureDeviceTopicList.forEach((deviceTopic, deviceTopicIndex) => {
            if (deviceTopicIndex < 10) {
                descriptionParagraphText += `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`;
                if (deviceTopicIndex < 9) {
                    descriptionParagraphText += '，';
                } else {
                    descriptionParagraphText += '等。';
                }
            }
        })
        descriptionParagraphText = replaceLastCharWithPeriod(descriptionParagraphText);
        // console.log("nearFutureDeviceTopicList---->", descriptionParagraphText)
        tempDocItemList.push({
            id: `${topic.id}-SP-0-8`,
            type: DocItemType.P,
            text: descriptionParagraphText
        })
    } else if (topic.topicName === '远期目标' || topic.topicName === '中长期目标') {
        tempDocItemList.push({
            id: `${topic.id}-SP-0-9`,
            type: DocItemType.P,
            text: `远期深入开展科研、保护和宣教活动，进一步完善基础设施配套建设，通过不断加强与国际国内学术交流与技术合作，提高经营管理和科学研究水平，更好的保护${protectLandType}的生态环境、生物资源及其生态过程，实现自然生态系统良性循环。积极探索资源可持续发展的有效途径，合理地利用环境资源发展经济，最终实现区域资源、环境与社会经济的可持续发展。将${protectLandType}建设成为生态环境优美、资源保护完好、管理科学、效益显著、和谐发展的${protectLandType}。具体包括：`
        })
        tempDocItemList.push({
            id: `${topic.id}-SP-0-10`,
            type: DocItemType.P,
            text: `1)基础配套工程建设全部完成，道路改造升级全面完成，资源保护和管理系统达到先进水平。`
        })
        tempDocItemList.push({
            id: `${topic.id}-SP-0-11`,
            type: DocItemType.P,
            text: `2)科研设施设备达到先进水平，科研人员每年都进行国际交流，科研人员整体素质得到提高，科研能力迅速增强，科研项目和经费得到充分保证，规范科研信息和成果，建立科技成果数据库，完善科研监测体系。`
        })
        tempDocItemList.push({
            id: `${topic.id}-SP-0-12`,
            type: DocItemType.P,
            text: `3)宣传教育内容丰富多彩，形式多种多样，不断提高社区群众的保护意识，调动社区群众积极参与保护工作，使群众掌握了一定的自然保护和科学技术知识，使环保意识和法律观念深入人心。`
        })
        tempDocItemList.push({
            id: `${topic.id}-SP-0-13`,
            type: DocItemType.P,
            text: `4)通过生态旅游基础设施的建设和多种经营项目的实施，提高${protectLandType}经济发展能力。`
        })
        let descriptionParagraphText = `中长期完成的工程包括`;
        const farFutureDeviceTopicList = topicList.filter(topic => {
            return (
                topic.topicType == 'device' &&
                topic.isMainProjectChcked &&
                // Number(topic.mediumAndLongTermMoneyRateOfValue) >= Number(topic.nearFutureMoneyRateOfValue)
                Number(topic.mediumAndLongTermMoneyRate) >= Number(topic.nearFutureMoneyRate)
            )
        })
        farFutureDeviceTopicList.forEach((deviceTopic, deviceTopicIndex) => {
            if (deviceTopicIndex < 10) {
                descriptionParagraphText += `${deviceTopic.topicName}${deviceTopic.count}${deviceTopic.unit}`;
                if (deviceTopicIndex < 9) {
                    descriptionParagraphText += '，';
                } else {
                    descriptionParagraphText += '等。';
                }
            }
        })
        descriptionParagraphText = replaceLastCharWithPeriod(descriptionParagraphText);
        tempDocItemList.push({
            id: `${topic.id}-SP-0-14`,
            type: DocItemType.P,
            text: descriptionParagraphText
        })
    }
    return tempDocItemList;
}

const generateProtectlandProblemChapter = (
    topic: TopicType,
    docInstance: DocInstance
) => {
    let tempDocItemList: DocItem[] = [];
    const {
        protectlandBaseInfo: {
            protectlandName,
            protectlandProblemList
        }
    } = docInstance;
    if (protectlandProblemList.length) {
        const protectlandProblemValueStr = protectlandProblemList[0];
        const protectlandProblemValueList = protectlandProblemValueStr.split(',');
        protectlandProblemValueList.forEach((value, index) => {
            const findProblem = protectlandProblemTableData.find(protectlandProblem => {
                return protectlandProblem.value == value;
            })
            if (findProblem) {
                tempDocItemList.push({
                    id: `${topic.id}-SP-0-1`,
                    text: `（${index + 1}） ${getReplacedDocInfoPlainText(findProblem.problemDetails, docInstance)}`,
                    type: DocItemType.H5
                })
                const problemBackgroundParagraphList: string[] = [];
                if (!isEmpty(findProblem.backgroundParagraph1)) {
                    problemBackgroundParagraphList.push(findProblem.backgroundParagraph1)
                }
                if (!isEmpty(findProblem.backgroundParagraph2)) {
                    problemBackgroundParagraphList.push(findProblem.backgroundParagraph2)
                }
                if (!isEmpty(findProblem.backgroundParagraph3)) {
                    problemBackgroundParagraphList.push(findProblem.backgroundParagraph3)
                }
                if (!isEmpty(findProblem.backgroundParagraph4)) {
                    problemBackgroundParagraphList.push(findProblem.backgroundParagraph4)
                }
                const {
                    randomText
                } = getRadomParagraphPlainText(problemBackgroundParagraphList);
                tempDocItemList.push({
                    id: `${topic.id}-SP-0-2`,
                    type: DocItemType.P,
                    text: getReplacedDocInfoPlainText(randomText, docInstance)
                })
            }
        })
    }
    return tempDocItemList;
}

const findTextTopicChildTopicListOneLayer = (
    topciTreeData: TopicType[],
    targetTextTopicName: string,
) => {
    let childDeviceList: TopicType[] = [];
    let hasFindTargetTopic = false;

    dfsRecursive(topciTreeData, (topic: TopicType, level: number) => {
        if (topic.topicType == 'text' && !hasFindTargetTopic) {
            if (topic.topicName === targetTextTopicName) {
                childDeviceList = topic.children;
                hasFindTargetTopic = true;
            }
        }
    })
    return childDeviceList;
}

/**
 * 深度遍历指定树节点下级路径
 * @param topicTreeData 
 * @param childeDeviceType 
 * @returns 
 */
const findMainProjectCheckedChildTopicList = (topicTreeData: TopicType[]): TopicType[] => {
    let childTopicList: TopicType[] = [];
    topicTreeData.forEach(topic => {
        if (topic.topicType == 'text' && topic.children) {
            childTopicList = childTopicList.concat(findMainProjectCheckedChildTopicList(topic.children))
        } else if (topic.topicType == 'device' && topic.checked && topic.isMainProjectChcked) {
            if (
                topic.isMainProjectChcked
            ) {
                childTopicList.push(topic)
            }
        }
    })
    return childTopicList;
}

/**
 * 
 * @param topciTreeData 
 * @param targetTextTopicName 
 * @returns 
 */
const findMainProjectCheckedDeviceTopicList = (
    topciTreeData: TopicType[],
    targetTextTopicName: string,
): TopicType[] => {
    let childDeviceList: TopicType[] = [];
    let hasFindTargetTopic = false;

    dfsRecursive(topciTreeData, (topic: TopicType, level: number) => {
        if (topic.topicType == 'text' && !hasFindTargetTopic) {
            if (topic.topicName === targetTextTopicName) {
                childDeviceList = findMainProjectCheckedChildTopicList(topic.children);
                hasFindTargetTopic = true;
            }
        }
    })

    return childDeviceList;
}

/**
 * 
 * @param childrenDeviceTopicList 
 * @returns 
 */
const spliceChildrenDeviceTopicList = (
    childrenDeviceTopicList: TopicType[]
): TopicType[][] => {
    let deviceTopicGroupList: TopicType[][] = [];
    childrenDeviceTopicList.forEach((deviceTopic, index) => {
        if (deviceTopicGroupList.length == 0) {
            deviceTopicGroupList.push([deviceTopic]);
        } else {
            const lastIndex = deviceTopicGroupList.length - 1;
            const lastSubIndex = deviceTopicGroupList[lastIndex].length - 1;
            if (deviceTopicGroupList[lastIndex].length >= 2) {
                if (deviceTopic.deviceType == 'X') {
                    deviceTopicGroupList[lastIndex].push(deviceTopic);
                } else {
                    deviceTopicGroupList.push([deviceTopic])
                }
            } else {
                deviceTopicGroupList[lastIndex].push(deviceTopic);
            }
        }
    })
    return deviceTopicGroupList
}

/**
 * 
 * @param tree 
 * @param targetId 
 * @returns 
 */
const extractLeafDeviceTopicList = (tree: TopicType[]): TopicType[] => {
    // let leafNodes = [];

    // function isLeafNode(node: TopicType) {
    //     return node.topicType == 'device';
    // }

    // function dfs(node: TopicType) {
    //     if (node.id) {
    //         collectLeafNodes(node);
    //     } else if (node.children) {
    //         for (let child of node.children) {
    //             dfs(child);
    //         }
    //     }
    // }

    // function collectLeafNodes(node) {
    //     if (isLeafNode(node)) {
    //         leafNodes.push(node);
    //     } else if (node.children) {
    //         for (let child of node.children) {
    //             collectLeafNodes(child);
    //         }
    //     }
    // }
    // for (let node of tree) {
    // }
    try {
        let leafNodes = [];
        dfsRecursive(tree, (topic: TopicType, level: number) => {
            if (topic.topicType == 'device') {
                leafNodes.push(topic)
            }
        })
        return leafNodes;
    } catch (e) {
        console.log("提取失败设备叶子节点失败---->", e)
        return [];
    }

}

const createTableOfKeyProjects = (
    tempTopicTreeData: TopicType[]
): DocItem => {
    let tableDocItem: DocItem = {
        id: ``,
        type: DocItemType.TABLE,
        externalData: {
            colSpanInfoList: [
                {
                    colIndex: 2,
                    colSpan: 2,
                    rowIndex: 0
                },
                {
                    colIndex: 4,
                    colSpan: 2,
                    rowIndex: 0
                },
            ],
            rowSpanInfoList: [
                {
                    rowIndex: 0,
                    colIndex: 0,
                    rowSpan: 2,
                },
                {
                    rowIndex: 0,
                    colIndex: 1,
                    rowSpan: 2,
                }
            ]
        }
    };
    let tableData = [
        ['分类', '工程项目名称', '建设规模', '', '建设期限', ''],
        ['', '', '单位', '数量', '近期', '远期'],
    ]
    // console.log("tempTopicTreeData---->", tempTopicTreeData)
    dfsRecursive(tempTopicTreeData, (topic: TopicType, level: number) => {
        if (level == 1 && topic.children && topic.children.length) {
            let deviceTopicList = extractLeafDeviceTopicList(topic.children);
            deviceTopicList = deviceTopicList.filter(ele => {
                return ele.isMainProjectChcked && ele.checked;
            })
            if (deviceTopicList.length) {
                let tempRowSpanInfo: RowSpanInfo = {
                    rowIndex: tableData.length,
                    rowSpan: deviceTopicList.length,
                    colIndex: 0
                }
                tableDocItem.externalData.rowSpanInfoList.push(tempRowSpanInfo);
                deviceTopicList.forEach((deviceTopic, deviceTopicIndex) => {
                    tableData.push([
                        deviceTopicIndex == 0 ? topic.topicName : '',
                        deviceTopic.topicName,
                        deviceTopic.unit,
                        deviceTopic.count,
                        deviceTopic.nearFutureMoneyRate && Number(deviceTopic.nearFutureMoneyRate) > 0 ? '√' : '',
                        deviceTopic.mediumAndLongTermMoneyRate && Number(deviceTopic.mediumAndLongTermMoneyRate) > 0 ? '√' : '',
                    ])
                })
            }
        }
    })
    console.log("重点工程表数据---->", tableData)
    tableDocItem.externalData.tableData = deepCloneV2(tableData);
    return tableDocItem;
}


/**
 * 
 * @param topic 
 * @param docInstance 
 * @param docConfig 
 * @returns 
 */
const generateonKeyProjectsChapter = (
    topic: TopicType,
    docInstance: DocInstance,
    tempTopicTreeData: TopicType[],
    originTableData: TableDataCell[]
): DocItem[] => {
    try {
        const tempDocInstance: DocInstance = { ...docInstance };
        let tempDocItemList: DocItem[] = [];
        const {
            protectlandBaseInfo: {
                protectlandName
            }
        } = docInstance;
        const planningTopicCellList = originTableData.filter(tableCell => {
            return (
                tableCell.bound[1] >= 5 &&
                tableCell.bound[0] == 1 &&
                tableCell.serialNumber.split('.').length == 1 &&
                !tableCell.id.includes('-')
            )
        })
        let titleTopicList: TopicType[] = [];
        const tempTopicList: TopicType[] = [...tempDocInstance.topicList];
        planningTopicCellList.forEach(tableCell => {
            const findTextTopic = tempTopicList.find(item => {
                return item.id == tableCell.id;
            })
            if (findTextTopic) {
                const findTopicChildList = findTextTopicChildTopicListOneLayer(tempTopicTreeData, findTextTopic.topicName);
                findTopicChildList.forEach(subTextTopic => {
                    const mainProjectCheckedChildTopicList = findMainProjectCheckedDeviceTopicList(tempTopicTreeData, subTextTopic.topicName);
                    if (mainProjectCheckedChildTopicList.length) {
                        subTextTopic.subTopicList = mainProjectCheckedChildTopicList;
                    } else {
                        subTextTopic.subTopicList = [];
                    }
                })
                const hasMainProjectCheckedChildSubTopicList = findTopicChildList.filter(subTextTopic => {
                    return subTextTopic.subTopicList.length;
                })
                if (hasMainProjectCheckedChildSubTopicList.length) {
                    findTextTopic.subTopicList = hasMainProjectCheckedChildSubTopicList;
                    titleTopicList.push(findTextTopic);
                }
            }
        })
        titleTopicList.forEach((textTopic, textTopicIndex) => {
            const textTopicTitlePlainText = `${topic.serialNumber}.${textTopicIndex + 1}   ${textTopic.topicName}`;
            tempDocItemList.push({
                id: `${topic.id}-SP-main-project-title-${textTopicIndex}-1`,
                text: textTopicTitlePlainText,
                type: DocItemType.H2
            })
            textTopic.subTopicList.forEach((subTextTopic, subTextTopicIndex) => {
                const subTextTopicTitlePlainText = `${topic.serialNumber}.${textTopicIndex + 1}.${subTextTopicIndex + 1}   ${subTextTopic.topicName}`;
                tempDocItemList.push({
                    id: `${topic.id}-SP-main-project-sub-title-${textTopicIndex}-${subTextTopicIndex}-2`,
                    text: subTextTopicTitlePlainText,
                    type: DocItemType.H3
                })
                const deviceTopicGroupList = spliceChildrenDeviceTopicList(subTextTopic.subTopicList);
                //设施文本分段生成，2个设施的文本生成一段，设备文本单独生成一段或者合并到设施文本中(采用并入的方式)
                deviceTopicGroupList.forEach((deviceTopicGroup, deviceTopicGroupIndex) => {
                    const {
                        deviceParagraphBlockList,
                        newNextEntityKey
                    } = getDeviceParagraphBlockList(topic, deviceTopicGroup, 1, docInstance, 'generateonKeyProjectsChapter');
                    let deviceParagraphPlainText = '';
                    deviceParagraphBlockList.forEach(deviceParagraph => {
                        deviceParagraphPlainText += deviceParagraph.text;
                    })
                    tempDocItemList.push({
                        id: `${topic.id}-SP-main-project-device-${textTopicIndex}-${subTextTopicIndex}-${deviceTopicGroupIndex}`,
                        type: DocItemType.P,
                        text: deviceParagraphPlainText
                    })
                })
            })
        })
        const topicSortNumber = topic.serialNumber.split('.')[0];
        // 表 [NAME]主要建设项目规划表
        tempDocItemList.push({
            id: `${topic.id}-SP-0-4-main-project-table-name`,
            type: DocItemType.TABLE_NAME,
            text: `$$[0]`,
            textStyleMap: {
                "$$[0]": {
                    // text: `表${topicSortNumber}-1 ${protectlandName}主要建设项目规划表`,
                    text: `表 主要建设项目规划表`,
                    fontFamily: 'SimSun',
                    bold: true,
                    fontSize: '11.2pt'
                }
            }
        })
        const tableOfKeyProjects = createTableOfKeyProjects(tempTopicTreeData)
        tableOfKeyProjects.id = `${topic.id}-SP-0-4-main-project-table`;
        tableOfKeyProjects.text = '表格'
        tempDocItemList.push(tableOfKeyProjects);
        return tempDocItemList;
    } catch (e) {
        console.error("生成重点工程失败---->", e)
    }
}
/**
 * 对于一些特殊的文本topic，需要系统自动生成一些段落
 * 1、
 * @param topic 
 */
const initOrUpdateSystemChapter = (
    topic: TopicType,
    docInstance: DocInstance,
    docConfig: DocConfig,
    //避免重复计算
    tempTopicTreeData: TopicType[],
    originTableData: TableDataCell[],
    gridTableData: TableDataCell[][]
): {
    topicSystemDocItemList: DocItem[],
    hasChangedSystemChapter: boolean
} => {
    try {
        let hasChangedSystemChapter = false;
        //保护地基本信息
        let {
            protectlandBaseInfo: {
                protectlandExtraInfo
            }
        } = docInstance;
        let tempSystemDocItemList: DocItem[] = [];
        if (isEmpty(protectlandExtraInfo)) {
            protectlandExtraInfo = {
                projectSubTableList: defaultProjectSubTableList,
                supplementaryInformation: {},
                protectedNaturalEnvironment: {}
            }
        }
        if (topic.topicName == '规划主要建设内容') {
            tempSystemDocItemList = generateMainContentsOfThePlanningChapter(
                topic,
                tempTopicTreeData,
                docInstance,
                docConfig,
                gridTableData
            );
        }
        //  else if (topic.topicName == '规划期限') {
        //     const planningYearsParagraphText = generatePlanningYearsTopic(topic, docInstance);
        //     tempSystemDocItemList = [{
        //         id: `${topic.id}-SP-0-1`,
        //         type: DocItemType.P,
        //         text: planningYearsParagraphText
        //     }]
        // } 
        else if (topic.topicName == '投资计划安排') {
            tempSystemDocItemList = generateInvestmentArrangementChapter(
                topic,
                docInstance,
                docConfig,
                gridTableData
            );
        } else if (topic.topicName == '规划投资') {
            const {
                paragraphTextList,
            } = getInvestmentArrangementContent(
                topic,
                docInstance,
                docConfig,
                false,
                gridTableData
            )
            if (paragraphTextList.length) {
                paragraphTextList.forEach((text, index) => {
                    tempSystemDocItemList.push({
                        id: `${topic.id}-SP-${index}`,
                        type: DocItemType.P,
                        text: text,
                    })
                })
            }
        } else if (topic.topicName == '存在问题与对策') {
            tempSystemDocItemList = generateProtectlandProblemChapter(
                topic,
                docInstance
            )
        } else if (topic.topicName == '重点工程' && topic.topicLevel === '1') {
            tempSystemDocItemList = generateonKeyProjectsChapter(
                topic,
                docInstance,
                tempTopicTreeData,
                originTableData,
            )
        }
        // const planningGoalChapterTopicNameList = ['总体目标', '近期目标', '远期目标', '中长期目标'];
        // if (planningGoalChapterTopicNameList.includes(topic.topicName)) {
        //     const tempDocItemList = generatePlanningGoalChapter(
        //         topic,
        //         docInstance
        //     )
        //     if (tempDocItemList.length) {
        //         tempSystemDocItemList = tempSystemDocItemList.concat(tempDocItemList);
        //     }
        // }
        if (tempSystemDocItemList.length) {
            hasChangedSystemChapter = true;
        }
        tempSystemDocItemList = tempSystemDocItemList.filter(item => {
            if (item.type == DocItemType.P && isEmpty(item.text)) {
                return null;
            }
            return item;
        })
        return {
            topicSystemDocItemList: tempSystemDocItemList,
            hasChangedSystemChapter
        }
    } catch (e) {
        toastShort("error", "生成系统章节失败" + e);
        return {
            topicSystemDocItemList: [],
            hasChangedSystemChapter: false,
        }
    }

}

export {
    initOrUpdateSystemChapter
}