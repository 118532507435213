import React from 'react';

import { BoldConfig, FontFamilyConfig, FontSizeConfig } from '../../constants';
import { useEmitSlateItemClick } from '../../hooks';
import { SlateElementProps } from '../../types';
import { createClassNameMark } from '../../utils';

import Style from './main.module.scss';
const classNames = createClassNameMark(Style);
export const Heading2 = React.memo(function Heading2({ attributes, children, element, style }: SlateElementProps) {
  const onClick = useEmitSlateItemClick(element);
  return (
    <h2
      {...attributes}
      style={{
        ...style,
        fontSize: FontSizeConfig.h2,
        fontFamily: FontFamilyConfig.h2,
        fontWeight: BoldConfig.h2 ? 'bold' : undefined,
      }}
      data-doc-item-id={element.id}
      className={classNames('doc-heading2')}
      onClick={onClick}
    >
      {children}
    </h2>
  );
});
