import React from 'react';
import { WordParagraphBlock } from '../../../utils/types';

interface Props {
    wordParagraphBlock: WordParagraphBlock
}

const stylesMap = {
    'ITALIC': { fontStyle: 'italic' },
    'TimesNewRoman': { fontFamily: 'Times New Roman' },
    'normal_inline_style': { /* 你的默认样式 */ },
};

function computeStyleObjects(text, inlineStyleRanges) {
    const styleObjects = Array(text.length).fill(null).map(() => ({}));

    for (let range of inlineStyleRanges) {
        let { offset, length, style } = range;
        let cssStyle = stylesMap[style] || {};

        for (let i = offset; i < offset + length; i++) {
            styleObjects[i] = { ...styleObjects[i], ...cssStyle };
        }
    }

    return styleObjects;
}

const WordPararagphBlockRender = (props: Props) => {
    const {
        wordParagraphBlock
    } = props;

    const {
        text,
        inlineStyleRanges
    } = wordParagraphBlock;

    const styleObjects = computeStyleObjects(text, inlineStyleRanges);

    let lastIndex = 0;
    const textElements = [];

    for (let i = 1; i <= text.length; i++) {
        if (i === text.length || JSON.stringify(styleObjects[i]) !== JSON.stringify(styleObjects[i - 1])) {
            textElements.push(
                <span
                    key={lastIndex}
                    style={styleObjects[i - 1]}
                >
                    {text.slice(lastIndex, i)}
                </span>
            );
            lastIndex = i;
        }
    }

    return (
        <div className='custom_paragraph_6_style'>
            {textElements}
        </div>
    );
};

export default WordPararagphBlockRender;
