import React, { forwardRef, useState, useImperativeHandle, ChangeEvent, useRef, useContext } from 'react';
// import './../../login/Login.scss';
// import './../EditPage.scss';
import { Segmented, Spin, Tabs, Checkbox, Button, Input, Table, Space, Form, Select, Radio, Image, Upload, DatePicker, Divider, DatePickerProps, TreeSelect, Switch } from 'antd';
import type { TabsProps } from 'antd';
import { deepClone, isEmpty, deepCopy, formatTime, formatYear, getChangeEventValue, provinceList, toastShort } from '../../../utils';
import { EconomicPerformanceTableCell, ProtectlandBaseInfoType, ProtectlandType, WordParagraphBlock } from '../../../utils/types';
import type { ColumnsType } from 'antd/es/table';
import comDocContext from '../../../context/DocContext';
import { replaceLastCharWithPeriod } from '../word-editor-deprecated/WordEditorHelper';
import { convertWordParagraphListToDocItemList } from '../word-editor/editor-doc-item-generate';

interface Props {
    insertDocItemByTargetTopicName: Function
};


const BenefitEvaluationContent2 = (props: Props) => {

    const {
        comDocInstance,
    } = useContext(comDocContext);

    const {
        insertDocItemByTargetTopicName
    } = props;

    const columns: ColumnsType<EconomicPerformanceTableCell> = [
        {
            title: '效益类型',
            dataIndex: 'type',
            width: 120,
            onCell: (_, index) => {
                if (index == 0) {
                    return { rowSpan: 8 };
                }
                if (index == 8) {
                    return { rowSpan: 4 };
                }
                if (index == 12) {
                    return { rowSpan: 1 };
                }
                if (
                    (index > 0 && index < 8) ||
                    (index > 8 && index < 12) ||
                    (index > 12 && index < 13)
                ) {
                    return { colSpan: 0 };
                }
            },
            align: 'center',
        },
        {
            title: '指标',
            dataIndex: 'target',
            width: 160,
            align: 'center',
        },
        {
            title: '现状值',
            dataIndex: 'actuality',
            width: 120,
            align: 'center',
            render: (value: any, record: any, index: number) => {
                return (
                    <>
                        {
                            (index >= 0 && index <= 6) ||
                                (index >= 8 && index <= 10)
                                ?
                                <input
                                    className='table-inline-input'
                                    value={value}
                                    placeholder={'请输入'}
                                    onChange={(e) => onChangeTableData('actuality', index, e)}
                                />
                                :
                                <span>
                                    {
                                        value ?
                                            value
                                            :
                                            <span style={{ color: '#909399' }}>自动计算</span>
                                    }
                                </span>
                        }
                    </>
                )
            }
        },
        {
            title: '规划值',
            dataIndex: 'plan',
            width: 120,
            align: 'center',
            render: (value: any, record: any, index: number) => {
                return (
                    <>
                        {
                            (index >= 0 && index <= 6) ||
                                (index >= 8 && index <= 10)
                                ?
                                <input
                                    className='table-inline-input'
                                    value={value}
                                    placeholder={'请输入'}
                                    onChange={(e) => onChangeTableData('plan', index, e)}
                                />
                                :
                                <span>
                                    {
                                        value ?
                                            value
                                            :
                                            <span style={{ color: '#909399' }}>自动计算</span>
                                    }
                                </span>
                        }
                    </>
                )
            }
        },
        {
            title: '效益增量',
            dataIndex: 'benefit',
            width: 120,
            align: 'center',
            render: (value: any, record: any, index: number) => {
                return (
                    <span>
                        {
                            value ?
                                value
                                :
                                <span style={{ color: '#909399' }}>自动计算</span>
                        }
                        {/* {getRowDataBenefit} */}
                    </span>
                )
            }
        }

    ];

    const [tableData, setTableData] = useState<EconomicPerformanceTableCell[]>([
        {
            key: '1',
            value: "1-1",
            type: '直接经济收益',
            target: '生态旅游收益',
            actuality: '',
            plan: '',
            benefit: '',
        },
        {
            key: '2',
            value: "1-1",
            type: '直接经济收益',
            target: '科学研究收益',
            actuality: '',
            plan: '',
            benefit: '',
        },
        {
            key: '3',
            value: "1-1",
            type: '直接经济收益',
            target: '文化教育收益',
            actuality: '',
            plan: '',
            benefit: '',
        },
        {
            key: '4',
            value: "1-1",
            type: '直接经济收益',
            target: '农业产品收益',
            actuality: '',
            plan: '',
            benefit: '',
        },
        {
            key: '5',
            value: "1-1",
            type: '直接经济收益',
            target: '生态养殖收益',
            actuality: '',
            plan: '',
            benefit: '',
        },
        {
            key: '6',
            value: "1-1",
            type: '直接经济收益',
            target: '种草药种植收益',
            actuality: '',
            plan: '',
            benefit: '',
        },
        //
        {
            key: '7',
            value: "1-1",
            type: '直接经济收益',
            target: '风(水)能发电收益',
            actuality: '',
            plan: '',
            benefit: '',
        },
        {
            key: '8',
            value: "1-1",
            type: '直接经济收益',
            target: '小计',
            actuality: '',
            plan: '',
            benefit: '',
        },
        {
            key: '9',
            value: "1-1",
            type: '间接经济收益',
            target: '森林木材保护价值',
            actuality: '',
            plan: '',
            benefit: '',
        },
        {
            key: '10',
            value: "1-1",
            type: '间接经济收益',
            target: '珍惜木材保护价值',
            actuality: '',
            plan: '',
            benefit: '',
        },
        //
        {
            key: '11',
            value: "1-1",
            type: '间接经济收益',
            target: '野生木材保护价值',
            actuality: '',
            plan: '',
            benefit: '',
        },
        {
            key: '12',
            value: "1-1",
            type: '间接经济收益',
            target: '小计',
            actuality: '',
            plan: '',
            benefit: '',
        },
        //
        {
            key: '13',
            value: "1-1",
            type: '总计',
            target: '',
            actuality: '',
            plan: '',
            benefit: '',
        },
    ]);

    const onChangeTableData = (type: string, rowIndex, e: ChangeEvent<HTMLInputElement>) => {
        const newValue = getChangeEventValue(e)
        let _tableData = tableData;
        let rowData: EconomicPerformanceTableCell = _tableData[rowIndex];
        rowData[type] = newValue;
        if (rowData.actuality && rowData.plan) {
            rowData.benefit = (Number(rowData.plan) - Number(rowData.actuality)).toString();
        }
        let totalActuality1 = 0;  //小记1
        let totalActuality2 = 0;  //小记2
        let totalPlan1 = 0;  //小记1
        let totalPlan2 = 0;  //小记2
        _tableData.forEach((rowData, rowIndex) => {
            if (rowIndex < 7) {
                totalPlan1 = totalPlan1 + Number(rowData.plan);
                totalActuality1 = totalActuality1 + Number(rowData.actuality);
            }
            if (rowIndex > 7 && rowIndex < 11) {
                totalPlan2 = totalPlan2 + Number(rowData.plan);
                totalActuality2 = totalActuality2 + Number(rowData.actuality);
            }
            if (rowIndex === 7) {
                rowData.plan = totalPlan1 + '';
                rowData.actuality = totalActuality1 + '';
                rowData.benefit = (Number(rowData.plan) - Number(rowData.actuality)).toString();
            }
            if (rowIndex === 11) {
                rowData.plan = totalPlan2 + '';
                rowData.actuality = totalActuality2 + '';
                rowData.benefit = (Number(rowData.plan) - Number(rowData.actuality)).toString();
            }
            if (rowIndex === 12) {
                rowData.plan = (Number(totalPlan1) + Number(totalPlan2)).toString()
                rowData.actuality = (Number(totalActuality1) + Number(totalActuality2)).toString();
                rowData.benefit = (Number(rowData.plan) - Number(rowData.actuality)).toString();
            }
        })
        setTableData(deepCopy(_tableData))
    }

    const wordParagraphList: WordParagraphBlock[] = [
        {
            type: 'text',
            text: '自然保护事业是利在千秋的公益性事业，是一项具有长期性、战略性的生态建设工程，是国家自然保护网络建设的组成部分。从长远和整体的眼光来看，自然保护区的建设将会产生巨大的生态效益、社会效益，但通过合理的、适度的建设和发展，以保护优先、适度利用为原则，也能产生较高的经济效益。在不影响保护目标实施的前提下，保护区经济效益主要表现在两大方面：直接经济效益和间接经济效益。'
        },
        {
            type: 'text',
            text: '自然保护区的直接经济效益主要来源于生态旅游和多种经营收入。这些收入主要用来提高保护区自养能力，解决保护区职工生活困难，适当提高职工福利，稳定职工队伍。保护区优美的自然环境和丰富的自然景观资源，是进行生态旅游的理想场所，生态旅游将会成为自然保护区的重要收入来源；自然保护区也将发展特色种植业、养殖业等多种经营产业，使保护区经济来源得到拓展，同时还带动保护区内及其周边地区的经济繁荣。随着保护区的建设，保护区实力迅速壮大，加强多种直接经济效益，形成以旅游业为主、多种经营产业为辅完整产业链，将显著提高保护区多种经营效益，大大提高保护区自养功能，间接提高保护区的保护管理水平，同时带动周边社区共同发展，为保护区发展提供有力支持。'
        },
        {
            type: 'text',
            text: '自然保护区建设间接经济效益也是十分巨大的，主要表现在：保护大量国家重点保护野生物种、提高区域内生态环境质量、降低生态灾难的发生以及区内自然生态系统所产生的对人类有益的全部效益。通过本次规划设计，保护措施进一步加强，保护建设工程进一步完善，科研工作进一步深入开展，将会取得更大的保护成效，它所产生的价值是难以用金钱来度量的。'
        },
    ];

    const checkdConfimBtnDisabled = (): boolean => {
        let disabled = false;
        const {
            protectlandBaseInfo: {
                protectlandName,
            }
        } = comDocInstance;
        let validTableData = tableData.filter(row => {
            return row.actuality && row.plan;
        })
        if (validTableData.length == 0) {
            disabled = true;
        }
        return disabled;
    }

    const handleGenerateEconomicBenefit = () => {
        // console.log("handleGenerateEconomicBenefit---->", tableData);
        const {
            protectlandBaseInfo: {
                protectlandName,
            }
        } = comDocInstance;
        let validTableData = tableData.filter(row => {
            return row.actuality && row.plan;
        })
        if (validTableData.length == 0) {
            return toastShort('warning', '请先完善经济效益汇总表')
        }
        const PT = tableData[12].actuality;
        const PT1 = tableData[7].actuality;
        const PT2 = tableData[11].actuality;
        //
        const T = tableData[12].plan;
        const T1 = tableData[7].plan;
        const T2 = tableData[11].plan;
        //
        const DT = tableData[12].benefit;
        const DT1 = tableData[7].benefit;
        const DT2 = tableData[11].benefit;
        //
        let descText1 = `按照${protectlandName}的建设要求，将逐步完善各项管理措施，建设完备的防护设施，创造更好的生态旅游环境，保护保护区内的自然资产，所获得的经济效益也将显著增加。初步估算，`;
        if (!isEmpty(PT) && PT != '0') {
            descText1 += `${protectlandName}的直接和间接经济价值总和现状值为${PT}万元，`;
        }
        if (!isEmpty(PT1) && PT1 != '0') {
            descText1 += `包括直接经济效益为${PT1}万元，`;
        }
        if (!isEmpty(PT2) && PT2 != '0') {
            descText1 += `间接经济效益为${PT2}万元；`
        }
        if (!isEmpty(T) && T != '0') {
            descText1 += `规划后的经济效益总之将达到${T}万元，`
        }
        if (!isEmpty(T1) && T1 != '0') {
            descText1 += `其中直接经济效益为${T1}万元，`
        }
        if (!isEmpty(T2) && T2 != '0') {
            descText1 += `间接经济效益为${T2}万元；`
        }
        if (!isEmpty(DT) && DT != '0') {
            descText1 += `与规划前相比，经济效益总共增加了${DT}万元，`
        }
        if (
            !isEmpty(DT1) && DT1 != '0'
            //要判断
            && DT2 != '0'
        ) {
            descText1 += `其中直接经济效益增加了${DT1}万元，`
        }
        if (
            !isEmpty(DT2) && DT2 != '0'
            && DT1 != '0'
        ) {
            descText1 += `间接经济效益增加了${DT2}万元；`
        }
        //
        let descText2 = `${protectlandName}的直接经济效益主要来自`;
        let descText3 = '其中';
        let descText4 = `${protectlandName}的间接经济效益包括`;
        let descText5 = `其中`;
        const wordTableData = [['效益类型', '指标', '现状值', '规划值', '效益增量']];
        let _tableRowSpanList = [
            {
                rowIndex: 1,
                colIndex: 0,
                rowSpan: 1
            },
            {
                rowIndex: 9,
                colIndex: 0,
                rowSpan: 1
            },
        ];
        let _tableColSpanList = [
            {
                rowIndex: 0,
                colIndex: 0,
                colSpan: 2,
            }
        ]
        tableData.forEach((rowData, rowIndex) => {
            console.log("rowData--->", rowData)
            let wordTableDataRow = [];
            if (rowIndex == 0 || rowIndex == 8 || rowIndex == 12) {
                wordTableDataRow.push(rowData.type);
            } else {
                wordTableDataRow.push(null);
            }
            wordTableDataRow.push(rowData.target);
            wordTableDataRow.push(rowData.actuality);
            wordTableDataRow.push(rowData.plan);
            wordTableDataRow.push(rowData.benefit);
            if (
                !isEmpty(rowData.actuality) && !isEmpty(rowData.plan)
            ) {
                wordTableData.push(wordTableDataRow);
            }
            if (
                !isEmpty(rowData.actuality) && !isEmpty(rowData.plan) &&
                Number(rowData.actuality) !== 0 && Number(rowData.plan) !== 0
            ) {
                if (rowIndex >= 0 && rowIndex <= 6) {
                    _tableRowSpanList[0].rowSpan++;
                    if (rowIndex < 6) {
                        descText2 = descText2 + rowData.target + '、';
                        descText3 = descText3 + `${rowData.target}的现状值为${rowData.actuality}万元，规划后将达到${rowData.plan}万元，较规划前增加了${rowData.benefit}万元；`
                    } else {
                        descText2 = descText2 + rowData.target + '7个方面。';
                        descText3 = descText3 + `${rowData.target}的现状值为${rowData.actuality}万元，规划后将达到${rowData.plan}万元，较规划前增加了${rowData.benefit}万元。`
                    }
                } else if (rowIndex === 7) {

                } else if (rowIndex > 7 && rowIndex <= 10) {
                    if (
                        !isEmpty(rowData.actuality) && !isEmpty(rowData.plan) &&
                        Number(rowData.actuality) !== 0 && Number(rowData.plan) !== 0
                    ) {
                        _tableRowSpanList[1].rowSpan++;
                        if (rowIndex < 10) {
                            descText4 = descText4 + rowData.target + '、';
                            descText5 = descText5 + `${rowData.target}的现状值为${rowData.actuality}万元，规划后将达到${rowData.plan}万元，较规划前增加了${rowData.benefit}万元；`
                        } else {
                            descText4 = descText4 + rowData.target + '3个方面。';
                            descText5 = descText5 + `${rowData.target}的现状值为${rowData.actuality}万元，规划后将达到${rowData.plan}万元，与规划前相比，增加了${rowData.benefit}万元。`
                        }
                    }
                }
            }
        })
        _tableRowSpanList[1].rowIndex = _tableRowSpanList[0].rowSpan + 1;
        _tableColSpanList[0].rowIndex = wordTableData.length - 1;
        wordTableData.forEach((rowList, rowIndex) => {
            if (isEmpty(rowList[0])) {
                if (rowIndex < _tableRowSpanList[1].rowIndex) {
                    rowList[0] = "直接经济收益"
                } else {
                    rowList[0] = "间接经济收益"
                }
            }
        })
        descText2 = descText2 + descText3;
        descText4 = descText4 + descText5;
        let _wordParagraphList: WordParagraphBlock[] = deepCopy(wordParagraphList)
        _wordParagraphList.push({
            type: 'text',
            text: replaceLastCharWithPeriod(descText1)
        })
        const directEconomyValidTableData = tableData.filter((row, rowIndex) => {
            return row.actuality && row.plan && (rowIndex >= 0 && rowIndex <= 6)
        })
        const indirectEconomyValidTableData = tableData.filter((row, rowIndex) => {
            return row.actuality && row.plan && (rowIndex > 7 && rowIndex <= 10)
        })
        // console.log("descText2--->", descText2)
        // console.log("descText4--->", descText4)
        if (
            directEconomyValidTableData.length &&
            !descText2.endsWith("的直接经济效益主要来自其中")
        ) {
            _wordParagraphList.push({
                type: 'text',
                text: replaceLastCharWithPeriod(descText2)
            })
        }
        if (
            indirectEconomyValidTableData.length &&
            !descText4.endsWith("间接经济效益包括其中")
        ) {
            _wordParagraphList.push({
                type: 'text',
                text: replaceLastCharWithPeriod(descText4)
            })
        }
        const tableName = `表 ${protectlandName}经济效益`;
        _wordParagraphList.push({
            type: 'text',
            text: tableName,
            isSubTable: true,
            //@ts-ignore
            inlineStyleRanges: [{ offset: 0, length: tableName.length, style: 'normal_inline_style' }],
            paragraphStyle: 'table_name_paragraph',
            paragraphType: 'custom_background_paragraph'
        })
        _wordParagraphList.push({
            type: 'text',
            text: '单位：万元',
            isSubTable: true,
            //@ts-ignore
            inlineStyleRanges: [{ offset: 0, length: tableName.length, style: 'normal_inline_style' }],
            paragraphStyle: 'table_desc_paragraph',
            paragraphType: 'custom_background_paragraph'
        })
        _wordParagraphList.push({
            type: 'Table',
            text: '表格',
            tableData: wordTableData,
            tableName: tableName,
            tableRowSpanList: _tableRowSpanList,
            tableColSpanList: _tableColSpanList,
        })
        const docItemList = convertWordParagraphListToDocItemList(_wordParagraphList);
        insertDocItemByTargetTopicName &&
            insertDocItemByTargetTopicName([{
                topicName: "经济效益",
                docItemList: docItemList
            }])
    }

    return (
        <div className='benefit-container word-editor-plugins-content benefit-evaluation-content'>
            <div className='benefit-container-title'>经济效益汇总表（单位：万元）</div>
            <Table
                className='benefit-container-table'
                columns={columns}
                dataSource={tableData}
                pagination={false}
                size={'small'}
                bordered
            />
            <Button
                type={'primary'}
                style={{ width: '100%', marginTop: 24 }}
                onClick={handleGenerateEconomicBenefit}
                disabled={checkdConfimBtnDisabled()}
                size={'middle'}
            >确定</Button>
        </div>
    )
}

export default BenefitEvaluationContent2;