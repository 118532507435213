import React from 'react';

interface Props {
    marginRight?: number
    marginBottom?: number
    name?: string
    color?: string
    defaultBorderColor?: string
    onClick?: Function
}

interface State {
}

export default class ColorPickerItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    };

    public readonly state: Readonly<State> = {
    };

    _onClick(){
        const {
            color,
            name,
            onClick
        } = this.props;
        onClick && onClick(color, name);
    };

    render() {
        const {
            marginRight = 5,
            marginBottom = 5,
            name,
            color
        } = this.props;
        return (
            <div
                className='color-picker-item flex-row'
                style={{ marginRight: marginRight + 'px', marginBottom: marginBottom + 'px' }}
                onClick={this._onClick.bind(this)}
            >
                <div className='color-picker-item-content flex-row' style={{ borderColor: '#ddd' }}>
                    {
                        color ?
                            <div
                                className='color-picker-item-box'
                                style={{
                                    backgroundColor: color,
                                    backgroundImage: 'none'
                                }}
                                title={name}
                            >
                            </div>
                            :
                            <div
                                className='color-picker-item-box'
                            >
                            </div>
                    }
                </div>
            </div>
        )
    }
}