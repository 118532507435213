import { forwardRef, useContext, useEffect, useRef, useState } from "react"
import OptionBarBtn from "../../../components/option-bar-btn/OptionBarBtn";
import SelectWidthToolip from "../../../components/select/SelectWidthToolip";
import { deepCopy, defaultExcelCellStyleConfig, excelFontFamilyOptionList, findExcelStyleByCell, fontSizeTemplateList, getObjectWithIntersectionAttributes, handleStopPropagation, inlineStyleTemplateList, mergeCellConfigList, sortOptions } from "../../../utils";
import { InlineStyleConfig, SelectOptionType, TableDataCell } from "../../../types";
import comDocContext from '../../../context/DocContext';
import { CellStyleConfig, DocConfig, InlineStyleType } from "../../../utils/types";
import { isEmpty } from "lodash";

interface Props {
    currentCellStyleConfig: CellStyleConfig
    onDispathCellStyle: Function
    focusTableCellList: TableDataCell[],
    updateCurrentCellStyleConfig: Function
}

const ExcelInlineStyleOptionBar = (props: Props, ref: any) => {

    const {
        comDocConfig,
    } = useContext(comDocContext);

    const {
        onDispathCellStyle,
        focusTableCellList,
        currentCellStyleConfig,
        updateCurrentCellStyleConfig
    } = props;

    const [fontFamilyOptions, setFontFamilyOptions] = useState(deepCopy(excelFontFamilyOptionList));
    const [fontSizeOptions, setFontSizeOption] = useState(deepCopy(fontSizeTemplateList));

    const tempDocConfig = useRef<DocConfig>(null);
    const tempFocusTableCellList = useRef<TableDataCell[]>(null)


    useEffect(() => {
        tempDocConfig.current = comDocConfig;
    }, [comDocConfig]);

    useEffect(() => {
        tempFocusTableCellList.current = focusTableCellList;
    }, [focusTableCellList])

    const onInlineFontFamilyChange = (e) => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.inlineFontFamily = e;
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        dispathCellStyle('inlineFontFamily', e);
    }

    const onInlineFontSizeChange = (e) => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.inlineFontSize = e;
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        dispathCellStyle('inlineFontSize', e);
    }

    const onInlineFontBoldAplay = () => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.inlineFontBold = !Boolean(tempInlineStyle.inlineFontBold);
        dispathCellStyle('inlineFontBold', tempInlineStyle.inlineFontBold ? 1 : 0);
        handleSetCurrentCellStyleConfig(tempInlineStyle);
    }

    const onInlineFontItalicAplay = () => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.inlineFontItalic = !Boolean(tempInlineStyle.inlineFontItalic);
        dispathCellStyle('inlineFontItalic', tempInlineStyle.inlineFontItalic ? 1 : 0);
        requestAnimationFrame(() => {
            handleSetCurrentCellStyleConfig(tempInlineStyle);
        })
    }

    const onInlineFontUnderLineAplay = () => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.inlineFontUnderLine = !Boolean(tempInlineStyle.inlineFontUnderLine);
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        dispathCellStyle('inlineFontUnderLine', 1);
    }

    const onInlineFontDeleteLineAplay = () => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.inlineFontDeleteLine = !Boolean(tempInlineStyle.inlineFontDeleteLine);
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        dispathCellStyle('inlineFontDeleteLine', 1);
    }

    const dispathCellStyle = (styleType: InlineStyleType, styleValue: number | string) => {
        console.log("dispathCellStyle--->", styleType, styleValue);
        onDispathCellStyle && onDispathCellStyle({ styleType, styleValue });
    }

    const handleSetCurrentCellStyleConfig = (newCellStyleConfig: CellStyleConfig) => {
        updateCurrentCellStyleConfig && updateCurrentCellStyleConfig(newCellStyleConfig);
    }

    useEffect(()=> {
        console.log("行内工具栏接收到的样式配置--->", currentCellStyleConfig)
    },[currentCellStyleConfig]);

    const checkInlineTypeActiveNotEmpty = (type: InlineStyleType): boolean => {
        let isActive = false;
        isActive = !isEmpty(currentCellStyleConfig[type]);
        return isActive;
    }

    const checkInlineTypeActiveBoolean = (type: InlineStyleType):boolean => {
        let isActive = false;
        isActive = Boolean(currentCellStyleConfig[type]);
        return isActive;
    }

    return (
        <div 
            className='edit-page-option-row-item flex-col' 
            style={{ width: '182px', paddingLeft: '12px' }}
            onMouseDown={handleStopPropagation}
        >
            <div className='edit-page-option-row-half flex-row'>
                <SelectWidthToolip
                    size={'small'}
                    value={currentCellStyleConfig.inlineFontFamily}
                    options={sortOptions(fontFamilyOptions) }
                    tooltipTitle={'字体'}
                    tooltipDesc={'设置不同的字体'}
                    selectStyle={{ width: '94px', marginRight: '10px' }}
                    selectClassName={'option-bar-select'}
                    onChange={onInlineFontFamilyChange}
                />
                <SelectWidthToolip
                    size={'small'}
                    value={currentCellStyleConfig.inlineFontSize}
                    options={fontSizeOptions}
                    tooltipTitle={'字号大小'}
                    // tooltipDesc={'⌥ ⌘ + / -'}
                    selectStyle={{ width: '68px' }}
                    selectOptionStyle={{ width: '62px' }}
                    selectClassName={'option-bar-select'}
                    onChange={onInlineFontSizeChange}
                />
            </div>
            <div className='edit-page-option-row-half text-edit-row-1 flex-row'>
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-jiacu' style={{ fontSize: 18, fontWeight: 'bold' }} />}
                    size={'small'}
                    onClick={onInlineFontBoldAplay}
                    tooltipTitle={'粗体'}
                    // tooltipDesc={'⌥ ⌘ B'}
                    active={checkInlineTypeActiveBoolean('inlineFontBold')}
                />
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-xieti1' style={{ fontSize: 18, fontWeight: '500' }} />}
                    size={'small'}
                    onClick={onInlineFontItalicAplay}
                    tooltipTitle={'斜体'}
                    // tooltipDesc={'⌥ ⌘ I'}
                    active={checkInlineTypeActiveBoolean('inlineFontItalic')}
                />
                <OptionBarBtn
                    icon={(
                        <div className='custom-option-bar-icon-container'>
                            <i className='iconfont ghzs-color-a-zu736' style={{ fontSize: 18, fontWeight: 'bold' }} />
                            <div
                                className='custom-option-bar-icon-underline'
                                // style={{ backgroundColor: currentCellStyleConfig.inlineFontColor }}
                            ></div>
                        </div>
                    )}
                    size={'small'}
                    onClick={onInlineFontUnderLineAplay}
                    tooltipTitle={'下划线'}
                    // tooltipDesc={'⌥ ⌘ U'}
                    active={checkInlineTypeActiveBoolean('inlineFontUnderLine')}
                />
                <OptionBarBtn
                    icon={(
                        <div className='custom-option-bar-icon-container'>
                            <i className='iconfont ghzs-color-a-zu7381' style={{ fontSize: 20, fontWeight: 'bold' }} />
                            <div
                                className='custom-option-bar-icon-deleteline'
                                // style={{ backgroundColor: currentCellStyleConfig.inlineFontColor }}
                            ></div>
                        </div>
                    )}
                    size={'small'}
                    onClick={onInlineFontDeleteLineAplay}
                    tooltipTitle={'删除线'}
                    // tooltipDesc={'⌥ ⌘ D'}
                    active={checkInlineTypeActiveBoolean('inlineFontDeleteLine')}
                />
            </div>
        </div>
    )
}


export default forwardRef(ExcelInlineStyleOptionBar);