import { ReactNode, forwardRef, useImperativeHandle, useRef } from "react"
import './ContextMenu.scss';

interface Props {
    width?: number,
    height?: number & 'auto',
    children: ReactNode
    onContextMenuHide?: Function
    disableOverlay?: boolean
    useLayoutOffsetType?: 'client' | 'offset'
    diffOffsetY?: number
    diffOffsetX?: number
}

const ContextMenuWrapper = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        showContextMenu,
        hideContextMenu
    }))

    const {
        width = 200,
        height = 'auto',
        children,
        onContextMenuHide,
        disableOverlay = false,
        useLayoutOffsetType = 'client',
        diffOffsetY = 0,
        diffOffsetX = 0
    } = props;

    const contextMenuOverlayRef = useRef<any>(null);
    const contextMenuContentRef = useRef<any>(null);

    const showContextMenu = (e) => {
        const { clientX, clientY, } = e;
        // console.log("showContextMenu---->11111", e, clientX, clientY)
        if (contextMenuOverlayRef.current) {
            contextMenuOverlayRef.current.className = 'context-menu-overlay show';
        } else {
            contextMenuContentRef.current.className = 'context-menu-content-without-overlay show';
        }
        if (useLayoutOffsetType == 'client') {
            contextMenuContentRef.current.style.top = clientY + 'px';
            contextMenuContentRef.current.style.left = clientX + 'px';
        } else {
            const x = clientX - diffOffsetX;
            const y = clientY - diffOffsetY;
            contextMenuContentRef.current.style.top = y + 'px';
            contextMenuContentRef.current.style.left = x + 'px'
        }
    }

    const hideContextMenu = () => {
        handleHideContextMenu();
    }

    const onMouseDown = (e) => {
        onContextMenuHide && onContextMenuHide();
        if (!disableOverlay) {
            contextMenuOverlayRef.current.className = 'context-menu-overlay hide';
        } else {
            contextMenuContentRef.current.className = 'context-menu-content-without-overlay hide';
        }
        contextMenuContentRef.current.style.top = 0;
        contextMenuContentRef.current.style.left = 0;
        e.preventDefault();
        e.stopPropagation();
    }

    const handleHideContextMenu = (e?: any) => {
        onContextMenuHide && onContextMenuHide();
        if (!disableOverlay) {
            contextMenuOverlayRef.current.className = 'context-menu-overlay hide';
        } else {
            contextMenuContentRef.current.className = 'context-menu-content-without-overlay hide';
        }
        contextMenuContentRef.current.style.top = 0;
        contextMenuContentRef.current.style.left = 0;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onClickContextMenuWrapper = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleHideContextMenu();
    }

    if (disableOverlay) {
        return (
            <div
                ref={contextMenuContentRef}
                className="context-menu-content-without-overlay hide"
                style={{ width, height }}
                onClick={onClickContextMenuWrapper}
            >
                {children}
            </div>
        )
    }
    return (
        <div
            ref={contextMenuOverlayRef}
            className="context-menu-overlay"
            onMouseDown={onMouseDown}
            onContextMenu={handleHideContextMenu}
        >
            <div
                className="context-menu-wrapper"
            >
                <div
                    ref={contextMenuContentRef}
                    className="context-menu-content"
                    style={{ width, height }}
                    onClick={onClickContextMenuWrapper}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}

export default forwardRef(ContextMenuWrapper)