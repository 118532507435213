import { Button, Tabs, Empty, Alert } from "antd";
import React, { CSSProperties, ReactNode, forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import comDocContext from '../../../context/DocContext';
import { convertDraftModelToSliceModel, generateDeviceParagraphSlice, getEditorBlockParagraphTypeByBlockKey, replaceDocInfo } from "../word-editor-deprecated/WordEditorHelper";
import { deepCloneV2, isEmpty } from "../../../utils";
import { DocInstance, TopicType, WordParagraphBlock, WordParagraphBlockSlice, WordParagraphBlockStatus } from "../../../utils/types";
import { SnippetsOutlined } from "@ant-design/icons";
import { ContentBlock } from "draft-js";
import EmptyWapper from "../../../components/loading/EmptyWapper";
import { WordEditStyleType } from "../../../types";
import { DocItem } from "../../slate-editor";

interface Props {
    handleSetFocusDocItem: Function
}

const WordEditorParagraphCheck = (props: Props, ref: any) => {

    const {
        comDocInstance,
        _setComDocInstance,
        currentFocusedDocItem
    } = useContext(comDocContext);

    const {
        handleSetFocusDocItem
    } = props;

    const [systemHighLightDocItemList, setSystemHighLightDocItemList] = useState<DocItem[]>([]);

    useEffect(() => {
        try {
            console.log("WordEditorParagraphCheck-->", comDocInstance)
            let tempsystemHighLightDocItemList: DocItem[] = [];
            const {
                topicList
            } = comDocInstance;
            topicList.forEach(topic => {
                if (topic.topicSlateDocItemList) {
                    const tempTopicSlateDocItemList: DocItem[] = deepCloneV2(topic.topicSlateDocItemList);
                    let highlightReminderDocItemList = tempTopicSlateDocItemList.filter(docItem => {
                        let flag = false;
                        if (docItem.textStyleMap) {
                            Object.values(docItem.textStyleMap).forEach(styleRule => {
                                if (Object.keys(styleRule).includes('highlightReminder')) {
                                    console.log("存在---->")
                                    flag = true;
                                    docItem.checkType = 'highLight';
                                }
                            })
                        }
                        if (String(docItem.id).includes('cp')) {
                            let defaultPlainText = '';
                            docItem.data && Object.values(docItem.data).forEach(val => {
                                defaultPlainText += val;
                            })
                            defaultPlainText += '。';
                            if (
                                docItem.data && Object.keys(docItem.data).find(key => key.includes('custom'))
                                && docItem.plainText == defaultPlainText
                            ) {
                                // console.log("defaultPlainText--->", defaultPlainText, docItem.plainText)
                                flag = true;
                                docItem.checkType = 'custom';
                            }
                        }
                        return flag;
                    })
                    tempsystemHighLightDocItemList = tempsystemHighLightDocItemList.concat(highlightReminderDocItemList)
                }
            })
            // console.log("tempsystemHighLightDocItemList--->", tempsystemHighLightDocItemList)
            setSystemHighLightDocItemList(tempsystemHighLightDocItemList)
        } catch (e) {
            console.error("高亮检查异常--->", e)
        }
    }, [comDocInstance]);



    const renderDocItemText = (docItem: DocItem) => {
        if (docItem.checkType == 'highLight') {
            // 解析并替换文本中的占位符
            const renderText = () => {
                const { text, textStyleMap } = docItem;
                const parts = text.split(/(\$\$\[\d+\])/).filter(part => part); // 将文本拆分为普通文本和占位符
                return parts.map((part, index) => {
                    // 如果部分是一个占位符，则渲染对应的样式化文本
                    if (/\$\$\[\d+\]/.test(part)) {
                        const style = textStyleMap[part];
                        const styleProps: CSSProperties = {
                            //@ts-ignore
                            backgroundColor: style.bgColor ? style.bgColor : 'transparent',
                            fontStyle: style.italic ? 'italic' : 'normal',
                        };
                        return (
                            <span key={index} style={styleProps}>
                                {style.text}
                            </span>
                        );
                    } else {
                        return part;
                    }
                });
            };
            return <div style={{flexWrap: 'wrap'}}>{renderText()}</div>;
        } else {
            return (
                <div style={{flexWrap: 'wrap'}}>
                    {docItem.plainText}
                    <div style={{fontSize: 14, color: '#cf1322'}}>提醒：请完善自定义项目的默认文本</div>
                </div>
            )
        }
    }

    const handleFocusEditorParagraph = (docItem: DocItem) => {
        handleSetFocusDocItem && handleSetFocusDocItem(docItem)
    }

    const renderHighLightParagraph = () => {
        return (
            <div
                className='background-paragraph-text-container'
                style={{ overflowY: 'auto' }}
                key={'111'}
            >
                <div className="background-paragraph-text-content">
                    <Alert
                        message="温馨提示：高亮段落为系统提示需要用户需要手动修改完善段落文本。"
                        type="warning"
                        style={{ marginRight: 10, }}
                        showIcon
                        closable
                    />
                    {
                        systemHighLightDocItemList.map((docItem, index) => {
                            return (
                                <React.Fragment
                                    key={index + ''}
                                >
                                    <div
                                        key={index + '-1'}
                                        className="background-paragraph-text-template"
                                    >
                                        ......
                                    </div>
                                    <div

                                        className="background-paragraph-text-template hover-underline"
                                    >
                                        {renderDocItemText(docItem)}
                                    </div>
                                    <div
                                        key={index + '-2'}
                                        className="background-paragraph-text-template"
                                    >
                                        ......
                                    </div>
                                    <div className="flex-row system-highlight-footer">
                                        <Button
                                            onClick={() => handleFocusEditorParagraph(docItem)}
                                        >
                                            聚焦到此段落
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>

            </div>
        )
    }

    const renderTab = (type: WordParagraphBlockStatus) => {
        switch (type) {
            case 'HighLight':
                return renderHighLightParagraph()
                break;
            default:
                return (
                    <div
                        className='word-editor-plugins-content benefit-evaluation-content'
                    >
                        <div className="flex-col word-editor-plugins-empty">
                            <Empty
                                description={(
                                    <div>
                                        <div style={{ fontSize: 15 }}>暂无内容</div>
                                        <span style={{ fontSize: 14, color: '#7f7f7f' }}>请检查文档是否版本过旧</span>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                )
                break;
        }
    };

    const tabList = [
        {
            key: '1',
            label: "高亮段落",
            children: renderTab('HighLight')
        },
        // {
        //     key: '2',
        //     label: "修改段落",
        //     children: renderTab('HighLight')
        // },
        // {
        //     key: '3',
        //     label: "自动生成",
        //     children: renderTab('HighLight')
        // },
        // {
        //     key: '4',
        //     label: "重置段落",
        //     children: renderTab('Reset')
        // },
    ]

    return (
        <div className="word-editor-plugins-content">
            <Tabs
                type={'card'}
                defaultActiveKey="currentTopic"
                items={tabList}
                tabPosition='top'
                className='word-editor-plugin-content-tabs disable-drag'
                size={'small'}
            />
        </div>
    )
}

export default forwardRef(WordEditorParagraphCheck);