import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import CustomPopover from './CustomPopover';
import './custom-popover.scss';
import { Switch, Popconfirm } from 'antd';
import { toastShort } from '../../utils';
import TablePopover from './TablePopover';

interface Props {
    clientX: number
    clientY: number
    onExpandedAllNode?: Function
    onCollapsedAllNode?: Function
    onConfirmDeleteSystemRecommandNode?: Function
}

const InsertToolsPopover = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openPopover
    }))

    const {
        clientX,
        clientY
    } = props;

    const customSelectRef = useRef<any>(null);

    const openPopover = () => {
        customSelectRef.current.show();
    };

    const onClickInsertToolsItem = (type: string) => {
        toastShort('warning', '表格界面不支持此操作');
        customSelectRef.current.handleClosePopover();
    }

    return (
        <CustomPopover
            ref={customSelectRef}
            clientX={clientX}
            clientY={clientY}
            contentRender={(props) => (
                <div className='insert-tools-popover'>
                    <div className='flex-row insert-tools-popover-title'>
                        <div>插入工具</div>
                    </div>
                    <div className='flex-row insert-tools-content'>
                        <div
                            className='flex-col insert-tools-item'
                            onClick={() => onClickInsertToolsItem('picture')}
                        >
                            <div className='insert-tools-item-icon'>
                                <i className='iconfont ghzs-color-tupian1' />
                            </div>
                            <div className='insert-tools-item-name'>图片</div>
                        </div>
                        <Popconfirm
                            placement="leftTop"
                            title={false}
                            description={
                                <TablePopover />
                            }
                            icon={false}
                            okText={false}
                            okType={null}
                            showCancel={false}
                        >
                            <div
                                className='flex-col insert-tools-item'
                                // onClick={() => onClickInsertToolsItem('table')}
                            >
                                <div className='insert-tools-item-icon'>
                                    <i className='iconfont ghzs-color-biaoge' />
                                </div>
                                <div className='insert-tools-item-name'>表格</div>
                            </div>
                        </Popconfirm>
                        <div
                            className='flex-col insert-tools-item'
                            onClick={() => onClickInsertToolsItem('attachment')}
                        >
                            <div className='insert-tools-item-icon'>
                                <i className='iconfont ghzs-color-fujian' />
                            </div>
                            <div className='insert-tools-item-name'>附件</div>
                        </div>
                        <div
                            className='flex-col insert-tools-item'
                            onClick={() => onClickInsertToolsItem('screenshot')}
                        >
                            <div className='insert-tools-item-icon'>
                                <i className='iconfont ghzs-color-jietu1' />
                            </div>
                            <div className='insert-tools-item-name'>截图</div>
                        </div>
                        <div
                            className='flex-col insert-tools-item'
                            onClick={() => onClickInsertToolsItem('shape')}
                        >
                            <div className='insert-tools-item-icon'>
                                <i className='iconfont ghzs-color-xingzhuang' />
                            </div>
                            <div className='insert-tools-item-name'>形状</div>
                        </div>
                        <div
                            className='flex-col insert-tools-item'
                            onClick={() => onClickInsertToolsItem('icon')}
                        >
                            <div className='insert-tools-item-icon'>
                                <i className='iconfont ghzs-color-tubiao' />
                            </div>
                            <div className='insert-tools-item-name'>图标</div>
                        </div>
                    </div>
                </div>
            )}
        />
    )
}


export default forwardRef(InsertToolsPopover);