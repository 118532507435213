export enum AlignEnum {
  left = 'left',
  center = 'center',
  right = 'right',
  justify = 'justify',
}
export const AlignOptions = [
  [AlignEnum.left, '居左对齐'],
  [AlignEnum.center, '居中对齐'],
  [AlignEnum.right, '居右对齐'],
  [AlignEnum.justify, '两端对齐'],
].map(([value, label]) => ({ value, label }));
