import React, { useEffect, useRef, useState } from 'react';

import { Button, ColorPicker } from 'antd';

import { BgColorConfig } from '../../../constants';
import { useChangeColor, useMatchMark } from '../hooks';
import { ItemProps } from '../types';
import OptionBarBtn from '../../../../../components/option-bar-btn/OptionBarBtn';
import CustomPopover from '../../../../../components/popover/CustomPopover';
import CustomColorPicker from '../../../../../components/color-picker/CustomColorPicker';

/**
 * 背景色工具项
 * - 修改文字背景色
 */
export const BgColorItem = React.memo(function BgColorItem({ editor, visible }: ItemProps) {
  const format = 'bgColor';
  const btnRef = useRef(null);
  const [value, setValue] = useState<string>(BgColorConfig.default);
  const changeColor = useChangeColor(editor, format);
  const mark = (useMatchMark(editor, format) as string) || BgColorConfig.default;
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const colorPickerRef = useRef(null);
  useEffect(() => {
    setValue(mark);
  }, [mark]);

  useEffect(() => {
    if (btnRef.current) {
      const { top, left } = btnRef.current.getBoundingClientRect();
      setPosition({ top, left });
    }
  }, [visible])

  const openColorPicker = () => {
    colorPickerRef.current.show();
  }

  const onPick = (e) => {
    changeColor(e);
  }
  // return (
  //   <ColorPicker value={`${value}`} onChange={changeColor}>
  //     <Button style={{ backgroundColor: value }}>字体填充色</Button>
  //   </ColorPicker>
  // );
  return (
    <div ref={btnRef}>
      <OptionBarBtn
        icon={(
          <div className='custom-option-bar-icon-container'>
            <i style={{ fontSize: 21 }} className='iconfont ghzs-color-a-bianzu31' />
            <div
              className='custom-option-bar-icon-bgline cell-background-line'
              style={{ bottom: 1, backgroundColor: value ? value : '#FFFFFF' }}
            ></div>
          </div>
        )}
        size={'small'}
        tooltipTitle={"文本背景色"}
        selectEnable={true}
        onClickArrow={openColorPicker}
      />
      <CustomPopover
        ref={colorPickerRef}
        clientX={position.left}
        clientY={position.top + 36}
        disableBackground
        contentRender={(props) =>
          <CustomColorPicker
            {...props}
            onPickerColor={onPick}
            screenName={'文本背景色'}
            screenTag={"fontBgColor"}
          />
        }
      />
    </div>

  )
});
