import React from 'react';

import { BoldConfig, FontFamilyConfig, FontSizeConfig } from '../../constants';
import { useEmitSlateItemClick } from '../../hooks';
import { SlateElementProps } from '../../types';
import { createClassNameMark } from '../../utils';

import Style from './main.module.scss';

const classNames = createClassNameMark(Style);
export const Heading1 = React.memo(function Heading1({ attributes, children, element, style }: SlateElementProps) {
  const onClick = useEmitSlateItemClick(element);
  // console.log("style--->", style, element)
  return (
    <h1
      {...attributes}
      style={{
        ...style,
        //TODO 这里不知道怎么合并到style,先用的element.align
        textAlign: element.align || 'left',
        fontSize: FontSizeConfig.h1,
        fontFamily: FontFamilyConfig.h1,
        fontWeight: BoldConfig.h1 ? 'bold' : undefined,
      }}
      data-doc-item-id={element.id}
      className={classNames('doc-heading1')}
      onClick={onClick}
    >
      {children}
    </h1>
  );
});
