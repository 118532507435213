import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import EditorStateOptionBar from "../excel-editor-option-bar/EditorStateOptionBar";
import EditorClipboard from "./WordClipboard";
import WordInlineStyleOptionBar from "./WordInlineStyleOptionBar";
import ParagraphOptionBar from "./ParagraphOptionBar";
import ParagraphStylePicker from "../../../components/paragraph-style-picker/ParagraphStylePicker";
import WordExtraOptionBar from "./WordExtraOptionBar";
import { Toolbar } from "../../slate-editor/components";
import DocEditorContext from './../../../context/DocEditorContext';
import { useSearchParams } from "react-router-dom";
import { checkIsTempAccount } from "../../../utils";

interface Props {
    getWordExportParams: Function
    getExcelExportParams: Function
    fillSpeciesInfoList: Function
}

const EditorOptionBarForWord = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        toggleLayoutVisible
    }))

    const {
        getWordExportParams,
        getExcelExportParams,
        fillSpeciesInfoList
    } = props;

    const [isRefresh, setIsRefresh] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const docEditorRef = useContext(DocEditorContext);

    const containerRef = useRef<any>(null);

    const excelInlineStyleOptionBarRef = useRef<any>(null);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        refrehLayout();
    }, [])

    useEffect(() => {
        refrehLayout();
    }, [visible])

    const refrehLayout = () => {
        setIsRefresh(true);
        setTimeout(() => {
            setIsRefresh(false);
        }, 0);
    }

    const toggleLayoutVisible = (_visible: boolean) => {
        setVisible(_visible);
        if (_visible) {
            containerRef.current.style.zIndex = 1;
            containerRef.current.style.display = 'flex';
        } else {
            containerRef.current.style.zIndex = -999;
            containerRef.current.style.display = 'none';
        }
    };

    const onMouseDown = (e: any) => {
        e.stopPropagation();
        e.preventDefault()
    };

    return (
        <div
            ref={containerRef}
            className='edit-page-option-row content-border content-border-radius flex-row'
            style={{ display: 'flex' }}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseDown}
            onClick={onMouseDown}
        >
            {
                isRefresh ?
                    <></>
                    :
                    <>
                        <EditorStateOptionBar
                            type={'word'}
                            getWordExportParams={getWordExportParams}
                            getExcelExportParams={getExcelExportParams}
                        />
                        <div className='edit-page-option-row-border' style={{ marginRight: 12 }}></div>
                        <EditorClipboard docEditorRef={docEditorRef} />
                        <div className='edit-page-option-row-border'></div>
                        <WordInlineStyleOptionBar
                            ref={excelInlineStyleOptionBarRef}
                            docEditorRef={docEditorRef}
                            visible={visible}
                        />
                        {
                            !checkIsTempAccount() ?
                                <>
                                    <div className='edit-page-option-row-border' style={{ marginLeft: 12 }}></div>
                                    <ParagraphStylePicker docEditorRef={docEditorRef} />
                                </>
                                :
                                null
                        }
                        {/* <Toolbar docEditorRef={docEditorRef}/> */}
                        <div className='edit-page-option-row-border' style={{ marginLeft: 12, marginRight: 12 }}></div>
                        <WordExtraOptionBar
                            getWordExportParams={getWordExportParams}
                            // fillSpeciesInfoList={fillSpeciesInfoList}
                            visible={visible}
                        />
                    </>
            }
        </div>
    )
}


export default forwardRef(EditorOptionBarForWord)