import { useEffect, useRef } from 'react';

export const useMountedEffect: typeof useEffect = (effect, deps = []) => {
  const isMounted = useRef(false);
  useEffect(() => {
    return isMounted.current ? effect() : undefined;
  }, deps);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
};
