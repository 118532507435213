import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button } from 'antd';
import { Editor } from 'slate';

import { useEditorMouseUp } from '../../../hooks';
import { emitFormatPainter, getDocItemTypeDefaultStyleConfig } from '../../../utils';
import { useMatchMark, useMatchSlateItem, useRangeIsExpanded } from '../hooks';
import { ItemProps } from '../types';
import OptionBarBtn from '../../../../../components/option-bar-btn/OptionBarBtn';

/**
 * 格式刷工具项
 */
export const FormatPainterItem = React.memo(function FormatPainterItem({ editor }: ItemProps) {
  const [keepEnabled, setKeepEnabled] = useState(false);
  const [activated, setActivated] = useState(false);
  const enable = useRangeIsExpanded(editor) || activated;
  const data = useRef<[string, string | boolean | null][]>([]);
  const bgColorMark = useMatchMark(editor, 'bgColor');
  const boldMark = useMatchMark(editor, 'bold');
  const colorMark = useMatchMark(editor, 'color');
  const deleteMark = useMatchMark(editor, 'delete');
  const fontFamilyMark = useMatchMark(editor, 'fontFamily');
  const fontSizeMark = useMatchMark(editor, 'fontSize');
  const italicMark = useMatchMark(editor, 'italic');
  const subMark = useMatchMark(editor, 'sub');
  const supMark = useMatchMark(editor, 'sup');
  const underlineMark = useMatchMark(editor, 'underline');
  const slateItemType = useMatchSlateItem(editor)?.type;
  const defaultSlateItemMarkMap = useMemo(() => getDocItemTypeDefaultStyleConfig(slateItemType), [slateItemType]);
  const reset = useCallback(() => {
    data.current = [];
    setActivated(false);
    setKeepEnabled(false);
  }, []);
  const record = useCallback(() => {
    if (data.current.length) return reset();
    const { fontSize: fs, fontFamily: ff, bold: b } = defaultSlateItemMarkMap;
    data.current = [
      ['bgColor', bgColorMark],
      ['bold', boldMark || b || null],
      ['color', colorMark],
      ['delete', deleteMark],
      ['fontFamily', fontFamilyMark || ff || null],
      ['fontSize', fontSizeMark || fs || null],
      ['italic', italicMark],
      ['sub', subMark],
      ['sup', supMark],
      ['underline', underlineMark],
    ] as [string, string | boolean | null][];
    setActivated(true);
  }, [
    bgColorMark,
    boldMark,
    colorMark,
    defaultSlateItemMarkMap,
    deleteMark,
    fontFamilyMark,
    fontSizeMark,
    italicMark,
    reset,
    subMark,
    supMark,
    underlineMark,
  ]);
  const toggleKeepEnabled = useCallback(() => {
    setKeepEnabled(!keepEnabled);
    record();
  }, [keepEnabled, record]);
  const onItemMouseDown: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.preventDefault();
      record();
    },
    [record],
  );
  const onMouseUp = useCallback(() => {
    const curEditor = editor?.current?.editor;
    if (!curEditor) return;
    // if (curEditor.selection && Range.isExpanded(curEditor.selection))
    data.current.forEach(([format, mark]) => {
      mark ? Editor.addMark(curEditor, format, mark) : Editor.removeMark(curEditor, format);
    });
    if (keepEnabled) return;
    data.current = [];
    setActivated(false);
  }, [editor, keepEnabled]);
  useEditorMouseUp(onMouseUp);
  useEffect(() => {
    emitFormatPainter(activated);
  }, [activated]);

  return (
    // <Button
    //   disabled={!enable}
    //   onMouseDown={onItemMouseDown}
    //   type={activated ? 'primary' : 'default'}
    //   onDoubleClick={toggleKeepEnabled}
    // >
    //   格式刷
    // </Button>
    <OptionBarBtn
    disabled={!enable}
      icon={<i className='iconfont-1 ghzs-gray-geshishua' />}
      size={'large'}
      name={'格式刷'}
      tooltipTitle={'格式刷'}
      tooltipDesc={'复制所选内容的格式，应用到不同位置的内容中，\n双击此控件可持续激活格式刷可将复制的格式应用到多个不同位置的内容中。'}
      active={activated}
      onDoubleClick={toggleKeepEnabled}
      onMouseDown={onItemMouseDown}
    />
  );
});
