import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { LockOutlined } from '@ant-design/icons'
import CustomPopover from './CustomPopover';
import './custom-popover.scss';
import { Switch, Tooltip } from 'antd';
import comDocContext from '../../context/DocContext';
import { DocConfig, DocConfigBaseInfo, DocInstance } from '../../utils/types';
import { deepCopy, isEmpty, toastShort } from '../../utils';

interface Props {
    clientX: number
    clientY: number
    onExpandedAllNode?: Function
    onCollapsedAllNode?: Function
    onConfirmDeleteSystemRecommandNode?: Function
}

const TopicSettingPopover = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openPopover
    }))

    const {
        comDocConfig,
        _setComDocConfig
    } = useContext(comDocContext);

    const {
        clientX,
        clientY,
        onExpandedAllNode,
        onCollapsedAllNode,
        onConfirmDeleteSystemRecommandNode
    } = props;

    const delayTimer = useRef<any>(null);
    const customSelectRef = useRef<any>(null);

    const tempComDocConfig = useRef<DocConfig>({});

    const [currentComDocConfig, setCurrentDocBaseConfigInfo] = useState<DocConfigBaseInfo>({
        topicTreeLinkage: true,             //大纲联动
        showTopicTreeIcon: false,           //大纲图标
        topicSideBarLocked: false,          //大纲锁定
        showTopicTreeLevel: true,           //展示当前大纲的序号
        systemRecommandNodeDeleted: false,  //是否已删除系统推荐的大纲
    });

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current)
        }
    }, [])

    useEffect(() => {
        if (isEmpty(comDocConfig) || (comDocConfig.updateComponentName && comDocConfig.updateComponentName == 'TopicSettingPopover')) {
            return;
        }
        console.log("设置面板开始更新comDocConfig--->", comDocConfig)
        tempComDocConfig.current = comDocConfig;
        setCurrentDocBaseConfigInfo(comDocConfig.docBaseConfigInfo)
    }, [comDocConfig])

    const openPopover = () => {
        customSelectRef.current.show();
    };

    const closePopover = () => {
        customSelectRef.current._handleClose();
    }

    const onChange = () => {

    }

    const handleUpdatecomDocConfig = (newcomDocConfig: DocConfig) => {
        console.log("侧栏设置面板派发--->", newcomDocConfig)
        // updateComponentName: 'TopicSettingPopover',
        delayTimer.current = setTimeout(() => {
            newcomDocConfig.updateComponentName = 'TopicSettingPopover';
            _setComDocConfig(deepCopy(newcomDocConfig));
        }, 350);
    }

    const _onExpandedAllNode = () => {
        closePopover();
        delayTimer.current = setTimeout(() => {
            onExpandedAllNode && onExpandedAllNode();
        }, 200);
    }

    const _onCollapsedAllNode = () => {
        closePopover();
        delayTimer.current = setTimeout(() => {
            onCollapsedAllNode && onCollapsedAllNode();
        })
    }

    const onTopicTreeLinkageChange = (checked: boolean) => {
        let _tempCurrentDocBaseConfigInfo = currentComDocConfig;
        _tempCurrentDocBaseConfigInfo.topicTreeLinkage = checked;
        _tempCurrentDocBaseConfigInfo = deepCopy(_tempCurrentDocBaseConfigInfo)
        setCurrentDocBaseConfigInfo(_tempCurrentDocBaseConfigInfo);
        let _tempComDocConfig = tempComDocConfig.current;
        _tempComDocConfig.docBaseConfigInfo = _tempCurrentDocBaseConfigInfo;
        if (checked) {
            toastShort('success', '大纲状态联动已开启，本文大纲与大纲库将会同步展开收起')
        }
        // console.log("_tempCurrentDocBaseConfigInfo--->", _tempCurrentDocBaseConfigInfo)
        handleUpdatecomDocConfig(_tempComDocConfig)
    }

    const onShowTopicTreeIconChange = (checked: boolean) => {
        let _tempCurrentDocBaseConfigInfo = currentComDocConfig;
        _tempCurrentDocBaseConfigInfo.showTopicTreeIcon = checked;
        _tempCurrentDocBaseConfigInfo = deepCopy(_tempCurrentDocBaseConfigInfo)
        setCurrentDocBaseConfigInfo(_tempCurrentDocBaseConfigInfo);
        let _tempComDocConfig = tempComDocConfig.current;
        _tempComDocConfig.docBaseConfigInfo = _tempCurrentDocBaseConfigInfo;
        handleUpdatecomDocConfig(_tempComDocConfig)
    }

    const onTopicSideBarLockedChange = (checked: boolean) => {
        let _tempCurrentDocBaseConfigInfo = currentComDocConfig;
        _tempCurrentDocBaseConfigInfo.topicSideBarLocked = checked;
        _tempCurrentDocBaseConfigInfo = deepCopy(_tempCurrentDocBaseConfigInfo)
        setCurrentDocBaseConfigInfo(_tempCurrentDocBaseConfigInfo);
        let _tempComDocConfig = tempComDocConfig.current;
        _tempComDocConfig.docBaseConfigInfo = _tempCurrentDocBaseConfigInfo;
        handleUpdatecomDocConfig(_tempComDocConfig)
    }

    const onShowTopicTreeLevelChange = (checked: boolean) => {
        let _tempCurrentDocBaseConfigInfo = currentComDocConfig;
        _tempCurrentDocBaseConfigInfo.showTopicTreeLevel = checked;
        _tempCurrentDocBaseConfigInfo = deepCopy(_tempCurrentDocBaseConfigInfo)
        setCurrentDocBaseConfigInfo(_tempCurrentDocBaseConfigInfo);
        let _tempComDocConfig = tempComDocConfig.current;
        _tempComDocConfig.docBaseConfigInfo = _tempCurrentDocBaseConfigInfo;
        handleUpdatecomDocConfig(_tempComDocConfig)
    }

    const _onDeleteSystemRecommandNode = () => {
        
    }

    return (
        <CustomPopover
            ref={customSelectRef}
            clientX={clientX}
            clientY={clientY}
            contentRender={(props) => (
                <div className='topic-setting-popover'>
                    <Tooltip
                        title={'本文大纲与大纲库同步展开收起'}
                    >
                        <div className='flex-row setting-item-row'>
                            <div className='flex-row setting-item-row-left'>
                                <div className='setting-item-row-icon'>
                                    <i className="iconfont-1 ghzs-gray-dagangliandong" />
                                </div>
                                <div className='setting-item-row-title'>大纲状态联动</div>
                            </div>
                            <Switch
                                defaultChecked={currentComDocConfig && currentComDocConfig.topicTreeLinkage}
                                onChange={onTopicTreeLinkageChange}
                                checked={currentComDocConfig.topicTreeLinkage}
                                size={'small'}
                            />
                        </div>
                    </Tooltip>
                    {/* <Tooltip
                        title={'辅助显示大纲类型，文本类、设施类、设备类'}
                    >
                        <div className='flex-row setting-item-row'>
                            <div className='flex-row setting-item-row-left'>
                                <div className='setting-item-row-icon'>
                                    <i className="iconfont-1 ghzs-gray-dagangtubiaofuzhuzhishi" />
                                </div>
                                <div className='setting-item-row-title'>大纲辅助图标</div>
                            </div>
                            <Switch
                                defaultChecked={currentComDocConfig && currentComDocConfig.showTopicTreeIcon}
                                onChange={onShowTopicTreeIconChange}
                                checked={currentComDocConfig.showTopicTreeIcon}
                                size={'small'}
                            />
                        </div>
                    </Tooltip> */}
                    {/* <Tooltip
                        title={'锁定大纲面板宽度'}
                    >
                        <div className='flex-row setting-item-row'>
                            <div className='flex-row setting-item-row-left'>
                                <div className='setting-item-row-icon'>
                                    <LockOutlined />
                                </div>
                                <div className='setting-item-row-title'>锁定大纲面板</div>
                            </div>
                            <Switch
                                defaultChecked={currentComDocConfig && currentComDocConfig.topicSideBarLocked}
                                onChange={onTopicSideBarLockedChange}
                                // checked={currentComDocConfig.topicSideBarLocked}
                                size={'small'}
                            />
                        </div>
                    </Tooltip> */}
                    <Tooltip
                        title={'展示大纲序号'}
                    >
                        <div className='flex-row setting-item-row'>
                            <div className='flex-row setting-item-row-left'>
                                <div className='setting-item-row-icon'>
                                    <i style={{ fontSize: 16 }} className="iconfont-1 ghzs-gray-he_16zhengjianbianhaoguanli" />
                                </div>
                                <div className='setting-item-row-title'>当前大纲序号</div>
                            </div>
                            <Switch
                                defaultChecked={currentComDocConfig && currentComDocConfig.showTopicTreeLevel}
                                onChange={onShowTopicTreeLevelChange}
                                size={'small'}
                            />
                        </div>
                    </Tooltip>
                    <div className='setting-item-row-border'></div>
                    <div
                        className='flex-row setting-item-row'
                        onClick={_onCollapsedAllNode}
                    >
                        <div className='flex-row setting-item-row-left'>
                            <div className='setting-item-row-icon'>
                                <i style={{ fontSize: 13 }} className="iconfont-1 ghzs-gray-zhediequanbujiedian" />
                            </div>
                            <div className='setting-item-row-title'>折叠全部节点</div>
                        </div>
                    </div>
                    <div
                        className='flex-row setting-item-row'
                        onClick={_onExpandedAllNode}
                    >
                        <div className='flex-row setting-item-row-left'>
                            <div className='setting-item-row-icon'>
                                <i style={{ fontSize: 13 }} className="iconfont-1 ghzs-gray-zhankaiquanbujiedian" />
                            </div>
                            <div className='setting-item-row-title'>展开全部节点</div>
                        </div>
                    </div>
                    {/* <div className='setting-item-row-border'></div>
                    <div className='flex-row setting-item-row disable-btn'>
                        <div className='flex-row setting-item-row-left'>
                            <div className='setting-item-row-icon'>
                                <i style={{ fontSize: 16 }} className="iconfont ghzs-color-shanchu" />
                            </div>
                            <div className='setting-item-row-title'>删除默认大纲/项目</div>
                        </div>
                    </div> */}
                </div>
            )}
        />
    )
}


export default forwardRef(TopicSettingPopover);