import { createSlice } from "@reduxjs/toolkit";
import { SystemType } from "../utils/types";

const defaultSystemConfig: SystemType = {
    userInfo: {},
    deptInfo: {},
    token: ""
};

const getLocalSystemConfig = (): SystemType => {
    try {
        if (localStorage.getItem('systemConfig')) {
            //@ts-ignore
            return JSON.parse(localStorage.getItem('systemConfig'))
        }
        return defaultSystemConfig
    } catch (err) {
        return defaultSystemConfig
    }
}

const initialState: SystemType = getLocalSystemConfig();


const systemSlice = createSlice({
    name: 'systemConfig',
    initialState,
    // 相当于原来reducer中的case
    reducers: {
        // 后面文中我们将这类函数称之为 case
        setSystemConfig: (state, action) => {
            console.log("action--->", action.payload)
            state = action.payload;
        },
        removeSystemConfig: (state, action) => {
            state = defaultSystemConfig;
        }
    }
});

// 官方推荐使用 ES6 解构和导出语法
// 提取action creator 对象与reducer函数
const { reducer, actions } = systemSlice;
// 提取并导出根据reducers命名的 action creator 函数
export const { setSystemConfig, removeSystemConfig } = actions;
// 导出 reducer 函数
export default reducer;