import { AlignConfig, BgColorConfig, BoldConfig, ColorConfig, FontFamilyConfig, FontSizeConfig } from '../constants';
import { DocItemType } from '../types';

/**
 * 获各类型文档项的默认样式配置
 * @param type
 */
export const getDocItemTypeDefaultStyleConfig = (type?: DocItemType) => {
  if (!type)
    return {
      fontSize: FontSizeConfig.default,
      fontFamily: FontFamilyConfig.default,
      bold: BoldConfig.default,
      bgColor: BgColorConfig.default,
      color: ColorConfig.default,
      align: AlignConfig.default,
    };
  return {
    fontSize: FontSizeConfig[type],
    fontFamily: FontFamilyConfig[type],
    bold: BoldConfig[type],
    bgColor: BgColorConfig.default,
    color: ColorConfig.default,
    align: AlignConfig[type],
  };
};
