import React from 'react';

import { BoldConfig, FontFamilyConfig, FontSizeConfig } from '../../constants';
import { useEmitSlateItemClick } from '../../hooks';
import { SlateElementProps } from '../../types';
import { createClassNameMark } from '../../utils';

import Style from './main.module.scss';
const classNames = createClassNameMark(Style);
export const Heading5 = React.memo(function Heading5({ attributes, children, element, style }: SlateElementProps) {
  const onClick = useEmitSlateItemClick(element);
  return (
    <h5
      {...attributes}
      style={{
        ...style,
        fontSize: FontSizeConfig.h5,
        fontFamily: FontFamilyConfig.h5,
        fontWeight: BoldConfig.h5 ? 'bold' : undefined,
      }}
      data-doc-item-id={element.id}
      className={classNames('doc-heading5')}
      onClick={onClick}
    >
      {children}
    </h5>
  );
});
