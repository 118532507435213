import { deepCloneV2, isEmpty } from "../../../utils";
import { DocItem, DocItemType } from "../../slate-editor";
import { AlignEnum } from "../../slate-editor/constants";

const isStyleText = (text: string) => /^\$\$\[\d+]$/.test(text);
const dynamicTextReg = /(\$?\[[SXNU].*?])|(\$?\[\*])|(\$?\[NAME])|(\$?\[P])|(\$?\[type])|(\$\$\[\d+])/;
const isItemScopeDynamicText = (text: string) => /^\$?\[[SXNU].*?]$/.test(text);

/**
 * 
 * @param textStyleMap 
 * @returns 
 */
const sortTextStyleMapAndFindMaxIndex = (textStyleMap) => {
    const indices = Object.keys(textStyleMap).map(key => parseInt(key.match(/\d+/)[0], 10));
    indices.sort((a, b) => a - b);
    const sortedTextStyleMap = {};
    indices.forEach(index => {
        const key = `$$[${index}]`;
        sortedTextStyleMap[key] = textStyleMap[key];
    });
    const maxIndex = indices.length > 0 ? indices[indices.length - 1] : 0;
    return maxIndex;
}

export const processDocItemListForExport = (docItemList: DocItem[]) => {
    const newDocItemList: DocItem[] = deepCloneV2(docItemList);
    newDocItemList.forEach(docItem => {
        if(!docItem.style){
            docItem.style = {
                align: AlignEnum.left
            }
        }
        if (docItem.textStyleMap) {
            if (docItem.data && docItem.data['$[INDEX]']) {
                docItem.textStyleMap[Object.keys(docItem.textStyleMap)[0]].text = docItem.data['$[INDEX]'] + " " + docItem.textStyleMap[Object.keys(docItem.textStyleMap)[0]].text;
            }
            docItem.text.split(/(\$\$\[\d+\])/)
                .filter(Boolean)
                .forEach((textPart, index) => {
                    if (!isStyleText(textPart)) {
                        let realText = '';
                        textPart.split(/(\$?\[[SXNU].*?])/)
                            .filter(Boolean)
                            .forEach(subTextPart => {
                                if (isItemScopeDynamicText(subTextPart)) {
                                    realText += docItem.data[subTextPart];
                                } else {
                                    realText += subTextPart;
                                }
                            })
                        docItem.textStyleMap[`$$[${index + 99999}]`] = {
                            text: realText
                        }
                    } else {
                        const styleMap = { ...docItem.textStyleMap[textPart] };
                        let realText = '';
                        const _text = docItem.textStyleMap[textPart].text;
                        _text.split(/(\$?\[[SXNU].*?])/)
                            .filter(Boolean)
                            .forEach(subTextPart => {
                                if (isItemScopeDynamicText(subTextPart)) {
                                    realText += docItem.data[subTextPart];
                                } else {
                                    realText += subTextPart;
                                }
                            })
                        delete docItem.textStyleMap[textPart];
                        styleMap.text = realText;
                        docItem.textStyleMap[`$$[${index + 99999}]`] = styleMap;
                    }
                })
        } else {
            let realText = '';
            docItem.text.split(dynamicTextReg)
                .filter(Boolean)
                .forEach(subTextPart => {
                    if (isItemScopeDynamicText(subTextPart)) {
                        realText += docItem.data[subTextPart];
                    } else {
                        realText += subTextPart;
                    }
                })
            docItem.textStyleMap = {
                "$$[99999]": {
                    text: realText,
                },
            }
            docItem.text = `$$[99999]`;
        }
        if (docItem.type == DocItemType.TABLE) {
            if (docItem.externalData && docItem.externalData.tableData) {
                let newTableData: string[][] = [];
                docItem.externalData.tableData.forEach(row => {
                    let tempRow = [];
                    row.forEach(str => {
                        if (isEmpty(str)) {
                            tempRow.push('-');
                        } else {
                            tempRow.push(str + '-');
                        }
                    })
                    newTableData.push(tempRow);
                })
                docItem.externalData.tableData = deepCloneV2(newTableData)
                if (isEmpty(docItem.externalData.tableHeaderEndRowIndex)) {
                    docItem.externalData.tableHeaderEndRowIndex = 1;
                }
            } else {
                docItem.externalData = {
                    tableData: [["表格数据异常", "表格数据异常", "表格数据异常"]],
                    tableHeaderEndRowIndex: 0,
                    tableName: "",
                }
            }
        }
    })
    return newDocItemList;
}