import { TypeMergeCellBound, TableDataCell } from "../../../types";

const initTableHeader: Array<TableDataCell> =  [
    {
        id: '0-0',
        bound: [0, 0],
        type: 'text',
        text: '投资估算表（单位：万元)',
    },
    {
        id: '0-1',
        bound: [0, 1],
        type: 'text',
        text: '序号',
    },
    {
        id: '2-1',
        bound: [1, 1],
        type: 'text',
        text: '项目名称',
    },
    {
        id: '3-1',
        bound: [2, 1],
        type: 'text',
        text: '单位',
    },
    {
        id: '4-1',
        bound: [3, 1],
        type: 'text',
        text: '数量',
    },
    {
        id: '5-1',
        bound: [4, 1],
        type: 'text',
        text: '单价',
    },
    {
        id: 'investmentCompositionMoney',
        bound: [5, 1],
        type: 'text',
        text: '投资金额',
    },

    {
        id: '1-3',
        bound: [0, 3],
        type: 'text',
        text: '总投资',
    },
    {
        id: '3-3',
        bound: [2, 3],
        type: 'text',
        text: '',
    },
    {
        id: '7-1',
        bound: [6, 1],
        type: 'text',
        text: '投资构成',
    },
    {
        id: '10-1',
        bound: [9, 1],
        type: 'text',
        text: '投资分期',
    },
    {
        id: '10-1',
        bound: [10, 1],
        type: 'text',
        text: '',
    },
    {
        id: 'buildingInstallation',
        bound: [6, 2],
        type: 'text',
        text: '建安工程',
    },
    {
        id: 'device',
        bound: [7, 2],
        type: 'text',
        text: '设备',
    },
    {
        id: 'other',
        bound: [8, 2],
        type: 'text',
        text: '其他',
    },
    {
        id: 'nearFutureMoneyRate',
        bound: [9, 2],
        type: 'text',
        text: '近期',
    },
    {
        id: 'mediumAndLongTermMoneyRate',
        bound: [10, 2],
        type: 'text',
        text: '中长期',
    },
    {
        id: '6-3',
        bound: [6, 3],
        type: 'text',
        text: '',
    },
    {
        id: '5-3',
        bound: [5, 3],
        type: 'text',
        text: '',
    },
    {
        id: '10-3',
        bound: [10, 3],
        type: 'text',
        text: '-',
    },
    {
        id: '7-3',
        bound: [7, 3],
        type: 'text',
        text: '-',
    },
    {
        id: '8-3',
        bound: [8, 3],
        type: 'text',
        text: '',
    },
    {
        id: '9-3',
        bound: [9, 3],
        type: 'text',
        text: '',
    },
    {
        id: '11-1',
        bound: [11, 1],
        type: 'text',
        text: '重点工程',  //推荐
    },
    {
        id: '12-1',
        bound: [12, 1],
        type: 'text',
        text: '备注',  //推荐
    },
];

const initMergeCellBoundGroup:Array<TypeMergeCellBound> = [
    {
        id: '0-0',
        bounds: [[0, 0], [12, 0]],
        focusCellbound: [0, 0],
        offsetX: 0,
        offsetY: 0
    },
    {
        id: '0-1',
        bounds: [[0, 1], [0, 2]],
        focusCellbound: [0, 1],
        offsetX: 0,
        offsetY: 0
    },
    {
        id: '1-1',
        bounds: [[1, 1], [1, 2]],
        focusCellbound: [1, 1],
        offsetX: 0,
        offsetY: 0
    },
    {
        id: '2-1',
        bounds: [[2, 1], [2, 2]],
        focusCellbound: [2, 1],
        offsetX: 0,
        offsetY: 0
    },
    {
        id: '3-1',
        bounds: [[3, 1], [3, 2]],
        focusCellbound: [3, 1],
        offsetX: 0,
        offsetY: 0
    },
    {
        id: '4-1',
        bounds: [[4, 1], [4, 2]],
        focusCellbound: [4, 1],
        offsetX: 0,
        offsetY: 0
    },
    {
        id: '5-1',
        bounds: [[5, 1], [5, 2]],
        focusCellbound: [5, 1],
        offsetX: 0,
        offsetY: 0
    },
    {
        id: '0-3',
        bounds: [[0, 3], [4, 3]],
        focusCellbound: [0, 3],
        offsetX: 0,
        offsetY: 0
    },
    {
        id: '6-1',
        bounds: [[6, 1], [8, 1]],
        focusCellbound: [6, 1],
        offsetX: 0,
        offsetY: 0
    },
    {
        id: '9-1',
        bounds: [[9, 1], [10, 1]],
        focusCellbound: [9, 1],
        offsetX: 0,
        offsetY: 0
    },
    {
        id: '11-1',
        bounds: [[11, 1], [11, 2]],
        focusCellbound: [11, 1],
        offsetX: 0,
        offsetY: 0
    },
    {
        id: '12-1',
        bounds: [[12, 1], [12, 2]],
        focusCellbound: [12, 1],
        offsetX: 0,
        offsetY: 0
    },
]


export {
    initTableHeader,
    initMergeCellBoundGroup,
}