import React from 'react';

import { useEmitSlateItemClick } from '../../hooks';
import { SlateElementProps } from '../../types';
import { createClassNameMark } from '../../utils';
import TeX from '@matejmazur/react-katex';

import Style from './main.module.scss';

const classNames = createClassNameMark(Style);
export const LaTex = React.memo(function SlateTable({ attributes, children, element }: SlateElementProps) {
    const { externalData } = element;
    const onClick = useEmitSlateItemClick(element);
    return (
        <div {...attributes} doc-item-id={element.id} className={classNames('doc-latex')} onClick={onClick}>
            {
                externalData ?
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '12pt', marginBottom: '12pt' }}>
                        <TeX
                            //@ts-ignore
                            math={externalData.laTex}
                        />
                    </div>
                    :
                    null
            }

        </div>
    );
});

export default LaTex