import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Select, Tooltip, } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { deepCopy, makeClassNameList } from '../../utils';
import CustomPopover from '../popover/CustomPopover';
import CustomSelect from './CustomSelect';
import { OptionBarConfig } from '../../types';
import store from '../../redux/store';
import useReSize from '../../hooks/useResize';

interface Props {
    options: Array<OptionType>
    style?: React.CSSProperties
    className?: string
    selectStyle?: React.CSSProperties
    selectOptionStyle?: React.CSSProperties
    selectClassName?: string
    placeholder?: string
    size?: SizeType
    value?: string
    onChange?: (value: any, option?: OptionType | OptionType[]) => void;
    tooltipEnable?: boolean
    tooltipPlacement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';
    tooltipTitle?: string
    tooltipDesc?: string
    mouseEnterDelay?: number
    mouseLeaveDelay?: number
    selectTarget?: 'label' | 'value'
}

type SizeType = 'small' | 'middle' | 'large' | undefined;

interface OptionType {
    value: any
    label: any
}

interface State {
    tooltipOpen: boolean
    selectFocused: boolean
    mouseInSelect: boolean
    clientX: number
    clientY: number

}

const SelectWidthToolip = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        measureSelectPosition
    }))

    const {
        options,
        style = {},
        className = '',
        selectStyle,
        selectOptionStyle,
        size,
        selectClassName,
        placeholder = '请选择',
        tooltipEnable = true,
        tooltipPlacement = 'bottomLeft',
        tooltipTitle = '',
        tooltipDesc = '',
        value = '',
        onChange,
        selectTarget
    } = props;

    const delayTimer = useRef(null);
    const customSelectRef = useRef(null);
    const selectContainerRef = useRef(null);
    const containerRef = useRef(null);

    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [selectFocused, setSelectFocused] = useState<boolean>(false);
    const mouseInSelect = useRef<boolean>(false);
    const [customPopoverPosition, setCustomPopoverPosition] = useState({ clientX: 0, clientY: 0 })

    const containerResize = useReSize(containerRef)

    useEffect(() => {
        measureSelectPosition();
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current);
        }
    }, [containerResize])

    const measureSelectPosition = () => {
        const clientRect = selectContainerRef.current.getBoundingClientRect();
        const { top, left } = clientRect;
        setCustomPopoverPosition({ clientX: left, clientY: top + 24 })
    }

    const onSelectChange = (e: any) => {
        console.log("onSelectChange---->", e)
    };

    const onSelectMouseEnter = () => {
        mouseInSelect.current = true;
        delayTimer.current = setTimeout(() => {
            if (mouseInSelect.current) {
                setTooltipOpen(true)
            }
        }, 500);
    }

    const onSelectMouseLeave = () => {
        mouseInSelect.current = false;
        delayTimer.current = setTimeout(() => {
            setTooltipOpen(false)
        }, 100);
    }

    const onSelectFocus = () => {
        delayTimer.current = setTimeout(() => {
            setTooltipOpen(true)
            setSelectFocused(true)
        }, 100);
    };

    const onSelectBlur = () => {
        setTooltipOpen(false)
        setSelectFocused(false)
    };

    const handleOpendSelect = (e?: any) => {
        measureSelectPosition();
        e?.preventDefault();
        e?.stopPropagation();
        customSelectRef.current.show();
        onSelectFocus();
    };

    const onSelect = (e: string) => {
        onChange && onChange(e);
    };

    const formatValue = () => {
        if(selectTarget == 'value') {
            let findOption = options.find(ele => ele.value == value);
            return findOption?.label || null;
        }
        return value;
    };

    const onMouseDown = (e) => {
        e.stopPropagation();
        e.preventDefault()
    }

    return (
        <div
            style={style}
            className={className}
            ref={containerRef}
            onMouseDown={onMouseDown}
        >
            <Tooltip
                open={tooltipOpen && !selectFocused}
                placement={tooltipPlacement}
                title={
                    <div className='custom-tool-tip-container'>
                        <p className='custom-tool-tip-title'>{tooltipTitle}</p>
                        {
                            tooltipDesc ?
                                <p className='custom-tool-tip-desc'>{tooltipDesc}</p>
                                :
                                null
                        }
                    </div>
                }
                autoAdjustOverflow
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0.2}
                showArrow={false}
                overlayInnerStyle={{ fontSize: '12px', marginTop: '-10px' }}
                color={'#404040'}
            >
                {/* <Select
                        style={selectStyle}
                        value={value}
                        options={options}
                        placeholder={placeholder}
                        className={selectClassName}
                        size={size}
                        onChange={this.onSelectChange.bind(this)}
                        onMouseEnter={this.onSelectMouseEnter.bind(this)}
                        onMouseLeave={this.onSelectMouseLeave.bind(this)}
                        onFocus={this.onSelectFocus.bind(this)}
                        onMouseDown={this.onMouseDown.bind(this)}
                        onClick={this.onClickSelect.bind(this)}
                        onKeyUp={this.onMouseDown.bind(this)}
                        onKeyDown={this.onMouseDown.bind(this)}
                        onBlur={this.onSelectBlur.bind(this)}
                        suffixIcon={<CaretDownOutlined />}
                        dropdownStyle={selectOptionStyle}
                        dropdownMatchSelectWidth={false}
                    /> */}
                <div
                    ref={selectContainerRef}
                    className={makeClassNameList([
                        'editor-option-select flex-row',
                        selectClassName
                    ])}
                    style={selectStyle}
                    onClick={handleOpendSelect}
                    onMouseDown={onMouseDown}
                    onMouseEnter={onSelectMouseEnter}
                    onMouseLeave={onSelectMouseLeave}
                >
                    {
                        value ?
                            <p className='editor-option-select-value'>{formatValue()}</p>
                            :
                            <p className='editor-option-select-placeholder'>{placeholder}</p>
                    }
                    <div className='editor-option-select-arrow flex-row'>
                        <CaretDownOutlined />
                    </div>
                </div>
            </Tooltip>
            <CustomPopover
                ref={customSelectRef}
                clientX={customPopoverPosition.clientX}
                clientY={customPopoverPosition.clientY}
                contentRender={(props) => (
                    <CustomSelect
                        {...props}
                        options={options}
                        value={value}
                        onSelect={onSelect}
                        selectTarget={selectTarget}
                    />
                )}
            />
        </div>
    )
}


export default forwardRef(SelectWidthToolip)