import React, { forwardRef, useState, useImperativeHandle, ChangeEvent, useRef, useEffect } from 'react';
import { Button, Modal, Input, Table, Space, Form, Pagination, Select, Radio, Image, Upload, DatePicker, UploadProps, DatePickerProps, TreeSelect, Switch, Tabs } from 'antd';
import { WomanOutlined, ManOutlined, PlusOutlined, InboxOutlined, PaperClipOutlined } from '@ant-design/icons';
import SmartMap from '../smart-map';
import systemApi from '../../api/system';
import * as XLSX from "xlsx";
import { commonErrorMsg, commonSuccessMsg, deepCopy, generateTreeData, getChangeEventValue, handleDownloadByName, isEmpty, makeClassNameList, toastShort } from '../../utils';
import LoadingWapper from '../loading/LoadingWapper';
import { BJHSpeciesInfoType, RoleType, SpeciesInfoType, UserType } from '../../utils/types';
import statisticalAnalysisTools from '../../statistical-analysis-temp/src';
import { setSystemConfig } from '../../redux/system.slice';
import redux from './../../redux/store';
import { commonPermissionsMsg } from '../smart-map/utils';
import emitter from '../../utils/emitter';

interface Props {
    onFinish?: Function
};

interface SecurityForm {
    oldPassword?: string
    newPassword?: string
}

const UserCenterModal = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openModal: openModal
    }));

    const {
        onFinish
    } = props;


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [form] = Form.useForm<UserType>();
    const [formData, setFormData] = useState<UserType>({});

    const [securityForm] = Form.useForm<SecurityForm>();
    const [securityFormData, setSecurityFormData] = useState<SecurityForm>({});

    const [roleData, setRoleData] = useState<RoleType[]>([]);
    const [groupList, setGroupList] = useState([])

    const [userInfo, setUserInfo] = useState<UserType>(redux.getState().systemConfig.userInfo);

    const [manPortraitList] = useState<Array<{ name: string, icon: any, url?: string }>>([
        {
            name: "男性头像-1",
            icon: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023032051978eacda-828c-4121-838e-2c0c1439ba6a30.png',
            url: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023032051978eacda-828c-4121-838e-2c0c1439ba6a30.png'
        },
        {
            name: "男性头像-2",
            icon: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023032051978eacda-828c-4121-838e-2c0c1439ba6a30.png',
            url: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023032051978eacda-828c-4121-838e-2c0c1439ba6a30.png',

        },
        {
            name: "男性头像-3",
            icon: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023032024b320e24f-d335-47f9-a076-b73b0cb6622d38.png',
            url: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023032024b320e24f-d335-47f9-a076-b73b0cb6622d38.png',
        },
    ]);
    const [womanPortraitList] = useState<Array<{ name: string, icon: any, url?: string }>>([
        {
            name: "女性头像-1",
            icon: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023032051bc32786d-227e-46e3-a8b0-e328c65d33e429.png',
            url: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023032051bc32786d-227e-46e3-a8b0-e328c65d33e429.png',
        },
        {
            name: "女性头像-2",
            icon: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/202303204356bf3a1e-002b-411c-bb52-a035a4b1bd4132.png',
            url: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/202303204356bf3a1e-002b-411c-bb52-a035a4b1bd4132.png',
        },
        {
            name: "女性头像-3",
            icon: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/202303202909a7b831-b7f3-4914-b27a-b4b59000046447.png',
            url: 'http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/202303202909a7b831-b7f3-4914-b27a-b4b59000046447.png',
        },
    ]);

    const openModal = () => {
        setIsModalOpen(true);
        getRoleList();
        setSecurityFormData({
            newPassword: "",
            oldPassword: ""
        })
        securityForm.setFieldValue("newPassword", "");
        securityForm.setFieldValue("oldPassword", "");
        let tempUserInfo: UserType = JSON.parse(localStorage.getItem("systemConfig"))['userInfo'];
        setFormData(tempUserInfo);
        const { keys, values } = Object;
        const keyList = keys(tempUserInfo);
        const valueList = values(tempUserInfo)
        keyList.forEach((key, index) => {
            form.setFieldValue(key, valueList[index] ? valueList[index] : null)
        })
    }

    const getRoleList = () => {
        let params = {
            pageNum: 999,
            pageSize: 1,
        };
        systemApi.getRoleList(params)
            .then(res => {
                console.log("这是角色---->", res)
                //@ts-ignore
                const dataList: RoleType[] = res.data.list;
                dataList.forEach(ele => {
                    //@ts-ignore
                    ele.label = ele.name;
                    //@ts-ignore
                    ele.value = ele.id;
                })
                console.log("dataList--->", dataList)
                setRoleData(dataList);
            })
            .catch(err => {

            })
    }

    const handleConfirm = () => {
        let requestTaskList = [];
        if (JSON.stringify(userInfo) == JSON.stringify(formData)) {
            console.log("没有变化")
        } else {
            let params = { ...formData };
            console.log("变化了---->", userInfo, params)
            //@ts-ignore
            delete params.password;
            requestTaskList.push(systemApi.editUserInfoBySelf(params));
        }
        if (
            !isEmpty(securityFormData.newPassword) &&
            !isEmpty(securityFormData.oldPassword)
        ) {
            let params = {
                // password: securityFormData.oldPassword,
                // oldPassword: securityFormData.newPassword
                oldPassword: securityFormData.oldPassword,
                password: securityFormData.newPassword
            }
            requestTaskList.push(systemApi.changePasswordBySelf(params));
        }
        if (requestTaskList.length == 0) {
            setIsModalOpen(false);
            return false;
        }
        Promise.all(requestTaskList)
            .then(resList => {
                console.log("res---->", resList)
                toastShort('success', commonSuccessMsg)
                let systemConfig = deepCopy(redux.getState().systemConfig);
                systemConfig.userInfo = deepCopy(formData);
                console.log("更新的用户信息是---->", systemConfig.userInfo)
                localStorage.setItem('systemConfig', JSON.stringify(systemConfig))
                redux.dispatch(setSystemConfig(deepCopy(systemConfig)));
                setIsModalOpen(false);
                emitter.emit('updateUserInfo')
            })
            .catch(err => {
                console.log("err---->", err)
                setIsModalOpen(false);
            })
    };

    const handleCancel = () => {
        setIsModalOpen(false)
    };

    const onNickNameTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData('nickName', getChangeEventValue(e))
    };

    const onPhoneTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData('phone', getChangeEventValue(e))
    };

    const onAccountTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData('account', getChangeEventValue(e))
    };

    const onAccounTypeChange = (value: string) => {
        handleSetFormData('userType', value);
    };

    const onRoleChange = (e) => {
        handleSetFormData('role_id', e)
        handleSetFormData('roleId', e)
    }

    const onDeptChange = (e) => {
        handleSetFormData('department_id', e)
    }

    const handleSetFormData = (target: string, value: string) => {
        let _formData = deepCopy(formData);
        //@ts-ignore
        _formData[target] = value;
        setFormData(_formData);
    };

    const handleSetSecurityFormData = (target: string, value: string) => {
        let _formData = deepCopy(securityFormData);
        //@ts-ignore
        _formData[target] = value;
        setSecurityFormData(_formData);
    };

    const onGroupChange = (e: string) => {
        handleSetFormData('groupId', e)
    }

    const adaptAvatarClassName = (portrait) => {
        let classNameList = ['user-portrait-container'];
        if (portrait.url === formData.avatar) {
            console.log("这是激活的---->", portrait)
            classNameList.push('active')
        }
        return makeClassNameList(classNameList)
    };

    const onGenderChange = (e: any) => {
        handleSetFormData('gender', getChangeEventValue(e))
    }

    const handleSetCurrentAvatar = (portrait) => {
        console.log("portrait---->", portrait)
        handleSetFormData('avatar', portrait.url)
        form.setFieldValue("avatar", portrait.url)
        console.log("formData.avatar--->", formData.avatar)
    }

    const renderUserInfoPanel = () => {
        return (
            <div>
                <Form
                    size={'middle'}
                    form={form}
                    layout={'horizontal'}
                    autoComplete="off"
                    labelAlign={'right'}
                    labelCol={{ span: 6 }}
                >
                    <Form.Item
                        name="nickName"
                        label="姓名"
                        rules={[{ required: true, message: "请输入真实姓名" }]}
                    >
                        <Input
                            style={{ width: '312px' }}
                            placeholder={'请输入真实姓名'}
                            value={formData.nickName}
                            onChange={onNickNameTextChange}
                            maxLength={6}
                            allowClear
                        />
                    </Form.Item>
                    {/* <Form.Item
                        name="phone"
                        label="手机号"
                        rules={[{ required: true, message: "请输入手机号" }]}
                    >
                        <Input
                            style={{ width: '312px' }}
                            placeholder={'请输入手机号'}
                            value={formData.phone}
                            onChange={onPhoneTextChange}
                            maxLength={11}
                            allowClear
                        />
                    </Form.Item> */}
                    <Form.Item
                        name="account"
                        label="账号"
                    >
                        <Input
                            style={{ width: '312px' }}
                            value={formData.account}
                            onChange={onAccountTextChange}
                            placeholder={'请输入账号'}
                            maxLength={11}
                            allowClear
                            disabled
                        />
                    </Form.Item>
                    {/* <Form.Item
                        name="groupId"
                        label="所属单位"
                    >
                        <Select
                            style={{ width: '312px' }}
                            placeholder={'请选择所属单位'}
                            options={groupList}
                            onChange={onGroupChange}
                            value={!isEmpty(formData.groupId) ? formData.groupId : null}
                            disabled
                            allowClear
                        />
                    </Form.Item> */}
                    {/* <Form.Item
                        name="roleId"
                        label="所属角色"
                    >
                        <Select
                            style={{ width: '312px' }}
                            placeholder={'请选择所属角色'}
                            options={roleData}
                            onChange={onRoleChange}
                            value={formData.roleId}
                            disabled
                            allowClear
                        />
                    </Form.Item> */}
                    <Form.Item
                        name="gender"
                        label="性别"
                        rules={[{ required: true, message: "请选择性别" }]}
                    >
                        <Radio.Group
                            onChange={onGenderChange}
                            value={formData.gender}
                        >
                            <Radio.Button value={1}><ManOutlined />&nbsp;男</Radio.Button>
                            <Radio.Button value={2}><WomanOutlined />&nbsp;女</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="avatar" label="头像"
                        rules={[{ required: true, message: "请选择账号头像" }]}

                    >
                        <div className='flex-row'>
                            {
                                formData.gender == '1' ?
                                    manPortraitList.map((portrait, index) => {
                                        return (
                                            <div
                                                key={index + ''}
                                                className={adaptAvatarClassName(portrait)}
                                                onClick={() => handleSetCurrentAvatar(portrait)}
                                            >
                                                <Image
                                                    src={portrait.url}
                                                    className={'user-portrait'}
                                                    preview={false}
                                                    draggable={false}
                                                />
                                            </div>
                                        )
                                    })
                                    :
                                    womanPortraitList.map((portrait, index) => {
                                        return (
                                            <div
                                                key={index + ''}
                                                className={adaptAvatarClassName(portrait)}
                                                onClick={() => handleSetCurrentAvatar(portrait)}
                                            >
                                                <Image
                                                    src={portrait.url}
                                                    className={'user-portrait'}
                                                    preview={false}
                                                    draggable={false}
                                                />
                                            </div>
                                        )
                                    })
                            }
                            <Upload
                                accept={'.png,.jpg,.jpeg'}
                                disabled
            
                            >
                                <div onClick={() => toastShort('warning', commonPermissionsMsg)} className='user-portrait-container'>
                                    <PlusOutlined style={{ fontSize: 16, marginBottom: '-4px' }} />
                                    <div style={{ marginTop: 8, fontSize: 12 }}>自定义</div>
                                </div>
                            </Upload>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        )
    }

    const onOldPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetSecurityFormData('oldPassword', getChangeEventValue(e))
    }

    const onNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetSecurityFormData('newPassword', getChangeEventValue(e))
    }

    const renderAccountSecurityPanel = () => {
        return (
            <div>
                <Form
                    size={'middle'}
                    form={securityForm}
                    layout={'horizontal'}
                    autoComplete="off"
                    labelAlign={'right'}
                    labelCol={{ span: 6 }}
                >
                    <Form.Item
                        name="oldPassword"
                        label="旧密码"
                        rules={[{ required: true, message: "请输入账号当前的密码" }]}
                    >
                        <Input.Password
                            style={{ width: '312px' }}
                            placeholder={'请输入账号当前的密码'}
                            value={securityFormData.oldPassword}
                            onChange={onOldPasswordChange}
                            maxLength={12}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="newPassword"
                        label="新密码"
                        rules={[{ required: true, message: "请输入需要设定的新密码" }]}
                    >
                        <Input.Password
                            style={{ width: '312px' }}
                            placeholder={'请输入需要设定的新密码'}
                            value={securityFormData.newPassword}
                            onChange={onNewPasswordChange}
                            maxLength={12}
                            allowClear
                        />
                    </Form.Item>
                </Form>
            </div>
        )
    }

    const tabs = [
        {
            key: '1',
            label: '个人信息',
            children: renderUserInfoPanel(),
        },
        {
            key: '2',
            label: '修改密码',
            children: renderAccountSecurityPanel()
        },
    ]

    return (
        <Modal
            title={'个人中心'}
            open={isModalOpen}
            onOk={handleConfirm}
            onCancel={handleCancel}
            okText={'确 认'}
            cancelText={'取 消'}
            width={'800px'}
            maskClosable={false}
            confirmLoading={submitLoading}
            centered
        >
            <div style={{ height: 420 }} className='custom-modal-container'>
                <Tabs
                    className='user-center-modal'
                    items={tabs}
                    tabPosition={'left'}
                />
            </div>
        </Modal>
    )
}


export default forwardRef(UserCenterModal);