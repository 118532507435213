import React, { CSSProperties, forwardRef, useImperativeHandle, useState } from "react";
import { ColSpanInfo, RowSpanInfo } from "../../../utils/types";
import { replaceUnitUpperCase } from "../../../utils";

interface Props {
    tableData: string[][],
    tableHeaderRowIndex?: number
    tableColSpanList?: ColSpanInfo[]
    tableRowSpanList?: RowSpanInfo[]
    tableStyle?: CSSProperties
}

const SubTablePreView = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
    }))

    const {
        tableData = [],
        tableHeaderRowIndex = 0,
        tableColSpanList = [],
        tableRowSpanList = [],
        tableStyle = {}
    } = props;

    // const [tableData, setTableData] = useState([]);
    // const [tableColSpanList, setCurrentTableColSpanList] = useState<ColSpanInfo[]>([]);

    const findRowSpanInfo = (rowIndex: number, columnIndex: number): RowSpanInfo => {
        const rowSpanInfo = tableRowSpanList.find(rowSpanItem => {
            return rowSpanItem.colIndex == columnIndex && rowSpanItem.rowIndex === rowIndex;
        })
        if (rowSpanInfo && rowSpanInfo.rowSpan) {
            return rowSpanInfo;
        }
        return null;
    }

    const findColSpanInfo = (rowIndex: number, columnIndex: number): ColSpanInfo => {
        const colSpanInfo = tableColSpanList.find(colSpanItem => {
            return colSpanItem.colIndex == columnIndex && colSpanItem.rowIndex == rowIndex;
        })
        if (colSpanInfo && colSpanInfo) {
            return colSpanInfo;
        }
        return null;
    }

    const checkTdNeedRander = (rowIndex: number, colIndex: number): boolean => {
        let needRander: boolean = true;
        //检查tableRowSpanList, 如果colIndex 相等，判断是否是rowIndex + colSpan区间内
        tableRowSpanList.forEach(tableRowSpanItem => {
            if (
                tableRowSpanItem.colIndex === colIndex &&
                rowIndex > tableRowSpanItem.rowIndex &&
                rowIndex < tableRowSpanItem.rowIndex + tableRowSpanItem.rowSpan
            ) {
                needRander = false;
            }
        })
        tableColSpanList.forEach(tableColSpanItem => {
            if (
                tableColSpanItem.rowIndex === rowIndex &&
                colIndex > tableColSpanItem.colIndex &&
                colIndex < tableColSpanItem.colIndex + tableColSpanItem.colSpan
            ) {
                needRander = false;
            }
        })
       
        return needRander;
    }

    const getLatinColIndex = () => {
        return tableData[0].findIndex(cellText => cellText == '拉丁文名');
    }

    return (
        <div>
            <table 
                border={1} 
                className='word-table'
                style={tableStyle}
            >
                <tbody>
                    {
                        tableData.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex + ''}>
                                    {
                                        row.map((cellValue, colIndex) => (
                                            <React.Fragment
                                                key={colIndex + ''}
                                            >
                                                {
                                                    checkTdNeedRander(rowIndex, colIndex) ?
                                                        <td
                                                            // key={colIndex + ''}
                                                            style={{ 
                                                                fontWeight: rowIndex <= tableHeaderRowIndex ? 'bold' : 'normal', 
                                                                textAlign: 'center',
                                                                fontStyle: getLatinColIndex() > -1 && colIndex == getLatinColIndex() &&  rowIndex > tableHeaderRowIndex ? 'italic' : 'normal',
                                                                fontFamily:  getLatinColIndex() > -1 && colIndex == getLatinColIndex() &&  rowIndex > tableHeaderRowIndex ? 'TimesNewRoman' : 'SourceHanSerif',
                                                            }}
                                                            //纵向合并
                                                            rowSpan={findRowSpanInfo(rowIndex, colIndex) ? findRowSpanInfo(rowIndex, colIndex).rowSpan : 1}
                                                            //横向合并
                                                            colSpan={findColSpanInfo(rowIndex, colIndex) ? findColSpanInfo(rowIndex, colIndex).colSpan : 1}
                                                        >
                                                            {replaceUnitUpperCase(cellValue)}
                                                        </td>
                                                        :
                                                        null
                                                }
                                            </React.Fragment>

                                        ))}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default forwardRef(SubTablePreView);