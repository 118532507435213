import { useEffect, useState } from 'react';

import { offFormatPainter, onFormatPainter } from '../utils';

export const useFormatPainterActive = () => {
  const [activated, setActivated] = useState(false);
  useEffect(() => {
    const listener = (activated: boolean) => setActivated(activated);
    onFormatPainter(listener);
    return () => offFormatPainter(listener);
  }, []);
  return activated;
};
