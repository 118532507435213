import React, { ReactElement, ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TypeLayoutPosition } from '../../types';
import { convertToHex, makeClassNameList } from '../../utils';
import { RightOutlined } from '@ant-design/icons';
import './custom-popover.scss';
import CustomColorPicker from '../color-picker/CustomColorPicker';
import CustomPopover from './CustomPopover';
import { CellBorderInfo, CellBorderStyle, SelectOption } from '../../utils/types';
import CustomSelect from '../select/CustomSelect';

interface Props {
    contentRender?: (props: { isLayoutShow: boolean, handleClose: Function }) => ReactNode
    clientY: number
    clientX: number
    onDispathCellBorderStyle: Function
    disableBackground?: boolean
}


const CellBorderPopover = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        show,
        hide
    }))

    const {
        contentRender,
        clientX,
        clientY,
        disableBackground = false,
        onDispathCellBorderStyle
    } = props;

    const subColorPickerPopoverRef = useRef(null);

    const [isLayoutShow, setIsLayoutShow] = useState<boolean>(false);
    const [isColorPickerLayoutShow, setIsColorPickerLayoutShow] = useState<boolean>(false);
    const [isCellBorderStylePickerLayoutShow, setIsCellBorderStylePickerLayoutShow] = useState<boolean>(false);

    const customPopoverRef = useRef(null);
    const customPopoverOverlayRef = useRef(null);
    const cellBorderStylePopoverRef = useRef(null);

    const [borderStyleOptions] = useState<SelectOption[]>([
        //单倍线
        {
            label: 'Thin',
            value: '1',
            icon: (
                <div
                    style={{ backgroundColor: '#000000', height: 2 }}
                    className='flex-row cell-border-eg'
                >
                </div>
            )
        },
        {
            label: 'Hair',
            value: '2',
            icon: (
                <div
                    className='flex-row cell-border-eg'
                    style={{ height: 2 }}
                >
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                </div>
            )
        },
        {
            label: 'Dotted',
            value: '3',
            icon: (
                <div
                    className='flex-row cell-border-eg'
                    style={{ height: 2 }}
                >
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dash'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dash'></div>
                    <div className='cell-border-dot'></div>
                </div>
            )
        },
        {
            label: 'DashDotDot',
            value: '5',
            icon: (
                <div
                    className='flex-row cell-border-eg'
                    style={{ height: 2 }}
                >
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dash'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                </div>
            )
        },
        {
            label: 'Medium',
            value: '8',
            icon: (
                <div
                    style={{ backgroundColor: '#000000', height: 3 }}
                    className='flex-row cell-border-eg'
                >
                </div>
            )
        },
        {
            label: 'MediumDashDot',
            value: '10',
            icon: (
                <div
                    className='flex-row cell-border-eg'
                    style={{ height: 3 }}
                >
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                </div>
            )
        },
        {
            label: 'MediumDashed',
            value: '9',
            icon: (
                <div
                    className='flex-row cell-border-eg'
                    style={{ height: 3 }}
                >
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dash'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dash'></div>
                    <div className='cell-border-dot'></div>
                </div>
            )
        },
        {
            label: '4',
            value: '4',
            icon: (
                <div
                    className='flex-row cell-border-eg'
                    style={{ height: 3 }}
                >
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dash'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                    <div className='cell-border-dot'></div>
                </div>
            )
        },
        {
            label: 'Thick',
            value: '13',
            icon: (
                <div
                    style={{ backgroundColor: '#000000', height: 4 }}
                    className='flex-row cell-border-eg'
                >
                </div>
            )
        },
    ])
    const [currentBorderStyle, setCurrentBorderStyle] = useState<CellBorderStyle>(1);
    const [currentBorderColor, setCurrentBorderColor] = useState<string>('#000000')

    const delayTimer = useRef(null);

    useEffect(() => {
        //@ts-ignore
        setCurrentBorderStyle(localStorage.getItem('currentBorderStyle') ? localStorage.getItem('currentBorderStyle') : 1);
        setCurrentBorderColor(localStorage.getItem('currentBorderColor') ? localStorage.getItem('currentBorderColor') : '#000000');
        return () => {
            delayTimer && clearTimeout(delayTimer.current);
        }
    }, [])

    const show = () => {
        customPopoverOverlayRef.current.style.zIndex = 1002;
        customPopoverOverlayRef.current.style.width = '100%';
        customPopoverOverlayRef.current.style.height = '100%';
        setIsLayoutShow(true)
    }

    const hide = () => {
        handleClosePopover();
    };


    const handleClosePopover = () => {
        requestAnimationFrame(() => {
            setIsLayoutShow(false)
        })
        delayTimer.current = setTimeout(() => {
            customPopoverOverlayRef.current.style.zIndex = -1;
            customPopoverOverlayRef.current.style.width = '0%';
            customPopoverOverlayRef.current.style.height = '0%';
        }, 200);
    };

    const onClickPopoverContainer = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const onMouseDown = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const _onPickerBorderColor = (e) => {
        // console.log("_onPickerBorderColor--->", e)
        const hex = convertToHex(e);
        // console.log("hex--->", hex)
        setCurrentBorderColor(hex);
        localStorage.setItem('currentBorderColor', hex)
        handleCloseColorPicker();
    }

    const handleCloseColorPicker = () => {
        subColorPickerPopoverRef.current._handleClose();
        _onClosePickerBorderColor();
    }

    const handleCloseCellBorderStylePicker = () => {
        cellBorderStylePopoverRef.current._handleClose();
        _onCloseBorderStylePopover();
    }

    const handleOpenSubColorPickerPopover = () => {
        if (isColorPickerLayoutShow) {
            subColorPickerPopoverRef.current._handleClose();
        } else {
            subColorPickerPopoverRef.current.show();
            handleCloseCellBorderStylePicker();
        }
        setIsColorPickerLayoutShow(!isColorPickerLayoutShow)
    }

    const handleOpenBorderStylePopover = () => {
        if (isCellBorderStylePickerLayoutShow) {
            cellBorderStylePopoverRef.current._handleClose();
        } else {
            cellBorderStylePopoverRef.current.show();
            handleCloseColorPicker();
        }
        setIsCellBorderStylePickerLayoutShow(!isCellBorderStylePickerLayoutShow)
    }

    const _onClosePickerBorderColor = () => {
        setIsColorPickerLayoutShow(false)
    }

    const onSelectBorderStyleOptions = (e: string) => {
        console.log("onSelectBorderStyleOptions--->", e)
        //@ts-ignore
        setCurrentBorderStyle(e);
        localStorage.setItem('currentBorderStyle', e)
        handleCloseCellBorderStylePicker();
    }

    const _onCloseBorderStylePopover = () => {
        setIsCellBorderStylePickerLayoutShow(false)
    }

    const handlePickerCellBorderType = (borderType: string) => {
        console.log("handlePickerCellBorderType---->", borderType)
        let borderStyleList: CellBorderInfo[] = [];
        switch (borderType) {
            case '1':
                borderStyleList.push({
                    borderType: 'border-none',
                    style: 0,
                    color: currentBorderColor
                })
                break;
            case '2':
                borderStyleList.push({
                    borderType: 'border-all',
                    style: currentBorderStyle,
                    color: currentBorderColor
                })
                break;
            case '3':
                borderStyleList.push({
                    borderType: 'border-outside',
                    style: currentBorderStyle,
                    color: currentBorderColor
                })
                break;
            case '4':
                borderStyleList.push({
                    borderType: 'border-outside',
                    style: 13,
                    color: currentBorderColor
                })
                break;
            case '5':
                borderStyleList.push({
                    borderType: 'border-bottom',
                    style: currentBorderStyle,
                    color: currentBorderColor
                })
                break;
            case '6':
                borderStyleList.push({
                    borderType: 'border-top',
                    style: currentBorderStyle,
                    color: currentBorderColor
                })
                break;
            case '7':
                borderStyleList.push({
                    borderType: 'border-left',
                    style: currentBorderStyle,
                    color: currentBorderColor
                })
                break;
            case '8':
                borderStyleList.push({
                    borderType: 'border-right',
                    style: currentBorderStyle,
                    color: currentBorderColor
                })
                break;
            case '9':
                borderStyleList.push({
                    borderType: 'border-bottom',
                    style: 8,
                    color: currentBorderColor
                })
                break;
            case '10':
                borderStyleList.push({
                    borderType: 'border-top',
                    style: currentBorderStyle,
                    color: currentBorderColor
                })
                borderStyleList.push({
                    borderType: 'border-bottom',
                    style: currentBorderStyle,
                    color: currentBorderColor
                })
                break;
            case '11':
                borderStyleList.push({
                    borderType: 'border-top',
                    style: currentBorderStyle,
                    color: currentBorderColor
                })
                borderStyleList.push({
                    borderType: 'border-bottom',
                    style: 8,
                    color: currentBorderColor
                })
                break;
            default:
                break;
        }
        hide();
        onDispathCellBorderStyle && onDispathCellBorderStyle(borderStyleList);

    }   

    return (
        <div
            className='custom-popover-overlay'
            ref={customPopoverOverlayRef}
            onClick={handleClosePopover}
            onMouseDown={onMouseDown}
        >
            <div
                ref={customPopoverRef}
                style={{
                    top: clientY + 'px',
                    left: clientX + 'px',
                }}
                className={makeClassNameList([
                    'custom-popover',
                    isLayoutShow ? 'show' : null,
                    // disableBackground ? 'custom-popover-no-background' : null
                ])}
                onClick={onClickPopoverContainer}
            >
                <div className='flex-col cell-border-select-container'>
                    <div
                        className='flex-row cell-border-select-item'
                        onClick={() => handlePickerCellBorderType('1')}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-wukuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>无框线</div>
                        </div>
                    </div>
                    <div
                        className='flex-row cell-border-select-item'
                        onClick={() => handlePickerCellBorderType('2')}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-suoyoukuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>所有框线</div>
                        </div>
                    </div>
                    <div
                        className='flex-row cell-border-select-item'
                        onClick={() => handlePickerCellBorderType('3')}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-waicekuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>外侧框线</div>
                        </div>
                    </div>
                    <div
                        className='flex-row cell-border-select-item'
                        onClick={() => handlePickerCellBorderType('4')}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-cuxiakuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>粗闸框线</div>
                        </div>
                    </div>
                    <div className='flex-row cell-border-select-border'></div>
                    <div
                        className='flex-row cell-border-select-item'
                        onClick={() => handlePickerCellBorderType('5')}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-xiakuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>下框线</div>
                        </div>
                    </div>
                    <div
                        className='flex-row cell-border-select-item'
                        onClick={() => handlePickerCellBorderType('6')}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-shangkuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>上框线</div>
                        </div>
                    </div>
                    <div
                        className='flex-row cell-border-select-item'
                        onClick={() => handlePickerCellBorderType('7')}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-zuokuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>左框线</div>
                        </div>
                    </div>
                    <div
                        className='flex-row cell-border-select-item'
                        onClick={() => handlePickerCellBorderType('8')}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-youkuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>右框线</div>
                        </div>
                    </div>
                    <div className='flex-row cell-border-select-border'></div>
                    {/* <div className='flex-row cell-border-select-item'>
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-shuangdikuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>双底框线</div>
                        </div>
                    </div> */}
                    <div
                        className='flex-row cell-border-select-item'
                        onClick={() => handlePickerCellBorderType('9')}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-cudikuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>粗底框线</div>
                        </div>
                    </div>
                    <div
                        className='flex-row cell-border-select-item'
                        onClick={() => handlePickerCellBorderType('10')}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-shangxiakuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>上下框线</div>
                        </div>
                    </div>
                    <div
                        className='flex-row cell-border-select-item'
                        onClick={() => handlePickerCellBorderType('11')}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-shangkuangxianhecuxiakuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>上框线和粗下框线</div>
                        </div>
                    </div>
                    {/* <div className='flex-row cell-border-select-item'>
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-shangkuangxianheshuangxiakuangxian' />
                            </div>
                            <div className='cell-border-select-item-name'>上框线和双下框线</div>
                        </div>
                    </div> */}
                    <div className='flex-row cell-border-select-border'></div>
                    <div
                        onClick={handleOpenSubColorPickerPopover}
                        className={
                            makeClassNameList([
                                'flex-row cell-border-select-item',
                                isColorPickerLayoutShow ? 'hover' : null
                            ])
                        }
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='flex-col cell-border-select-item-icon'>
                                <i className='iconfont-2 ghzs-gray-tubiao5' />
                                <div
                                    className='cell-border-color'
                                    style={{ backgroundColor: currentBorderColor }}
                                ></div>
                            </div>
                            <div className='cell-border-select-item-name'>框线颜色</div>
                        </div>
                        <div className='cell-border-select-item-right'>
                            <RightOutlined />
                        </div>
                    </div>
                    <div
                        className={
                            makeClassNameList([
                                'flex-row cell-border-select-item',
                                isCellBorderStylePickerLayoutShow ? 'hover' : null
                            ])
                        }
                        onClick={handleOpenBorderStylePopover}
                    >
                        <div className='flex-row cell-border-select-item-left'>
                            <div className='cell-border-select-item-icon'>
                                <i className='iconfont-1 ghzs-gray-quxiaodongjie' />
                            </div>
                            <div className='cell-border-select-item-name'>框线形状</div>
                        </div>
                        <div className='cell-border-select-item-right'>
                            <RightOutlined />
                        </div>
                    </div>
                </div>
            </div>
            <CustomPopover
                ref={subColorPickerPopoverRef}
                clientX={1020}
                clientY={279}
                animatedDirection={'leftRight'}
                disableBackground
                contentRender={(props) =>
                    <CustomColorPicker
                        {...props}
                        mode={'dark'}
                        onPickerColor={_onPickerBorderColor}
                        handleClose={_onClosePickerBorderColor}
                        screenName={'框线颜色'}
                        screenTag={'cellBorderColor'}
                    />
                }
            />
            <CustomPopover
                ref={cellBorderStylePopoverRef}
                clientX={1020}
                clientY={290}
                animatedDirection={'leftRight'}
                contentRender={(props) =>
                    <CustomSelect
                        {...props}
                        options={borderStyleOptions}
                        //@ts-ignore
                        value={currentBorderStyle}
                        hideLable
                        onSelect={onSelectBorderStyleOptions}
                        handleClose={_onCloseBorderStylePopover}
                        selectTarget={'value'}
                    />
                }
            />
        </div>
    )
}

export default forwardRef(CellBorderPopover);

