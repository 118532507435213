import { useEffect, useRef } from "react"
import { Dropdown, MenuProps } from "antd";
import emitter from "../../../utils/emitter";
import { Down, Drag } from "@icon-park/react";

const ExcelEditorNextBtn = () => {

    const dropDownBtnRef = useRef(null);

    const timer = useRef(null);

    const delaySaveTimer = useRef(null);

    useEffect(() => {
        const postion = localStorage.getItem('newPosition');
        if (postion) {
            const newPosition = JSON.parse(postion);
            nextBtnPosition.current = newPosition;
            dropDownBtnRef.current.style.top = newPosition.top + 'px';
            dropDownBtnRef.current.style.left = newPosition.left + 'px';
            dropDownBtnRef.current.style.right = 'none';
            dropDownBtnRef.current.style.bottom = 'none';
        }
        return () => {
            timer.current && clearTimeout(timer.current);
            delaySaveTimer.current && clearTimeout(delaySaveTimer.current);
        }
    })
    const nextMenuList: MenuProps['items'] = [
        {
            key: '1',
            icon: <img style={{ width: 20, marginLeft: -5 }} src={require('./../../../assets/image/export-excel-file.png')} />,
            label: '导出',
        },
        {
            key: '2',
            icon: <i style={{ color: '#262626' }} className='iconfont-1 ghzs-gray-baocun' />,
            label: '保存',
        },
    ]

    const dispatchNextBtn = (e) => {
        if (timer.current) {
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(() => {
            switch (e.key) {
                case "1":
                    emitter.emit('emitExportExcel')
                    break;
                case "2":
                    emitter.emit('emitHotSaveDocInstance')
                    break;
                default:
                    break;
            }
        }, 200);
        e.preventDefault();
        e.stopPropagation();
    }

    const handleJumpToWordPage = () => {
        if (isDragging.current) {
            return false;
        }
        emitter.emit('AutoSwitchNavType', { newNavType: 'word' })
    };

    const isDragging = useRef(false);
    const monseDowned = useRef(false);

    const parentPosition = useRef({
        x: 0,
        y: 0,
    })

    const nextBtnPosition = useRef({
        left: 0,
        top: 0,
    })

    const onBtnMonseDown = (e) => {
        const contentEl = document.getElementById('ExcelV2ContentId');
        if (contentEl) {
            const rect = contentEl.getBoundingClientRect();
            parentPosition.current = {
                x: rect.left,
                y: rect.top
            }
        }
        e.preventDefault();
        e.stopPropagation();
        monseDowned.current = true;
    };

    const onBtnContentMouseUp = (e) => {
        if (isDragging.current) {
            setTimeout(() => {
                isDragging.current = false;
            }, 100);
        } else {
            handleJumpToWordPage();
        }
        monseDowned.current = false;
    }

    const onBtnMonseLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        monseDowned.current = false;
        isDragging.current = false;
    };

    const onBtnMouseMove = (e) => {
        if (monseDowned.current) {
            isDragging.current = true;
        }else {
            return;
        }
        const btnEl = document.getElementById("ExcelNextBtnContentId");
        const _parentPos = parentPosition.current;
        if (isDragging.current) {
            const newPosition = {
                left: e.pageX - _parentPos.x - 12,
                top: e.pageY - _parentPos.y - 16,
            };
            if (newPosition.left < 10) {
                newPosition.left = 10;
            }
            if (newPosition.top < 10) {
                newPosition.top = 10;
            }
            nextBtnPosition.current = newPosition;
            dropDownBtnRef.current.style.top = newPosition.top + 'px';
            dropDownBtnRef.current.style.left = newPosition.left + 'px';
            dropDownBtnRef.current.style.right = 'none';
            dropDownBtnRef.current.style.bottom = 'none';
            if (delaySaveTimer.current) {
                clearTimeout(delaySaveTimer.current)
            }
            setTimeout(() => {
                localStorage.setItem("newPosition", JSON.stringify(newPosition))
            }, 100);
        }
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div
            ref={dropDownBtnRef}
            className='flex-row excel-v2-float-btn'

        >
            <Dropdown.Button
                menu={{
                    items: nextMenuList,
                    onClick: dispatchNextBtn,
                }}
                type={"primary"}
                icon={<Down />}
                autoAdjustOverflow
                arrow
            >
                <div
                    id="ExcelNextBtnContentId"
                    className="flex-row excel-next-btn-content"
                    onMouseUp={onBtnContentMouseUp}
                    onMouseLeave={onBtnMonseLeave}
                    onMouseDown={onBtnMonseDown}
                    onMouseMove={onBtnMouseMove}
                >
                    <div className="flex-row excel-next-btn-drag-handle">
                        <Drag
                            theme="outline"
                            size="16"
                            fill="#fff"
                        />
                    </div>
                    <div className="flex-row excel-next-btn-inner">
                        下一步
                    </div>
                </div>
            </Dropdown.Button>
        </div>
    )
}


export default ExcelEditorNextBtn;