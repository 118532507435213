import { CheckOne } from '@icon-park/react';
import { saasConfig } from '../config/theme-config';
import './guide.scss'
import { Button } from 'antd';
import { showGuide } from './guide-steps-config';
import { useEffect, useRef, useState } from 'react';
import systemApi from '../api/system';
import { isEmpty } from '../utils';

const WelComePage = () => {

    const [submitLoading, setSubmitLoading] = useState(false);

    const checkGuideStep = () => {
        if (saasConfig.systemType !== 'HuaWeiTestServer') {
            return;
        }
        systemApi.getUserGuideCurrentStep({})
            .then(res => {
                if (res && res.data) {
                    //@ts-ignore
                    if (isEmpty(res.data.guideNowStep)) {
                        //@ts-ignore
                        localStorage.setItem('guideNowStep', res.data.guideNowStep)
                        welcomePageRef.current.style.zIndex = 9999;
                        welcomePageRef.current.style.display = 'flex';
                        welcomePageRef.current.style.width = '100vw';
                        welcomePageRef.current.style.height = '100vh';
                        welcomePageRef.current.style.opacity = 1;
                    }
                }
            })
            .catch(err => {
            })
    };

    useEffect(() => {
        welcomePageRef.current.style.zIndex = -999;
        welcomePageRef.current.style.display = 'none';
        welcomePageRef.current.style.width = 0;
        welcomePageRef.current.style.height = 0;
        checkGuideStep();
    }, []);

    const welcomePageRef = useRef<any>(null);

    const handleHide = async () => {
        setSubmitLoading(true);
        const res = await systemApi.updateUserGuideCurrentStep({ guideNowStep: 'homePage' });
        setSubmitLoading(false);
        welcomePageRef.current.style.zIndex = -999;
        welcomePageRef.current.style.display = 'none';
        welcomePageRef.current.style.width = 0;
        welcomePageRef.current.style.height = 0;
        showGuide('HomePage')
    }

    return (
        <div
            ref={welcomePageRef}
            className='flex-col welcome-page'
        >
            <div
                className='flex-row welcome-page-title-1 fade-in-1'
                style={{ marginTop: 50, justifyContent: 'center' }}
            >
                Hi!  欢迎使用
            </div>
            <div className='flex-row welcome-page-title-1  fade-in-2' style={{ marginTop: 20, marginBottom: 28, justifyContent: 'center' }}>
                {saasConfig.systemNameFirst}{saasConfig.systemNameSecond}
            </div>
            <div className='flex-row welcome-page-title-2  fade-in-3'>
                <CheckOne theme="outline" size="24" fill="#fff" style={{ marginRight: 6 }} />
                提供丰富规划项目库
            </div>
            <div className='flex-row welcome-page-title-2  fade-in-4'>
                <CheckOne theme="outline" size="24" fill="#fff" style={{ marginRight: 6 }} />
                智能生成投资估算表
            </div>
            <div className='flex-row welcome-page-title-2  fade-in-5'>
                <CheckOne theme="outline" size="24" fill="#fff" style={{ marginRight: 6 }} />
                表文联动高效编规划
            </div>
            <div
                className='flex-row fade-in-6'
                style={{ marginTop: 62 }}
            >
                <Button
                    type="primary"
                    style={{ backgroundColor: '#F2994A' }}
                    size={'large'}
                    onClick={handleHide}
                    loading={submitLoading}
                >一起编制规划报告吧！</Button>
            </div>
        </div>
    )
}

export default WelComePage;