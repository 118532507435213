import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import OptionBarBtn from "../../../components/option-bar-btn/OptionBarBtn";
import { AlignItem, BgColorItem, BoldItem, ColorItem, DeleteItem, FontFamilyItem, FontSizeItem, FontSizeOperationItem, ItalicItem, SubItem, SupItem, UnderlineItem } from "../../slate-editor/components/toolbar/components";
import { toastShort } from "../../../utils";
import { commonPermissionsMsg } from "../../../components/smart-map/utils";

interface Props {
    docEditorRef: any
    visible: boolean
}

const WordInlineStyleOptionBar = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({

    }))

    const {
        docEditorRef,
        visible
    } = props;

    return (
        <>
            <div className='edit-page-option-row-item flex-col' style={{ width: '184px', paddingLeft: '12px' }}>
                <div className='edit-page-option-row-half flex-row'>
                    <FontFamilyItem editor={docEditorRef} />
                    <FontSizeItem editor={docEditorRef} />
                </div>
                <div className='edit-page-option-row-half text-edit-row-1 flex-row'>
                    <BoldItem editor={docEditorRef} />
                    <ItalicItem editor={docEditorRef} />
                    <UnderlineItem editor={docEditorRef} />
                    <DeleteItem editor={docEditorRef} />
                </div>
            </div>
            <div className='edit-page-option-row-item flex-col' style={{ width: '186px', paddingLeft: '10px' }}>
                <div className='edit-page-option-row-half text-edit-row-1 flex-row'>
                    <FontSizeOperationItem isIncrease={true} editor={docEditorRef} />
                    <FontSizeOperationItem isIncrease={false} editor={docEditorRef} />
                    <BgColorItem editor={docEditorRef} visible={visible}/>
                    <AlignItem editor={docEditorRef} />
                    
                </div>
                <div className='edit-page-option-row-half text-edit-row-1 flex-row'>
                    <SupItem editor={docEditorRef} />
                    <SubItem editor={docEditorRef} />
                    <ColorItem editor={docEditorRef} visible={visible}/>
                    {/* <AlignItem editor={docEditorRef} /> */}
                    <OptionBarBtn
                        icon={(
                            <div className='custom-option-bar-icon-container'>
                                <i style={{ fontSize: 19, marginLeft: 2 }} className='iconfont ghzs-color-youxubiaoge' />
                            </div>
                        )}
                        size={'small'}
                        tooltipTitle={"序号"}
                        selectEnable={false}
                        style={{opacity: 0, pointerEvents: 'none'}}
                        // onClick={() => toastShort('warning', commonPermissionsMsg)}
                        // onClickArrow={() => toastShort('warning', commonPermissionsMsg)}
                    />
                </div>
            </div>
        </>
    )
}


export default forwardRef(WordInlineStyleOptionBar);