import React, { useCallback } from 'react';

import { Button } from 'antd';
import { Editor, Element as SlateElement, Transforms } from 'slate';

import { ItemProps } from '../types';
import OptionBarBtn from '../../../../../components/option-bar-btn/OptionBarBtn';

/**
 * 格式擦工具项
 */
export const FormatEraseItem = React.memo(function FormatEraseItem({ editor }: ItemProps) {
  const onMouseDown = useCallback(() => {
    const curEditor = editor?.current?.editor;
    if (!curEditor) return;
    Editor.removeMark(curEditor, 'sup');
    Editor.removeMark(curEditor, 'sub');
    Editor.removeMark(curEditor, 'delete');
    Editor.removeMark(curEditor, 'underline');
    Editor.removeMark(curEditor, 'italic');
    Editor.removeMark(curEditor, 'fontSize');
    Editor.removeMark(curEditor, 'fontFamily');
    Editor.removeMark(curEditor, 'heading');
    Editor.removeMark(curEditor, 'color');
    Editor.removeMark(curEditor, 'bold');
    Editor.removeMark(curEditor, 'bgColor');
    // @ts-ignore
    Transforms.setNodes<SlateElement>(curEditor, { align: undefined });
  }, [editor]);
  // return <Button onMouseDown={onMouseDown}>格式擦</Button>;
  return (
    <OptionBarBtn
      icon={<i style={{ fontSize: '22px' }} className='iconfont-1 ghzs-gray-xiangpica' />}
      size={'large'}
      name={'格式擦'}
      tooltipTitle={'格式擦'}
      tooltipDesc={'清除光标所选文本段落的样式'}
      onMouseDown={onMouseDown}
    />
  )
});
