import React from 'react';
//@ts-ignore
import { SketchPicker } from 'react-color';
import { commonErrorMsg, convertToHex, hexToRgba, makeClassNameList, toastShort } from '../../utils';
import ColorPickerItem from './ColorPickerItem';
import './CustomColorPicker.scss';
import { Button } from 'antd';

interface Props {
    isLayoutShow: boolean
    onPickerColor: Function
    handleClose?: Function
    mode?: 'light' | 'dark'
    disableCustomColor?: boolean
    screenName?: string
    screenTag?: string
}

interface State {
    presetColorList: Array<{
        name: string
        color: string
    }>
    commonlyColorList: Array<{
        name?: string
        color: string
    }>
    showColorPickerBox: boolean
    pickerColorValue: string
}

export default class CustomColorPicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    };

    public colorPickerBoxContainerRef: any = null;

    public readonly state: Readonly<State> = {
        presetColorList: [
            {
                name: '薄暮-1',
                color: '#f5222d',
            },
            {
                name: '火山-1',
                color: '#fa541c',
            },
            {
                name: '日暮-1',
                color: '#fa8c16',
            },
            {
                name: '金盏花-1',
                color: '#faad14',
            },
            {
                name: '日出-1',
                color: '#fadb14',
            },
            {
                name: '极光绿-1',
                color: '#52c41a',
            },
            {
                name: '明青-1',
                color: '#13c2c2',
            },
            {
                name: '拂晓蓝-1',
                color: '#1677ff',
            },
            {
                name: '极客蓝-1',
                color: '#2f54eb',
            },
            {
                name: '薄暮-1',
                color: '#eb2f96',
            },
            //
            {
                name: '薄暮-2',
                color: '#ffccc7',
            },
            {
                name: '火山-2',
                color: '#ffd8bf',
            },
            {
                name: '日暮-2',
                color: '#ffe7ba',
            },
            {
                name: '金盏花-2',
                color: '#fff1b8',
            },
            {
                name: '日出-2',
                color: '#ffffb8',
            },
            {
                name: '极光绿-2',
                color: '#d9f7be',
            },
            {
                name: '明青-2',
                color: '#b5f5ec',
            },
            {
                name: '拂晓蓝-2',
                color: '#bae0ff',
            },
            {
                name: '极客蓝-2',
                color: '#d6e4ff',
            },
            {
                name: '法式洋红-2',
                color: '#ffd6e7',
            },
            //
            {
                name: '薄暮-3',
                color: '#ff7875',
            },
            {
                name: '火山-3',
                color: '#ff9c6e',
            },
            {
                name: '日暮-3',
                color: '#ffc069',
            },
            {
                name: '金盏花-3',
                color: '#ffd666',
            },
            {
                name: '日出-3',
                color: '#fff566',
            },
            {
                name: '极光绿-3',
                color: '#95de64',
            },
            {
                name: '明青-3',
                color: '#5cdbd3',
            },
            {
                name: '拂晓蓝-3',
                color: '#69b1ff',
            },
            {
                name: '极客蓝-3',
                color: '#85a5ff',
            },
            {
                name: '法式洋红-3',
                color: '#ff85c0',
            },
            //
            {
                name: '薄暮-4',
                color: '#ff4d4f',
            },
            {
                name: '火山-4',
                color: '#ff7a45',
            },
            {
                name: '日暮-4',
                color: '#ffa940',
            },
            {
                name: '金盏花-4',
                color: '#ffc53d',
            },
            {
                name: '日出-4',
                color: '#ffec3d',
            },
            {
                name: '极光绿-4',
                color: '#73d13d',
            },
            {
                name: '明青-4',
                color: '#36cfc9',
            },
            {
                name: '拂晓蓝-4',
                color: '#3fb075',
            },
            {
                name: '极客蓝-4',
                color: '#597ef7',
            },
            {
                name: '薄暮-4',
                color: '#f759ab',
            },
            //
            {
                name: '薄暮-5',
                color: '#cf1322',
            },
            {
                name: '火山-5',
                color: '#d4380d',
            },
            {
                name: '日暮-5',
                color: '#d46b08',
            },
            {
                name: '金盏花-5',
                color: '#d48806',
            },
            {
                name: '日出-5',
                color: '#d4b106',
            },
            {
                name: '极光绿-5',
                color: '#389e0d',
            },
            {
                name: '明青-5',
                color: '#08979c',
            },
            {
                name: '拂晓蓝-5',
                color: '#0958d9',
            },
            {
                name: '极客蓝-5',
                color: '#1d39c4',
            },
            {
                name: '法式洋红-5',
                color: '#c41d7f',
            },
            //
            {
                name: '薄暮-6',
                color: '#a8071a',
            },
            {
                name: '火山-6',
                color: '#ad2102',
            },
            {
                name: '日暮-6',
                color: '#ad4e00',
            },
            {
                name: '金盏花-6',
                color: '#ad6800',
            },
            {
                name: '日出-6',
                color: '#ad8b00',
            },
            {
                name: '极光绿-6',
                color: '#237804',
            },
            {
                name: '明青-6',
                color: '#006d75',
            },
            {
                name: '拂晓蓝-6',
                color: '#003eb3',
            },
            {
                name: '极客蓝-6',
                color: '#10239e',
            },
            {
                name: '薄暮-6',
                color: '#9e1068',
            },
        ],
        commonlyColorList: [
            {
                color: '#a8071a',
            },
        ],
        showColorPickerBox: false,
        pickerColorValue: '#eb2f96'
    };

    componentDidMount(): void {
        const {
            screenName = '文字背景色',
            screenTag = 'font-bg'
        } = this.props;
        let _commonlyColorList = [];
        if (localStorage.getItem(`${screenTag}CommonlyColorList`)) {
            _commonlyColorList = JSON.parse(localStorage.getItem(`${screenTag}CommonlyColorList`));
        }
        this.setState({ commonlyColorList: _commonlyColorList })
    }

    componentWillReceiveProps(nextProps: Props) {
        const { isLayoutShow } = nextProps;
        const { showColorPickerBox } = this.state;
        if (!isLayoutShow && showColorPickerBox) {
            this.handleSwitchColorPickerBoxShow();
        }
    };

    handleSwitchColorPickerBoxShow() {
        const { showColorPickerBox } = this.state;
        if (showColorPickerBox) {
            this.colorPickerBoxContainerRef.style.zIndex = -1;
        } else {
            this.colorPickerBoxContainerRef.style.zIndex = 9999;
        }
        this.setState({ showColorPickerBox: !showColorPickerBox });
    }

    onColorPickerBoxChange(e: any) {
        this.setState({ pickerColorValue: e.hex })
    }

    _onPickerColor(colorString: string) {
        try {
            const hexColor = convertToHex(colorString);
            const { onPickerColor, handleClose } = this.props;
            onPickerColor && onPickerColor(hexColor);
            handleClose && handleClose();
            this.handleCloaseColorPickerBox();
            const {
                screenName = '文字背景色',
                screenTag = 'font-bg'
            } = this.props;
            const {
                commonlyColorList
            } = this.state;
            let findIndex = -1;
            for (let i = 0; i < commonlyColorList.length; i++) {
                if (commonlyColorList[i].color == hexColor) {
                    findIndex = i;
                    break;
                }
            }
            if (findIndex == -1) {
                if (commonlyColorList.length == 10) {
                    commonlyColorList.splice(commonlyColorList.length - 1, 1);
                }
                commonlyColorList.unshift({ color: hexColor });
            }
            this.setState({ commonlyColorList: commonlyColorList })
            localStorage.setItem(`${screenTag}CommonlyColorList`, JSON.stringify(commonlyColorList))
        } catch (e) {
            toastShort('error', commonErrorMsg)
        }
    };

    onClickColorItem(color: string, name?: string) {
        console.log("onClickColorItem--->", color)
        const rgba = hexToRgba(color, 1);
        const rgbaStr = `rgba(${rgba['red']},${rgba['green']},${rgba['blue']},${1})`
        this._onPickerColor(rgbaStr);
    }

    handleCloaseColorPickerBox() {
        this.handleSwitchColorPickerBoxShow();
    }

    handleConfirmColorPickerBoxValue() {
        const { pickerColorValue } = this.state;
        if (pickerColorValue) {
            this.onClickColorItem(pickerColorValue)
        } else {
            this.handleCloaseColorPickerBox();
        }
    }

    render() {
        const {
            mode = 'light',
            disableCustomColor = false,
            screenName = '文字背景色',
            screenTag = 'font-bg'
        } = this.props;
        const {
            presetColorList,
            commonlyColorList,
            showColorPickerBox,
            pickerColorValue,
        } = this.state;
        return (
            <div className='custom-color-picker-container'>
                <div
                    className='custom-color-picker'
                >
                    {
                        mode == 'light' ?
                            <div 
                                className='color-picker-header flex-row'
                                onClick={() => this.onClickColorItem('#FFFFFFFF')}
                            >
                                <ColorPickerItem
                                    marginBottom={0}
                                    marginRight={0}
                                />
                                <p className='color-picker-name'>无填充色</p>
                            </div>
                            :
                            <div 
                                className='color-picker-header flex-row'
                                onClick={() => this.onClickColorItem('#000000')}
                            >
                                <ColorPickerItem
                                    marginBottom={0}
                                    marginRight={0}
                                    color='#000'
                                />
                                <p className='color-picker-name'>自动</p>
                            </div>
                    }
                    <div className='custom-color-picker-content flex-row'>
                        {
                            presetColorList && presetColorList.map((item, index) => {
                                return (
                                    <ColorPickerItem
                                        color={item.color}
                                        name={item.name}
                                        key={index + 'color-item'}
                                        marginRight={(index + 1) % 10 == 0 ? 0 : 5}
                                        marginBottom={index < 10 ? 12 : 8}
                                        onClick={this.onClickColorItem.bind(this)}
                                    />
                                )
                            })
                        }
                    </div>
                    <p className='commonly-color-title'>常用的{screenName}</p>
                    <div className='commonly-color-content flex-row'>
                        {
                            commonlyColorList && commonlyColorList.map((item, index) => {
                                return (
                                    <ColorPickerItem
                                        color={item.color}
                                        name={item.name}
                                        key={index + 'color-item'}
                                        marginRight={(index + 1) % 10 == 0 ? 0 : 5}
                                        marginBottom={index < 10 ? 12 : 8}
                                        onClick={this.onClickColorItem.bind(this)}
                                    />
                                )
                            })
                        }
                    </div>
                    {
                        !disableCustomColor ?
                            <div
                                className='custom-color-picker-footer flex-row'
                                onClick={this.handleSwitchColorPickerBoxShow.bind(this)}
                            >
                                <div className='custom-color-picker-footer-left flex-row'>
                                    <img src={require('./../../assets/image/color-board.png')} />
                                    <p>更多颜色</p>
                                </div>
                                <i className='iconfont-n normala-lujing48' />
                            </div>
                            :
                            null
                    }
                </div>
                <div
                    ref={(c) => this.colorPickerBoxContainerRef = c}
                    className={makeClassNameList([
                        'flex-col custom-color-picker-box',
                        showColorPickerBox ? 'show' : null
                    ])}
                >
                    <SketchPicker
                        color={pickerColorValue}
                        onChange={this.onColorPickerBoxChange.bind(this)}
                        presetColors={[]}
                        width={202}
                    />
                    <div className='flex-row custom-color-picker-box-footer'>
                        <Button
                            size={'small'}
                            onClick={this.handleCloaseColorPickerBox.bind(this)}
                        >
                            取消
                        </Button>
                        <Button
                            size={'small'}
                            type={'primary'}
                            style={{ marginLeft: 12 }}
                            onClick={this.handleConfirmColorPickerBoxValue.bind(this)}
                        >
                            确定
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}