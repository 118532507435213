import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import { Editor } from 'slate';

import { useMatchMark, useToggle } from '../hooks';
import { ItemProps } from '../types';
import OptionBarBtn from '../../../../../components/option-bar-btn/OptionBarBtn';

/**
 * 文字下划线工具项
 */
export const DeleteItem = React.memo(function DeleteItem({ editor }: ItemProps) {
  const format = 'delete';
  const [value, setValue] = useState(false);
  const toggle: React.MouseEventHandler<HTMLElement> = useToggle(editor, format, value);
  const mark = (useMatchMark(editor, format) as boolean) ?? false;
  useEffect(() => {
    const curEditor = editor?.current?.editor;
    setValue(mark);
    if (mark && curEditor) Editor.removeMark(curEditor, 'underline');
  }, [editor, mark]);
  // return (
  //   <Button type={value ? 'primary' : 'default'} onMouseDown={toggle}>
  //     删除线
  //   </Button>
  // );
  return (
    <OptionBarBtn
      icon={(
        <div className='custom-option-bar-icon-container'>
          <i className='iconfont ghzs-color-a-zu7381' style={{ fontSize: 20, fontWeight: 'bold' }} />
          <div className='custom-option-bar-icon-deleteline'></div>
        </div>
      )}
      size={'small'}
      onMouseDown={toggle}
      tooltipTitle={'删除线'}
      tooltipDesc={''}
      active={value}
    />
  )
});
