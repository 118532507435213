import React, { useCallback, useEffect, useState } from 'react';

import { Button } from 'antd';
import { Editor } from 'slate';

import { FontFamilyConfig, FontSizeConfig } from '../../../constants';
import { emitToolbarRefresh } from '../../../utils';
import { useMatchMark } from '../hooks';
import { ItemProps } from '../types';
import { makeClassNameList, toastShort } from '../../../../../utils';
import { commonPermissionsMsg } from '../../../../../components/smart-map/utils';

/**
 * 标题工具项
 */
export const HeadingItem = React.memo(function HeadingItem({
  editor,
  level,
}: ItemProps & { level: 0 | 1 | 2 | 3 | 4 | 5 }) {
  const format = 'heading';
  const [value, setValue] = useState(-1);
  const toggle: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.preventDefault();
      const curEditor = editor?.current?.editor;
      if (!curEditor) return;
      //   if (curEditor.selection && Range.isCollapsed(curEditor.selection)) return;
      if (value > -1 && value === level) {
        Editor.removeMark(curEditor, format);
        Editor.removeMark(curEditor, 'fontSize');
        Editor.removeMark(curEditor, 'fontFamily');
        Editor.removeMark(curEditor, 'bold');
      } else {
        Editor.addMark(curEditor, format, level);
        Editor.addMark(
          curEditor,
          'fontSize',
          [
            FontSizeConfig.p,
            FontSizeConfig.h1,
            FontSizeConfig.h2,
            FontSizeConfig.h3,
            FontSizeConfig.h4,
            FontSizeConfig.h5,
          ][level],
        );
        Editor.addMark(
          curEditor,
          'fontFamily',
          [
            FontFamilyConfig.p,
            FontFamilyConfig.h1,
            FontFamilyConfig.h2,
            FontFamilyConfig.h3,
            FontFamilyConfig.h4,
            FontFamilyConfig.h5,
          ][level],
        );
        Editor.addMark(curEditor, 'bold', level > 0);
      }
      emitToolbarRefresh();
    },
    [editor, format, level, value],
  );
  const mark = (useMatchMark(editor, format) as number) ?? -1;
  useEffect(() => {
    setValue(mark);
  }, [mark]);
  const getFontSize = () => {
    switch (level) {
      case 0:
        return 16;
        break;
      case 1:
      case 2:
      case 3:
      case 4:
      case 4:
        return 28 - (level * 2)
    }
  }
  // return (
  //   <Button type={value === level ? 'primary' : 'default'} onMouseDown={toggle}>
  //     {['正文', '标题1', '标题2', '标题3', '标题4', '标题5'][level]}
  //   </Button>
  // );
  return (
    <div
      onMouseDown={toggle}
      // onClick={() => toastShort('warning', commonPermissionsMsg)}
      className={
        makeClassNameList([
          'flex-col word-title-font-size-item',
          value === level ? 'active' : null
        ]
        )
      }
    >
      <div style={{ fontSize: getFontSize(), fontWeight: level ? 700 : 500 }} className="word-title-font-size-style">AaBbCcDd</div>
      <div className='word-title-font-size-content'>{['正文', '标题1', '标题2', '标题3', '标题4', '标题5'][level]}</div>
    </div>
  )
});
