import { createSlice } from "@reduxjs/toolkit";
import { SystemType } from "../utils/types";


const initialState = null;


const systemSlice = createSlice({
    name: 'systemConfig',
    initialState,
    reducers: {
        setNavigate: (state, action) => {
            state = action.payload;
        },
        removeNavigate: (state, action) => {
            state = null;
        }
    }
});

const { reducer, actions } = systemSlice;
export const { setNavigate, removeNavigate } = actions;
export default reducer;