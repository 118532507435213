import { toastShort } from "../utils";
import emitter from "../utils/emitter";
import { GuideStepConfig, ScreenName } from "./type";


const screenGuideSteps:GuideStepConfig[] = [
    {
        screenName: "HomePage",
        screenSteps: [
            {
                elementId: "OpenCreateDocModalBtnId",
                forceClick: false,
                stepTitle: "点击这里开始编制规划",
                contentPosition: 'bottom',
                leftInsetPx: 72,
                topInsectPx: 24,
                nextStepTargetElId: "OpenCreateDocModalBtnId"
            },
            {
                elementId: "ProtectlandTypeSelectId",
                forceClick: false,
                stepTitle: "填写保护地基本信息",
                contentPosition: 'bottom',
                horizontalPaddingPx: 18,
                verticalPaddingPx: 7,
                leftInsetPx: 172,
                topInsectPx: 32,
                fakeClickEl: true
            },
            {
                elementId: "ProtectlandExtraInfoExpandBtnId",
                forceClick: false,
                stepTitle: "点击补充其他信息",
                contentPosition: 'bottom',
                horizontalPaddingPx: 7,
                verticalPaddingPx: 7,
                leftInsetPx: 172,
                topInsectPx: 32,
                fakeClickEl: true,
                nextStepTargetElId: 'ProtectlandExtraInfoExpandBtnId'
            },
            {
                elementId: "ProtectlandBaseTextInfoListId",
                forceClick: false,
                stepTitle: "补充保护地基本概况",
                stepDescList: [
                    "下载模板",
                    "补充模板后上传"
                ],
                contentPosition: 'bottom',
                horizontalPaddingPx: 18,
                verticalPaddingPx: 7,
                leftInsetPx: 242,
                topInsectPx: 32,
                fakeClickEl: true,
                nextStepTargetElId: 'addProjectSubTableTabId'
            },
            {
                elementId: "ProtectlandSubTableId",
                forceClick: false,
                stepTitle: "补充规划附表",
                stepDescList: [
                    "下载模板",
                    "补充模板后上传"
                ],
                contentPosition: 'bottom',
                horizontalPaddingPx: 18,
                verticalPaddingPx: 7,
                leftInsetPx: 242,
                topInsectPx: 32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: false,
            },
        ]
    },
    // {
    //     screenName: "AddProjectModal",
    //     screenSteps: [
           
    //     ]
    // },
    {
        screenName: "TopicManagePageV3",
        screenSteps: [
            {
                elemmntClassName: "ant-tree-checkbox-inner",
                queryType: 'className',
                classIndex: 1,
                stepTitle: "1、勾选确定规划大纲",
                contentPosition: 'bottom',
                horizontalPaddingPx: 18,
                verticalPaddingPx: 7,
                leftInsetPx: 0,
                topInsectPx: 32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elemmntClassName: "ant-tree-treenode ant-tree-treenode-switcher-close ant-tree-treenode-checkbox-checked ant-tree-treenode-draggable",
                queryType: 'className',
                classIndex: 1,
                stepTitle: "2、点击这里管理大纲，右键也可以唤起功能菜单",
                contentPosition: 'bottom',
                horizontalPaddingPx: 18,
                verticalPaddingPx: 7,
                leftInsetPx: 280,
                topInsectPx: 32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elemmntClassName: "ant-tree-treenode ant-tree-treenode-switcher-close ant-tree-treenode-checkbox-checked ant-tree-treenode-draggable",
                queryType: 'className',
                classIndex: 1,
                stepTitle: "3、鼠标可拖动大纲调整层级",
                contentPosition: 'bottom',
                horizontalPaddingPx: 18,
                verticalPaddingPx: 7,
                leftInsetPx: 42,
                topInsectPx: 32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: "goToSelectDeviceListBtnId"
            },
            {
                elemmntClassName: "topic-manage-page-pannel-body center-body",
                queryType: 'className',
                classIndex: 1,
                stepTitle: "4、选择规划项目",
                stepDescList: [
                    "系统为您提供规划项目库，并智能推荐了规划项目"
                ],
                contentPosition: 'right',
                horizontalPaddingPx: 18,
                verticalPaddingPx: 7,
                leftInsetPx: 72,
                topInsectPx: 0,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elemmntClassName: "flex-row device-manage-tree-node-total-count",
                queryType: 'className',
                classIndex: 0,
                stepTitle: "5、本节包含项目总数量",
                contentPosition: 'right',
                horizontalPaddingPx: 7,
                verticalPaddingPx: 7,
                leftInsetPx: 42,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elemmntClassName: "flex-row device-manage-tree-node-count",
                queryType: 'className',
                classIndex: 0,
                stepTitle: "6、本节选择项目总数量",
                contentPosition: 'right',
                horizontalPaddingPx: 7,
                verticalPaddingPx: 7,
                leftInsetPx: 42,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elemmntClassName: "flex-row device-manage-tree-node-content real-topic-node active",
                queryType: 'className',
                classIndex: 0,
                stepTitle: "7、点击选中的规划大纲",
                contentPosition: 'right',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 42,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elemmntClassName: "flex-col topic-manage-page-pannel-second-right",
                queryType: 'className',
                classIndex: 0,
                stepTitle: "8、右侧面板中，可管理选中的规划大纲下关联的所有项目",
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 42,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elementId: "topicManagePageAddTopicBtnId",
                queryType: 'id',
                stepTitle: "9、点击这里新建项目",
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 42,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elementId: "topicManagePageResetRecommandBtnId",
                queryType: 'id',
                stepTitle: "10、点击这里还原系统默认推荐的项目",
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 42,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elemmntClassName: "device-topic-check-cell",
                queryType: 'className',
                classIndex: 2,
                stepTitle: "11、勾选规划项目",
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 42,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: "topicManagePageNextBtnId"
            },
            // {
            //     elementId: "topicManagePageNextBtnId",
            //     queryType: 'id',
            //     stepTitle: "完成规划内容选择后，点击下一步即可保存配置开始规划编制",
            //     contentPosition: 'left',
            //     horizontalPaddingPx: 15,
            //     verticalPaddingPx: 7,
            //     leftInsetPx: 42,
            //     topInsectPx: -32,
            //     fakeClickEl: true,
            //     disableBack: false,
            //     disableClose: true,
            // },
            {
                elementId: "editPageNavId",
                queryType: 'id',
                stepTitle: "在此处可以进行功能模块切换",
                stepDescList: [
                    "规划项目选择 - 可调整规划大纲与规划项目的勾选与层级关系",
                    "投资估算表格 - 可进行投资估算表项目金额单价等信息编辑以及查看投资表构成",
                    "规划报告撰写 - 系统根据规划内容智能生成规划报告，也可自由编辑报告内容"
                ],
                contentPosition: 'right',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 42,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elementId: "editPageNavId",
                queryType: 'id',
                stepTitle: "在此处可以进行功能模块切换",
                stepDescList: [
                    "规划项目选择 - 可调整规划大纲与规划项目的勾选与层级关系",
                    "投资估算表格 - 可进行投资估算表项目金额单价等信息编辑以及查看投资表构成",
                    "规划报告撰写 - 系统根据规划内容智能生成规划报告，也可自由编辑报告内容"
                ],
                contentPosition: 'right',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 42,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elementId: "ExcelV2ContainerId",
                queryType: 'id',
                stepTitle: "在此处可以进行功能模块切换",
                stepDescList: [
                    "白色单元格代表内容可修改，双击单元格即可打开编辑框，修改完成后后的数值会自动同步规划报告，并自动更新投资规划表",
                    "灰色单元格代表内容不可直接修改",
                    "“右键单元格”可进行新增/移除规划项目",
                ],
                contentPosition: 'right',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: -600,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elementId: "ExcelToolDocMoneyCardinalNumberId",
                queryType: 'id',
                stepTitle: "点击修改投资表单位",
                contentPosition: 'right',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 32,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elementId: "ExcelToolDecimalNumberId",
                queryType: 'id',
                stepTitle: "点击修改小数位数",
                contentPosition: 'right',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 32,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elementId: "ExcelToolMainProjectRecommandId",
                queryType: 'id',
                stepTitle: "点击修改小数位数",
                contentPosition: 'right',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 32,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elementId: "ExcelToolOtherExpensesId",
                queryType: 'id',
                stepTitle: "点击配置其它费用",
                contentPosition: 'right',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 32,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
            },
            {
                elemmntClassName: "DocExportBtnId",
                queryType: 'className',
                classIndex: 0,
                stepTitle: "投资表制作完成，点击这里导出投资表",
                contentPosition: 'right',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 32,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: "jumpToWordPageFakeBtnId"
            },
            {
                elementId: "WordExitorV3ContainerId",
                queryType: 'id',
                stepTitle: "规划报告撰写",
                stepDescList: [
                    "上下滑动可查看报告全文",
                    "报告中输入框代表“项目名称”、“数量”、“单位”等信息，修改其内容可与投资估算表联动",
                    "点击报告任意章节，可自动在左侧规划大纲以及投资估算表中自动为您聚焦对应内容",
                ],
                contentPosition: 'right',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: -600,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: ""  //TextTopicBackgroundParagraphTabBtnId
            },
            {
                elementId: "WordPluginContainerId",
                queryType: 'id',
                stepTitle: "插件1：参考范文",
                stepDescList: [
                    "点击报告中任意规划段落",
                    "即可在本区域显示系统为该段落内置的背景文本",
                    "鼠标点击下方“使用此范文”即可使用新范文替换左侧段落章节",
                ],
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 32,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: "BenefitEvaluationTabBtnId"
            },
            {
                elementId: "WordPluginContainerId",
                queryType: 'id',
                stepTitle: "效益评价/生态效益",
                stepDescList: [
                    "输入保护地地类面积",
                    "勾选生态系统服务指标",
                    "点击下方确定按钮，生成文本并插入报告",
                ],
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: -600,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: "BenefitEvaluationTabBtnId2"
            },
            {
                elementId: "WordPluginContainerId",
                queryType: 'id',
                stepTitle: "效益评价/经济效益",
                stepDescList: [
                    "输入经济效益值",
                    "点击下方确定按钮，生成文本并插入报告",
                ],
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: -600,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: "BenefitEvaluationTabBtnId3"
            },
            {
                elementId: "WordPluginContainerId",
                queryType: 'id',
                stepTitle: "效益评价/社会效益",
                stepDescList: [
                    "勾选社会效益指标",
                    "点击下方确定按钮，生成文本并插入报告",
                ],
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 30,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: "TourismCapacityTabBtnId"
            },
            {
                elementId: "WordPluginContainerId",
                queryType: 'id',
                stepTitle: "插件3：旅游容量",
                stepDescList: [
                    "下拉选择旅游容量计算方法",
                    "根据保护地/国家公园实际情况，填写参数",
                    "点击下方确定按钮，生成文本并插入报告"
                ],
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 30,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: "InsetSubExtraTableTabBtnId"
            },
            {
                elementId: "WordPluginContainerId",
                queryType: 'id',
                stepTitle: "插件4：附表制作",
                stepDescList: [
                    "选择附表类型",
                    "下载对应附表上传模板",
                    "上传补充后的附表",
                    "点击下方确定按钮，生成文本并插入报告"
                ],
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: -600,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: "FillSpeciesInfoPluginTabBtnId"
            },
            {
                elementId: "WordPluginContainerId",
                queryType: 'id',
                stepTitle: "插件5：拉丁名制作",
                stepDescList: [
                    "下载物种上传模板",
                    "根据保护地现状，补充物种名单",
                    "上传物种名单",
                    "点击“填充拉丁名”即可为报告中的物种填充拉丁名"
                ],
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 32,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: "WordEditorParagraphCheckTabBtnId"
            },
            {
                elementId: "WordPluginContainerId",
                queryType: 'id',
                stepTitle: "插件6：文档检查",
                stepDescList: [
                    "查看高亮段落列表",
                    "点击“聚焦到此段落”即可快速定位至需要修改的段落",
                    "完善文本后，删除高亮提示，此段落的检查提示将会关闭",
                ],
                contentPosition: 'left',
                horizontalPaddingPx: 15,
                verticalPaddingPx: 7,
                leftInsetPx: 32,
                topInsectPx: -32,
                fakeClickEl: true,
                disableBack: false,
                disableClose: true,
                nextStepTargetElId: ""
            },
        ]
    }
]

export const showGuide = (screenName: ScreenName) => {
    emitter.emit('ShowGuide', {screenName})
}

export const getCurrentScreenGuides = (screenName: ScreenName) => {
    const findScreenGuides = screenGuideSteps.find(screenGuideStep => {
        return screenGuideStep.screenName == screenName;
    })
    if(findScreenGuides){
        return findScreenGuides;
    }else {
        toastShort('error', "暂无指引配置")
    }
}