import { Button, Tabs, Empty } from "antd";
import React, { ReactNode, forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import comDocContext from '../../../context/DocContext';
import { generateDeviceParagraphSlice, getEditorBlockParagraphTypeByBlockKey, replaceDocInfo } from "../word-editor-deprecated/WordEditorHelper";
import { isEmpty } from "../../../utils";
import { DocInstance, TopicType } from "../../../utils/types";
import { SnippetsOutlined } from "@ant-design/icons";
import { ContentBlock } from "draft-js";
import BenefitEvaluationContent1 from "./BenefitEvaluationContent1";
import BenefitEvaluationContent2 from "./BenefitEvaluationContent2";
import BenefitEvaluationContent3 from "./BenefitEvaluationContent3";


interface Props {
    insertDocItemByTargetTopicName: Function
    // insertSocialResultsTopicList: Function
    // insertEconomicBenefitAnalysisParagraphList: Function,
    // insertEcologicalBenefitAnalysisParagraphList: Function
}

const BenefitEvaluation = (props: Props) => {

    const {
        // insertSocialResultsTopicList,
        // insertEconomicBenefitAnalysisParagraphList,
        // insertEcologicalBenefitAnalysisParagraphList,
        insertDocItemByTargetTopicName
    } = props;

    const tabList = [
        {
            key: '1',
            label: "生态效益",
            children: (
                <BenefitEvaluationContent1
                    insertDocItemByTargetTopicName={insertDocItemByTargetTopicName}
                />

            )
        },
        {
            key: '2',
            label: <div id="BenefitEvaluationTabBtnId2">经济效益</div>,
            children: (
                <BenefitEvaluationContent2
                    insertDocItemByTargetTopicName={insertDocItemByTargetTopicName}
                />
            )
        },
        {
            key: '3',
            label: <div id="BenefitEvaluationTabBtnId3">社会效益</div>,
            children: (
                <BenefitEvaluationContent3
                    insertDocItemByTargetTopicName={insertDocItemByTargetTopicName}
                />
            )
        },
    ]

    return (
        <div className="word-editor-plugins-content">
            <Tabs
                type={'card'}
                defaultActiveKey="currentTopic"
                items={tabList}
                tabPosition='top'
                className='word-editor-plugin-content-tabs disable-drag'
                size={'small'}
            />
        </div>
    )
}

export default BenefitEvaluation;