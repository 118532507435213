import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Select, Slider, Switch, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { ExcelColumnFillMode, SelectOption } from "../../../utils/types";
import { makeClassNameList, toastShort } from "../../../utils";
import emitter from "../../../utils/emitter";
import { Extend } from "@icon-park/react";

interface Props {
    initZoomValue: number
    onZoomChange: Function
    onFillMethodChange: Function
    initFillMode: ExcelColumnFillMode
}

const ExcelZoomKit = (props: Props) => {

    const {
        initZoomValue,
        initFillMode,
        onZoomChange,
        onFillMethodChange
    } = props;


    const [zoomOptions] = useState<SelectOption[]>([
        {
            label: '200%',
            value: '200%',
        },
        {
            label: '150%',
            value: '150%',
        },
        {
            label: '125%',
            value: '125%',
        },
        {
            label: '100%',
            value: '100%',
        },
        {
            label: '75%',
            value: '75%',
        },
        {
            label: '50%',
            value: '50%',
        },
        {
            label: '25%',
            value: '25%',
        },
        {
            label: '10%',
            value: '10%',
        },
    ]);

    const [excelFullScreen, setExcelFullScreen] = useState(false);

    const [currentFillMethod, setCurrentFillMethod] = useState<ExcelColumnFillMode>(initFillMode ? initFillMode : 'autoColumnWidth');

    useEffect(() => {
        emitter.sub('handleQuitFullScreenMode', () => {
            setExcelFullScreen(false)
        })
    }, []);

    const [currentZoom, setCurrentZoom] = useState(initZoomValue ? initZoomValue : 1);

    const onZoomSliderChange = (e) => {
        const newZoom = e / 100;
        setCurrentZoom(newZoom)
        handleEmitZoomChange(newZoom)
    }

    const onZoomSelectChange = (e: string) => {
        const zoomValue = e.slice(0, -1);
        let newZoom = Number(zoomValue) / 100;
        setCurrentZoom(newZoom);
        handleEmitZoomChange(newZoom)
    };

    const handleMinusZoom = () => {
        if (currentZoom <= 0.1) {
            return toastShort('error', '已达最小缩放比例')
        }
        let newZoom = currentZoom - 0.1;
        setCurrentZoom(newZoom);
        handleEmitZoomChange(newZoom)
    };

    const handleAddZoom = () => {
        if (currentZoom >= 2) {
            return toastShort('error', '已达最大缩放比例')
        }
        let newZoom = currentZoom + 0.1;
        setCurrentZoom(newZoom);
        handleEmitZoomChange(newZoom)
    };

    const handleEmitZoomChange = (newZoom: number) => {
        onZoomChange && onZoomChange(newZoom)
    }

    const onCurrentFillMethodChange = (e: ExcelColumnFillMode) => {
        onFillMethodChange(e);
        setCurrentFillMethod(e);
    }

    const onExcelFullScreenChange = (e: boolean) => {
        // alert('onExcelFullScreenChange' + e)
        setExcelFullScreen(e);
        emitter.emit('onExcelFullScreenChange', { value: e });
    };

    return (
        <div className="flex-row excel-bottom-bar-right">
            <div className="excel-bottom-bar-title">
                布局方式：
            </div>
            <Select
                options={[
                    {
                        label: "自动调整列宽",
                        value: "autoColumnWidth",
                    },
                    {
                        label: "适应窗口大小",
                        value: "fillContainer",
                    },
                    // {
                    //     label: "表格网页全屏",
                    //     value: "fullScreen",
                    // },
                ]}
                style={{ width: 128, marginRight: 18 }}
                value={currentFillMethod}
                size={'small'}
                onChange={onCurrentFillMethodChange}
                getPopupContainer={(e) => e.parent}
            />
            {/* <div className="excel-bottom-bar-title">
                网页全屏：
            </div>
            <Switch
                size={'small'}
                style={{ marginRight: 15 }}
                value={excelFullScreen}
                onChange={onExcelFullScreenChange}
            /> */}
            <Tooltip
                title={"表格网页全屏"}
            >
                <div
                    className={makeClassNameList([
                        "excel-bottom-bar-btn",
                        excelFullScreen ? 'active' : null
                    ])}
                    onClick={() => onExcelFullScreenChange(!excelFullScreen)}
                >
                    <Extend
                        theme="outline"
                        size="20"
                        strokeWidth={2}
                    />
                </div>
            </Tooltip>
            {/* <div className="excel-bottom-bar-title">
                缩放比例：
            </div> */}
            <Select
                options={zoomOptions}
                style={{ width: 78, border: 'none' }}
                value={(currentZoom * 100).toFixed(0) + '%'}
                size={'small'}
                onChange={onZoomSelectChange}
                getPopupContainer={(e) => e.parent}
            />
            <Button
                icon={<MinusOutlined />}
                type={'text'}
                onClick={handleMinusZoom}
                disabled={currentZoom <= 0.25}
                className="excel-bottom-bar-zoom-btn"
            />
            <div className="flex-row excel-bottom-bar-zoom-line">
                <Slider
                    defaultValue={30}
                    tooltip={{
                        open: false
                    }}
                    style={{ width: '100%' }}
                    min={10}
                    max={200}
                    value={Number(currentZoom * 100)}
                    onChange={onZoomSliderChange}
                    step={1}
                />
            </div>
            <Button
                icon={<PlusOutlined />}
                type={'text'}
                onClick={handleAddZoom}
                disabled={currentZoom >= 2}
                className="excel-bottom-bar-zoom-btn"
            />
        </div>
    )
}


export default ExcelZoomKit;