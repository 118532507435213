import { forwardRef, useState, useImperativeHandle, ChangeEvent, useRef, useEffect } from 'react';
import { Modal, Input, Form, Select, Image, Upload, UploadProps } from 'antd';
import { deepCopy, getChangeEventValue, toastShort } from '../../utils';
import { UserType } from '../../utils/types';
import redux from './../../redux/store';
import type { UploadFile } from 'antd/es/upload/interface';
import { saasConfig } from '../../config/theme-config';
import { PlusOutlined } from '@ant-design/icons';
import systemApi from '../../api/system';

interface Props {
    onFinish?: Function
};

interface FeedBackForm {
    title?: string
    type?: string
    desc?: string
    imgs?: string[]
    contactInfo?: string
    sort?: number
    remark: string
    nickName?: string
}

const FeedBackModal = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openModal: openModal
    }));

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [form] = Form.useForm<FeedBackForm>();
    const [defaultFormData] = useState<FeedBackForm>({
        title: "无",
        type: "问题反馈",
        desc: "",
        imgs: [],
        contactInfo: "",
        sort: 1,
        remark: "",
    })
    const [formData, setFormData] = useState<FeedBackForm>();
    const [options, setOptions] = useState([
        {
            value: '问题反馈',
            label: '问题反馈',
        },
        {
            value: '优化建议',
            label: '优化建议',
        },
    ])
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([
    ]);
    const delayTimer = useRef(null);

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current)
        }
    }, [])

    const handleConfirm = async () => {
        try {
            const values = await form.validateFields();
            setSubmitLoading(true);
            let params = {
                title: formData.title,
                type: formData.type,
                desc: formData.desc,
                contactInfo: formData.contactInfo,
                sort: 1,
                imgs: [],
                remark: ""
            };
            if (fileList.length) {
                params.imgs = fileList.map(file => {
                    return file.response && file.response.data && file.response.data.url
                }).filter(url => url);
            }
            // console.log("参数---->", params);
            systemApi.createFeedBack(params)
                .then(res => {
                    setIsModalOpen(false);
                    setSubmitLoading(false)
                    toastShort('success', '提交成功，您的反馈会被尽快处理！');
                })
                .catch(err => {
                    setIsModalOpen(false);
                    setSubmitLoading(false)
                })
        } catch (e) {
            console.log("e", e)
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setFileList([]);
        form.setFieldValue('type', null);
        form.setFieldValue('title', null);
        form.setFieldValue('desc', null);
        form.setFieldValue('nickName', null);
        form.setFieldValue('contactInfo', null);
    }

    const handlePreview = async (file: UploadFile) => {
        // console.log("handlePreview--->", file)
        if (!file.response) {
            return toastShort('error', '文件正在上传中...')
        }
        setPreviewImage(file.response && file.response.data.url);
        setPreviewOpen(true);
        setPreviewTitle(file.name);
    };

    const handleChange: UploadProps['onChange'] = (e) => {
        // console.log("handleChange----->", e)
        const { file, fileList } = e;
        setFileList([...fileList]);
    }

    const openModal = () => {
        setIsModalOpen(true);
        let tempUserInfo: UserType = redux.getState().systemConfig.userInfo;
        let newForm: FeedBackForm = { ...defaultFormData };
        newForm.nickName = tempUserInfo.nickName;
        //@ts-ignore
        newForm.contactInfo = tempUserInfo.phone || null;
        setFormData(newForm);
        form.setFieldsValue(newForm);
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>上传</div>
        </div>
    );

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: false,
        accept: ".xlx,.xlsx,.doc,.docx,.ppt,.pptx,.jpg,.png,.jpeg,.zip,.rar,.pdf,.mp4",
        beforeUpload: (file) => {
            console.log("file----->", file)
            let newFileList = [...fileList, file];
            if (newFileList.length > 5) {
                toastShort('warning', '文件上传数量超出限制')
            } else {
                setFileList(newFileList);
            }
            return false;
        },
        maxCount: 5,
        fileList: fileList,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                toastShort('success', `${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                toastShort('error', `${info.file.name} file upload failed.`);
            }
        },
        onRemove(e) {
            let _fileList = fileList;
            for (let i = 0; i < _fileList.length; i++) {
                if (_fileList[i].uid == e.uid) {
                    _fileList.splice(i, 1);
                    break;
                }
            }
            setFileList([..._fileList])
        }
    };

    const handleSetFormData = (target: string, value: any) => {
        let _formData = formData;
        //@ts-ignore
        _formData[target] = value;
        setFormData(deepCopy(_formData));
    };

    const onTypeChange = (e: string) => {
        handleSetFormData('type', e);
    }

    const onTitleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData('title', getChangeEventValue(e));
    }

    const onDescTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        handleSetFormData('desc', getChangeEventValue(e));
    }

    const onContactInfoTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData('contactInfo', getChangeEventValue(e));
    }

    return (
        <Modal
            title={'问题反馈'}
            open={isModalOpen}
            onOk={handleConfirm}
            onCancel={handleCancel}
            okText={'确 认'}
            cancelText={'取 消'}
            width={'800px'}
            maskClosable={false}
            confirmLoading={submitLoading}
            destroyOnClose
            centered
        >
            <div className='custom-modal-container'>
                <Form
                    form={form}
                    labelCol={{
                        span: 3,
                        style: {
                            paddingLeft: 32,
                            // backgroundColor: 'red'
                        }
                    }}
                    labelAlign={'left'}
                    style={{ marginRight: 32, width: 672 }}
                >
                    {/* <Form.Item
                        name="type"
                        label="反馈类型"
                        required={true}
                        rules={[{ required: true, message: "请选择反馈类型" }]}
                    >
                        <Select
                            options={options}
                            placeholder={'请选择反馈类型'}
                            onChange={onTypeChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name="title"
                        label="标题"
                        rules={[{ required: true, message: "请输入反馈标题" }]}
                    >
                        <Input
                            placeholder='请输入反馈标题'
                            allowClear
                            maxLength={20}
                            onChange={onTitleTextChange}
                        />
                    </Form.Item> */}
                    <Form.Item
                        name="desc"
                        label="详细描述"
                        rules={[{ required: true, message: "请输入问题详细描述" }]}
                    >
                        <Input.TextArea
                            placeholder='请输入问题详细描述(最多不超过1000字)'
                            onChange={onDescTextChange}
                            rows={8}
                            maxLength={1000}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="imageList"
                        label="反馈截图"
                    >
                        <Upload
                            action={saasConfig.nodeJsApiBaseUrl + '/shared_folder/base_file_upload'}
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {fileList.length >= 8 ? null : uploadButton}
                        </Upload>
                    </Form.Item>
                    {/* <Form.Item
                        name="nickName"
                        label="提交人"
                    >
                        <Input
                            disabled
                            placeholder=''
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="contactInfo"
                        label="联系方式"
                    >
                        <Input
                            placeholder='请输入联系方式(电话或邮件)'
                            onChange={onContactInfoTextChange}
                            allowClear
                        />
                    </Form.Item> */}
                </Form>
                {previewImage && (
                    <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
            </div>
        </Modal>
    )
}

export default forwardRef(FeedBackModal)