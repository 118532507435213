import { useRef } from "react";
import { DocEditor, DocItem } from "../../slate-editor"

interface Props {
    docItemList: DocItem[]
}

const DocItemParagraphRender = (props: Props) => {

    const docEditorRef = useRef(null);

    const {
        docItemList
    } = props;

    const slateEditorBaseData = { '[*]': '保护区', '[NAME]': '名称', '[type]': '类型', '[P]': 'P数据' };

    const onSlateEditorChange = () => {

    };

    const onDocItemClick = () => {

    };

    const onSlateInputDataChanged = () => {

    }

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <DocEditor
                ref={docEditorRef}
                value={docItemList}
                data={slateEditorBaseData}
                onChange={onSlateEditorChange}
                onDocItemClick={onDocItemClick}
                onSlateInputDataChanged={onSlateInputDataChanged}
                editableWrapStyle={{padding: '0!important'}}
            />
        </div>
    );
}


export default DocItemParagraphRender;