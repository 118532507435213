import React, { CSSProperties, ReactNode } from 'react';
import { Tooltip, } from 'antd';
import './OptionBarBtn.scss';
import { makeClassNameList } from '../../utils';

interface Props {
    size: 'small' | 'large'
    name?: string
    icon: ReactNode
    tooltipTitle?: string
    tooltipDesc?: string
    onClick?: Function
    onDoubleClick?: Function
    onMouseDown?: Function
    onClickArrow?: Function
    onHover?: Function
    active?: boolean
    selectEnable?: boolean
    disabled?: boolean
    style?: CSSProperties
}

interface State {

}

export default class OptionBarBtn extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    };

    public delayTimer: any = null;

    public readonly state: Readonly<State> = {
    };

    componentDidMount() {
        this.delayTimer = setTimeout(() => {

        }, 200);
    };

    componentWillUnmount(): void {

    };

    onClick(e: any) {
        const { onClick } = this.props;
        onClick && onClick();
        e.preventDefault();
        e.stopPropagation();
    };

    onDoubleClick(e: any) {
        const { onDoubleClick } = this.props;
        onDoubleClick && onDoubleClick();
        e.preventDefault();
        e.stopPropagation();
    }

    _onMouseDown(e: any) {
        const { onMouseDown } = this.props;
        onMouseDown &&  onMouseDown(e);
        e.preventDefault();
        e.stopPropagation();
    };


    onClickArrow(e: any) {
        console.log("测试")
        const { onClickArrow } = this.props;
        onClickArrow && onClickArrow();
        e.preventDefault();
        e.stopPropagation();
    };

    render() {
        const {
            icon,
            name,
            size,
            tooltipTitle,
            tooltipDesc,
            active,
            selectEnable,
            disabled,
            style = {}
        } = this.props;
        return (
            <React.Fragment>
                {
                    tooltipTitle ?
                        <Tooltip
                            
                            placement={'bottomLeft'}
                            title={
                                <div className='custom-tool-tip-container'>
                                    <p className='custom-tool-tip-title'>{tooltipTitle}</p>
                                    {
                                        tooltipDesc ?
                                            <p className='custom-tool-tip-desc'>{tooltipDesc}</p>
                                            :
                                            null
                                    }
                                </div>
                            }
                            autoAdjustOverflow
                            mouseEnterDelay={1}
                            mouseLeaveDelay={0.2}
                            showArrow={false}
                            overlayInnerStyle={{ fontSize: '12px', marginTop: '-10px' }}
                            color={'#404040'}
                        >
                            <div 
                                style={style}
                                className={
                                makeClassNameList([
                                    'flex-row',
                                    selectEnable ? 'kit-option-bar-select-btn' : null,
                                    disabled? 'kit-option-bar-select-btn-disable' : null
                                ])
                            }>
                                <div
                                    className={
                                        makeClassNameList([
                                            'flex-col',
                                            size == 'large' ? 'kit-option-bar-btn' : 'kit-option-bar-small-btn',
                                            active ? 'active' : null,
                                        ])
                                    }
                                    onClick={this.onClick.bind(this)}
                                    onMouseDown={this._onMouseDown.bind(this)}
                                >
                                    <div className='kit-option-bar-btn-icon flex-col'>
                                        {icon}
                                    </div>
                                    {
                                        size == 'large' ?
                                            <div
                                                className='single-line-text kit-option-bar-btn-name'
                                                style={{ maxWidth: 120 }}
                                            >
                                                {name}
                                            </div>
                                            :
                                            null
                                    }

                                </div>
                                {
                                    selectEnable ?
                                        <a>
                                            <div
                                                onClick={this.onClickArrow.bind(this)}
                                                onMouseDown={this._onMouseDown.bind(this)}
                                                className='kit-option-bar-btn-select-arrow'
                                            >
                                                <div className='kit-option-bar-btn-select-arrow-content flex-col'>
                                                    <i className='iconfont-1 ghzs-gray-a-mengbanzu2' />
                                                </div>
                                            </div>
                                        </a>
                                        :
                                        null
                                }
                            </div>
                        </Tooltip>
                        :
                        <div
                            className={
                                makeClassNameList([
                                    size == 'large' ?
                                    active ? 'kit-option-bar-btn flex-col active' : 'kit-option-bar-btn flex-col'
                                    :
                                    active ? 'kit-option-bar-small-btn flex-col active' : 'kit-option-bar-small-btn',
                                    disabled? 'kit-option-bar-select-btn-disable' : null
                                ])
                            }
                            onClick={this.onClick.bind(this)}
                            onDoubleClick={this.onDoubleClick.bind(this)}
                        >
                            <div className='kit-option-bar-btn-icon flex-col'>
                                {icon}
                            </div>
                            {
                                size == 'large' ?
                                    <div
                                        className='single-line-text kit-option-bar-btn-name'
                                        style={{ maxWidth: 120 }}
                                    >
                                        {name}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                }
            </React.Fragment>
        )
    }
}