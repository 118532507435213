import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import './ContextMenu.scss'
import ContextMenuWrapper from "./ContextMenuWrapper"
import { TopicNodeActionType, TopicType } from "../../utils/types"
import { toastShort } from "../../utils"

interface Props {
    currentScreen: 'currentTopic' | 'allTopic'
    onContextMenuClose: Function
    currentTopicNode: TopicType
    allowOption?: 'all' | 'just-text' | 'just-device'
    onPickerActionType: (TopicNodeActionType) => void
}


const TopicTreeContextMenu = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        showContextMenu
    }));

    const {
        allowOption = 'all',
        onContextMenuClose,
        currentTopicNode,
        onPickerActionType,
        currentScreen = 'allTopic'
    } = props;

    const delayTimer = useRef<any>(null);
    const [subContextMenuType, setSubContextMenuType] = useState<'addChildTopic' | 'addPeerTopic'>(null);

    const contextMenuWrapperRef = useRef<any>(null);
    const subContextMenuRef = useRef<any>(null);

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current)
        }
    }, [])

    const showContextMenu = (e) => {
        console.log("showContextMenu--->", e)
        contextMenuWrapperRef.current.showContextMenu(e);
    }

    const _onPickerActionType = (type: TopicNodeActionType) => {
        onPickerActionType && onPickerActionType(type);
        delayTimer.current = setTimeout(() => {
            handleHideContextMenu();
        }, 200)
    }

    const onClickContextMenu = (e: any, type: TopicNodeActionType) => {
        if (!currentTopicNode) {
            return toastShort('error', '未找到操作节点')
        }
        const { target, clientX, clientY } = e;
        const rect = target.getBoundingClientRect();
        const { top, left, right } = rect;
        const rightTop = { x: right + 6, y: top };

        switch (type) {
            case 'addChildTopic':
                if (allowOption == 'just-text') {
                    setSubContextMenuType('addChildTopic');
                    onClickSubContextMenu(e, 'AddTextNode', 'addChildTopic')
                } else if (allowOption == 'just-device') {
                    onClickSubContextMenu(e, 'AddDeviceNode')
                } else {
                    if (currentTopicNode && currentTopicNode.topicType == 'device') {
                        return toastShort('warning', '设施设备不支持继续添加子节点')
                    }
                    subContextMenuRef.current.style.zIndex = 1002;
                    subContextMenuRef.current.style.top = rightTop.y + 'px';
                    subContextMenuRef.current.style.left = rightTop.x + 'px';
                    setSubContextMenuType('addChildTopic')
                }
                break;
            case 'addPeerTopic':
                if (allowOption == 'just-text') {
                    setSubContextMenuType('addPeerTopic');
                    onClickSubContextMenu(e, 'AddTextNode', 'addPeerTopic')
                } else if (allowOption == 'just-device') {
                    onClickSubContextMenu(e, 'AddDeviceNode')
                } else {
                    subContextMenuRef.current.style.zIndex = 1002;
                    subContextMenuRef.current.style.top = rightTop.y + 'px';
                    subContextMenuRef.current.style.left = rightTop.x + 'px';
                    setSubContextMenuType('addPeerTopic')
                }
                break;
            case 'MoveUp':
                _onPickerActionType('MoveUp')
                break;
            case 'MoveDown':
                _onPickerActionType('MoveDown')
                break;
            case 'MoveTop':
                _onPickerActionType('MoveTop')
                break;
            case 'MoveBottom':
                _onPickerActionType('MoveBottom')
                break;
            case 'ReName':
                _onPickerActionType('ReName')
                break;
            case 'Delete':
                _onPickerActionType('Delete')
                break;
            case 'UnChecked':
                _onPickerActionType('UnChecked');
                break;
            default:
                break;
        }
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    const onContextMenuContanerMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const onContextMenuHide = () => {
        onContextMenuClose && onContextMenuClose();
        subContextMenuRef.current.style.zIndex = -1;
        subContextMenuRef.current.style.left = -999 + 'px';
    }

    const onClickSubContextMenu = (e: any, type: 'AddDeviceNode' | 'AddTextNode', _subContextMenuType?: any) => {
        const usedSubContextMenuType = _subContextMenuType || subContextMenuType;
        switch (type) {
            case 'AddDeviceNode':
                if (usedSubContextMenuType == 'addChildTopic') {
                    _onPickerActionType('AddDeviceChildNode')
                } else {
                    if (currentTopicNode.pid == '0') {
                        return toastShort('warning', '当前层级不可添加项目节点')
                    }
                    _onPickerActionType('AddDevicePeerNode')
                }
                break;
            case 'AddTextNode':
                if (usedSubContextMenuType == 'addChildTopic') {
                    _onPickerActionType('AddTextChildNode')
                } else {
                    _onPickerActionType('AddTextPeerNode')
                }
                break;
            default:
                break;
        }
        e.preventDefault();
        e.stopPropagation();
    }

    const handleHideContextMenu = () => {
        contextMenuWrapperRef.current?.hideContextMenu();
    }

    return (
        <ContextMenuWrapper
            ref={contextMenuWrapperRef}
            onContextMenuHide={onContextMenuHide}
        >
            <div
                className="flex-col context-menu-container"
                onMouseDown={onContextMenuContanerMouseDown}
            >
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickContextMenu(e, 'addChildTopic')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-xinzengzijiedian" />
                        </div>
                        <p className="context-menu-item-title">{
                            allowOption == "just-text" ?
                                "新增下级大纲"
                                :
                                "新增子级节点"
                        }</p>
                    </div>
                    {
                        allowOption == 'all' ?
                            <div className="context-menu-item-right">
                                <div className="context-menu-item-right-icon">
                                    <i className="iconfont-1 ghzs-gray-xiangxia2" />
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickContextMenu(e, 'addPeerTopic')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-xinzengtongjijiedian" />
                        </div>
                        <p className="context-menu-item-title">{
                            allowOption == "just-text" ?
                            "新增同级大纲"
                            :
                            "新增同级节点"
                        }</p>
                    </div>
                    {
                        allowOption == 'all' ?
                            <div className="context-menu-item-right">
                                <div className="context-menu-item-right-icon">
                                    <i className="iconfont-1 ghzs-gray-xiangxia2" />
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                <div className="context-menu-item-border"></div>
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickContextMenu(e, 'ReName')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-wenben1" />
                        </div>
                        <p className="context-menu-item-title">重新命名</p>
                    </div>
                    <div className="context-menu-item-right">
                    </div>
                </div>
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickContextMenu(e, 'MoveUp')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-shangyiyiji" />
                        </div>
                        <p className="context-menu-item-title">上移一级</p>
                    </div>
                    <div className="context-menu-item-right">
                    </div>
                </div>
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickContextMenu(e, 'MoveDown')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-xiayiyiji" />
                        </div>
                        <p className="context-menu-item-title">下移一级</p>
                    </div>
                    <div className="context-menu-item-right">
                    </div>
                </div>
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickContextMenu(e, 'MoveTop')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-zhiding" />
                        </div>
                        <p className="context-menu-item-title">同级置顶</p>
                    </div>
                    <div className="context-menu-item-right">
                    </div>
                </div>
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickContextMenu(e, 'MoveBottom')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-zhidi" />
                        </div>
                        <p className="context-menu-item-title">同级置底</p>
                    </div>
                    <div className="context-menu-item-right">
                    </div>
                </div>
                {
                    currentScreen == 'currentTopic' ?
                        <>
                            <div className="context-menu-item-border"></div>
                            <div
                                className="flex-row context-menu-item"
                                onClick={(e) => onClickContextMenu(e, 'UnChecked')}
                            >
                                <div className="flex-row context-menu-item-left">
                                    <div className="context-menu-item-icon">
                                        <i className="iconfont ghzs-color-shanchu" />
                                    </div>
                                    <p
                                        className="context-menu-item-title"
                                        style={{ color: '#D81E06' }}
                                    >移除</p>
                                </div>
                                <div className="context-menu-item-right">
                                </div>
                            </div>
                        </>
                        :
                        null
                }
                {/* <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickContextMenu(e, 'Delete')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont ghzs-color-shanchu" />
                        </div>
                        <p
                            className="context-menu-item-title"
                            style={{ color: '#D81E06' }}
                        >删除</p>
                    </div>
                    <div className="context-menu-item-right">
                    </div>
                </div> */}
            </div>
            <div
                ref={subContextMenuRef}
                className="sub-context-menu-container"
                onMouseDown={onContextMenuContanerMouseDown}
            >
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickSubContextMenu(e, 'AddTextNode')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-wenben" />
                        </div>
                        <p className="context-menu-item-title">大纲节点</p>
                    </div>
                    <div className="context-menu-item-right">
                    </div>
                </div>
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickSubContextMenu(e, 'AddDeviceNode')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-sheshi" />
                        </div>
                        <p className="context-menu-item-title">项目节点</p>
                    </div>
                    <div className="context-menu-item-right">
                    </div>
                </div>
            </div>
        </ContextMenuWrapper>
    )
}


export default forwardRef(TopicTreeContextMenu)