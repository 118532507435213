import './AddTopicModal.scss';
import React, { forwardRef, useState, useImperativeHandle, ChangeEvent, useRef, useEffect, useContext, useMemo } from 'react';
import { Button, Modal, Input, Table, Space, Form, Pagination, Select, Radio, Image, Upload, DatePicker, Divider, DatePickerProps, TreeSelect, Switch } from 'antd';
import { FontSizeOutlined, PayCircleOutlined, BankOutlined, DesktopOutlined, ExclamationCircleOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import type { RadioChangeEvent, TabsProps } from 'antd';
import { deepCopy, getChangeEventValue, getUuid, isEmpty, toastShort } from '../../../../utils';
import { SearchSelectResult, SelectOption, TopicNodeActionType, TopicType } from '../../../../utils/types';
import SearchSelect from '../../../../components/select/SearchSelect';
import comDocContext from '../../../../context/DocContext';
import CustomSelect from '../../../../components/select/CustomSelect';
import { unitOptionsList } from '../../../../utils/local-options';
import AutoSelectInput from '../../../../components/override-antd-kit/AutoSelectInput';
import TopicTreeSelectPanel from '../../../../components/topic-tree-kit/TopicTreeSelectPanel';
import { saasConfig } from '../../../../config/theme-config';

interface Props {
    onFinish?: (topic: TopicType, systemTopic?: TopicType) => void
    currentTopicNode?: TopicType
    currentTreeNodeActionType?: TopicNodeActionType
}

const AddTopicModal = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openModal: openModal
    }))

    const {
        comDocInstance,
        _setComDocInstance,
        comDocConfig,
    } = useContext(comDocContext);

    const {
        onFinish,
        currentTopicNode,
        currentTreeNodeActionType
    } = props;

    const delayTimer = useRef(null);

    const [submitLoading, setSubmitLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm<TopicType>();
    const [formData, setFormData] = useState<TopicType>({});
    const [defaultFormData] = useState<TopicType>({
        topicName: '',
        mainProjectRecommand: 0,
    });

    const topicTreeSelectPanelRef = useRef<any>(null);

    const [showDevicePanel, setShowDevicePanel] = useState(true);

    const [topicOptions, setTopicOptions] = useState<SelectOption[]>([]);

    const [tempTopicList, setTempTopicList] = useState<TopicType[]>([]);

    const selectedTempTopic = useRef<TopicType>(null);

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current);
        }
    }, [])

    useEffect(() => {
        const { topicList } = comDocInstance;
        setTempTopicList(deepCopy(topicList))
    }, [comDocInstance]);

    const handleConfirm = async () => {
        try {
            const values = await form.validateFields();
            if (isEmpty(currentTopicNode)) {
                return toastShort('error', '目标节点丢失')
            }
            let textTopic: TopicType = {
                id: 'custom' + getUuid(8),
                topicName: formData.topicName,
                topicType: 'text',
                backgroundParagraph1: "",
                createDate: new Date().getDate(),
                isCustom: true,
                childrenDeviceTopicListLength: 0,
            }
            let deviceTopic: TopicType = {
                id: 'custom' + getUuid(8),
                topicName: formData.topicName,
                topicType: 'device',
                unit: formData.unit,
                count: formData.count,
                unitPrice: formData.unitPrice,
                deviceType: 'X',
                investmentCompositionType: formData.investmentCompositionType,
                investmentCompositionMoney: 0,
                nearFutureMoneyRate: formData.nearFutureMoneyRate,
                mediumAndLongTermMoneyRate: formData.mediumAndLongTermMoneyRate,
                facilitiesBackgroundParagraph1: formData.facilitiesBackgroundParagraph1,
                facilitiesBackgroundParagraph2: formData.facilitiesBackgroundParagraph2,
                facilitiesBackgroundParagraph3: formData.facilitiesBackgroundParagraph3,
                facilitiesBackgroundParagraph4: formData.facilitiesBackgroundParagraph4,
                deviceBackgroundParagraph1: formData.deviceBackgroundParagraph1,
                deviceBackgroundParagraph2: formData.deviceBackgroundParagraph2,
                deviceBackgroundParagraph3: formData.deviceBackgroundParagraph3 || "$[X]$[N]$[U]",
                mainProjectRecommand: formData.mainProjectRecommand ? 1 : 0,
                isMainProject: formData.mainProjectRecommand ? true : false,
                isMainProjectChcked: formData.mainProjectRecommand ? true : false,
                createDate: new Date().getDate(),
                isCustom: true
            }
            deviceTopic = {
                ...selectedTempTopic.current,
                ...deviceTopic
            }
            let params: TopicType = {};
            console.log("测试类型", currentTreeNodeActionType)
            switch (currentTreeNodeActionType) {
                case 'AddDeviceChildNode':
                    params = deviceTopic;
                    params.pid = currentTopicNode.id;
                    break;
                case 'AddDevicePeerNode':
                    params = deviceTopic;
                    params.pid = currentTopicNode.pid;
                    break;
                case "AddTextChildNode":
                    params = textTopic;
                    params.pid = currentTopicNode.id;
                    //@ts-ignore
                    params.topicLevel = Number(currentTopicNode.topicLevel) + 1 + '';
                    break;
                case 'AddTextPeerNode':
                    params = textTopic;
                    params.pid = currentTopicNode.pid;
                    //@ts-ignore
                    params.topicLevel = Number(currentTopicNode.topicLevel) + '';
                    break;
                default:
                    toastShort('error', '操作类型异常')
                    break;
            }
            delayTimer.current = setTimeout(() => {
                onFinish && onFinish(deepCopy(params), selectedTempTopic.current);
            }, 50);
            setIsModalOpen(false);
            handleResetModal();
        } catch (e) {

        }
    };

    const openModal = (rowData: { name?: string }) => {
        const { keys, values } = Object;
        if (rowData) {
            let _formData = deepCopy(rowData);
            const keyList = keys(_formData);
            const valueList = values(_formData)
            keyList.forEach((key, index) => {
                form.setFieldValue(key, valueList[index])
            })
            setFormData(_formData);
        } else {
            let _formData = deepCopy(defaultFormData);
            const keyList = keys(_formData);
            const valueList = values(_formData)
            keyList.forEach((key, index) => {
                form.setFieldValue(key, valueList[index])
            })
            setFormData(_formData);
        }
        if (saasConfig.systemType !== 'HuaWeiTestServer') {
            setShowDevicePanel(false);
        }
        setIsModalOpen(true);
    };

    useEffect(() => {
        filterTopicOptions();
        if (saasConfig.systemType == 'HuaWeiTestServer') {
            setShowDevicePanel(adaptModalTitle().includes("项目") ? true : false);
        } else {
            setShowDevicePanel(false)
        }
    }, [currentTreeNodeActionType, isModalOpen])

    const handleCancel = () => {
        setIsModalOpen(false);
        handleResetModal();
    };

    const handleSetFormData = (target: string, value: any) => {
        let _formData = formData;
        //@ts-ignore
        _formData[target] = value;
        setFormData(deepCopy(_formData));
    };

    const onTopicNameTextChange = (e: SearchSelectResult) => {
        const { value, option } = e;
        if (option) {
            const findTopic = deepCopy(tempTopicList.find(topic => {
                return topic.id === option.value;
            }))
            if (findTopic) {
                delete findTopic.id;
                const { keys, values } = Object;
                selectedTempTopic.current = findTopic;
                let _formData = deepCopy(findTopic);
                const keyList = keys(_formData);
                const valueList = values(_formData)
                keyList.forEach((key, index) => {
                    form.setFieldValue(key, valueList[index])
                })
                setFormData(deepCopy(_formData));
                topicTreeSelectPanelRef.current.handleSynchronizationSelectedNode(findTopic)
            } else {
                selectedTempTopic.current = null;
            }
        } else {
            topicTreeSelectPanelRef.current.handleSynchronizationSelectedNode(null)
            selectedTempTopic.current = null;
            handleSetFormData('topicName', value);
        }
    }

    const handleResetModal = () => {
        setFormData({
            topicName: '',
            topicLevel: null
        });
        form.resetFields();
        setSubmitLoading(false)
    }

    const adaptModalTitle = (): string => {
        let title = '';
        if (currentTreeNodeActionType) {
            switch (currentTreeNodeActionType) {
                case 'AddDeviceChildNode':
                    title = '新增项目'
                    break;
                case 'AddDevicePeerNode':
                    title = '新增项目'
                    break;
                case 'AddTextChildNode':
                    title = '新增下级大纲'
                    break;
                case 'AddTextPeerNode':
                    title = '新增同级大纲'
                    break;
                default:
                    title = '未知操作'
                    break;
            }
        }
        return title;
    }

    const adaptName = () => {
        let title = '';
        if (currentTreeNodeActionType) {
            switch (currentTreeNodeActionType) {
                case 'AddDeviceChildNode':
                    title = '项目名称'
                    break;
                case 'AddDevicePeerNode':
                    title = '项目名称'
                    break;
                case 'AddTextChildNode':
                    title = '大纲标题'
                    break;
                case 'AddTextPeerNode':
                    title = '大纲标题'
                    break;
                default:
                    title = '大纲标题'
                    break;
            }
        }
        return title;
    }

    const filterTopicOptions = () => {
        let tempOptions: SelectOption[] = [];
        switch (currentTreeNodeActionType) {
            case 'AddDeviceChildNode':
            case 'AddDevicePeerNode':
                tempTopicList.forEach(topic => {
                    if (topic.topicType == 'device') {
                        tempOptions.push({
                            tag: '项目',
                            label: topic.topicName,
                            value: topic.id,
                            // disable: topic.checked ? true : false
                        })
                    }
                })
                break;
            case 'AddTextChildNode':
            case 'AddTextPeerNode':
                tempTopicList.forEach(topic => {
                    if (topic.topicType == 'text') {
                        tempOptions.push({
                            tag: '大纲',
                            label: topic.topicName,
                            value: topic.id,
                            // disable: topic.checked ? true : false
                        })
                    }
                })
                break;
            default:
                break;
        }
        setTopicOptions(tempOptions);
    }


    const onUnitTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData('unit', getChangeEventValue(e))
    };

    const onUnitSelect = (e: SearchSelectResult) => {
        console.log("e---->", e);
        handleSetFormData('unit', e.value);
    }

    const onCountTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const count = getChangeEventValue(e);
        const RE = /^[0-9]*[1-9][0-9]*$/;
        // if ((!RE.test(count) && !isEmpty(count)) || count.includes('-')) {
        //     form.setFieldValue('count', formData.count);
        //     return toastShort('error', '请输入正整数');;
        // }
        if (count.split('.').length > 2) {
            form.setFieldValue('count', formData.count);
            return toastShort('error', '请输入正确的数值');;
        }
        if (!isEmpty(count) && !isEmpty(formData.unitPrice)) {
            const investmentCompositionMoney = (Number(count) * Number(formData.unitPrice)).toFixed(2);
            handleSetFormData('investmentCompositionMoney', investmentCompositionMoney);
            form.setFieldValue('investmentCompositionMoney', investmentCompositionMoney);
        }
        handleSetFormData('count', count);
    };

    const onUnitPriceTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const unitPrice = getChangeEventValue(e);
        const RE = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
        if (!RE.test(unitPrice) && !isEmpty(unitPrice)) {
            form.setFieldValue('unitPrice', formData.unitPrice);
            return toastShort('error', '请输入合理的单价');;
        }
        if (!isEmpty(unitPrice) && !isEmpty(formData.count)) {
            const investmentCompositionMoney = (Number(unitPrice) * Number(formData.count)).toFixed(2);
            handleSetFormData('investmentCompositionMoney', investmentCompositionMoney);
            form.setFieldValue('investmentCompositionMoney', investmentCompositionMoney);
        }
        handleSetFormData('unitPrice', getChangeEventValue(e))
    };

    const onMainProjectRecommandChange = (e: RadioChangeEvent) => {
        handleSetFormData('mainProjectRecommand', getChangeEventValue(e))
    }

    const onInvestmentCompositionTypeChange = (value: string) => {
        handleSetFormData('investmentCompositionType', value)
    };

    const onNearFutureMoneyRateTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = getChangeEventValue(e);
        if (Number(value) > 100) {
            form.setFieldValue('nearFutureMoneyRate', '100');
            handleSetFormData('nearFutureMoneyRate', '100');
            handleSetFormData('mediumAndLongTermMoneyRate', '0');
            form.setFieldValue('mediumAndLongTermMoneyRate', '0');
            return toastShort("error", "最大不能超过100%")
        }
        if (value.length > 5) {
            form.setFieldValue('nearFutureMoneyRate', value.substring(0, 5));
            handleSetFormData('nearFutureMoneyRate', value.substring(0, 5));
            handleSetFormData('mediumAndLongTermMoneyRate', (100 - Number(value)).toString());
            form.setFieldValue('mediumAndLongTermMoneyRate', (100 - Number(value)).toString());
            return toastShort("error", "最大长度不能5位")
        }
        handleSetFormData('mediumAndLongTermMoneyRate', (100 - Number(value)).toString());
        form.setFieldValue('mediumAndLongTermMoneyRate', (100 - Number(value)).toString());
        handleSetFormData('nearFutureMoneyRate', getChangeEventValue(e))
    }

    const onMediumAndLongTermMoneyRateTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = getChangeEventValue(e);
        if (Number(value) > 100) {
            form.setFieldValue('mediumAndLongTermMoneyRate', '100');
            handleSetFormData('mediumAndLongTermMoneyRate', '100');
            handleSetFormData('nearFutureMoneyRate', '0');
            form.setFieldValue('nearFutureMoneyRate', '0');
            return toastShort("error", "最大不能超过100%")
        }
        if (value.length > 5) {
            form.setFieldValue('mediumAndLongTermMoneyRate', value.substring(0, 5));
            handleSetFormData('mediumAndLongTermMoneyRate', value.substring(0, 5));
            handleSetFormData('nearFutureMoneyRate', (100 - Number(value)).toString());
            form.setFieldValue('nearFutureMoneyRate', (100 - Number(value)).toString());
            return toastShort("error", "最大长度不能5位")
        }
        handleSetFormData('nearFutureMoneyRate', (100 - Number(value)).toString());
        form.setFieldValue('nearFutureMoneyRate', (100 - Number(value)).toString());
        handleSetFormData('mediumAndLongTermMoneyRate', getChangeEventValue(e))
    }

    const switchShowDevicePanel = () => {
        setShowDevicePanel(!showDevicePanel);
    }

    return (
        <Modal
            title={adaptModalTitle()}
            open={isModalOpen}
            onOk={handleConfirm}
            onCancel={handleCancel}
            okText={'确 认'}
            cancelText={'取 消'}
            width={!showDevicePanel ? 672 : 1020}
            // style={{ transition: `all ease 0.2s` }}
            maskClosable={false}
            confirmLoading={submitLoading}
            centered
        >
            <div className='flex-row custom-modal-container add-topic-modal'>
                <div className='add-topic-modal-left'>
                    <Form
                        size={'large'}
                        form={form}
                        layout={'horizontal'}
                        autoComplete="off"
                        labelAlign={'right'}
                        labelCol={{ span: 5 }}
                        style={{ width: '100%' }}
                    >
                        <Form.Item
                            label={adaptName()}
                            name={'topicName'}
                            rules={[{ required: true, message: `请输入${adaptName()}` }]}
                        >
                            {
                                currentTreeNodeActionType == 'AddDeviceChildNode' || currentTreeNodeActionType === 'AddDevicePeerNode' ?
                                    <div className='flex-row' style={{ width: 456 }}>
                                        <SearchSelect
                                            placeholder={`请输入${adaptName()}(20个字符之内)`}
                                            // style={{ width: '456px' }}
                                            style={{ flex: 1 }}
                                            maxLength={20}
                                            value={formData.topicName}
                                            onChange={onTopicNameTextChange}
                                            // options={topicOptions.filter(options => options.label.includes(formData.topicName))}
                                            options={topicOptions}
                                            selectPopoverWidth={456}
                                            showSelectWhenMatch={true}
                                            allowClear
                                        />
                                        {
                                            saasConfig.systemType == 'HuaWeiTestServer' ?
                                                <Button
                                                    type={showDevicePanel ? 'primary' : 'default'}
                                                    style={{ marginLeft: 12 }}
                                                    onClick={switchShowDevicePanel}
                                                    ghost={showDevicePanel}
                                                >
                                                    系统项目库
                                                    {
                                                        showDevicePanel ?
                                                            <DoubleLeftOutlined />
                                                            :
                                                            <DoubleRightOutlined />
                                                    }
                                                </Button>
                                                :
                                                null
                                        }
                                    </div>
                                    :
                                    <AutoSelectInput
                                        placeholder={`请输入${adaptName()}(20个字符之内)`}
                                        style={{ width: '426px' }}
                                        maxLength={20}
                                        value={formData.topicName}
                                        onChange={(e) => onTopicNameTextChange({ value: getChangeEventValue(e), option: null })}
                                        allowClear
                                    />
                            }
                        </Form.Item>
                        {
                            currentTreeNodeActionType == 'AddDeviceChildNode' || currentTreeNodeActionType === 'AddDevicePeerNode' ?
                                <>
                                    <Form.Item
                                        name="unit"
                                        label="单位"
                                        rules={[{ required: true, message: `请输入单位` }]}
                                    >
                                        <SearchSelect
                                            value={formData.unit}
                                            selectPopoverWidth={456}
                                            style={{ width: '456px' }}
                                            showSelectWhenMatch={false}
                                            // handleClose={() => console.log("close")}
                                            options={unitOptionsList}
                                            onChange={onUnitSelect}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="count"
                                        label="数量"
                                        rules={[{ required: true, message: `请输入数量` }]}
                                    >
                                        <AutoSelectInput
                                            style={{ width: '456px' }}
                                            placeholder={'请输入数量'}
                                            value={formData.count}
                                            onChange={onCountTextChange}
                                            type={'number'}
                                            maxLength={24}
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="unitPrice"
                                        label="单价"
                                        rules={[{ required: true, message: `请输入单价` }]}
                                    >
                                        <AutoSelectInput
                                            style={{ width: '456px' }}
                                            placeholder={'请输入单价'}
                                            value={formData.unitPrice}
                                            onChange={onUnitPriceTextChange}
                                            type={'number'}
                                            maxLength={24}
                                            addonAfter={'万元'}
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="investmentCompositionType"
                                        label="默认投资构成"
                                    >
                                        <Select
                                            style={{ width: '458px' }}
                                            placeholder={'请选择默认投资构成'}
                                            value={formData.investmentCompositionType}
                                            onChange={onInvestmentCompositionTypeChange}
                                            options={[
                                                {
                                                    label: '建安',
                                                    value: 'buildingInstallation',
                                                },
                                                {
                                                    label: '设备',
                                                    value: 'device',
                                                },
                                                {
                                                    label: '其他',
                                                    value: 'other',
                                                }
                                            ]}
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div>投资比例</div>}
                                        style={{ width: '622px', marginLeft: -5 }}
                                    >
                                        <Space align={'baseline'}>
                                            <Form.Item
                                                name="nearFutureMoneyRate"
                                                style={{ display: 'inline-block', width: '220px', marginRight: 10 }}
                                            >
                                                <AutoSelectInput
                                                    placeholder="近期投资比例"
                                                    addonAfter="%"
                                                    type={'number'}
                                                    maxLength={5}
                                                    value={formData.nearFutureMoneyRate}
                                                    onChange={onNearFutureMoneyRateTextChange}
                                                    allowClear
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="mediumAndLongTermMoneyRate"
                                                style={{ display: 'inline-block', width: '220px' }}
                                            >
                                                <AutoSelectInput
                                                    placeholder="中长期投资比例"
                                                    addonAfter="%"
                                                    type={'number'}
                                                    maxLength={5}
                                                    value={formData.mediumAndLongTermMoneyRate}
                                                    onChange={onMediumAndLongTermMoneyRateTextChange}
                                                    allowClear
                                                />
                                            </Form.Item>
                                        </Space>
                                    </Form.Item>
                                    <Form.Item
                                        name="mainProjectRecommand"
                                        label="重点工程"
                                        style={{ marginTop: -24 }}
                                    >
                                        <Radio.Group
                                            value={formData.mainProjectRecommand}
                                            onChange={onMainProjectRecommandChange}
                                        >
                                            <Radio value={1}>是</Radio>
                                            <Radio value={0}>否</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </>
                                :
                                null
                        }
                    </Form>
                </div>
                {
                    adaptModalTitle().includes("项目") ?
                        <div
                            className='flex-col add-topic-modal-right'
                            // style={{ display: showDevicePanel ? 'flex' : 'none', marginTop: -12 }}
                            style={{ display: showDevicePanel ? 'flex' : 'none', marginTop: -12 }}
                        >
                            <TopicTreeSelectPanel
                                ref={topicTreeSelectPanelRef}
                                initalTopicList={tempTopicList}
                                onSelectTopicNode={onTopicNameTextChange}
                            />
                        </div>
                        :
                        null
                }
            </div>
        </Modal>
    )
}


export default forwardRef(AddTopicModal)