
//社区情况统计表0~2
const subTableWorkSheet1Template: string[][] = [
    [
        "统计单元1",
        "统计单位2",
        "人口",
        "户数",
        "人均年收入/万元",
        "产值/万元",
        "",
        "",
        ""
    ],
    [
        "",
        "",
        "",
        "",
        "",
        "第一产业",
        "第二产业",
        "第三产业",
        ""
    ],
]


//社区情况统计表0~2
const subTableWorkSheet1Template1: string[][] = [
    [
        "统计单元1",
        "统计单位2",
        "人口",
        "户数",
        "人均年收入/万元",
        "产值/万元",
        "",
        "",
    ],
    [
        "",
        "",
        "",
        "",
        "",
        "第一产业",
        "第二产业",
        "第三产业",
    ],
]

//土地利用现状表0~2
const subTableWorkSheet2Template: string[][] = [
    [
        "统计单元",
        "总面积/公顷",
        "林地",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "非林地",
        "",
        "",
        "",
        "",
        ""
    ],
    [
        "",
        "",
        "合计",
        "有林地",
        "",
        "",
        "疏林地",
        "灌木林地",
        "",
        "未成林地",
        "",
        "苗圃地",
        "无立木林地",
        "",
        "",
        "宜林地",
        "",
        "",
        "林业辅助生产用地",
        "合计",
        "耕地",
        "牧草地",
        "水域",
        "未利用地",
        "建设用地"
    ],
    [
        "",
        "",
        "",
        "乔木林",
        "红树林",
        "竹林",
        "",
        "国家特别规定灌木林地",
        "其它灌木林地",
        "未成林造林地",
        "未成林封育地",
        "",
        "采伐迹地",
        "火烧迹地",
        "其它无立木林地",
        "宜林荒山荒地",
        "宜林沙荒地",
        "其它宜林地",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    ],
]

//土地利用现状表0~1
const subTableWorkSheet2Template1: string[][] = [
    [
        "统计单元",
        "总面积/公顷",
        "林地",
        "",
        "",
        "",
        "",
        "草地",
        "",
        "",
        "",
        "湿地",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "园地",
        "",
        "",
        "",
        "",
        "",
        "陆地水域",
        "",
        "",
        "",
        "",
        "",
        "",
        "耕地",
        "农业设施建设用地",
        "居住用地",
        "公共管理与公共服务用地",
        "商业服务业用地",
        "工矿用地",
        "仓储用地",
        "交通运输用地",
        "公用设施用地",
        "绿地与开敞空间用地",
        "特殊用地",
        "留白用地",
        "其他土地",
        "渔业用海",
        "工矿通信用海",
        "交通运输用海",
        "游憩用海",
        "特殊用海",
        "其他海域"
    ],
    [
        "",
        "",
        "合计",
        "乔木林地",
        "竹林地",
        "灌木林地",
        "其他林地",
        "合计",
        "天然牧草地",
        "人工牧草地",
        "其他草地",
        "合计",
        "森林沼泽",
        "灌丛沼泽",
        "沼泽草地",
        "其他沼泽地",
        "沿海滩涂",
        "内陆滩涂",
        "红树林地",
        "合计",
        "果园",
        "茶园",
        "橡胶园地",
        "油料园地",
        "其他园地",
        "合计",
        "河流水面",
        "湖泊水面",
        "水库水面",
        "坑塘水面",
        "沟渠",
        "冰川及常年积雪",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    ]
]

//功能区划表0
const subTableWorkSheet3Template: string[][] = [
    [
        "功能区",
        "面积/公顷",
        "比例/%",
        "辖区范围",
        "局、站等设置"
    ],
]


//人员现状统计表
const subTableWorkSheet4Template: string[][] = [
    [
        "人员构成",
        "文化结构",
        "",
        "",
        "",
        "",
        "",
        "职称结构",
        "",
        "",
        "",
        "",
        "职工数",
        "",
        "",
        "退休人员"
    ],
    [
        "",
        "小计",
        "硕士以上",
        "本科",
        "专科",
        "中专或高中",
        "初中及以下",
        "小计",
        "高级",
        "中级",
        "助工",
        "技术员",
        "小计",
        "正式职工",
        "临时工",
        ""
    ]
]

//基础设施现状统计表0
const subTableWorkSheet5Template: string[][] = [
    [
        "设施设备名称",
        "数量",
        "备注"
    ],
]

//野生动植物资源统计表0
const subTableWorkSheet6Template: string[][] = [
    [
        "物种名称",
    ]
]


export {
    subTableWorkSheet1Template,
    subTableWorkSheet1Template1,
    subTableWorkSheet2Template,
    subTableWorkSheet2Template1,
    subTableWorkSheet3Template,
    subTableWorkSheet4Template,
    subTableWorkSheet5Template,
    subTableWorkSheet6Template,
}