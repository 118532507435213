/**
 * 结合module style创建类名标记
 * @param style
 * @returns
 */
// export const createClassNameMark = (style: { readonly [name: string]: string }) => {
//   return (...classNames: string[] | { [name: string]: boolean }[]) => {
//     if (classNames.every((name) => typeof name === 'string'))
//       return classNames
//         .map((name) => style[name as string])
//         .filter(Boolean)
//         .join(' ');
//     if (classNames.every((nameMap) => nameMap instanceof Object))
//       return Object.entries(
//         (classNames as { [name: string]: boolean }[]).reduce(
//           (r, c) => ({ ...r, ...c }),
//           {} as { [name: string]: boolean },
//         ),
//       )
//         .filter(([, val]) => val)
//         .map(([name]) => style[name])
//         .filter(Boolean)
//         .join(' ');
//     return '';
//   };
// };
export const createClassNameMark = (style: { readonly [name: string]: string }) => {
  return (...classNames: (string | { [name: string]: boolean })[]) => {
    if (classNames.every(name => typeof name === 'string')) {
      return classNames
        .map(name => style[name as string])
        .filter(Boolean)
        .join(' ');
    }

    const classNameMap = classNames.filter((name): name is { [name: string]: boolean } => typeof name === 'object');
    if (classNameMap.length > 0) {
      return Object.entries(
        classNameMap.reduce(
          (result, current) => ({ ...result, ...current }),
          {} as { [name: string]: boolean }
        )
      )
        .filter(([, value]) => value)
        .map(([name]) => style[name])
        .filter(Boolean)
        .join(' ');
    }

    return '';
  };
};