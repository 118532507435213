import React from 'react';

import { useEmitSlateItemClick } from '../../hooks';
import { SlateElementProps } from '../../types';
import { createClassNameMark } from '../../utils';

import Style from './main.module.scss';

const classNames = createClassNameMark(Style);
export const CombineParagraph = React.memo(function CombineParagraph({
  attributes,
  children,
  element,
}: SlateElementProps) {
  const onClick = useEmitSlateItemClick(element);
  return (
    <div {...attributes} doc-item-id={element.id} className={classNames('doc-combine-paragraph')} onClick={onClick}>
      {children}
    </div>
  );
});
