import React from 'react';

import { RenderLeafProps } from 'slate-react';

export const Leaf = React.memo(function Leaf({ attributes, children, leaf }: RenderLeafProps) {
  const l = leaf as RenderLeafProps['leaf'] & { [format: string]: boolean | string };
  if (l.delete) children = <del>{children}</del>;
  if (l.italic) children = <i>{children}</i>;
  if (l.underline) children = <u>{children}</u>;
  if (l.sup) children = <sup>{children}</sup>;
  if (l.sub) children = <sub>{children}</sub>;
  if (l.color) children = <span style={{ color: l.color as string }}>{children}</span>;
  if (l.bgColor) children = <span style={{ backgroundColor: l.bgColor as string }}>{children}</span>;
  if (l.bold) children = <strong>{children}</strong>;
  if (l.fontSize)
    children = (
      <span
        style={{
          fontSize: l.fontSize as string,
        }}
      >
        {children}
      </span>
    );
  if (l.fontFamily)
    children = (
      <span
        style={{
          fontFamily: l.fontFamily as string,
        }}
      >
        {children}
      </span>
    );
  return <span {...attributes}>{children}</span>;
});
