import { useLayoutEffect, useState } from "react"
import useResizeObserver from '@react-hook/resize-observer';

const useReSize = (target) => {
    const [size, setSize] = useState<{bottom?: number, height?: number, width?: number}>({
        width: 0,
        height: 0,
    })

    useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target])

    // @ts-ignore
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}

export default useReSize;