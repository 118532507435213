import React, { forwardRef, useState, useImperativeHandle, ChangeEvent, useRef, useEffect, useContext, useMemo } from 'react';
import { Modal, Input, Table, Space, Form, Select, Radio } from 'antd';
import type { RadioChangeEvent, TabsProps } from 'antd';
import { deepCopy, getChangeEventValue, getUuid, isEmpty, toastShort } from './../../../utils';
import { SearchSelectResult, SelectOption, TopicNodeActionType, TopicType } from './../../../utils/types';
import SearchSelect from './../../../components/select/SearchSelect';
import comDocContext from './../../../context/DocContext';
import AutoSelectInput from "../../../components/override-antd-kit/AutoSelectInput";

interface Props {
    onFinish?: (topic: TopicType, systemTopic?: TopicType) => void
}

const AddTopicModalNew = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openModal: openModal
    }))

    const {
        comDocInstance,
        _setComDocInstance,
        comDocConfig,
    } = useContext(comDocContext);

    const {
        onFinish,
    } = props;

    const delayTimer = useRef(null);

    const [submitLoading, setSubmitLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm<TopicType>();
    const [formData, setFormData] = useState<TopicType>({});
    const [defaultFormData] = useState<TopicType>({
        topicName: '',
        mainProjectRecommand: 0,
    });

    const tempNodeRef = useRef<TopicType>(null);

    const [topicOptions, setTopicOptions] = useState<SelectOption[]>([]);

    const [tempTopicList, setTempTopicList] = useState<TopicType[]>([]);

    const selectedTempTopic = useRef<TopicType>(null);

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current);
        }
    }, [])

    useEffect(() => {
        const { topicList } = comDocInstance;
        setTempTopicList(deepCopy(topicList))
    }, [comDocInstance]);

    const handleConfirm = async () => {
        try {
            const values = await form.validateFields();
            const tempNode = tempNodeRef.current;
            //如果是文本，那么是新创建
            let textTopic: TopicType = {
                topicName: formData.topicName,
                topicType: 'text',
                backgroundParagraph1: "",
                createDate: new Date().getDate(),
                // isCustom: true,
                childrenDeviceTopicListLength: 0,
            }
            //如果是设施设备
            let deviceTopic: TopicType = {
                topicName: formData.topicName,
                topicType: 'device',
                unit: formData.unit,
                count: formData.count,
                unitPrice: formData.unitPrice,
                deviceType: 'X',
                investmentCompositionType: formData.investmentCompositionType,
                investmentCompositionMoney: 0,
                nearFutureMoneyRate: formData.nearFutureMoneyRate,
                mediumAndLongTermMoneyRate: formData.mediumAndLongTermMoneyRate,
                facilitiesBackgroundParagraph1: "",
                deviceBackgroundParagraph2: "",
                deviceBackgroundParagraph3: "$[X]$[N]$[U]",
                mainProjectRecommand: formData.mainProjectRecommand ? 1 : 0,
                isMainProject: formData.mainProjectRecommand ? true : false,
                isMainProjectChcked: formData.mainProjectRecommand ? true : false,
                createDate: new Date().getDate(),
                // isCustom: true
            }
            deviceTopic = {
                ...tempNode,
                ...deviceTopic
            }
            let params: TopicType = deviceTopic;
            console.log("编辑状态下构造好的参数是---->", deviceTopic);
            delayTimer.current = setTimeout(() => {
                onFinish && onFinish(deepCopy(params));
            }, 50);
            setIsModalOpen(false);
            handleResetModal();
        } catch (e) {

        }
    };

    const openModal = (rowData: TopicType) => {
        const { keys, values } = Object;
        tempNodeRef.current  = rowData;
        if (rowData) {
            let _formData = deepCopy(rowData);
            const keyList = keys(_formData);
            const valueList = values(_formData)
            keyList.forEach((key, index) => {
                form.setFieldValue(key, valueList[index])
            })
            setFormData(_formData);
        } else {
            let _formData = deepCopy(defaultFormData);
            const keyList = keys(_formData);
            const valueList = values(_formData)
            keyList.forEach((key, index) => {
                form.setFieldValue(key, valueList[index])
            })
            setFormData(_formData);
        }
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        handleResetModal();
    };

    const handleSetFormData = (target: string, value: any) => {
        let _formData = formData;
        //@ts-ignore
        _formData[target] = value;
        setFormData(deepCopy(_formData));
    };

    const onTopicNameTextChange = (e: SearchSelectResult) => {
        const { value, option } = e;
        if (option) {
            const findTopic = tempTopicList.find(topic => {
                return topic.id === option.value;
            })
            if (findTopic) {
                delete findTopic.id;
                // console.log("findTopic--->", findTopic)
                const { keys, values } = Object;
                selectedTempTopic.current = findTopic;
                let _formData = deepCopy(findTopic);
                const keyList = keys(_formData);
                const valueList = values(_formData)
                keyList.forEach((key, index) => {
                    form.setFieldValue(key, valueList[index])
                })
                setFormData(deepCopy(_formData));
            } else {
                selectedTempTopic.current = null;
            }
        } else {
            selectedTempTopic.current = null;
            handleSetFormData('topicName', value);
        }
    }

    const handleResetModal = () => {
        setFormData({
            topicName: '',
            topicLevel: null
        });
        form.resetFields();
        setSubmitLoading(false)
    }

    const adaptModalTitle = (): string => {
        let title = '编辑项目';
        return title;
    }

    const adaptName = () => {
        let title = '项目名称'
        return title;
    }

    // const filterTopicOptions = () => {
    //     let tempOptions: SelectOption[] = [];
    //     switch (currentTreeNodeActionType) {
    //         case 'AddDeviceChildNode':
    //         case 'AddDevicePeerNode':
    //             tempTopicList.forEach(topic => {
    //                 if (topic.topicType == 'device') {
    //                     tempOptions.push({
    //                         tag: '项目',
    //                         label: topic.topicName,
    //                         value: topic.id,
    //                         disable: topic.checked ? true : false
    //                     })
    //                 }
    //             })
    //             break;
    //         case 'AddTextChildNode':
    //         case 'AddTextPeerNode':
    //             tempTopicList.forEach(topic => {
    //                 if (topic.topicType == 'text') {
    //                     tempOptions.push({
    //                         tag: '大纲',
    //                         label: topic.topicName,
    //                         value: topic.id,
    //                         disable: topic.checked ? true : false
    //                     })
    //                 }
    //             })
    //             break;
    //         default:
    //             break;
    //     }
    //     setTopicOptions(tempOptions);
    // }


    const onUnitTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData('unit', getChangeEventValue(e))
    };

    const onUnitSelect = (e: SearchSelectResult) => {
        console.log("e---->", e);
        handleSetFormData('unit', e.value);
    }

    const onCountTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const count = getChangeEventValue(e);
        const RE = /^[0-9]*[1-9][0-9]*$/;
        if ((!RE.test(count) && !isEmpty(count)) || count.includes('-')) {
            form.setFieldValue('count', formData.count);
            return toastShort('error', '请输入正整数');;
        }
        if (!isEmpty(count) && !isEmpty(formData.unitPrice)) {
            const investmentCompositionMoney = (Number(count) * Number(formData.unitPrice)).toFixed(2);
            handleSetFormData('investmentCompositionMoney', investmentCompositionMoney);
            form.setFieldValue('investmentCompositionMoney', investmentCompositionMoney);
        }
        handleSetFormData('count', count);
    };

    const onUnitPriceTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const unitPrice = getChangeEventValue(e);
        const RE = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
        if (!RE.test(unitPrice) && !isEmpty(unitPrice)) {
            form.setFieldValue('unitPrice', formData.unitPrice);
            return toastShort('error', '请输入合理的单价');;
        }
        if (!isEmpty(unitPrice) && !isEmpty(formData.count)) {
            const investmentCompositionMoney = (Number(unitPrice) * Number(formData.count)).toFixed(2);
            handleSetFormData('investmentCompositionMoney', investmentCompositionMoney);
            form.setFieldValue('investmentCompositionMoney', investmentCompositionMoney);
        }
        handleSetFormData('unitPrice', getChangeEventValue(e))
    };

    const onMainProjectRecommandChange = (e: RadioChangeEvent) => {
        handleSetFormData('mainProjectRecommand', getChangeEventValue(e))
    }

    const onInvestmentCompositionTypeChange = (value: string) => {
        handleSetFormData('investmentCompositionType', value)
    };

    const onNearFutureMoneyRateTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = getChangeEventValue(e);
        if (Number(value) > 100) {
            form.setFieldValue('nearFutureMoneyRate', '100');
            handleSetFormData('nearFutureMoneyRate', '100');
            handleSetFormData('mediumAndLongTermMoneyRate', '0');
            form.setFieldValue('mediumAndLongTermMoneyRate', '0');
            return toastShort("error", "最大不能超过100%")
        }
        if (value.length > 5) {
            form.setFieldValue('nearFutureMoneyRate', value.substring(0, 5));
            handleSetFormData('nearFutureMoneyRate', value.substring(0, 5));
            handleSetFormData('mediumAndLongTermMoneyRate', (100 - Number(value)).toString());
            form.setFieldValue('mediumAndLongTermMoneyRate', (100 - Number(value)).toString());
            return toastShort("error", "最大长度不能5位")
        }
        handleSetFormData('mediumAndLongTermMoneyRate', (100 - Number(value)).toString());
        form.setFieldValue('mediumAndLongTermMoneyRate', (100 - Number(value)).toString());
        handleSetFormData('nearFutureMoneyRate', getChangeEventValue(e))
    }

    const onMediumAndLongTermMoneyRateTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = getChangeEventValue(e);
        if (Number(value) > 100) {
            form.setFieldValue('mediumAndLongTermMoneyRate', '100');
            handleSetFormData('mediumAndLongTermMoneyRate', '100');
            handleSetFormData('nearFutureMoneyRate', '0');
            form.setFieldValue('nearFutureMoneyRate', '0');
            return toastShort("error", "最大不能超过100%")
        }
        if (value.length > 5) {
            form.setFieldValue('mediumAndLongTermMoneyRate', value.substring(0, 5));
            handleSetFormData('mediumAndLongTermMoneyRate', value.substring(0, 5));
            handleSetFormData('nearFutureMoneyRate', (100 - Number(value)).toString());
            form.setFieldValue('nearFutureMoneyRate', (100 - Number(value)).toString());
            return toastShort("error", "最大长度不能5位")
        }
        handleSetFormData('nearFutureMoneyRate', (100 - Number(value)).toString());
        form.setFieldValue('nearFutureMoneyRate', (100 - Number(value)).toString());
        handleSetFormData('mediumAndLongTermMoneyRate', getChangeEventValue(e))
    }

    return (
        <Modal
            title={adaptModalTitle()}
            open={isModalOpen}
            onOk={handleConfirm}
            onCancel={handleCancel}
            okText={'确 认'}
            cancelText={'取 消'}
            width={672}
            maskClosable={false}
            confirmLoading={submitLoading}
            centered
        >
            <div className='custom-modal-container'>
                <Form
                    size={'large'}
                    form={form}
                    layout={'horizontal'}
                    autoComplete="off"
                    labelAlign={'right'}
                    labelCol={{ span: 5 }}
                    style={{ width: '100%' }}
                >
                    <Form.Item
                        label={adaptName()}
                        name={'topicName'}
                        rules={[{ required: true, message: `请输入${adaptName()}` }]}
                    >
                        <AutoSelectInput
                            placeholder={`请输入${adaptName()}(20个字符之内)`}
                            style={{ width: '426px' }}
                            maxLength={20}
                            value={formData.topicName}
                            onChange={(e) => onTopicNameTextChange({ value: getChangeEventValue(e), option: null })}
                            allowClear
                        />
                    </Form.Item>
                    <>
                        <Form.Item
                            name="unit"
                            label="单位"
                            rules={[{ required: true, message: `请输入单位` }]}
                        >
                            {/* <Input
                                        style={{ width: '426px' }}
                                        placeholder={'请输入单位'}
                                        value={formData.unit}
                                        onChange={onUnitTextChange}
                                        maxLength={24}
                                        allowClear
                                    /> */}
                            <SearchSelect
                                value={formData.unit}
                                selectPopoverWidth={425}
                                style={{ width: 425 }}
                                showSelectWhenMatch={false}
                                // handleClose={() => console.log("close")}
                                options={[
                                    {
                                        label: "m",
                                        value: "m"
                                    },
                                    {
                                        label: "km",
                                        value: "km"
                                    },
                                    {
                                        label: "㎡",
                                        value: "㎡",
                                    },
                                    {
                                        label: "k㎡",
                                        value: "k㎡",
                                    },
                                    {
                                        label: "h㎡",
                                        value: "h㎡"
                                    },
                                    {
                                        label: "m³",
                                        value: "m³"
                                    },
                                    {
                                        label: "km³",
                                        value: "km³",
                                    },
                                    {
                                        label: "㎡",
                                        value: "㎡",
                                    },
                                    {
                                        label: "亩",
                                        value: "亩"
                                    },
                                    {
                                        label: "把",
                                        value: "把"
                                    },
                                    {
                                        label: "部",
                                        value: "部"
                                    },
                                    {
                                        label: "册",
                                        value: "册"
                                    },
                                    {
                                        label: "场",
                                        value: "场"
                                    },
                                    {
                                        label: "处",
                                        value: "处"
                                    },
                                    {
                                        label: "次",
                                        value: "次"
                                    },
                                    {
                                        label: "份",
                                        value: "份"
                                    },
                                    {
                                        label: "个",
                                        value: "个"
                                    },
                                    {
                                        label: "块",
                                        value: "块"
                                    },
                                    {
                                        label: "辆",
                                        value: "辆"
                                    },
                                    {
                                        label: "年",
                                        value: "年"
                                    },
                                    {
                                        label: "台",
                                        value: "台"
                                    },
                                    {
                                        label: "套",
                                        value: "套"
                                    },
                                    {
                                        label: "条",
                                        value: "条"
                                    },
                                    {
                                        label: "项",
                                        value: "项"
                                    },
                                    {
                                        label: "张",
                                        value: "张"
                                    },
                                    {
                                        label: "只",
                                        value: "只"
                                    },
                                    {
                                        label: "组",
                                        value: "组"
                                    },
                                    {
                                        label: "座",
                                        value: "座"
                                    },
                                ]}
                                onChange={onUnitSelect}
                            />
                        </Form.Item>
                        <Form.Item
                            name="count"
                            label="数量"
                            rules={[{ required: true, message: `请输入数量` }]}
                        >
                            <AutoSelectInput
                                style={{ width: '426px' }}
                                placeholder={'请输入数量'}
                                value={formData.count}
                                onChange={onCountTextChange}
                                type={'number'}
                                maxLength={24}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item
                            name="unitPrice"
                            label="单价"
                            rules={[{ required: true, message: `请输入单价` }]}
                        >
                            <AutoSelectInput
                                style={{ width: '426px' }}
                                placeholder={'请输入单价'}
                                value={formData.unitPrice}
                                onChange={onUnitPriceTextChange}
                                type={'number'}
                                maxLength={24}
                                addonAfter={'万元'}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item
                            name="investmentCompositionType"
                            label="默认投资构成"
                        >
                            <Select
                                style={{ width: '428px' }}
                                placeholder={'请选择默认投资构成'}
                                value={formData.investmentCompositionType}
                                onChange={onInvestmentCompositionTypeChange}
                                options={[
                                    {
                                        label: '建安',
                                        value: 'buildingInstallation',
                                    },
                                    {
                                        label: '设备',
                                        value: 'device',
                                    },
                                    {
                                        label: '其他',
                                        value: 'other',
                                    }
                                ]}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item label="投资比例" style={{ width: '482px', paddingLeft: 30 }}>
                            <Space align={'baseline'}>
                                <Form.Item
                                    name="nearFutureMoneyRate"
                                    style={{ display: 'inline-block', width: '180px', marginRight: 12 }}
                                >
                                    <AutoSelectInput
                                        placeholder="近期投资比例"
                                        addonAfter="%"
                                        type={'number'}
                                        maxLength={5}
                                        value={formData.nearFutureMoneyRate}
                                        onChange={onNearFutureMoneyRateTextChange}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="mediumAndLongTermMoneyRate"
                                    style={{ display: 'inline-block', width: '230px' }}
                                >
                                    <AutoSelectInput
                                        placeholder="中长期投资比例"
                                        addonAfter="%"
                                        type={'number'}
                                        maxLength={5}
                                        value={formData.mediumAndLongTermMoneyRate}
                                        onChange={onMediumAndLongTermMoneyRateTextChange}
                                        allowClear
                                    />
                                </Form.Item>
                            </Space>
                        </Form.Item>
                        <Form.Item
                            name="mainProjectRecommand"
                            label="重点工程"
                        >
                            <Radio.Group
                                value={formData.mainProjectRecommand}
                                onChange={onMainProjectRecommandChange}
                            >
                                <Radio value={1}>是</Radio>
                                <Radio value={0}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </>
                </Form>
            </div>
        </Modal>
    )
}


export default forwardRef(AddTopicModalNew)