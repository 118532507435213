import { BaseSlateItemType, DocItem, ShareDocEditorData } from '../types';

import { isHeading } from './is-heading';

/**
 * 解析数据至slate格式数据
 * @param docItems
 * @param data
 * @returns
 */
export const parseToSlateData = (docItems: DocItem[], data: { [name: string]: string | number }) => {
  const state: ShareDocEditorData = {
    ...data,
  };
  console.log("parseToSlateData--->", docItems)
  // const dynamicTextReg = /(\$?\[[SXNU]])|(\$?\[\*])|(\$?\[NAME])|(\$?\[P])|(\$?\[type])|(\$\$\[\d+])/;
  //这里我需要匹配$[U|X|N|U...]这种格式
  const dynamicTextReg = /(\$?\[[SXNU].*?])|(\$?\[\*])|(\$?\[NAME])|(\$?\[P])|(\$?\[type])|(\$\$\[\d+])/;
  const isGlobalScopeDynamicText = (text: string) => /^\$?\[(\*|(NAME)|P|(type))]$/.test(text);
  // const isItemScopeDynamicText = (text: string) => /^\$?\[[SXNU]]$/.test(text);
  const isItemScopeDynamicText = (text: string) => /^\$?\[[SXNU].*?]$/.test(text);
  const isStyleText = (text: string) =>  /^\$\$\[\d+]$/.test(text);
  const createBaseSlateItemChildren = (
    id: string | number,
    mark: string,
    initialVal: string | number,
    type: BaseSlateItemType,
    scene?: string,
    children = [{ text: '' }],
  ) => [
      { text: '' },
      {
        id,
        mark,
        initialVal,
        children,
        type,
        scene,
      },
      { text: '' },
    ];
  const slateData = docItems
    .map((item) => {
      const text = item.text + '';
      if (text.trim() === '') return item;
      return [
        ...(text.match(/^\n+/g)?.[0]?.split('') || []),
        ...(text.replace(/^\n+/, '').match(/(\n?[^\n]+)|(\n)/g) || []),
      ].map((t) => ({ ...item, text: `${t || ''}`.replace(/^\n/, '') }));
    })
    .flat()
    .map(({ type, text: textStr, id, data: d, textStyleMap, style, externalData }) => {
      const text = textStr.replace(/\$(\[(\*|(NAME)|P|(type))])/g, '$1');
      const children = text
        .split(dynamicTextReg)
        .filter(Boolean)
        .map((text) => {
          if (isItemScopeDynamicText(text)) {
            const initialVal = d?.[text] || '';
            state[`${id}/${text}`] = initialVal;
            return createBaseSlateItemChildren(id, text, initialVal, BaseSlateItemType.Input);
          }
          if (isGlobalScopeDynamicText(text)) {
            const initialVal = data[text];
            return createBaseSlateItemChildren(id, text, initialVal, BaseSlateItemType.Tag);
          }
          if (isStyleText(text)) {
            // if(id === "-ADDITIONAL-INSET_SUB_TABLE_2_1"){
            //   console.log("这是带样式的文本------>", textStyleMap)
            // }
            const item = textStyleMap?.[text];
            if (item) {
              const leaf = { ...item };
              const text = leaf.text;
              if (isItemScopeDynamicText(text)) {
                const initialVal = d?.[text] || '';
                leaf.text = '';
                state[`${id}/${text}`] = initialVal;
                return createBaseSlateItemChildren(id, text, initialVal, BaseSlateItemType.Input, 'default', [leaf]);
              }
              if (isGlobalScopeDynamicText(text)) {
                const initialVal = data[text];
                leaf.text = '';
                return createBaseSlateItemChildren(id, text, initialVal, BaseSlateItemType.Tag, 'default', [leaf]);
              }
              return leaf;
            }
            return { text };
          }
          return { text };
        })
        .flat();
      const index = d?.['$[INDEX]'];
      if (isHeading(type) && index !== null && index !== undefined) {
        state[`${id}/$[INDEX]`] = index;
        const item = textStyleMap?.index;
        children.unshift(
          ...createBaseSlateItemChildren(id, '', index, BaseSlateItemType.Tag, 'index', item ? [item] : undefined),
        );
      }
      return {
        id,
        type,
        children: children.length ? children : [{ text: '' }],
        align: style?.align,
        externalData
      };
    });
  // console.log("----->1111", [slateData, state] as const)
  return [slateData, state] as const;
};
