import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import { Editor } from 'slate';

import { useMatchMark, useToggle } from '../hooks';
import { ItemProps } from '../types';
import OptionBarBtn from '../../../../../components/option-bar-btn/OptionBarBtn';

/**
 * 文字下标工具项
 */
export const SubItem = React.memo(function SubItem({ editor }: ItemProps) {
  const format = 'sub';
  const [value, setValue] = useState(false);
  const toggle: React.MouseEventHandler<HTMLElement> = useToggle(editor, format, value);
  const mark = (useMatchMark(editor, format) as boolean) ?? false;
  useEffect(() => {
    const curEditor = editor?.current?.editor;
    setValue(mark);
    if (mark && curEditor) Editor.removeMark(curEditor, 'sup');
  }, [editor, mark]);
  // return (
  //   <Button type={value ? 'primary' : 'default'} onMouseDown={toggle}>
  //     下标
  //   </Button>
  // );
  return (
    <OptionBarBtn
      icon={<i className='iconfont ghzs-color-xiabiao' style={{ fontSize: 18 }} />}
      size={'small'}
      tooltipTitle={"下标"}
      active={value}
      onMouseDown={toggle}
    />
  )
});
