import React from 'react';
import {
    Input,
    InputProps
} from 'antd'


const AutoSelectSearchInput = (props: InputProps) => {
    const handleFocus = (event) => {
        event.target.select();
    };

    return (
        <Input.Search
            {...props}
            onFocus={handleFocus}
        />
    )

}

export default AutoSelectSearchInput;