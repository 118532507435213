import { makeClassNameList } from "../../../utils";

interface Props {
    id?: string
    title?: string
    isActive?: boolean
    onClick?: any
}

const ArrowNavItem = (props: Props) => {

    const {
        id,
        title,
        isActive = false,
        onClick
    } = props;

    return (
        <div
            id={id}
            className={makeClassNameList([
                'flex-row arrow-nav-item',
                isActive ? 'active' : null
            ])}
            onClick={onClick}
        >
            <div className="flex-row arrow-nav-item-content">
                <div className="arrow-nav-item-title">{title}</div>
            </div> 
            <div className="arrow-nav-item-angle-left-container-top">
                <div className="arrow-nav-item-angle-left-content-top">
                    <div className="arrow-nav-item-angle-left-top"></div>
                </div>
            </div>
            <div className="arrow-nav-item-angle-left-container-bottom">
                <div className="arrow-nav-item-angle-left-content-bottom">
                    <div className="arrow-nav-item-angle-left-bottom"></div>
                </div>
            </div>
            <div className="flex-row arrow-nav-item-angle-right"></div>
            {/* <p style={{ color: isActive ? '#fff' : '#3fb075' }}> {title}</p> */}
        </div>
    )
}

export default ArrowNavItem;