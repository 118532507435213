import { forwardRef, useRef, useImperativeHandle } from "react"
import CustomPopover from "./CustomPopover";
import { Button } from "antd";

interface Props {
    clientX: number
    clientY: number
}


const ExcelThemePopover = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openPopover
    }))

    const {
        clientX,
        clientY
    } = props;

    const customSelectRef = useRef(null);

    const openPopover = () => {
        customSelectRef.current.show();
    };

    const closePopover = ()=> {
        customSelectRef.current._handleClose();
    }

    return (
        <CustomPopover
            ref={customSelectRef}
            clientX={clientX}
            clientY={clientY}
            contentRender={(props) => (
                <div className='flex-col excel-theme-popover'>
                    <div className="flex-row excel-theme-popover-content">
                        <div className="flex-col excel-theme-item">
                            <div className="excel-theme-item-preview">

                            </div>
                            <div className="flex-row excel-theme-item-name">
                                表格样式1
                            </div>
                        </div>
                        <div className="flex-col excel-theme-item">
                            <div className="excel-theme-item-preview">

                            </div>
                            <div className="flex-row excel-theme-item-name">
                                表格样式2
                            </div>
                        </div>
                        <div className="flex-col excel-theme-item">
                            <div className="excel-theme-item-preview">

                            </div>
                            <div className="flex-row excel-theme-item-name">
                                表格样式3
                            </div>
                        </div>
                        <div className="flex-col excel-theme-item">
                            <div className="excel-theme-item-preview">

                            </div>
                            <div className="flex-row excel-theme-item-name">
                                表格样式4
                            </div>
                        </div>
                        <div className="flex-col excel-theme-item">
                            <div className="excel-theme-item-preview">

                            </div>
                            <div className="flex-row excel-theme-item-name">
                                表格样式5
                            </div>
                        </div>
                    </div>
                    <div className="flex-row excel-theme-popover-footer">
                        <Button
                            size={'small'}
                            onClick={closePopover}
                        >
                            取消
                        </Button>
                        <Button
                            size={'small'}
                            type={"primary"}
                            style={{ marginLeft: 12 }}
                        >
                            确认
                        </Button>
                    </div>
                </div>
            )}
        />
    )
}


export default forwardRef(ExcelThemePopover);