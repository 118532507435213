import React, { forwardRef, useState, useImperativeHandle, ChangeEvent, useRef } from 'react';
import { Button, Modal, Input, Table, Space, Form, Pagination, Select, Radio, Image, Upload, DatePicker, Divider, DatePickerProps, TreeSelect, Switch } from 'antd';
import { FontSizeOutlined, PayCircleOutlined, BankOutlined, DesktopOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { deepCopy, getChangeEventValue } from '../../../../utils';


interface Props {
    onFinish?: Function
}


const ReNameTopicModal = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openModal: openModal
    }))

    const {
        onFinish
    } = props;

    const inputRef = useRef(null);

    const [submitLoading, setSubmitLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm<{ topicName?: string }>();
    const [formData, setFormData] = useState<{ topicName?: string }>({});
    const [defaultFormData] = useState<{ topicName?: string }>({
        topicName: ""
    });

    const handleConfirm = async () => {
        onFinish && onFinish(formData.topicName);
        setIsModalOpen(false)
    };

    const openModal = (topicName?: string) => {
        const { keys, values } = Object;
        let _formData = deepCopy(formData);
        _formData.topicName = topicName;
        const keyList = keys(_formData);
        const valueList = values(_formData)
        keyList.forEach((key, index) => {
            form.setFieldValue(key, valueList[index])
        })
        setFormData(_formData);
        setIsModalOpen(true);
        setTimeout(() => {
            inputRef.current.select();
        }, 200);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSetFormData = (target: string, value: any) => {
        let _formData = formData;
        //@ts-ignore
        _formData[target] = value;
        setFormData(deepCopy(_formData));
    };

    const onNameTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData('topicName', getChangeEventValue(e));
    }

    return (
        <Modal
            title={'重命名'}
            open={isModalOpen}
            onOk={handleConfirm}
            onCancel={handleCancel}
            okText={'确 认'}
            cancelText={'取 消'}
            width={612}
            maskClosable={false}
            confirmLoading={submitLoading}
            centered
        >
            <div className='custom-modal-container'>
                <Form
                    size={'large'}
                    form={form}
                    layout={'horizontal'}
                    autoComplete="off"
                    labelAlign={'right'}
                    labelCol={{ span: 3 }}
                    style={{ width: '100%' }}
                >
                    <Form.Item
                        label="名称"
                        name={'topicName'}
                        rules={[{ required: true, message: '请输入节点名称' }]}
                    >
                        <Input
                            ref={inputRef}
                            placeholder='请输入节点名称'
                            style={{ width: '426px' }}
                            maxLength={20}
                            value={formData.topicName}
                            onChange={onNameTextChange}
                            allowClear
                        />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}


export default forwardRef(ReNameTopicModal)