import { defaultProjectSubTableList, isEmpty } from "../../../utils"
import { DocConfig, DocInstance, PlanningBasisGroupType, TopicType } from "../../../utils/types"
import { DocItem, DocItemType } from "../../slate-editor"
import { getTitleDocItemType } from "./editor-doc-item-generate"
import { generateScheduleTextDocItemList } from "./schedule-doc-item-list"

export const isPresetresetDocItemTextTopicName = (topic: TopicType) => {
    const nameList = [

        {
            topicName: "规划依据",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "地理位置及范围",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "地理位置",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "范围",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "历史沿革",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "法律地位",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "主要保护对象",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "行政区域",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "土地权属",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "地质地貌",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "气候",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "水文",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "土壤",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "植被",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "大型真菌资源",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "景观资源",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "自然灾害",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "自然灾害",
            topicLevelList: [0, 1, 2, 3],
        },
        //附表标题
        {
            topicName: "人口与分布状况",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "社区经济",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "土地利用状况",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "功能区划",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "管理队伍",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "公共基础设施",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "野生植物资源",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "野生动物资源",
            topicLevelList: [0, 1, 2, 3],
        },
        {
            topicName: "规划期限",
            topicLevelList: [0, 1, 2, 3],
        }
    ]
    return nameList.find(ele => ele.topicName == topic.topicName)
}

const getProtectlandTypeText = (docInstance: DocInstance) => {
    try{
        const {
            protectland: {
                type,
                subType,
                subTypeStr
            }
        } = docInstance;
        return type;
    }catch(e){
        return '自然保护区'
    }
};

const generatePlanningYearsTopic = (topic: TopicType, comDocInstance: DocInstance): string => {
    const {
        protectland: {
            type,
            subType,
        },
        protectlandBaseInfo: {
            protectlandArea,
            protectlandAreaUnit = '公顷',
            protectlandLevel,
            protectlandName,
            protectlandProvince,
            protectlandTypeId,
            startYear,
            endYear
        }
    } = comDocInstance;
    if (startYear && endYear) {
        const startYearStr = new Date(startYear).getFullYear();
        const endYearStr = new Date(endYear).getFullYear();
        let newTopicName = `规划期限（${startYearStr}年—${endYearStr}年）`;
        const yearLen = Number(endYearStr) - Number(startYearStr) + 1;
        const centerYearStr = (((Number(endYearStr) - Number(startYearStr)) / 2) + Number(startYearStr)).toFixed(0);
        let yearRange1 = `${startYearStr}年—${centerYearStr}年`;
        let yearRange2 = `${Number(centerYearStr) + 1}年—${endYearStr}年`;
        let topicBackgroundPargraphText = `根据${getProtectlandTypeText(comDocInstance)}建设发展的现实需要，按照规划原则，确定${protectlandName}总体规划的期限为${yearLen}年，即${startYearStr}年—${endYearStr}年。分两期：其中，近期：${yearRange1}；远期：${yearRange2}。`
        return topicBackgroundPargraphText;
    }
    return '';
}

export const presetDocItemText = (
    topic: TopicType,
    docInstance: DocInstance,
    docConfig: DocConfig
): DocItem[] => {
    let docItemList = [];
    //规划依据
    const {
        planBasisList
    } = docConfig;
    let {
        protectlandBaseInfo: {
            protectlandExtraInfo
        }
    } = docInstance;
    const {
        projectSubTableList = defaultProjectSubTableList,
        supplementaryInformation,
        protectedNaturalEnvironment
    } = protectlandExtraInfo;
    if (isPresetresetDocItemTextTopicName(topic) && !topic.topicSlateDocItemList) {
        const planningYearsParagraphText = generatePlanningYearsTopic(topic, docInstance);
        if(topic.topicName == '规划期限'){
            docItemList.push({
                id: `${topic.id}-preset-00-1`,
                type: DocItemType.P,
                text: planningYearsParagraphText,
            })
        }
        else if (topic.topicName == '规划依据' && planBasisList && planBasisList.length) {
            let planBasisGroupList: PlanningBasisGroupType[] = [];
            planBasisList.forEach(ele => {
                let findIndex = -1;
                planBasisGroupList.forEach((planBasisGroup, index) => {
                    if (planBasisGroup.basisType == ele.basisType) {
                        findIndex = index;
                    }
                })
                if (findIndex > -1) {
                    planBasisGroupList[findIndex].planningBasisList.push(ele);
                } else {
                    planBasisGroupList.push({
                        basisType: ele.basisType,
                        planningBasisList: [ele]
                    })
                }
            })
            // console.log("planBasisGroupList--->", planBasisGroupList)
            planBasisGroupList.forEach((planBasisGroup, planBasisGroupindex) => {
                docItemList.push({
                    id: `${topic.id}-preset-0-${planBasisGroupindex}`,
                    type: getTitleDocItemType(Number(topic.treeLevel) + 1),
                    text: `${topic.serialNumber}.${planBasisGroupindex + 1} ${planBasisGroup.basisType}`
                })
                planBasisGroup.planningBasisList.forEach((planBasis, planBasisIndex) => {
                    docItemList.push({
                        id: `${topic.id}-preset-${planBasisGroupindex + 1}-${planBasisIndex}`,
                        type: DocItemType.P,
                        text: `（${planBasisIndex + 1}）${planBasis.basisName}`
                    })
                })
            })
            console.log("规划依据docItemList---->", docItemList)
        } else if (topic.topicName === '地理位置及范围') {
            docItemList = [];
            if (!isEmpty(supplementaryInformation.rangeText)) {
                docItemList.push({
                    id: `${topic.id}-preset-0-2`,
                    type: DocItemType.P,
                    text: supplementaryInformation.rangeText,
                })
            }
            if (!isEmpty(supplementaryInformation.geographicalLocationText)) {
                docItemList.push({
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.geographicalLocationText,
                })
            }
        } else if (topic.topicName == '地理位置' && !isEmpty(supplementaryInformation.geographicalLocationText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.geographicalLocationText,
                }
            ]
        } else if (topic.topicName == '范围' && !isEmpty(supplementaryInformation.rangeText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.rangeText,
                }
            ]
        } else if (topic.topicName == '历史沿革' && !isEmpty(supplementaryInformation.historicalEvolutionText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.historicalEvolutionText,
                }
            ]
        } else if (topic.topicName == '法律地位' && !isEmpty(supplementaryInformation.legalStatusText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.legalStatusText,
                }
            ]
        } else if (topic.topicName == '主要保护对象' && !isEmpty(supplementaryInformation.mainProtectionObjecText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.mainProtectionObjecText,
                }
            ]
        } else if (topic.topicName == '行政区域' && !isEmpty(supplementaryInformation.administrativeDivisionText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.administrativeDivisionText,
                }
            ]
        } else if (topic.topicName == '土地权属' && !isEmpty(supplementaryInformation.landOwnershipText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.landOwnershipText,
                }
            ]
        } else if (topic.topicName == '地质地貌' && !isEmpty(supplementaryInformation.geologyAndGeomorphologyText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.geologyAndGeomorphologyText,
                }
            ]
        } else if (topic.topicName == '气候' && !isEmpty(supplementaryInformation.climateText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.climateText,
                }
            ]
        } else if (topic.topicName == '水文' && !isEmpty(supplementaryInformation.hydrologyText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.hydrologyText,
                }
            ]
        } else if (topic.topicName == '土壤' && !isEmpty(supplementaryInformation.soilText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.soilText,
                }
            ]
        } else if (topic.topicName == '植被' && !isEmpty(supplementaryInformation.vegetationText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.vegetationText,
                }
            ]
        } else if (topic.topicName == '大型真菌资源' && !isEmpty(supplementaryInformation.fungusText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.fungusText,
                }
            ]
        } else if (topic.topicName == '景观资源' && !isEmpty(supplementaryInformation.landscapeResourcesText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.landscapeResourcesText,
                }
            ]
        } else if (topic.topicName == '自然灾害' && !isEmpty(supplementaryInformation.naturalDisasterText)) {
            docItemList = [
                {
                    id: `${topic.id}-preset-0-1`,
                    type: DocItemType.P,
                    text: supplementaryInformation.naturalDisasterText,
                }
            ]
        } else if (topic.topicName == '人口与分布状况') {
            const { docItemGroupList } = generateScheduleTextDocItemList("1", docInstance, docConfig);
            const findDocItemGroup = docItemGroupList.find(ele => ele.topicName == topic.topicName);
            if (findDocItemGroup && findDocItemGroup.docItemList) {
                docItemList = findDocItemGroup.docItemList.map((docItem, index) => {
                    docItem.id = `${topic.id}-SUB-TABLE-0-${index}`;
                    return docItem;
                })
            }
        } else if (topic.topicName == '社区经济') {
            const { docItemGroupList } = generateScheduleTextDocItemList("1", docInstance, docConfig);
            const findDocItemGroup = docItemGroupList.find(ele => ele.topicName == topic.topicName);
            if (findDocItemGroup && findDocItemGroup.docItemList) {
                docItemList = findDocItemGroup.docItemList.map((docItem, index) => {
                    docItem.id = `${topic.id}-SUB-TABLE-0-${index}`;
                    return docItem;
                })
            }
        } else if (topic.topicName == '土地利用状况') {
            const { docItemGroupList } = generateScheduleTextDocItemList("2", docInstance, docConfig);
            const findDocItemGroup = docItemGroupList.find(ele => ele.topicName == topic.topicName);
            if (findDocItemGroup && findDocItemGroup.docItemList) {
                docItemList = findDocItemGroup.docItemList.map((docItem, index) => {
                    docItem.id = `${topic.id}-SUB-TABLE-0-${index}`;
                    return docItem;
                })
            }
        } else if (topic.topicName == '功能区划') {
            const { docItemGroupList } = generateScheduleTextDocItemList("3", docInstance, docConfig);
            const findDocItemGroup = docItemGroupList.find(ele => ele.topicName == topic.topicName);
            if (findDocItemGroup && findDocItemGroup.docItemList) {
                docItemList = findDocItemGroup.docItemList.map((docItem, index) => {
                    docItem.id = `${topic.id}-SUB-TABLE-0-${index}`;
                    return docItem;
                })
            }
        } else if (topic.topicName == '管理队伍') {
            const { docItemGroupList } = generateScheduleTextDocItemList("4", docInstance, docConfig);
            const findDocItemGroup = docItemGroupList.find(ele => ele.topicName == topic.topicName);
            if (findDocItemGroup && findDocItemGroup.docItemList) {
                docItemList = findDocItemGroup.docItemList.map((docItem, index) => {
                    docItem.id = `${topic.id}-SUB-TABLE-0-${index}`;
                    return docItem;
                })
            }
        } else if (topic.topicName == '公共基础设施') {
            const { docItemGroupList } = generateScheduleTextDocItemList("5", docInstance, docConfig);
            const findDocItemGroup = docItemGroupList.find(ele => ele.topicName == topic.topicName);
            if (findDocItemGroup && findDocItemGroup.docItemList) {
                docItemList = findDocItemGroup.docItemList.map((docItem, index) => {
                    docItem.id = `${topic.id}-SUB-TABLE-0-${index}`;
                    return docItem;
                })
            }
        } else if (topic.topicName == '野生植物资源') {
            const { docItemGroupList } = generateScheduleTextDocItemList("6", docInstance, docConfig);
            const findDocItemGroup = docItemGroupList.find(ele => ele.topicName == topic.topicName);
            if (findDocItemGroup && findDocItemGroup.docItemList) {
                docItemList = findDocItemGroup.docItemList.map((docItem, index) => {
                    docItem.id = `${topic.id}-SUB-TABLE-6-0-${index}`;
                    return docItem;
                })
            }
        } else if (topic.topicName == '野生动物资源') {
            const { docItemGroupList } = generateScheduleTextDocItemList("6", docInstance, docConfig);
            const findDocItemGroup = docItemGroupList.find(ele => ele.topicName == topic.topicName);
            if (findDocItemGroup && findDocItemGroup.docItemList) {
                docItemList = findDocItemGroup.docItemList.map((docItem, index) => {
                    docItem.id = `${topic.id}-SUB-TABLE-6-1-${index}`;
                    return docItem;
                })
            }
        }
    }
    return docItemList;
}

