import React from 'react';

import { Slate } from 'slate-react';

export const SlateWrap = React.memo(function SlateWrap(props: Parameters<typeof Slate>[0]) {
  return <Slate {...props}>{props.children}</Slate>;
});


// export const SlateWrap = (props: Parameters<typeof Slate>[0]) => {
//   return <Slate {...props}>{props.children}</Slate>;
// };