import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Tabs } from 'antd';
import CustomPopover from './CustomPopover';
import './custom-popover.scss';
import { Switch } from 'antd';
import { copyTextToClipboard, toastShort } from '../../utils';
import { BookOutlined, NodeIndexOutlined, ArrowUpOutlined } from '@ant-design/icons';

const TablePopover = () => {

    return (
        <div className='table-popover-box'>
            <div className='table-set-up-title'>插入表格</div>
            <div className='table-build-box'>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
                <div className='table-grid-items'></div>
            </div>
            <div className='table-build-custom'>
                <div className='table-build-custom-icon'>
                    <i className='iconfont ghzs-color-biaoge' />
                </div>
                <div>插入自定义表格</div>
            </div>
            <div className='table-build-custom'>
                <div className='table-build-custom-icon'>
                    <i className='iconfont ghzs-color-biaoge' />
                    <div className='table-build-custom-upload'>
                        <ArrowUpOutlined />
                    </div>
                </div>
                <div>上传表格文件</div>
            </div>
        </div>
    )
}

export default forwardRef(TablePopover);