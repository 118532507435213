import { defaultProjectSubTableList } from ".";
import { saasConfig } from "../config/theme-config";
import { ProtectlandBaseInfoType } from "./types";


export const defaultProjectFormData: ProtectlandBaseInfoType = {
    id: "",
    type: "6424f622019ebb24cd3d9ea7",
    subType: "6424f622019ebb24cd3d9ea7",
    protectlandLevel: "1",
    protectlandProvince: saasConfig.systemType == 'QingYuanDefault' ? "北京" : "云南",
    protectlandName: saasConfig.systemType == 'QingYuanDefault' ? "青远生态保护区" : null,
    protectlandArea: "10000",
    protectlandTypeId: "6424f622019ebb24cd3d9ea7",
    protectlandAreaUnit: "",
    startYear: (new Date().getFullYear() - 1).toString(),
    endYear: (new Date().getFullYear() + 8).toString(),
    endYearStep: "10",
    protectlandProblemList: "",
    yearRange: [],
    topicTemplateId: "",
    protectlandExtraInfo: {
        //@ts-ignore
        projectSubTableList: [...defaultProjectSubTableList],
        supplementaryInformation: {
            geographicalLocationText: "",
            rangeText: "",
            historicalEvolutionText: "",
            legalStatusText: "",
            mainProtectionObjecText: "",
            administrativeDivisionText: "",
            landOwnershipText: "",
            geologyAndGeomorphologyText: "",
            climateText: "",
            hydrologyText: "",
            soilText: "",
            vegetationText: "",
            fungusText: "",
            landscapeResourcesText: "",
            naturalDisasterText: "",
        },
        protectedNaturalEnvironment: {
            geographicalLocationText: "",
            climateText: ""
        }
    },
}