export enum FontFamilyEnum {
  simSun = 'SimSun',
  yahei = 'Microsoft YaHei',
  simHei = 'SimHei',
  times = 'Times New Roman',
  fs_GB2312 = '仿宋_BG2312',
  fzfs_GBK = '方正仿宋_GBK',
  fzxbs_GBK = '方正小标宋_GBK',
  fzkt_GBK = '方正楷体_GBK',
  fzktjt = '方正楷体简体',
  fzht_GBK = '方正黑体_GBK',
  dxt = '等线体',
}

export const FontFamilyOptions = [
  [FontFamilyEnum.simSun, '宋体'],
  [FontFamilyEnum.yahei, '微软雅黑'],
  [FontFamilyEnum.simHei, '黑体'],
  [FontFamilyEnum.times, 'Times New Roman'],
  [FontFamilyEnum.fs_GB2312, '仿宋_GB2312'],
  [FontFamilyEnum.fzfs_GBK, '方正仿宋_GBK'],
  [FontFamilyEnum.fzxbs_GBK, '方正小标宋_GBK'],
  [FontFamilyEnum.fzkt_GBK, '方正楷体_GBK'],
  [FontFamilyEnum.fzktjt, '方正楷体简体'],
  [FontFamilyEnum.fzht_GBK, '方正黑体_GBK'],
  [FontFamilyEnum.dxt, '等线体']
].map(([value, label]) => ({ value, label }));
