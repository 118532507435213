import React from 'react';

import { useFocused, useSelected } from 'slate-react';

import { useEmitSlateItemClick, useShareDocEditorData } from '../../hooks';
import { SlateElementProps } from '../../types';
import { createClassNameMark } from '../../utils';
import { InlineChromiumBugfix } from './../inline-chromium-bugfix';

import Style from './main.module.scss';

const classNames = createClassNameMark(Style);
export const Tag = React.memo(function Tag({ attributes, element, children }: SlateElementProps) {
  const prop = element.mark || '';
  const textData = (element.children?.[0] || {}) as unknown as { [format: string]: boolean };
  const [data] = useShareDocEditorData([prop]);
  const selected = useSelected();
  const focused = useFocused();
  const value = `${data[prop] ?? (element.initialVal || '')}`;
  const onClick = useEmitSlateItemClick(element);
  return (
    <span
      {...attributes}
      //@ts-ignore
      className={classNames({
        [`doc-tag--${element.scene || 'default'}`]: true,
        'doc-tag': true,
        'doc-tag--light': selected && focused,
        'doc-tag--del': textData.delete,
        'doc-tag--sup': textData.sup,
        'doc-tag--sub': textData.sub,
        'doc-tag--underline': textData.underline,
        'doc-tag--italic': textData.italic,
        'doc-tag--bold': textData.bold,
      })}
      style={{
        color: textData.color as unknown as string,
        backgroundColor: textData.bgColor as unknown as string,
        fontSize: textData.fontSize as unknown as string,
        fontFamily: textData.fontFamily as unknown as string,
      }}
      contentEditable={false}
      onClick={onClick}
    >
      {value}
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </span>
  );
});
