import { configureStore } from "@reduxjs/toolkit"
import systemConfigReducer from "./system.slice";
import optionBarReducer from "./optionBarConfig.slice";
import navigateReducer from "./navigate.slice";

export default configureStore({
    reducer: {
        systemConfig: systemConfigReducer,
        optionBar: optionBarReducer,
        navigate: navigateReducer
        // ... 其他的 reducer
    }
});
