import './DeviceNodeManagePopUp.scss';
import { forwardRef, useRef, useImperativeHandle, useState, ChangeEvent } from "react"
import { Button, Checkbox, Input } from "antd";
import CustomPopover from "../../../../components/popover/CustomPopover";
import { TopicType } from '../../../../utils/types';
import { getChangeEventValue, getCheckBoxChangeEventValue, isEmpty } from '../../../../utils';

interface Props {
}


const {
    Search
} = Input;

const DeviceNodeManagePopUp = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openPopover
    }))

    const {

    } = props;

    const [clientX, setClientX] = useState(0);
    const [clientY, setClientY] = useState(0);
    const [tabKey, setTabKey] = useState<'selected' | 'all'>('selected');

    const [allDeviceNodeList, setAllDeviceNodeList] = useState<TopicType[]>([]);

    const [currentTextNode, setcurrentTextNode] = useState<TopicType>(null);

    const [currentSearchValue, setCurrentSearchValue] = useState<string>('');

    const customSelectRef = useRef(null);

    const onTopicSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const searchValue = getChangeEventValue(e);
        setCurrentSearchValue(searchValue);
    }

    const openPopover = (position, textTopic: TopicType, deviceNodeList: TopicType[]) => {
        setClientX(position.clientX);
        setClientY(position.clientY);
        customSelectRef.current.show();
        setcurrentTextNode(textTopic);
        setAllDeviceNodeList(deviceNodeList)
    };

    const closePopover = () => {
        customSelectRef.current._handleClose();
    }

    const switchTabKey = (newTabKey) => {
        setTabKey(newTabKey);
    }

    const adaptNodeIcon = (node: TopicType) => {
        let nodeIcon = <i className='iconfont-1 ghzs-gray-shebei' />;
        switch (node.deviceType) {
            case 'S':
                nodeIcon = <i className='iconfont-1 ghzs-gray-sheshi' />
                break;
            case 'X':
                nodeIcon = <i className='iconfont-1 ghzs-gray-shebei' />

                break;
        }
        return (
            <div
                className='flex-row device-tree-node-icon'
            >
                {nodeIcon}
            </div>
        );
    }

    const onCheckedChange = (e: any, node: TopicType) => {
        // console.log("onCheckedChange--->", e, node)
        const checked = getCheckBoxChangeEventValue(e);
        let tempNodeList = allDeviceNodeList;
        tempNodeList.forEach(deviceNode => {
            if (deviceNode.id == node.id) {
                deviceNode.checked = checked;
                deviceNode.pid = currentTextNode.id;
            }
        })
        setAllDeviceNodeList([...tempNodeList])
    }

    const formatSearchText = (originStr: string) => {
        try {
            if (isEmpty(currentSearchValue)) {
                return originStr;
            }
            let reg = new RegExp(currentSearchValue);
            let str = originStr.split(reg);
            let txt = [];
            if (str.length === 0 || str.length === 1) {
                return originStr
            }
            if (str.length > 0) {
                txt.push(<span key={'0-0'}>{str[0]}</span>);
            }
            txt.push(<span key={'0-1'} style={{ color: '#ff0000' }}>{currentSearchValue}</span>);
            if (str.length > 1) {
                txt.push(<span key={'0-2'}>{str[1]}</span>);
            }
            if (str.length >= 3) {
                for (let i = 2; i < str.length; i++) {
                    txt.push(<span key={'0-3'} style={{ color: '#ff0000' }}>{currentSearchValue}</span>);
                    txt.push(<span key={'0-4'}>{str[i]}</span>);
                }
            }
            return txt;
        } catch (e) {
            return originStr;
        }
    }

    const renderDeviceNodeList = () => {
        let tempNodeList = tabKey == 'all' ? allDeviceNodeList : allDeviceNodeList.filter(node => currentTextNode && node.pid == currentTextNode.id && node.checked);

        return tempNodeList.map(node => {
            return (
                <div
                    className='flex-row device-tree-node'
                    key={node.id}
                    title={node.title}
                >
                    <div className='flex-row device-tree-node-content'>
                        <Checkbox
                            checked={node.checked && node.pid == currentTextNode.id}
                            onChange={e => onCheckedChange(e, node)}
                        />
                        {adaptNodeIcon(node)}
                        <div className='single-line-text' style={{ width: 420, fontWeight: node.topicType == 'text' ? 'normal' : 'bold' }}>
                            {formatSearchText(node.title)}
                        </div>
                    </div>
                </div>
            )
        })
    };

    return (
        <CustomPopover
            ref={customSelectRef}
            clientX={clientX}
            clientY={clientY}
            animatedDirection={'leftRight'}
            contentRender={(props) => (
                <div className="flex-col topic-manage-popup-container">
                    {/* <div className="flex-row topic-manage-title">
                        项目关联设置：{currentTextNode && currentTextNode.title}
                    </div> */}
                    <div className="flex-row topic-manage-tab-bar">
                        <div onClick={() => switchTabKey('selected')} className="flex-row topic-manage-tab-item">
                            已选项目
                        </div>
                        <div onClick={() => switchTabKey('all')} className="flex-row topic-manage-tab-item">
                            所有项目
                        </div>
                        <div className='topic-manage-tab-bar-bottom-line' style={{ left: tabKey == 'selected' ? 5 : 85 }}></div>
                    </div>
                    <div className="flex-col topic-manage-popup-content">
                        <Search
                            placeholder={'搜索项目关键字'}
                            size={'middle'}
                            style={{ marginBottom: 10, width: 270 }}
                            allowClear
                            value={currentSearchValue}
                            onChange={onTopicSearchValueChange}
                        />
                        {renderDeviceNodeList()}
                    </div>
                    <div className="flex-row topic-manage-bottom-bar">
                        <Button onClick={closePopover} size={'small'} style={{ marginRight: 10 }}>取消</Button>
                        <Button size={'small'} type={'primary'}>确认</Button>
                    </div>
                </div>
            )}
        />
    )
}


export default forwardRef(DeviceNodeManagePopUp);