import { ChangeEvent, LegacyRef, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import CustomPopover from './CustomPopover';
import './custom-popover.scss';
import { InputRef, Button, Tree } from 'antd';
import { commonErrorMsg, commonSuccessMsg, deepCloneV2, deepCopy, defaultDocBaseConfigInfo, getChangeEventValue, isEmpty, toastShort } from '../../utils';
import { ExpensesItemType } from '../../types';
import comDocContext from './../../context/DocContext';
import { DocConfig } from '../../utils/types';
import AutoSelectInput from "../override-antd-kit/AutoSelectInput";
import DragList from '../drag-list/DragList';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
    clientX: number
    clientY: number
}

const OtherExpensesPopover = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openPopover
    }))

    const {
        clientX,
        clientY
    } = props;

    const {
        comFocusTableCellList,
        comDocConfig,
        _setComDocConfig,
        comDocInstance,
        _setComDocInstance,
        comCellStyleConfig,
        _setComCellStyleConfig
    } = useContext(comDocContext);

    const [currentDocConfig, setCurrentDocConfig] = useState<DocConfig>({});

    const customSelectRef = useRef<any>(null);
    const lastOthterExpensesItemNameInputRef = useRef(null);


    const processOtherExpensesItemList = (itemList: ExpensesItemType[]): ExpensesItemType[] => {
        const _itemList = itemList.map((item, index) => {
            let obj = { ...item };
            obj.key = index + '';
            obj.title = index + '';
            obj.pid = 0;
            return obj;
        })
        return _itemList;
    }

    useEffect(() => {
        const { docBaseConfigInfo } = comDocConfig;
        const {
            otherExpensesItemList = defaultDocBaseConfigInfo.otherExpensesItemList,
            basicReserveExpensesList = defaultDocBaseConfigInfo.basicReserveExpensesList
        } = docBaseConfigInfo;
        setOtherExpensesItemList([...processOtherExpensesItemList(otherExpensesItemList)]);
        setBasicReserveExpensesList([...basicReserveExpensesList]);
        setCurrentDocConfig(comDocConfig);
    }, [comDocConfig])

    const openPopover = () => {
        customSelectRef.current.show();
    };

    const refCollect = useRef<{ [key: string]: InputRef }>({});

    const [otherExpensesItemList, setOtherExpensesItemList] = useState<ExpensesItemType[]>([
        {
            key: '0',
            name: "勘查设计费用",
            value: 2.5
        },
        {
            key: '1',
            name: "建设单位管理费",
            value: 2.0
        },
        {
            key: '2',
            name: "工程监理费",
            value: 2.0
        },
        {
            key: '3',
            name: "招标费",
            value: 0.5
        },
        {
            key: '4',
            name: "",
            value: null,
        },
    ]);

    const [basicReserveExpensesList, setBasicReserveExpensesList] = useState<ExpensesItemType[]>([
        {
            name: "基本预备费",
            value: 5
        },
    ])

    const onOtherExpensesItemValueChange = (node: ExpensesItemType, e: ChangeEvent<HTMLInputElement>) => {
        const findIndex = otherExpensesItemList.findIndex(item => item.key == node.key);
        const tempOtherExpensesItemList = deepCopy(otherExpensesItemList);
        let _otherExpensesItemList = otherExpensesItemList;
        //@ts-ignore
        _otherExpensesItemList[findIndex].value = getChangeEventValue(e);
        const tempTotalValue = getOtherExpensesTotalValue(_otherExpensesItemList);
        if (tempTotalValue > 100) {
            setOtherExpensesItemList([...tempOtherExpensesItemList])
            return toastShort('error', '费用占比不得超过工程费用100%')
        }
        setOtherExpensesItemList([..._otherExpensesItemList])
    }

    const onOtherExpensesItemNameChange = (node: ExpensesItemType, e: ChangeEvent<HTMLInputElement>) => {
        console.log("node--->", node)
        const findIndex = otherExpensesItemList.findIndex(item => item.key == node.key);
        console.log("findIndex--->", findIndex)
        let _otherExpensesItemList = otherExpensesItemList;
        _otherExpensesItemList[findIndex].name = getChangeEventValue(e);
        setOtherExpensesItemList([..._otherExpensesItemList])
    }

    const onBaseExpensesItemValueChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
        const tempBasicReserveExpensesList = deepCopy(basicReserveExpensesList);
        let _baseReserveExpensesItemList = basicReserveExpensesList;
        //@ts-ignore
        _baseReserveExpensesItemList[index].value = getChangeEventValue(e);
        const tempTotalValue = getBaseExpensesTotalValue(_baseReserveExpensesItemList);
        if (tempTotalValue > 100) {
            setOtherExpensesItemList([...tempBasicReserveExpensesList])
            return toastShort('error', '费用占比不得超过工程费用100%')
        }
        setBasicReserveExpensesList([..._baseReserveExpensesItemList])
    }

    const onBaseExpensesItemNameChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
        let _baseReserveExpensesItemList = basicReserveExpensesList;
        _baseReserveExpensesItemList[index].name = getChangeEventValue(e);
        setBasicReserveExpensesList([..._baseReserveExpensesItemList])
    }


    const handleFocusInput = (index: number) => {
        refCollect.current[index + 'value'].focus();
    }

    const handleFocusNameInput = (index: number) => {
        refCollect.current[index + 'name'].focus();
    }

    const handleFocusBaseExpensesItemNameInput = (index: number) => {
        refCollect.current[index + 'name-1'].focus();
    }

    const handleFocusBaseExpensesItemValueInput = (index: number) => {
        refCollect.current[index + 'value-1'].focus();
    }

    const closePopover = () => {
        customSelectRef.current._handleClose();
    }

    const getOtherExpensesTotalValue = (_otherExpensesItemList?: ExpensesItemType[]): number => {
        let totalValue = 0;
        if (isEmpty(_otherExpensesItemList) || _otherExpensesItemList.length == 0) {
            _otherExpensesItemList = otherExpensesItemList;
        }
        _otherExpensesItemList.forEach(item => {
            totalValue += Number(item.value);
        })
        return totalValue;
    }

    const getBaseExpensesTotalValue = (_baseExpensesItemList?: ExpensesItemType[]): number => {
        let totalValue = 0;
        if (isEmpty(_baseExpensesItemList) || _baseExpensesItemList.length == 0) {
            _baseExpensesItemList = basicReserveExpensesList;
        }
        _baseExpensesItemList.forEach(item => {
            totalValue += Number(item.value);
        })
        return totalValue;
    }

    const deleteOtherExpensesItem = (node: ExpensesItemType) => {
        let _otherExpensesItemList = otherExpensesItemList;
        const findIndex = otherExpensesItemList.findIndex(item => item.key == node.key);
        _otherExpensesItemList.splice(findIndex, 1);
        setOtherExpensesItemList([..._otherExpensesItemList])
    }

    const confirmToAddOtherExpensesItem = () => {
        let _otherExpensesItemList = otherExpensesItemList;
        const _otherExpensesItemListLength = _otherExpensesItemList.length;
        const lastOtherExpensesItem = _otherExpensesItemList[_otherExpensesItemListLength - 1];
        if (isEmpty(lastOtherExpensesItem.name) && isEmpty(lastOtherExpensesItem.value)) {
            return toastShort('error', '请先完善费用名称与占比')
        }
        _otherExpensesItemList.push({
            key: _otherExpensesItemList.length + '',
            title: _otherExpensesItemList.length + '',
            name: "",
            value: null,
        })
        setOtherExpensesItemList([..._otherExpensesItemList])
    }

    const handleConfirmChangeExpenses = () => {
        try {
            let _tempDocConfig = currentDocConfig;
            _tempDocConfig.docBaseConfigInfo.otherExpensesItemList = deepCloneV2(otherExpensesItemList);
            _tempDocConfig.docBaseConfigInfo.basicReserveExpensesList = deepCloneV2(basicReserveExpensesList);
            _tempDocConfig = deepCopy(_tempDocConfig);
            setCurrentDocConfig(_tempDocConfig);
            _setComDocConfig(_tempDocConfig);
            let _tempComDocInstance = comDocInstance;
            _tempComDocInstance.updateComponentName = 'EditPageNav';
            _setComDocInstance(_tempComDocInstance);
            closePopover();
            toastShort('success', commonSuccessMsg)
        } catch (e) {
            toastShort('error', commonErrorMsg)
        }
    }

    const titleRender = (node: ExpensesItemType) => {
        return (
            <div 
                className='flex-row other-expenses-tree-node'
                style={isEmpty(node.name) ? {marginLeft: 10}: null}
            >
                <AutoSelectInput
                    className='other-expenses-tree-node-name-input'
                    value={node.name}
                    disabled={false}
                    // onClick={() => handleFocusNameInput(index)}
                    onChange={(e) => onOtherExpensesItemNameChange(node, e)}
                    maxLength={12}
                    placeholder={'输入费用名称'}
                />
                <AutoSelectInput
                    className='other-expenses-tree-node-value-input'
                    addonAfter={'%'}
                    value={node.value}
                    disabled={false}
                    type={'number'}
                    // onClick={() => handleFocusInput(index)}
                    onChange={(e) => onOtherExpensesItemValueChange(node, e)}
                    maxLength={12}
                    placeholder={'输入费用占比'}
                />
                <Button
                    type={'text'}
                    icon={<PlusOutlined style={{ transform: `rotateZ(45deg)` }} />}
                    className='other-expenses-tree-node-button'
                     onClick={() => deleteOtherExpensesItem(node)}
                />
            </div>
        )
    };

    const findNode = (key, data) => {
        let result;
        const find = (key, data) => {
            data.forEach(item => {
                if (item.key === key) {
                    result = item;
                } else if (item.children) {
                    find(key, item.children);
                }
            });
        };
        find(key, data);
        return result;
    };

    const deleteNode = (key, data) => {
        data.forEach((item, index, arr) => {
            if (item.key === key) {
                arr.splice(index, 1);
            } else if (item.children) {
                deleteNode(key, item.children);
            }
        });
    };

    const getSiblingsAndParent = (key, data, parentKey = null) => {
        for (const item of data) {
            if (item.key === key) {
                return { siblings: data, parentKey };
            }
            if (item.children) {
                const result = getSiblingsAndParent(key, item.children, item.key);
                if (result) return result;
            }
        }
        return null;
    };

    const updateNodePid = (node: ExpensesItemType, pid: string): ExpensesItemType => {
        node.pid = pid;
        if (node.children) {
            node.children.forEach(child => updateNodePid(child, node.key));
        }
        return node;
    };

    const onTreeNodeDrop = (info: any) => {
        // console.log("info--->", info)
        const treeData = deepCloneV2(otherExpensesItemList);
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const targetNode = info.node;
        const dropNode = findNode(dropKey, treeData);
        const dragNode = findNode(dragKey, treeData);
        let newDragNode = dragNode;
        deleteNode(dragKey, treeData);
        if (info.dropToGap) {
            const siblingsAndParent = getSiblingsAndParent(dropKey, treeData);
            if (!siblingsAndParent) return;

            const siblings = siblingsAndParent.siblings;
            const parentKey = siblingsAndParent.parentKey;
            const index = siblings.findIndex(item => item.key === dropKey);
            if (dropPosition < 0) {
                siblings.splice(Math.max(0, index), 0, newDragNode);
            } else {
                siblings.splice(index + 1, 0, newDragNode);
            }
            newDragNode = updateNodePid(newDragNode, parentKey);
        }
        setOtherExpensesItemList([...treeData])
    }

    return (
        <CustomPopover
            ref={customSelectRef}
            clientX={clientX}
            clientY={clientY}
            contentRender={(props) => (
                <div style={{ width: 320, pointerEvents: 'all', zIndex: 999 }} className='insert-tools-popover'>
                    <div className='flex-row insert-tools-popover-title'>
                        <div>其它费用占比设置</div>
                        <div style={{ fontSize: 12 }}>({`按照工程费用的${getOtherExpensesTotalValue()}%估算`})</div>
                    </div>
                    <div className='flex-row insert-tools-content'>
                        <Tree
                            //@ts-ignore
                            treeData={otherExpensesItemList}
                            className='other-expenses-tree'
                            //@ts-ignore
                            titleRender={titleRender}
                            checkable={false}
                            blockNode
                            //@ts-ignore
                            draggable={(node: ExpensesItemType) => {
                                if (node.name) {
                                    return true;
                                }
                                return false;
                            }}
                            selectable={false}
                            onDrop={onTreeNodeDrop}
                        />
                    </div>
                    <div className='flex-row' style={{ width: '100%', justifyContent: 'flex-end', paddingRight: 5 }}>
                        <Button
                            icon={<PlusOutlined />}
                            onClick={confirmToAddOtherExpensesItem}
                        />
                    </div>
                    <div style={{ marginTop: 12 }} className='flex-row insert-tools-popover-title'>
                        <div>基本预备费占比设置</div>
                        <div style={{ fontSize: 12 }}>({`按照工程费用的${getBaseExpensesTotalValue()}%估算`})</div>
                    </div>
                    <div className='flex-row insert-tools-content' style={{ paddingBottom: 12 }}>
                        {
                            basicReserveExpensesList.map((item, index) => {
                                return (
                                    <div
                                        className='flex-row other-expenses-item'
                                        key={index + ''}
                                    >
                                        <div className='other-expenses-item-name'>
                                            <AutoSelectInput
                                                // ref={c => { refCollect.current[index + 'name-1'] = c }}
                                                className='other-expenses-item-name-input'
                                                value={item.name}
                                                disabled={false}
                                                onClick={() => handleFocusBaseExpensesItemNameInput(index)}
                                                onChange={(e) => onBaseExpensesItemNameChange(index, e)}
                                                maxLength={12}
                                                placeholder={'输入费用名称'}
                                                allowClear
                                            />
                                        </div>
                                        <AutoSelectInput
                                            // ref={c => { refCollect.current[index + 'value-1'] = c }}
                                            className='other-expenses-item-input'
                                            addonAfter={'%'}
                                            value={item.value}
                                            disabled={false}
                                            type={'number'}
                                            onClick={() => handleFocusBaseExpensesItemValueInput(index)}
                                            onChange={(e) => onBaseExpensesItemValueChange(index, e)}
                                            maxLength={12}
                                            placeholder={'输入费用占比'}
                                            allowClear
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="flex-row other-expenses-popover-footer">
                        <Button
                            size={'small'}
                            onClick={closePopover}
                        >
                            取消
                        </Button>
                        <Button
                            size={'small'}
                            type={"primary"}
                            style={{ marginLeft: 12 }}
                            onClick={handleConfirmChangeExpenses}
                        >
                            确认
                        </Button>
                    </div>
                </div>
            )}
        />
    )
}


export default forwardRef(OtherExpensesPopover);