import { Button, Tabs } from "antd";
import DocInstanceOptionHistory from "./DocInstanceOptionHistory";
import TextTopicBackgroundParagraph from "./TextTopicBackgroundParagraph";
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined } from "@ant-design/icons";
import TourismCapacity from "./TourismCapacity";
import BenefitEvaluation from "./BenefitEvaluation";
import WordEditorParagraphCheck from "./WordEditorParagraphCheck";
import FillSpeciesInfoPlugin from "./FillSpeciesInfoPlugin";
import { ReactNode, forwardRef, useRef, useState } from "react";
import InsertSubTable from "./InsertSubTable";
import TextTopicBackgroundParagraphOld from "./TextTopicBackgroundParagraphOld";

interface Props {
    changeBackgroundParagraph: Function
    changeBackgroundParagraphV2: Function,
    insertDocItemByTargetTopicName: Function
    insertDocItemToCursorPosition: Function
    fillSpeciesInfoList: Function
    handleSetFocusDocItem: Function
}

type WordEditorPluginsTabType =
    'TextTopicBackgroundParagraph'
    | 'BenefitEvaluation'
    | 'TourismCapacity'
    | 'ExtraTable'
    | 'FillSpeciesInfoPlugin'
    | 'WordEditorParagraphCheck'

const WordEditorPluginsTabs = (props: Props, ref: any) => {

    const {
        changeBackgroundParagraph,
        changeBackgroundParagraphV2,
        insertDocItemToCursorPosition,
        insertDocItemByTargetTopicName,
        handleSetFocusDocItem,
        fillSpeciesInfoList
    } = props;

    const activeTabTypeRef = useRef<WordEditorPluginsTabType>('TextTopicBackgroundParagraph');


    const [sideBarIsFold, setSideBarIsFold] = useState(false);

    const renderTabContentWrapper = (content: any) => {
        return (
            <div
               
                style={{ width: sideBarIsFold ? 0 : '100%', height: '100%', overflow: 'hidden' }}
            >
                {content}
            </div>
        )
    };

    const onClickTabIcon = (type: WordEditorPluginsTabType) => {
        if (
            activeTabTypeRef.current == type
        ) {
            setSideBarIsFold(!sideBarIsFold)
        }
    };

    const pluginTabs = [
        {
            key: 'TextTopicBackgroundParagraph',
            label: (
                <div
                    id="TextTopicBackgroundParagraphTabBtnId"
                    className='flex-col word-editor-plugins-tab'
                    onClick={() => onClickTabIcon('TextTopicBackgroundParagraph')}
                >
                    <span style={{ fontSize: 22 }} className="iconfont-1 ghzs-gray-wenbenyulan"></span>
                    <span>参考范文</span>
                </div>
            ),
            children: renderTabContentWrapper(
                // <TextTopicBackgroundParagraph
                //     changeBackgroundParagraph={changeBackgroundParagraph}
                //     changeBackgroundParagraphV2={changeBackgroundParagraphV2}
                // />
                <TextTopicBackgroundParagraphOld
                    changeBackgroundParagraph={changeBackgroundParagraph}
                />
            )
        },
        // {
        //     key: 'DocInstanceOptionHistory',
        //     label: (
        //         <div
        //             // style={{marginTop: -12}} 
        //             className='flex-col word-editor-plugins-tab'
        //         >
        //             <span style={{ fontSize: 22 }} className="iconfont ghzs-color-caozuorizhi"></span>
        //             <span>操作记录</span>
        //         </div>
        //     ),
        //     children: renderTabContentWrapper(<DocInstanceOptionHistory />)
        // },
        {
            key: 'BenefitEvaluation',
            label: (
                <div
                    id="BenefitEvaluationTabBtnId"
                    className='flex-col word-editor-plugins-tab'
                    onClick={() => onClickTabIcon('BenefitEvaluation')}
                >
                    <span style={{ fontSize: 22 }} className="iconfont-1 ghzs-gray-shengtaixiaoyi1"></span>
                    <span>效益评价</span>
                </div>
            ),
            children: renderTabContentWrapper(
                <BenefitEvaluation
                    insertDocItemByTargetTopicName={insertDocItemByTargetTopicName}
                />
            )
        },
        {
            key: 'TourismCapacity',
            label: (
                <div
                    id="TourismCapacityTabBtnId"
                    className='flex-col word-editor-plugins-tab'
                    onClick={() => onClickTabIcon('TourismCapacity')}
                >
                    <span style={{ fontSize: 22 }} className="iconfont-1 ghzs-gray-huanjingrongliangfenxi"></span>
                    <span>旅游容量</span>
                </div>
            ),
            children: renderTabContentWrapper(
                <TourismCapacity
                    insertDocItemByTargetTopicName={insertDocItemByTargetTopicName}
                />
            )
        },
        {
            key: 'ExtraTable',
            label: (
                <div
                    id="InsetSubExtraTableTabBtnId"
                    className='flex-col word-editor-plugins-tab'
                    onClick={() => onClickTabIcon('ExtraTable')}
                >
                    <span style={{ fontSize: 22 }} className="iconfont-1 ghzs-gray-charufuben"></span>
                    <span>附表制作</span>
                </div>
            ),
            children: renderTabContentWrapper(
                <InsertSubTable
                    insertDocItemToCursorPosition={insertDocItemToCursorPosition}
                />
            )
        },
        {
            key: 'FillSpeciesInfoPlugin',
            label: (
                <div
                    id="FillSpeciesInfoPluginTabBtnId"
                    className='flex-col word-editor-plugins-tab'
                    onClick={() => onClickTabIcon('FillSpeciesInfoPlugin')}
                >
                    <div className="flex-row latin-name-icon">拉</div>
                    <span>拉丁名填充</span>
                </div>
            ),
            children: renderTabContentWrapper(
                <FillSpeciesInfoPlugin
                    fillSpeciesInfoList={fillSpeciesInfoList}
                />
            )
        },
        {
            key: 'WordEditorParagraphCheck',
            label: (
                <div
                    id="WordEditorParagraphCheckTabBtnId"
                    className='flex-col word-editor-plugins-tab'
                    onClick={() => onClickTabIcon('WordEditorParagraphCheck')}
                >
                    <span style={{ fontSize: 22 }} className="iconfont ghzs-color-a-zu557"></span>
                    <span>文档检查</span>
                </div>
            ),
            children: renderTabContentWrapper(
                <WordEditorParagraphCheck
                    handleSetFocusDocItem={handleSetFocusDocItem}
                />)
        },
    ]

    const onActiveTabChange = (e: WordEditorPluginsTabType) => {
        activeTabTypeRef.current = e;
        if (sideBarIsFold) {
            setSideBarIsFold(false)
        }
    }

    const switchSlidBarFold = () => {
        setSideBarIsFold(!sideBarIsFold)
    }

    const onMouseDown = (e: any) => {
        // e.preventDefault();
        // e.stopPropagation();
    };

    return (
        <div
            className='edit-page-left-content'
            style={{
                width: sideBarIsFold ? 74 : 420
            }}
            id="WordPluginContainerId"
            onMouseDown={onMouseDown}
        >
            <Tabs
                defaultActiveKey="currentTopic"
                items={pluginTabs}
                tabPosition='right'
                className='word-editor-plugin-tabs disable-drag'
                onChange={onActiveTabChange}
                tabBarExtraContent={{
                    right: (
                        <div
                            className="flex-row word-editor-plugin-tabs-fold"
                        >
                            <Button
                                type={'text'}
                                icon={sideBarIsFold ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                                size={'small'}
                                onClick={switchSlidBarFold}
                            >
                                {sideBarIsFold ? '展开' : '收起'}
                            </Button>
                        </div>
                    )
                }}
            />
        </div>
    )
}


export default forwardRef(WordEditorPluginsTabs);