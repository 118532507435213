import { createContext } from 'react';
import { CellStyleConfig, DocConfig, DocInstance, ExcelFormatBrush, LuckySheetSelectionRange, TopicType } from '../utils/types';
import { DocEditorPageBaseMode, TableDataCell } from '../types';
import { ContentBlock } from 'draft-js';
import { DocItem } from '../views/slate-editor';

interface DocContextType {
    baseMode: DocEditorPageBaseMode
    _setBaseMode: (mode: DocEditorPageBaseMode) => void
    //规划数据实例
    comDocInstance: DocInstance,
    _setComDocInstance: Function
    //
    comDocConfig: DocConfig
    _setComDocConfig: Function
    //撤销重做方法引用
    undoComDocInstance?: Function
    redoComDocInsatnce?: Function
    //
    comFocusTopicNode?: TopicType
    _setComFocusTopicNode?: Function
    //
    comFocusTableCellList?: TableDataCell[]
    _setComFocusTableCellList?: Function
    //
    _pasteCellText?: Function
    //
    comCellStyleConfig?: CellStyleConfig
    _setComCellStyleConfig?: Function
    //
    currentFocusedDocItem?: DocItem,
    _setCurrentFocusedDocItem?: Function,
    comExcelSelectionRange?: LuckySheetSelectionRange,
    _setComExcelSelectionRange?: (range: LuckySheetSelectionRange) => void,
    comExcelFormatBrushConfig?: ExcelFormatBrush
    _setComExcelFormatBrushConfig?: (excelFormatBrush: ExcelFormatBrush) => void
    comFocusLayoutTopicNode?: {
        from: 'excel' | 'topic' | 'word',
        topic: TopicType
    },
    _setComFocusLayoutTopicNode?: (e: { from: "excel" | "topic" | "word", topic: TopicType }) => void
}
const docContext = createContext<DocContextType>(null);

export default docContext;