import { FileSyncOutlined, InboxOutlined, PaperClipOutlined, SnippetsOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Drawer, Radio, Tabs, Tag, Upload, UploadProps } from "antd";
import React, { forwardRef, useContext, useRef, useState } from "react"
import * as XLSX from "xlsx";
import { handleDownloadByName, replaceUnitUpperCase, toastShort, isEmpty, deepClone, deepCloneV2, handleDownloadByNameV2, comDownloadTemplate } from "../../../utils";
import SubTablePreView from "./SubTablePreView";
import comDocContext from '../../../context/DocContext';
import { WordParagraphBlock } from "../../../utils/types";
import {
    subTableWorkSheet1Template,
    subTableWorkSheet1Template1,
    subTableWorkSheet2Template1,
    subTableWorkSheet3Template,
    subTableWorkSheet4Template,
    subTableWorkSheet5Template,
    subTableWorkSheet6Template,
} from "./WorkSheetTemplate";
import { generateSubTableSpeciesBook } from "../../../utils/reportUtils";
import WordPararagphBlockRender from "./WordPararagphBlockRender";
import {
    deleteTableDataEmptyColumn, findCellValuColIndex,
    removeAndAdjustColumn, replaceLastCharWithPeriod
} from "../word-editor-deprecated/WordEditorHelper";
import { isNumber } from "lodash";
import { convertWordParagraphListToDocItemList } from "../word-editor/editor-doc-item-generate";
import { DocItem, DocItemType } from "../../slate-editor";
import DocItemParagraphRender from "./DocItemParagraphRender";

interface Props {
    insertDocItemToCursorPosition: Function
}

const InsertSubTable = (props: Props, ref: any) => {

    const {
        comDocInstance,
    } = useContext(comDocContext);

    const {
        insertDocItemToCursorPosition,
    } = props;

    const tempDocItemGroupList = useRef([]);

    const [speciesDrawerOpen, setSpeciesDrawerOpen] = useState(false);

    const onCloseSpeciesDrawer = () => {
        setSpeciesDrawerOpen(false);
    }

    const onCloseSpeciesDrawerAndReset = () => {
        setCurrentFileName("");
        setResultParagraphList([])
        onCloseSpeciesDrawer();
    }

    const {
        protectland: {
            type,
            subType,
        },
        protectlandBaseInfo: {
            protectlandArea,
            protectlandAreaUnit = '公顷',
            protectlandLevel,
            protectlandName,
            protectlandProvince,
            protectlandTypeId,
            startYear,
            endYear
        }
    } = comDocInstance;

    const { Dragger } = Upload;

    const [docSubTableList] = useState([
        {
            type: "1",
            name: "社区情况统计表",
            fileName: "社区情况统计表上传模版.xlsx",
            url: "http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/20230824543cca7621-c09d-462e-b2a7-b70226fb7d4384.xlsx"
        },
        {
            type: "2",
            name: "土地利用现状表",
            fileName: "土地利用现状表上传模版.xlsx",
            url: "http://220.163.43.218:9001/ghzs_api/assets/uploads/2024-04-19/6813c0c7-7959-4233-9e96-f9e431a3849c地类上传模板.xlsx"
        },
        {
            type: "3",
            name: "功能区划表",
            fileName: "功能区划表上传模版.xlsx",
            url: "http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023082453bfc70327-34e4-4887-bad0-c51a6cd0a7b448.xlsx"
        },
        {
            type: "4",
            name: "人员现状统计表",
            fileName: "人员现状统计表上传模版.xlsx",
            url: "http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023082411acc6ad25-ff0a-4dfc-b8c5-ecbad195449998.xlsx"
        },
        {
            type: "5",
            name: "基础设施现状统计表",
            fileName: "基础设施现状统计表上传模版.xlsx",
            url: "http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023082430765a379d-1f4d-4ed5-bed7-c8f4428070eb17.xlsx"
        },
        {
            type: "6",
            name: "野生动植物资源统计表",
            fileName: "野生动植物资源统计表上传模版.xlsx",
            url: "http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/20230824512668dc1a-5e2b-43d9-ae46-72343666d02e67.xlsx"
        }
    ])

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const [currentSubTableType, setCurrentSubTableType] = useState('1');

    const [currentFileName, setCurrentFileName] = useState("");

    const [currentTableParagraph, setCurrentTableParagraph] = useState<WordParagraphBlock>(null);

    const [resultDocItemList, setResultDocItemList] = useState<DocItem[]>([]);
    const [resultParagraphList, setResultParagraphList] = useState<WordParagraphBlock[]>([]);

    const [matchFailedSpeciesNameList, setMatchFailedSpeciesNameList] = useState<string[]>([]);

    const onCheckedChange = (e: any, subTableType: any) => {
        if (currentSubTableType !== subTableType.type) {
            setCurrentFileName("");
            setResultParagraphList([])
            setCurrentSubTableType(subTableType.type);
        }
    };

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            let reader = new FileReader()
            reader.readAsBinaryString(file)//读取这个文件
            reader.onload = function (event) {
                try {
                    let result = event.target.result
                    let xlsxdata = XLSX.read(result, { type: 'binary' })//读取xlsx
                    const workSheetNameList: string[] = xlsxdata.SheetNames;
                    if (workSheetNameList.length == 0) {
                        return toastShort('error', '上传文件中工作表为空，请检查上传文件！')
                    }
                    const workSheet: string[][] = XLSX.utils.sheet_to_json(xlsxdata.Sheets[workSheetNameList[0]], { header: 1, defval: '', blankrows: true });
                    setCurrentFileName(file.name)
                    processWorkSheet(workSheet)
                } catch (e) {

                }
            }
            return false;
        },
        onChange(info) {
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const filterPlaceHolderTextForWorkSheet = (originWorkSheet: string[][]): string[][] => {
        let newWorkSheet: string[][] = [];
        originWorkSheet.forEach(rowList => {
            let newRowList = [];
            rowList.forEach(cellValue => {
                let replacedCellValue = replaceUnitUpperCase(cellValue);
                if (!isEmpty(replacedCellValue)) {
                    replacedCellValue = replacedCellValue.toString().replace("待填写", "");
                    if (replacedCellValue == "无" || replacedCellValue === "待填写管理局、站名称") {
                        replacedCellValue = '';
                    }
                    // if (replacedCellValue == '0') {
                    //     newRowList.push('')
                    // } 
                    // else {
                    //     newRowList.push(replacedCellValue)
                    // }
                    newRowList.push(replacedCellValue)
                } else {
                    newRowList.push('')
                }
            })
            newWorkSheet.push(newRowList)
        })
        return newWorkSheet;
    }

    const presetWorkSheet = (originWorkSheet: string[][]) => {
        let newWorkSheet: string[][] = [];
        originWorkSheet.forEach(rowList => {
            let newRowList = [];
            rowList.forEach(cellValue => {
                let replacedCellValue = replaceUnitUpperCase(cellValue);
                // console.log("replacedCellValue---->", replacedCellValue)
                // 待填写
                if (!isEmpty(replacedCellValue)) {
                    replacedCellValue = replacedCellValue.toString().replace("待填写", "");
                    // console.log("替换后---->", replacedCellValue)
                    if (replacedCellValue == '0') {
                        newRowList.push('')
                    } else {
                        newRowList.push(replacedCellValue)
                    }
                } else {
                    newRowList.push('')
                }
            })
            newWorkSheet.push(newRowList)
        })
        // console.log("处理之后的newWorkSheet---->", newWorkSheet)
        return deepCloneV2(newWorkSheet);
    }

    const processWordParagraphBlockList = (wordParagraphBlockList: WordParagraphBlock[]): WordParagraphBlock[] => {
        wordParagraphBlockList.forEach(wordParagraphBlock => {
            if (isEmpty(wordParagraphBlock.inlineStyleRanges)) {
                if (isEmpty(wordParagraphBlock.text)) {
                    wordParagraphBlock.text = '表格';
                }
                //@ts-ignore
                wordParagraphBlock.inlineStyleRanges = [{ offset: 0, length: wordParagraphBlock.text.length, style: 'normal_inline_style' }]
            }
        })
        return wordParagraphBlockList;
    }

    const getPercentRate = (value: any, totalValue: any): number => {
        let rate = ((Number(value) / Number(totalValue)) * 100).toFixed(2);
        return Number(rate);
    }

    const fillZeroToWorkSheet = (workSheet: string[][]): string[][] => {
        let newWorkSheet = [];
        workSheet.forEach((rowList, rowIndex) => {
            let newRowList = [];
            rowList.forEach((cell, cellIndex) => {
                if (isEmpty(cell)) {
                    newRowList.push('0')
                } else {
                    newRowList.push(cell)
                }
            })
            newWorkSheet.push(newRowList)
        })
        return newWorkSheet;
    }

    const processWorkSheet = async (workSheet: string[][]) => {
        let newWorkSheet = presetWorkSheet(workSheet);
        let docItemGroupList: { topicName: string, docItemList?: DocItem[], wordParagraphList: WordParagraphBlock[] }[] = [];
        switch (currentSubTableType) {
            case '1':
                if (
                    JSON.stringify(newWorkSheet.slice(0, 2)) !== JSON.stringify(subTableWorkSheet1Template) &&
                    JSON.stringify(newWorkSheet.slice(0, 2)) !== JSON.stringify(subTableWorkSheet1Template1)
                ) {
                    return toastShort('error', '上传文件与导入模版不符，请检查上传文件！')
                }
                let subTableData1: string[][] = [];
                newWorkSheet = fillZeroToWorkSheet(newWorkSheet);
                newWorkSheet.forEach(rowList => {
                    let tempRowList = [];
                    rowList.forEach((cell, colIndex) => {
                        if (colIndex <= 7) {
                            tempRowList.push(cell)
                        }
                    })
                    subTableData1.push(tempRowList);
                })
                let tempWorkSheet1 = [...newWorkSheet].slice(2, newWorkSheet.length);
                let workSheet1: string[][] = [];
                tempWorkSheet1.forEach(rowList => {
                    let tempRowList = [];
                    rowList.forEach((cell, colIndex) => {
                        if (colIndex <= 7) {
                            tempRowList.push(cell)
                        }
                    })
                    workSheet1.push(tempRowList);
                })
                let tempParagraph1_1: string = `${protectlandName}周边地区现有`;
                let tempParagraph1_2: string = `${protectlandName}周边的地方经济以`;
                tempParagraph1_1 += `${getCellValueTotalUniqueCountByColGroup(workSheet1, 0, 0, workSheet1.length)}个县、`;
                tempParagraph1_1 += `${getCellValueTotalUniqueCountByColGroup(workSheet1, 1, 0, workSheet1.length)}个村。`;
                tempParagraph1_1 += `${protectlandName}内总人口${getCellValueTotalByColGroup(workSheet1, 2, 0, workSheet1.length)}人，`;
                tempParagraph1_1 += `共计${getCellValueTotalByColGroup(workSheet1, 3, 0, workSheet1.length)}户，`;
                //第二段
                const industrialValue1 = getCellValueTotalByColGroup(workSheet1, 5, 0, workSheet1.length - 1);
                const industrialValue2 = getCellValueTotalByColGroup(workSheet1, 6, 0, workSheet1.length - 1);
                const industrialValue3 = getCellValueTotalByColGroup(workSheet1, 7, 0, workSheet1.length - 1);
                let arr = [industrialValue1, industrialValue2, industrialValue3];
                arr = arr.sort((a, b) => b - a);
                switch (arr[0]) {
                    case industrialValue1:
                        tempParagraph1_2 += `${newWorkSheet[1][5]}为主，`;
                        break;
                    case industrialValue2:
                        tempParagraph1_2 += `${newWorkSheet[1][6]}为主，`;
                        break;
                    case industrialValue3:
                        tempParagraph1_2 += `${newWorkSheet[1][7]}为主，`;
                        break;
                    default:
                        break;
                }
                tempParagraph1_2 += `${protectlandName}总产值${industrialValue1 + industrialValue2 + industrialValue3}万元，`;
                workSheet1.forEach((rowList, rowIndex) => {
                    if (rowIndex == 0) {
                        tempParagraph1_1 += '其中';
                        tempParagraph1_2 += '其中';
                    }
                    tempParagraph1_1 += `${rowList[1]}现有居民${rowList[3]}户${rowList[2]}人`;
                    tempParagraph1_2 += `${rowList[1]}总产值${getCellValueTotalByRowGroup(workSheet1, rowIndex, 5, 7)}万元，人均年收入${Number(rowList[4]).toFixed(1)}万元`;
                    if (rowIndex < workSheet1.length - 1) {
                        tempParagraph1_1 += '；';
                        tempParagraph1_2 += '；';
                    } else {
                        tempParagraph1_1 += '。';
                        tempParagraph1_2 += '。';
                    }
                })
                let tempWordParahraphList1: WordParagraphBlock[] = [];
                tempWordParahraphList1.push({
                    type: 'text',
                    text: tempParagraph1_1,
                    paragraphStyle: 'normal_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
                tempWordParahraphList1.push({
                    type: 'text',
                    text: tempParagraph1_2,
                    paragraphStyle: 'normal_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
                docItemGroupList.push({
                    topicName: "人口与分布状况",
                    wordParagraphList: [tempWordParahraphList1[0]]
                })
                docItemGroupList.push({
                    topicName: "社区经济",
                    wordParagraphList: [tempWordParahraphList1[1]]
                })
                //
                tempWordParahraphList1.push({
                    type: 'text',
                    isSubTable: true,
                    text: `附表  ${protectlandName}社区情况统计表`,
                    paragraphStyle: 'table_name_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
                tempWordParahraphList1.push({
                    type: 'Table',
                    text: '表格',
                    tableName: `附表  ${protectlandName}社区情况统计表`,
                    isSubTable: true,
                    tableData: subTableData1,
                    tableHeaderRowIndex: 1,
                    tableRowSpanList: [
                        { rowIndex: 0, colIndex: 0, rowSpan: 2 },
                        { rowIndex: 0, colIndex: 1, rowSpan: 2 },
                        { rowIndex: 0, colIndex: 2, rowSpan: 2 },
                        { rowIndex: 0, colIndex: 3, rowSpan: 2 },
                        { rowIndex: 0, colIndex: 4, rowSpan: 2 },
                    ],
                    tableColSpanList: [
                        { rowIndex: 0, colIndex: 5, colSpan: 3 },
                    ]
                })
                setResultParagraphList(processWordParagraphBlockList(tempWordParahraphList1));
                break;
            case '2':
                if (JSON.stringify(newWorkSheet.slice(0, 2)) !== JSON.stringify(subTableWorkSheet2Template1)) {
                    return toastShort('error', '上传文件与导入模版不符，请检查上传文件！')
                }
                workSheet = fillZeroToWorkSheet(workSheet);
                newWorkSheet = fillZeroToWorkSheet(newWorkSheet);
                let workSheet2 = [...newWorkSheet].slice(2, newWorkSheet.length);
                let tempWordParahraphList2: WordParagraphBlock[] = [];
                const totalLandUseArea = getCellValueTotalByColGroup(workSheet2, 1, 0, workSheet2.length - 1);
                let tempParagraph2_1: string = `${protectlandName}总面积${totalLandUseArea}公顷，`
                let tableRowSpanListForTuDi = [
                    { rowIndex: 0, colIndex: 0, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 1, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 32, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 33, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 34, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 35, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 36, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 37, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 38, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 39, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 40, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 41, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 42, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 43, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 44, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 45, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 46, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 47, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 48, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 49, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 50, rowSpan: 2 },
                ]
                let tableColSpanListForTuDi = [
                    { rowIndex: 0, colIndex: 2, colSpan: 5, originColSpan: 5, deleteCount: 0 },
                    { rowIndex: 0, colIndex: 7, colSpan: 4, originColSpan: 4, deleteCount: 0 },
                    { rowIndex: 0, colIndex: 11, colSpan: 8, originColSpan: 8, deleteCount: 0 },
                    { rowIndex: 0, colIndex: 19, colSpan: 6, originColSpan: 6, deleteCount: 0 },
                    { rowIndex: 0, colIndex: 25, colSpan: 7, originColSpan: 7, deleteCount: 0 },
                ]
                let tableDataForTuDi = [...workSheet];
                const checkTableColumnTotalValueIsEmpty = (tableData: string[][], colIndex: number, startRowIndex: number): boolean => {
                    let columnTotalValue = 0;
                    let columnIsEmpty = true;
                    const tempColCellList = [];
                    tableData.forEach((rowList, rowIndex) => {
                        if (rowIndex >= startRowIndex
                        ) {

                            const targetCellValue = rowList[colIndex].toString().trim();
                            if (!isEmpty(targetCellValue) && isNumber(Number(targetCellValue)) && Number(targetCellValue) > 0) {
                                columnIsEmpty = false;
                            }
                            tempColCellList.push(targetCellValue);
                            columnTotalValue += Number(targetCellValue)
                        }
                    })
                    let isNeedToDelete = false;
                    if (!columnIsEmpty) {
                        isNeedToDelete = false;
                    } else {
                        if (isNumber(columnTotalValue) && Number(columnTotalValue) !== 0) {
                            isNeedToDelete = false;
                        } else {
                            isNeedToDelete = true;
                        }
                    }
                    console.log("colIndex--->", colIndex, columnIsEmpty, isNeedToDelete, columnTotalValue)
                    return isNeedToDelete;
                }
                const tableDataForTuDiHandledResult = deleteTableDataEmptyColumn(
                    tableDataForTuDi,
                    tableColSpanListForTuDi,
                    tableRowSpanListForTuDi,
                    2,
                    0,
                    checkTableColumnTotalValueIsEmpty
                );
                console.log("tableDataForTuDiHandledResult--->", deepCloneV2(tableDataForTuDiHandledResult))
                let handledTableDataOfLandUseType = tableDataForTuDiHandledResult.tableData;
                let handledTableRowSpanInfoListOfLandUseType = tableDataForTuDiHandledResult.tableRowSpanInfoList;
                let handledTableColSpanInfoListOfLandUseType = tableDataForTuDiHandledResult.tableColSpanInfoList.filter(ele => ele.colSpan > 2);
                const landUseTypeDistribution = [
                    {
                        landType: "林地",
                        children: [
                            "乔木林地",
                            "竹林地",
                            "灌木林地",
                            "其他林地"
                        ],
                    },
                    {
                        landType: "草地",
                        children: [
                            "天然牧草地",
                            "人工牧草地",
                            "其他草地",
                        ],
                    },
                    {
                        landType: "湿地",
                        children: [
                            "森林沼泽",
                            "灌丛沼泽",
                            "沼泽草地",
                            "其他沼泽地",
                            "沿海滩涂",
                            "内陆滩涂",
                            "红树林地",
                        ],
                    },
                    {
                        landType: "园地",
                        children: [
                            "果园",
                            "茶园",
                            "橡胶园地",
                            "油料园地",
                            "其他园地",
                        ],
                    },
                    {
                        landType: "陆地水域",
                        children: [
                            "河流水面",
                            "湖泊水面",
                            "水库水面",
                            "坑塘水面",
                            "沟渠",
                            "冰川及常年积雪",
                        ],
                    },
                    {
                        landType: "耕地",
                        children: [],
                    },
                    {
                        landType: "居住用地",
                        children: [],
                    },
                    {
                        landType: "公共管理与公共服务用地",
                        children: [],
                    },
                    {
                        landType: "商业服务业用地",
                        children: [],
                    },
                    {
                        landType: "工矿用地",
                        children: [],
                    },
                    {
                        landType: "仓储用地",
                        children: [],
                    },
                    {
                        landType: "交通运输用地",
                        children: [],
                    },
                    {
                        landType: "公用设施用地",
                        children: [],
                    },
                    {
                        landType: "绿地与开敞空间用地",
                        children: [],
                    },
                    {
                        landType: "特殊用地",
                        children: [],
                    },
                    {
                        landType: "留白用地",
                        children: [],
                    },
                    {
                        landType: "其他土地",
                        children: [],
                    },
                    {
                        landType: "渔业用海",
                        children: [],
                    },
                    {
                        landType: "工矿通信用海",
                        children: [],
                    },
                    {
                        landType: "交通运输用海",
                        children: [],
                    },
                    {
                        landType: "游憩用海",
                        children: [],
                    },
                    {
                        landType: "特殊用海",
                        children: [],
                    },
                    {
                        landType: "其他海域",
                        children: [],
                    },
                ]
                let landUseSubTypeParagraphPlainTextList: string[] = [];
                landUseTypeDistribution.forEach(((landUseType, landUseTypeIndex) => {
                    const landUseTypeFindIndex = findCellValuColIndex(handledTableDataOfLandUseType[0], landUseType.landType);
                    if (landUseTypeFindIndex > -1) {
                        let landUseSubTypeFindIndexList: { landUseSubType: string; findIndex: number }[] = [];
                        landUseType.children.forEach((lanUseSubTypeName => {
                            const tempLandUseSubTypeFindIndex = findCellValuColIndex(handledTableDataOfLandUseType[1], lanUseSubTypeName);
                            if (tempLandUseSubTypeFindIndex > -1) {
                                landUseSubTypeFindIndexList.push({
                                    landUseSubType: lanUseSubTypeName,
                                    findIndex: tempLandUseSubTypeFindIndex
                                })
                            }
                        }))
                        const landUseTypeArea = getCellValueTotalByColGroup(handledTableDataOfLandUseType, landUseTypeFindIndex, 2, handledTableDataOfLandUseType.length - 1);
                        if (landUseTypeIndex == 0) {
                            tempParagraph2_1 += '其中'
                        }
                        tempParagraph2_1 += `${landUseType.landType}${landUseTypeArea}公顷，占总面积的${getPercentRate(landUseTypeArea, totalLandUseArea)}%；`;
                        if (landUseSubTypeFindIndexList.length == 1) {
                            handledTableDataOfLandUseType = removeAndAdjustColumn(handledTableDataOfLandUseType, landUseTypeFindIndex, handledTableRowSpanInfoListOfLandUseType, handledTableColSpanInfoListOfLandUseType);
                            const deletedColIndex = landUseSubTypeFindIndexList[0].findIndex - 1;
                            handledTableDataOfLandUseType[0][deletedColIndex] = landUseType.landType;
                            handledTableDataOfLandUseType[1][deletedColIndex] = "";
                            handledTableRowSpanInfoListOfLandUseType.push({
                                rowIndex: 0,
                                colIndex: deletedColIndex,
                                rowSpan: 2,
                            })
                        }
                    }
                }))
                landUseTypeDistribution.forEach((landUseType => {
                    const landUseTypeFindIndex = findCellValuColIndex(handledTableDataOfLandUseType[0], landUseType.landType);
                    if (landUseTypeFindIndex > -1) {
                        const landUseTypeArea = getCellValueTotalByColGroup(handledTableDataOfLandUseType, landUseTypeFindIndex, 2, handledTableDataOfLandUseType.length - 1);
                        let landUseSubTypeFindIndexList: { landUseSubType: string; findIndex: number }[] = [];
                        landUseType.children.forEach((lanUseSubTypeName => {
                            const tempLandUseSubTypeFindIndex = findCellValuColIndex(handledTableDataOfLandUseType[1], lanUseSubTypeName);
                            if (tempLandUseSubTypeFindIndex > -1) {
                                landUseSubTypeFindIndexList.push({
                                    landUseSubType: lanUseSubTypeName,
                                    findIndex: tempLandUseSubTypeFindIndex
                                })
                            }
                        }))
                        if (landUseSubTypeFindIndexList.length > 2) {
                            let tempParagraphSlice1 = `在${landUseType.landType}中，`;
                            landUseSubTypeFindIndexList.forEach(landUseSubTypeInfo => {
                                let tempLandUseSubTypeArea = getCellValueTotalByColGroup(handledTableDataOfLandUseType, landUseSubTypeInfo.findIndex, 2, handledTableDataOfLandUseType.length - 1);
                                if (Number(tempLandUseSubTypeArea) > 0) {
                                    tempParagraphSlice1 += `${landUseSubTypeInfo.landUseSubType}面积${tempLandUseSubTypeArea}公顷，占${landUseType.landType}面积的${getPercentRate(tempLandUseSubTypeArea, landUseTypeArea)}%；`
                                }
                            })
                            tempParagraphSlice1 = replaceLastCharWithPeriod(tempParagraphSlice1);
                            landUseSubTypeParagraphPlainTextList.push(tempParagraphSlice1);
                        }
                    }
                }))
                tempParagraph2_1 = replaceLastCharWithPeriod(tempParagraph2_1);
                tempWordParahraphList2.push({
                    type: 'text',
                    text: tempParagraph2_1,
                    paragraphStyle: 'normal_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
                landUseSubTypeParagraphPlainTextList.forEach(str => {
                    tempWordParahraphList2.push({
                        type: 'text',
                        text: str,
                        paragraphStyle: 'normal_paragraph',
                        paragraphType: 'custom_background_paragraph'
                    })
                })
                docItemGroupList.push({
                    topicName: "土地利用状况",
                    wordParagraphList: deepCloneV2(tempWordParahraphList2)
                })
                //
                tempWordParahraphList2.push({
                    type: 'text',
                    isSubTable: true,
                    text: `附表  ${protectlandName}土地利用现状表`,
                    paragraphStyle: 'table_name_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
                tempWordParahraphList2.push({
                    type: 'text',
                    isSubTable: true,
                    text: `单位：公顷`,
                    paragraphStyle: 'table_desc_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
                tempWordParahraphList2.push({
                    type: 'Table',
                    text: "表格",
                    isSubTable: true,
                    tableName: `附表  ${protectlandName}土地利用现状表`,
                    tableData: handledTableDataOfLandUseType,
                    tableHeaderRowIndex: 1,
                    tableRowSpanList: handledTableRowSpanInfoListOfLandUseType,
                    tableColSpanList: handledTableColSpanInfoListOfLandUseType
                });
                setResultParagraphList(processWordParagraphBlockList(tempWordParahraphList2));
                break;
            case '3':
                if (JSON.stringify(newWorkSheet.slice(0, 1)) !== JSON.stringify(subTableWorkSheet3Template)) {
                    return toastShort('error', '上传文件与导入模版不符，请检查上传文件！')
                }
                // newWorkSheet = fillZeroToWorkSheet(newWorkSheet);
                let totalArea = 0;
                let workSheet3 = [...newWorkSheet].slice(1, newWorkSheet.length);
                workSheet3.forEach(rowList => {
                    if (rowList[1]) {
                        totalArea += Number(rowList[1])
                    }
                })
                let subTableData2: string[][] = [];
                workSheet.forEach((rowList, rowIndex) => {
                    let tempRowList: string[] = [];
                    rowList.forEach((cell, cellIndex) => {
                        if (rowIndex > 0 && cellIndex == 2) {
                            let newCell = cell;
                            if (!Number.isNaN(Number(rowList[1]))) {
                                newCell = (Number(rowList[1]) / totalArea * 100).toFixed(2)
                            };
                            tempRowList.push(newCell);
                        } else {
                            tempRowList.push(cell)
                        }
                    })
                    subTableData2.push(tempRowList);
                })
                let tempParagraphList3: string[] = [
                    `按照功能区划原则和依据，在实地调查与充分论证的基础上，根据保护对象的数量、空间分布特点，结合环境条件及区内居民生活方式等情况，采取自然区划为主的区划法，将该${protectlandName}内部按照功能性差异划分为`,
                    `${protectlandName}总面积${getCellValueTotalByColGroup(workSheet3, 1, 0, workSheet3.length - 1).toFixed(2)}公顷。其中，`
                ];
                workSheet3 = workSheet3.filter(rowList => !isEmpty(rowList[0]))
                // console.log("workSheet3--->", workSheet3)
                workSheet3.forEach((rowList, rowIndex) => {
                    if (rowIndex < workSheet3.length - 1) {
                        tempParagraphList3[0] += `${rowList[0]}、`;
                        tempParagraphList3[1] += `${rowList[0]}面积${rowList[1]}公顷，占${protectlandName}总面积的${getPercent(Number(rowList[1]), getCellValueTotalByColGroup(workSheet3, 1, 0, workSheet3.length - 1))}%；`;
                    } else {
                        tempParagraphList3[0] += `${rowList[0]}${workSheet3.length}个功能区。`;
                        tempParagraphList3[1] += `${rowList[0]}面积${rowList[1]}公顷，占${protectlandName}总面积的${getPercent(Number(rowList[1]), getCellValueTotalByColGroup(workSheet3, 1, 0, workSheet3.length - 1))}%。`;
                    }
                    tempParagraphList3.push(`（${rowIndex + 1}）${rowList[0]}`);
                    let lastParagraph3 = `${protectlandName}${rowList[0]}面积${rowList[1]}公顷，占总面积的${getPercent(Number(rowList[1]), getCellValueTotalByColGroup(workSheet3, 1, 0, workSheet3.length - 1))}%`;
                    if (!isEmpty(rowList[3])) {
                        lastParagraph3 += `，${rowList[0]}范围为${rowList[3]}`;
                    }
                    if (!isEmpty(rowList[4])) {
                        lastParagraph3 += `，分布有${rowList[4]}`;
                    }
                    lastParagraph3 += `。`;
                    tempParagraphList3.push(lastParagraph3);
                })
                let tempWordParahraphList3: WordParagraphBlock[] = [];
                tempParagraphList3.forEach(str => {
                    tempWordParahraphList3.push({
                        type: 'text',
                        text: str,
                        paragraphStyle: 'normal_paragraph',
                        paragraphType: 'custom_background_paragraph'
                    })
                })
                docItemGroupList.push({
                    topicName: "功能区划",
                    wordParagraphList: deepCloneV2(tempWordParahraphList3)
                })
                tempWordParahraphList3.push({
                    type: 'text',
                    isSubTable: true,
                    text: `附表  ${protectlandName}功能区划表`,
                    paragraphStyle: 'table_name_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
                tempWordParahraphList3.push({
                    type: 'Table',
                    isSubTable: true,
                    tableData: subTableData2,
                    tableHeaderRowIndex: 0,
                    tableName: `附表  ${protectlandName}功能区划表`,
                })
                setResultParagraphList(processWordParagraphBlockList(tempWordParahraphList3))
                break;
            case '4':
                if (JSON.stringify(newWorkSheet.slice(0, 2)) !== JSON.stringify(subTableWorkSheet4Template)) {
                    return toastShort('error', '上传文件与导入模版不符，请检查上传文件！')
                }
                newWorkSheet = fillZeroToWorkSheet(newWorkSheet);
                let workSheet4 = [...newWorkSheet].splice(2, newWorkSheet.length - 1);
                const totalPersonNum = Number(workSheet4[0][1]);
                let tempParagraph4_1 = `目前，${protectlandName}共有在职工${totalPersonNum}人，包括`;
                workSheet4.forEach((rowList, rowIndex) => {
                    if (rowIndex > 0) {
                        if (rowIndex < workSheet4.length - 1) {
                            tempParagraph4_1 += `${rowList[0]}${rowList[1]}人，`
                        } else {
                            tempParagraph4_1 += `${rowList[0]}${rowList[1]}人。`
                        }
                    }
                })
                if (workSheet4[0][2] && workSheet4[0][2] != '0') {
                    tempParagraph4_1 += `按照文化结构划分，${protectlandName}硕士以上人员${workSheet4[0][2]}人，`;
                }
                if (workSheet4[0][3] && workSheet4[0][3] != '0') {
                    tempParagraph4_1 += `本科人员${workSheet4[0][3]}人，`;
                }
                if (workSheet4[0][4] && workSheet4[0][4] != '0') {
                    tempParagraph4_1 += `专科人员${workSheet4[0][4]}人，`;
                }
                if (workSheet4[0][5] && workSheet4[0][5] != '0') {
                    tempParagraph4_1 += `中专或高中${workSheet4[0][5]}人，`;
                }
                if (workSheet4[0][6] && workSheet4[0][6] != '0') {
                    tempParagraph4_1 += `初中及以下${workSheet4[0][6]}人；`;
                }
                if (workSheet4[0][8] && workSheet4[0][8] != '0') {
                    tempParagraph4_1 += `按照职称结构划分，${protectlandName}高级职工${workSheet4[0][8]}人，`;
                }
                if (workSheet4[0][9] && workSheet4[0][9] != '0') {
                    tempParagraph4_1 += `中级职工${workSheet4[0][9]}人，`;
                }
                if (workSheet4[0][10] && workSheet4[0][10] != '0') {
                    tempParagraph4_1 += `助工${workSheet4[0][10]}人，`;
                }
                if (workSheet4[0][11] && workSheet4[0][11] != '0') {
                    tempParagraph4_1 += `技术员${workSheet4[0][11]}人，`;
                }
                if (workSheet4[0][13] && workSheet4[0][13] != '0') {
                    tempParagraph4_1 += `${protectlandName}正式职工${workSheet4[0][13]}人，`;
                }
                if (workSheet4[0][14] && workSheet4[0][14] != '0') {
                    tempParagraph4_1 += `临时工${workSheet4[0][14]}人，`;
                }
                if (workSheet4[0][15] && workSheet4[0][15] != '0') {
                    tempParagraph4_1 += `退休人员${workSheet4[0][15]}人。`;
                }
                let tempWordParahraphList4: WordParagraphBlock[] = [];
                let tableRowSpanListForRenYuan = [
                    { rowIndex: 0, colIndex: 0, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 15, rowSpan: 2 },
                ];
                let tableColSpanListForRenYuan = [
                    { rowIndex: 0, colIndex: 1, colSpan: 6 },
                    { rowIndex: 0, colIndex: 7, colSpan: 5 },
                    { rowIndex: 0, colIndex: 12, colSpan: 3 },
                ];
                const workSheet4DeleteResult = deleteTableDataEmptyColumn(newWorkSheet, tableColSpanListForRenYuan, tableRowSpanListForRenYuan, 3, 1)
                tempWordParahraphList4.push({
                    type: 'text',
                    text: replaceLastCharWithPeriod(tempParagraph4_1),
                    paragraphType: 'custom_background_paragraph',
                    paragraphStyle: 'normal_paragraph'
                })
                docItemGroupList.push({
                    topicName: "管理队伍",
                    wordParagraphList: deepCloneV2(tempWordParahraphList4)
                })
                //
                tempWordParahraphList4.push({
                    type: 'text',
                    isSubTable: true,
                    text: `附表  ${protectlandName}管理局（处）人员现状统计表`,
                    paragraphStyle: 'table_name_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
                tempWordParahraphList4.push({
                    type: 'Table',
                    isSubTable: true,
                    tableName: `附表  ${protectlandName}管理局（处）人员现状统计表`,
                    tableData: filterPlaceHolderTextForWorkSheet(workSheet4DeleteResult.tableData),
                    tableHeaderRowIndex: 1,
                    tableRowSpanList: workSheet4DeleteResult.tableRowSpanInfoList,
                    tableColSpanList: workSheet4DeleteResult.tableColSpanInfoList
                })
                setResultParagraphList(processWordParagraphBlockList(tempWordParahraphList4));
                break;
            case '5':
                if (JSON.stringify(newWorkSheet.slice(0, 1)) !== JSON.stringify(subTableWorkSheet5Template)) {
                    return toastShort('error', '上传文件与导入模版不符，请检查上传文件！')
                }
                newWorkSheet = fillZeroToWorkSheet(newWorkSheet);
                let workSheet5 = [...newWorkSheet].slice(1, newWorkSheet.length - 1);
                const realUseWorkSheet5: string[][] = presetWorkSheet(workSheet5)
                let tempParagraphList5: string[] = [
                    `${protectlandName}地理位置较为偏远，社区的社会事业发展较为缓慢，社会服务水平较低，大型服务设施主要位于乡镇中心。近些年，在国家的大力发展支持下，${protectlandName}社区内学校、交通、医疗、通讯及文化等基础设施建设有所改善加强，社区及周边群众生活条件得到明显改善。`,
                    `${protectlandName}当前的基础设施有`
                ]
                // console.log("realUseWorkSheet5--->", realUseWorkSheet5)
                const _realUseWorkSheet5 = realUseWorkSheet5.filter(rowList => rowList[0] && rowList[1]);
                _realUseWorkSheet5.forEach((rowList, rowIndex) => {
                    if (rowIndex < _realUseWorkSheet5.length - 1) {
                        tempParagraphList5[1] += `${rowList[0]}${rowList[1]}，`
                    } else {
                        tempParagraphList5[1] += `${rowList[0]}${rowList[1]}。`
                    }
                })
                let tempWordParahraphList5: WordParagraphBlock[] = [];
                tempParagraphList5.forEach(str => {
                    tempWordParahraphList5.push({
                        type: 'text',
                        text: str,
                        paragraphStyle: 'normal_paragraph',
                        paragraphType: 'custom_background_paragraph'
                    })
                })
                docItemGroupList.push({
                    topicName: "公共基础设施",
                    wordParagraphList: deepCloneV2(tempWordParahraphList5)
                })
                //
                tempWordParahraphList5.push({
                    type: 'text',
                    isSubTable: true,
                    text: `附表  ${protectlandName}基础设施现状统计表`,
                    paragraphStyle: 'table_name_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
                tempWordParahraphList5.push({
                    type: 'Table',
                    isSubTable: true,
                    tableData: filterPlaceHolderTextForWorkSheet(workSheet),
                    tableHeaderRowIndex: 0,
                    tableName: `附表  ${protectlandName}基础设施现状统计表`,
                });
                setResultParagraphList(processWordParagraphBlockList(tempWordParahraphList5));
                break;
            case '6':
                if (JSON.stringify(newWorkSheet.slice(0, 1)) !== JSON.stringify(subTableWorkSheet6Template)) {
                    return toastShort('error', '上传文件与导入模版不符，请检查上传文件！')
                }
                //这里要开始生成物种名录等表格文件。而不是原始导入文件
                let workSheet6 = [...newWorkSheet].splice(1, newWorkSheet.length - 1);
                let speciesNameList = workSheet6.map(rowList => {
                    return rowList[0]
                })
                const {
                    wordParagraphList,
                    docItemListForNewVersion,
                    matchFailedSpeciesNameList
                } = await generateSubTableSpeciesBook(speciesNameList, { protectlandName });
                if (matchFailedSpeciesNameList.length) {
                    setSpeciesDrawerOpen(true)
                }
                setMatchFailedSpeciesNameList(matchFailedSpeciesNameList);
                wordParagraphList.forEach(ele => {
                    if (ele.type == 'text' && ele.paragraphStyle == 'table_name_paragraph' && ele.isSubTable) {
                        ele.text = '附' + ele.text;
                    }
                })
                let plantParagraphList = wordParagraphList.filter(ele => !ele.isSubTable).filter(ele => ele.text?.includes("植物") || ele.tableName?.includes("植物"));
                let animalParagraphList = wordParagraphList.filter(ele => !ele.isSubTable).filter(ele => ele.text?.includes("动物") || ele.tableName?.includes("动物"));
                docItemGroupList.push({
                    topicName: "野生植物资源",
                    wordParagraphList: plantParagraphList,
                    // docItemList: docItemListForNewVersion.map(docItem => plantParagraphList.find(paragraph => docItem.id == paragraph.blockId) ? docItem : null).filter(item => item)
                })
                docItemGroupList.push({
                    topicName: "野生动物资源",
                    wordParagraphList: animalParagraphList,
                    // docItemList: docItemListForNewVersion.map(docItem => plantParagraphList.find(paragraph => docItem.id == paragraph.blockId) ? docItem : null).filter(item => item)
                })
                console.log("docItemGroupList---->123", docItemGroupList)
                setResultParagraphList(wordParagraphList);
                setResultDocItemList(docItemListForNewVersion);
                break;
            default:
                break;
        }
        tempDocItemGroupList.current = docItemGroupList;
    }

    const handleDownLoadTemplate = () => {
        const currentSubTable = docSubTableList.find(ele => {
            return ele.type == currentSubTableType;
        })
        // handleDownloadByNameV2(currentSubTable.url, currentSubTable.fileName)
        //@ts-ignore
        comDownloadTemplate(currentSubTable.fileName)
    }

    const renderXlsxTemplate = () => {
        const currentSubTable = docSubTableList.find(ele => {
            return ele.type == currentSubTableType;
        })
        return (
            <div
                className="single-line-text link-text"
                style={{ width: 320 }}
                onClick={handleDownLoadTemplate}
                // title={currentSubTable.fileName}
                title={currentSubTable.name}
            >
                <PaperClipOutlined />
                下载模版-{currentSubTable.name}
                {/* {currentSubTableType} */}
            </div>
        )
    }

    const insertSubTable = () => {
        if (currentSubTableType == '6') {
            const newDocItemGroupList: { topicName: string, docItemList: DocItem[] }[] = [];
            const plantDocItemList: DocItem[] = [];
            const animalDocItemList: DocItem[] = [];
            const additionalDocItemList: DocItem[] = [];
            let findAnimalStartIndex = resultDocItemList.findIndex(docItem => docItem.plainText && docItem.plainText.includes('动物资源丰富'));
            // console.log("findAnimalStartIndex--->", findAnimalStartIndex);
            if (findAnimalStartIndex === -1) {
                findAnimalStartIndex = 999;
            }
            let plantSubTableNameStartIndex = -1;
            let animalSubTableNameStartIndex = -1;
            resultDocItemList.forEach((docItem, index) => {
                // console.log("docItem---->", docItem)
                if (index < findAnimalStartIndex) {
                    //处理植物的
                    if (
                        docItem.type == DocItemType.P ||
                        docItem.type == DocItemType.TABLE_NAME
                    ) {
                        if (docItem.plainText.startsWith("附表")) {
                            plantSubTableNameStartIndex = index;
                            additionalDocItemList.push(docItem);
                        } else {
                            plantDocItemList.push(docItem);
                        }
                    } else if (docItem.type == DocItemType.TABLE) {
                        if(index = plantSubTableNameStartIndex + 1){
                            additionalDocItemList.push(docItem);
                        }else {
                            plantDocItemList.push(docItem);
                        }
                    }
                }else {
                    //处理动物的
                    if (
                        docItem.type == DocItemType.P ||
                        docItem.type == DocItemType.TABLE_NAME
                    ) {
                        if (docItem.plainText.startsWith("附表")) {
                            animalSubTableNameStartIndex = index;
                            additionalDocItemList.push(docItem);
                        } else {
                            animalDocItemList.push(docItem);
                        }
                    } else if (docItem.type == DocItemType.TABLE) {
                        if(index = animalSubTableNameStartIndex + 1){
                            additionalDocItemList.push(docItem);
                        }else {
                            animalDocItemList.push(docItem);
                        }
                    }
                }
            })
            newDocItemGroupList.push({
                topicName: "野生植物资源",
                docItemList: plantDocItemList,
            })
            newDocItemGroupList.push({
                topicName: "野生动物资源",
                docItemList: animalDocItemList,
            })
            insertDocItemToCursorPosition && insertDocItemToCursorPosition({
                docItemGroupList: newDocItemGroupList,
                docItemListFragment: plantDocItemList,
                docItemListAdditional: additionalDocItemList,
            });
        } else {
            const normalParagraphList = resultParagraphList.filter(ele => {
                return !ele.isSubTable;
            })
            const subTableParagraphList = resultParagraphList.filter(ele => {
                return ele.isSubTable;
            })
            if (isEmpty(normalParagraphList) || normalParagraphList.length == 0) {
                return toastShort('error', '没有可插入的内容')
            }
            const docItemListAdditional = convertWordParagraphListToDocItemList(subTableParagraphList);
            docItemListAdditional.forEach((item, index) => {
                item.id = `-ADDITIONAL-INSET_SUB_TABLE_${currentSubTableType}_${index}`
            })
            let docItemGroupList = tempDocItemGroupList.current.map(group => {
                let newGroup = group;
                newGroup.docItemList = convertWordParagraphListToDocItemList(group.wordParagraphList)
                return newGroup;
            })
            insertDocItemToCursorPosition && insertDocItemToCursorPosition({
                docItemGroupList: docItemGroupList,
                docItemListFragment: convertWordParagraphListToDocItemList(normalParagraphList),
                docItemListAdditional: docItemListAdditional,
            });
        }
        setTimeout(() => {
            setResultDocItemList([]);
            setResultParagraphList([]);
            setCurrentFileName(null);
        }, 200);
    }

    /**
     * 获取指定列的固定行区间的总值
     * @param tableData 
     * @param colIndex 
     * @param startRowIndex 
     * @param endRowIndex 
     * @returns 
     */
    const getCellValueTotalByColGroup = (
        tableData: string[][],
        colIndex: number,
        startRowIndex: number,
        endRowIndex: number,
    ) => {
        let columnList = [];
        tableData.forEach((rowList, rowIndex) => {
            if (rowIndex >= startRowIndex && rowIndex <= endRowIndex) {
                columnList.push(rowList[colIndex])
            }
        })
        let totalValue = 0;
        columnList.forEach((value, colIndex) => {
            if (!Number.isNaN(value)) {
                totalValue += Number(value);
            }
        })
        return totalValue;
    }

    /**
     * 获取指定列的固定行区间的去重值
     * @param tableData 
     * @param colIndex 
     * @param startRowIndex 
     * @param endRowIndex 
     * @returns 
     */
    const getCellValueTotalUniqueCountByColGroup = (
        tableData: string[][],
        colIndex: number,
        startRowIndex: number,
        endRowIndex: number,
    ) => {
        let columnList = [];
        tableData.forEach((rowList, rowIndex) => {
            if (rowIndex >= startRowIndex && rowIndex <= endRowIndex) {
                columnList.push(rowList[colIndex])
            }
        })
        let uniqueValueList = [];
        columnList.forEach((value, colIndex) => {
            if (!uniqueValueList.includes(value)) {
                uniqueValueList.push(value)
            }
        })
        uniqueValueList = uniqueValueList.filter(item => {
            return !isEmpty(item)
        })
        return uniqueValueList.length;
    }

    /**
     * 获取指定行的固定列区间的总值
     * @param tableData 
     * @param rowIndex 
     * @param startColIndex 
     * @param endColIndex 
     */
    const getCellValueTotalByRowGroup = (
        tableData: string[][],
        rowIndex: number,
        startColIndex: number,
        endColIndex: number,
    ) => {
        let rowList = tableData[rowIndex];
        let totalValue = 0;
        rowList.forEach((value, colIndex) => {
            if (
                !Number.isNaN(value) &&
                colIndex >= startColIndex &&
                colIndex <= endColIndex
            ) {
                totalValue += Number(value);
            }
        })
        return totalValue;
    }

    /**
     * 获取表格区域的总值
     * @param tableData 
     * @param startRowIndex 
     * @param endRowIndex 
     * @param startColIndex 
     * @param endColIndex 
     */
    const getTableMatrixTotalValue = (
        tableData: string[][],
        startRowIndex: number,
        endRowIndex: number,
        startColIndex: number,
        endColIndex: number
    ) => {
        let totalValue = 0;
        tableData.forEach((rowList, rowIndex) => {
            rowList.forEach((value, colIndex) => {
                if (!Number.isNaN(Number(value))) {
                    totalValue += Number(value)
                }
            })
        })
        return totalValue;
    }

    /**
     * 获取表格区域最大的行总值
     * @param tableData 
     * @param startRowIndex 
     * @param endRowIndex 
     * @param startColIndex 
     * @param endColIndex 
     */
    const getTableMatrixRowTotalValueMax = (
        tableData: string[][],
        startRowIndex: number,
        endRowIndex: number,
        startColIndex: number,
        endColIndex: number
    ) => {
        let rowTotalValueMax = 0;
        tableData.forEach((rowList, rowIndex) => {
            if (
                rowIndex >= startRowIndex &&
                rowIndex <= endRowIndex
            ) {
                let tempRowTotalValue = 0;
                rowList.forEach((value, colIndex) => {
                    if (
                        colIndex >= startColIndex &&
                        colIndex <= endColIndex
                    ) {
                        tempRowTotalValue += Number(value)
                    }
                })
                if (tempRowTotalValue > rowTotalValueMax) {
                    rowTotalValueMax = tempRowTotalValue;
                }
            }
        })
        return rowTotalValueMax;
    }

    const getPercent = (value1: number, value2: number) => {
        return ((Number(value1) / Number(value2)) * 100).toFixed(2)
    }

    const showSubTablePreViewModal = (tableParagraph: WordParagraphBlock) => {
        // subTablePreViewModalRef.current.openModal(currentSubTableConfig)
        setCurrentTableParagraph(tableParagraph)
        setDrawerOpen(true);
    }

    const renderTab1 = () => {
        return (
            <div className="word-editor-plugins-content scroll-y">
                <div className='edit-benefit-header'>
                    <div className='edit-benefit-title'>选择附表</div>
                </div>
                <div className='edit-benefit-bnt-more'>
                    {
                        docSubTableList.map((subTableType, index) => {
                            return (
                                <div
                                    className='edit-benefit-bnt-item'
                                    style={{ minWidth: 148, marginRight: 8, paddingRight: 0, paddingLeft: 5 }}
                                    key={index + ''}
                                >
                                    <Radio
                                        className='edit-benefit-bnt-checkbox'
                                        checked={currentSubTableType == subTableType.type}
                                        onChange={(e) => onCheckedChange(e, subTableType)}
                                    >
                                        {subTableType.name}
                                    </Radio>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='edit-benefit-header'>
                    <div className='edit-benefit-title'>上传数据</div>
                </div>
                <div className='edit-benefit-bnt-more' style={{ paddingRight: 10 }}>
                    <Dragger
                        {...uploadProps}
                        style={{ width: 325 }}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            {
                                currentFileName ?
                                    currentFileName
                                    :
                                    '点击或拖拽附表文件到此处'
                            }
                        </p>
                        <p className="ant-upload-hint">
                            支持单次上传一个表格文件<br />(xlsx文件, 且大小在2M之内)
                        </p>
                    </Dragger>
                    <div
                        className="flex-row"
                        style={{ marginTop: 3 }}
                    >
                        {renderXlsxTemplate()}
                    </div>
                </div>
                <div
                    className='flex-row word-editor-plugins-content-option-bar'
                >
                    <Button
                        type={'primary'}
                        icon={<SnippetsOutlined />}
                        style={{ marginRight: '12px' }}
                        onClick={insertSubTable}
                        disabled={resultParagraphList.length == 0}
                    >
                        {/* 插入附表内容至规划报告 */}
                        确定
                    </Button>
                    {/* <Button
                        type={'default'}
                        icon={<SnippetsOutlined />}
                        style={{ marginRight: '12px' }}
                        onClick={insertSubTable}
                    >
                        插入报告正文
                    </Button>
                    <Button
                        type={'default'}
                        icon={<FileSyncOutlined />}
                        onClick={insertAllSubTable}
                    >
                        插入所有附表
                    </Button> */}
                </div>
                <Alert
                    message="温馨提示：附表生成内容分为两部分,正文部分与附表部分，正文会插入至对应大纲下，附表会插入至文档最末尾。"
                    type="warning"
                    style={{ marginRight: 10, }}
                    showIcon
                    closable
                />
                <Divider
                    type={'horizontal'}
                    orientation={'center'}
                    style={{ width: 'calc(100% - 18px)', marginRight: 12 }}
                >
                    报告效果预览
                </Divider>
                {disPatchResultRender()}
            </div>
        )
    }

    const disPatchResultRender = () => {
        if (resultDocItemList && resultDocItemList.length && currentSubTableType == '6') {
            return (
                <DocItemParagraphRender
                    docItemList={resultDocItemList}
                />
            )
        }
        return resultParagraphList.map((resultParagraph, index) => {
            switch (resultParagraph.type) {
                case 'text':
                    if (resultParagraph.paragraphStyle == 'normal_paragraph') {
                        return (
                            // <p
                            //     key={index + ''}
                            //     className={'custom_paragraph_6_style'}
                            // >
                            //     {resultParagraph.text}
                            // </p>
                            <WordPararagphBlockRender
                                key={index + ''}
                                wordParagraphBlock={resultParagraph}
                            />
                        )
                    } else if (resultParagraph.paragraphStyle == 'table_name_paragraph') {
                        return (
                            <p
                                key={index + ''}
                                className={'custom_table_name_paragraph_style'}
                            >
                                {resultParagraph.text}
                            </p>
                        )
                    }
                    break;
                case 'Table':
                    return (
                        <React.Fragment
                            key={index + ''}
                        >
                            <div

                                className="word-editor-plugins-content-sub-table-area"
                            >
                                <SubTablePreView
                                    tableData={resultParagraph.tableData}
                                    tableColSpanList={resultParagraph.tableColSpanList}
                                    tableRowSpanList={resultParagraph.tableRowSpanList}
                                    tableHeaderRowIndex={resultParagraph.tableHeaderRowIndex}
                                    tableStyle={{ tableLayout: 'auto' }}
                                />
                            </div>
                            <div
                                className='flex-row word-editor-plugins-content-option-bar'
                            >
                                {/* <Button
                                    type={'default'}
                                    icon={<SnippetsOutlined />}
                                    style={{ marginRight: '12px' }}
                                    onClick={insertSubTable}
                                >
                                    插入附表
                                </Button> */}
                                <Button
                                    type={'default'}
                                    icon={<FileSyncOutlined />}
                                    onClick={() => showSubTablePreViewModal(resultParagraph)}
                                >
                                    表格预览
                                </Button>
                            </div>
                        </React.Fragment>
                    )
                    break;
                default:
                    break;
            }
        })
    }

    const insertSubTableTabs = [
        {
            key: '1',
            label: '附表制作',
            children: renderTab1()
        },
    ]

    const onClose = () => {
        setDrawerOpen(false)
    }

    return (
        <div className="word-editor-plugins-content">
            <Tabs
                type={'card'}
                defaultActiveKey="1"
                items={insertSubTableTabs}
                tabPosition='top'
                className='word-editor-plugin-content-tabs disable-drag'
                size={'small'}
            />
            {/* <SubTablePreViewModal
                ref={subTablePreViewModalRef}
            /> */}
            <Drawer
                title="Word纸张效果预览"
                placement="right"
                onClose={onClose}
                open={drawerOpen}
                width={'90vw'}
            >
                <div className="flex-col sub-table-preview-modal">
                    <div className="word-A4-container">
                        <div className="word-A4-content">
                            {
                                currentTableParagraph ?
                                    <>
                                        <p
                                            className={'custom_table_name_paragraph_style'}
                                        >
                                            {currentTableParagraph.tableName}
                                        </p>
                                        <SubTablePreView
                                            tableData={currentTableParagraph.tableData}
                                            tableHeaderRowIndex={currentTableParagraph.tableHeaderRowIndex}
                                            tableRowSpanList={currentTableParagraph.tableRowSpanList}
                                            tableColSpanList={currentTableParagraph.tableColSpanList}
                                            tableStyle={{
                                                width: '81vw',
                                                tableLayout: 'fixed',
                                            }}
                                        />
                                    </>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </Drawer>
            <Drawer
                title="温馨提示"
                placement="bottom"
                closable={false}
                onClose={onCloseSpeciesDrawer}
                open={speciesDrawerOpen}
                getContainer={false}
            >
                <div className="flex-col" style={{ width: '100%', height: 270 }}>
                    <Alert
                        message="以下物种名称在系统中查询失败，请检查名称是否正确。"
                        type="warning"
                        style={{ marginRight: 10, marginBottom: 12, marginTop: -12 }}
                        showIcon
                        closable
                    />
                    <div className="flex-row" style={{ flex: 1, width: '100%', flexWrap: 'wrap', overflowY: 'auto', alignItems: 'flex-start', alignContent: 'flex-start' }}>
                        {matchFailedSpeciesNameList.map((name, index) => {
                            return (
                                <div key={index + ''} style={{ marginBottom: '12px', flexShrink: 0 }}>
                                    <Tag title={name} color={'red'}>{name}</Tag>
                                </div>
                            )
                        })}
                    </div>
                    <div className="flex-row" style={{ width: '100%', justifyContent: 'flex-end', marginTop: 24 }}>
                        <Button style={{ marginRight: 12 }} onClick={onCloseSpeciesDrawerAndReset}>重新上传</Button>
                        <Button type={'primary'} onClick={onCloseSpeciesDrawer}>我已知晓</Button>
                    </div>
                </div>
            </Drawer>

        </div>
    )
}


export default forwardRef(InsertSubTable);

