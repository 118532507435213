import { useEffect, useRef } from "react";
import { saasConfig } from "../../config/theme-config";


const EditPageLoading = () => {
    const delayTimer = useRef<any>(null);
    const sloganRef = useRef(null);

    useEffect(() => {
        startAnimate();
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current)
        }
    });

    const startAnimate = () => {
        sloganRef.current.className = 'slogan show';
        delayTimer.current = setTimeout(() => {
            hideAnimate();
        }, 800);
    }

    const hideAnimate = () => {
        sloganRef.current.className = 'slogan hide';
        delayTimer.current = setTimeout(() => {
            startAnimate();
        }, 1000);
    };

    return (
        <div className="flex-col full-page-loading">
            <div 
                className="slogan"
                ref={sloganRef}
            >{saasConfig.systemNameFirst}{saasConfig.systemNameSecond}</div>
        </div>
    )
}


export default EditPageLoading;