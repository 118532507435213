import { useState } from 'react';

import { Range } from 'slate';

import { useOnToolbarRefresh } from '../../../hooks';
import { DocEditorRef } from '../../../types';

export const useRangeIsExpanded = (editor: DocEditorRef | undefined) => {
  const [IsExpanded, setIsExpanded] = useState(false);
  useOnToolbarRefresh(() => {
    const curEditor = editor?.current?.editor;
    setIsExpanded(!!curEditor?.selection && Range.isExpanded(curEditor.selection));
  });
  return IsExpanded;
};
