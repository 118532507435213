

export const unitOptionsList = [
    {
        label: "m",
        value: "m"
    },
    {
        label: "km",
        value: "km"
    },
    {
        label: "㎡",
        value: "㎡",
    },
    {
        label: "k㎡",
        value: "k㎡",
    },
    {
        label: "h㎡",
        value: "h㎡"
    },
    {
        label: "m³",
        value: "m³"
    },
    {
        label: "km³",
        value: "km³",
    },
    {
        label: "㎡",
        value: "㎡",
    },
    {
        label: "亩",
        value: "亩"
    },
    {
        label: "把",
        value: "把"
    },
    {
        label: "部",
        value: "部"
    },
    {
        label: "册",
        value: "册"
    },
    {
        label: "场",
        value: "场"
    },
    {
        label: "处",
        value: "处"
    },
    {
        label: "次",
        value: "次"
    },
    {
        label: "份",
        value: "份"
    },
    {
        label: "个",
        value: "个"
    },
    {
        label: "块",
        value: "块"
    },
    {
        label: "辆",
        value: "辆"
    },
    {
        label: "年",
        value: "年"
    },
    {
        label: "台",
        value: "台"
    },
    {
        label: "套",
        value: "套"
    },
    {
        label: "条",
        value: "条"
    },
    {
        label: "项",
        value: "项"
    },
    {
        label: "张",
        value: "张"
    },
    {
        label: "只",
        value: "只"
    },
    {
        label: "组",
        value: "组"
    },
    {
        label: "座",
        value: "座"
    },
]