import { getChineseNumber } from "../../../statistical-analysis-temp/src/utils";
import { TypeMergeCellBound, TableDataCell, XAxisType, YAxisType } from "../../../types";
import { deepCloneV2, deepCopy, defaultExcelCellStyleConfig, fontSizeTemplateList, isEmpty, isNumber, tableCellDefaultBlockStyle } from "../../../utils";
import { CellStyleConfig, CellStyleNameType, DocConfig, ExcelCellData, FrozenInfo, InlineStyleType, SheetSelection, TopicType } from "../../../utils/types";

const xAxis: Array<string> = [];

const EnCode = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
]

const getXAxisData = (offset: number): XAxisType[] => {
    offset = Number(offset);
    let axisData: Array<XAxisType> = [];
    if (offset <= 0) {
        return axisData;
    };
    for (let i = 0; i < offset; i++) {
        const lastIndex = i % EnCode.length;
        const deepth = Math.floor(i / EnCode.length);
        if (deepth == 0) {
            axisData.push({
                lable: EnCode[lastIndex],
                columnWidth: 72,
            });
        } else if (deepth <= 25) {
            axisData.push({
                lable: EnCode[deepth - 1] + EnCode[lastIndex],
                columnWidth: 72,
            });
        }

    };
    return axisData;
};

const getYAxisData = (offset: number): YAxisType[] => {
    offset = Number(offset);
    let axisData: Array<YAxisType> = [];
    for (let i = 0; i < offset; i++) {
        let yAxisItem: YAxisType = {
            id: i + 1,
            pid: '',
            isExpand: false,
            lable: i + 1,
            rowHeight: 36
        }
        axisData.push(yAxisItem);
    };
    return axisData;
}

/**
 * 检查行是否展开
 */
// const checkRowIsExpand = ()

const getTableDataCell = (columnIndex: number, rowIndex: number, tableData: TableDataCell[]): TableDataCell => {
    try {
        // const excelCellId = columnIndex + '-' + rowIndex;
        //@ts-ignore
        let tableDataCell: TableDataCell = null;
        const findTableDataCell = tableData.find(cell => {
            return cell.bound[0] == columnIndex && cell.bound[1] == rowIndex;
        })
        if (findTableDataCell) {
            tableDataCell = findTableDataCell;
        }
        return tableDataCell;
    } catch (e) {
        //@ts-ignore
        return null;
    }
};

const getMergeCellBoundInfo = (
    columnIndex: number,
    rowIndex: number,
    mergeCellBoundGroup: TypeMergeCellBound[],
    xAxisList: XAxisType[],
    yAxisList: YAxisType[]
): TypeMergeCellBound => {
    try {
        const excelCellId = columnIndex + '-' + rowIndex;
        //@ts-ignore
        let mergeCellBound: TypeMergeCellBound = null;
        const findMergeCellBound = mergeCellBoundGroup.find(cell => {
            return cell.id == excelCellId;
        })
        if (findMergeCellBound) {
            mergeCellBound = findMergeCellBound;
            const columnIndexList = [findMergeCellBound?.bounds[0][0], findMergeCellBound?.bounds[1][0]];
            const rowIndexList = [findMergeCellBound?.bounds[0][1], findMergeCellBound?.bounds[1][1]];
            columnIndexList.sort((a, b) => {
                return a - b;
            })
            rowIndexList.sort((a, b) => {
                return a - b;
            })
            let offsetX = 0;
            xAxisList.forEach((xAxisItem, xAxisItemIndex) => {
                if (xAxisItemIndex >= columnIndexList[0] && xAxisItemIndex <= columnIndexList[1]) {
                    offsetX += xAxisItem.columnWidth;
                }
            })
            let offsetY = 0;
            yAxisList.forEach((yAxisItem, yAxisItemIndex) => {
                if (yAxisItemIndex >= rowIndexList[0] && yAxisItemIndex <= rowIndexList[1]) {
                    offsetY += yAxisItem.rowHeight;
                }
            })
            mergeCellBound.offsetX = offsetX - 1;
            mergeCellBound.offsetY = offsetY - 1;
        }
        // if (mergeCellBound) {
        //     console.log("mergeCellBound结果", mergeCellBound)
        // }
        return deepCopy(mergeCellBound);
        return null
    } catch (e) {
        //@ts-ignore
        return null;
    }
};

//这是切换类型
const convertCellGroupByRowToTopicObject1 = (
    cellGroupByRow: TableDataCell[],
    topicList: TopicType[],
    docConfig: DocConfig
): TopicType[] => {
    let needToUpdateTopicList: TopicType[] = [];
    // console.log("组装topic数据---->", cellGroupByRow)
    const { docBaseConfigInfo: { docMoneyCardinalNumber } } = docConfig;
    let topicObject: TopicType = topicList.find(topic => {
        return topic.id == cellGroupByRow[0].id;
    })
    // console.log("topicObject--->", topicObject)
    if (topicObject.topicType == 'text') {
        let _topicObject = topicList.find(topic => {
            return topic.pid == topicObject.id && topic.topicType == 'device' && topic.checked;
        })
        if (_topicObject) {
            _topicObject.topicName = cellGroupByRow[1].text;
            _topicObject.unit = cellGroupByRow[2].text;
            _topicObject.count = cellGroupByRow[3].text;
            _topicObject.unitPrice = (cellGroupByRow[4].text / Number(docMoneyCardinalNumber)).toString();
            _topicObject.investmentCompositionMoney = cellGroupByRow[3].text * Number(_topicObject.unitPrice) / Number(docMoneyCardinalNumber);
            if (Number(cellGroupByRow[6].text) > 0) {
                //建安工程
                _topicObject.investmentCompositionType = 'buildingInstallation';
            } else if (Number(cellGroupByRow[7].text) > 0) {
                //设备
                _topicObject.investmentCompositionType = 'device';
            } else if (Number(cellGroupByRow[8].text) > 0) {
                //其他
                _topicObject.investmentCompositionType = 'other';
            }
            if (Boolean(cellGroupByRow[11]) == true) {
                _topicObject.isMainProject = true;
            }
            if (!isEmpty(cellGroupByRow[12].text)) {
                _topicObject.remark = cellGroupByRow[12].text;
            }
            needToUpdateTopicList.push(_topicObject);
        }

    } else {
        topicObject.unit = cellGroupByRow[2].text;
        topicObject.count = cellGroupByRow[3].text;
        topicObject.unitPrice = (cellGroupByRow[4].text / Number(docMoneyCardinalNumber)).toString();
        topicObject.investmentCompositionMoney = cellGroupByRow[3].text * Number(topicObject.unitPrice) / Number(docMoneyCardinalNumber);
        if (Number(cellGroupByRow[6].text) > 0) {
            //建安工程
            topicObject.investmentCompositionType = 'buildingInstallation';
        } else if (Number(cellGroupByRow[7].text) > 0) {
            //设备
            topicObject.investmentCompositionType = 'device';
        } else if (Number(cellGroupByRow[8].text) > 0) {
            //其他
            topicObject.investmentCompositionType = 'other';
        }
        if (Boolean(cellGroupByRow[11]) == true) {
            topicObject.isMainProject = true;
        }
        if (!isEmpty(cellGroupByRow[12].text)) {
            topicObject.remark = cellGroupByRow[12].text;
        }

    }
    topicObject.topicName = cellGroupByRow[1].text;

    needToUpdateTopicList.push(topicObject);

    return needToUpdateTopicList;
}

//这里是中长期
const convertCellGroupByRowToTopicObject2 = (
    cellGroupByRow: TableDataCell[], topicList: TopicType[]
): TopicType[] => {
    let needToUpdateTopicList: TopicType[] = [];
    let topicObject: TopicType = topicList.find(topic => {
        return topic.id == cellGroupByRow[0].id;
    })
    console.log("topicObject--->", topicObject)
    if (topicObject.topicType == 'text') {
        let _topicObject = topicList.find(topic => {
            return topic.pid == topicObject.id && topic.topicType == 'device' && topic.checked && topic.topicName == topicObject.topicName;
        })
        if (_topicObject) {
            _topicObject.nearFutureMoneyRate = parsePriceForExcel(Number(cellGroupByRow[9].text / _topicObject.investmentCompositionMoney) * 100);
            _topicObject.mediumAndLongTermMoneyRate = parsePriceForExcel(Number(cellGroupByRow[10].text / _topicObject.investmentCompositionMoney) * 100);
            _topicObject.nearFutureMoneyRateOfValue = cellGroupByRow[9].text;
            _topicObject.mediumAndLongTermMoneyRateOfValue = cellGroupByRow[10].text;
            needToUpdateTopicList.push(_topicObject);
        }
    } else {
        topicObject.nearFutureMoneyRate = parsePriceForExcel(Number(cellGroupByRow[9].text / topicObject.investmentCompositionMoney) * 100);
        topicObject.mediumAndLongTermMoneyRate = parsePriceForExcel(Number(cellGroupByRow[10].text / topicObject.investmentCompositionMoney) * 100);
        topicObject.nearFutureMoneyRateOfValue = cellGroupByRow[9].text;
        topicObject.mediumAndLongTermMoneyRateOfValue = cellGroupByRow[10].text;
    }
    needToUpdateTopicList.push(topicObject);
    return needToUpdateTopicList;
}

const getCellGroupByColumnTotalValue = (cellList: TableDataCell[]): number => {
    let totalValue = 0;
    cellList.forEach(ele => {
        if (ele.text) {
            totalValue += Number(ele.text)
        }
    })
    return totalValue;
}

const addLastTotalRowListToTableData = (cellDataGroupRow: TableDataCell[], startY: number, topPid: string) => {
    cellDataGroupRow = deepCopy(cellDataGroupRow);
    let cellGroupByColumn5: TableDataCell[] = [];  //投资金额
    let cellGroupByColumn6: TableDataCell[] = [];  //建安工程
    let cellGroupByColumn7: TableDataCell[] = [];  //设备
    let cellGroupByColumn8: TableDataCell[] = [];  //其他
    let cellGroupByColumn9: TableDataCell[] = [];  //近期
    let cellGroupByColumn10: TableDataCell[] = []; //中长期
    cellDataGroupRow.forEach(cell => {
        if (cell.bound[0] == 5 && cell.isDeviceTopic) {
            cellGroupByColumn5.push(cell)
        }
        if (cell.bound[0] == 6 && cell.isDeviceTopic) {
            cellGroupByColumn6.push(cell)
        }
        if (cell.bound[0] == 7 && cell.isDeviceTopic) {
            cellGroupByColumn7.push(cell)
        }
        if (cell.bound[0] == 8 && cell.isDeviceTopic) {
            cellGroupByColumn8.push(cell)
        }
        if (cell.bound[0] == 9 && cell.isDeviceTopic) {
            cellGroupByColumn9.push(cell)
        }
        if (cell.bound[0] == 10 && cell.isDeviceTopic) {
            cellGroupByColumn10.push(cell)
        }
    })
    const totalDeviceValue5 = getCellGroupByColumnTotalValue(cellGroupByColumn5);
    const totalDeviceValue6 = getCellGroupByColumnTotalValue(cellGroupByColumn6);
    const totalDeviceValue7 = getCellGroupByColumnTotalValue(cellGroupByColumn7);
    const totalDeviceValue8 = getCellGroupByColumnTotalValue(cellGroupByColumn8);
    const totalDeviceValue9 = getCellGroupByColumnTotalValue(cellGroupByColumn9);
    const totalDeviceValue10 = getCellGroupByColumnTotalValue(cellGroupByColumn10);
    // :TableDataCell[] 
    const serialNumberForRow1 = '二';
    //其他费用
    cellDataGroupRow.push({
        id: 'extra-total-row-1',
        pid: topPid,
        serialNumber: serialNumberForRow1,
        bound: [0, startY],
        type: 'text',
        text: serialNumberForRow1,
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'extra-total-row-1',
        pid: topPid,
        serialNumber: serialNumberForRow1,
        bound: [1, startY],
        type: 'text',
        text: '其他费用',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'extra-total-row-1',
        pid: topPid,
        serialNumber: serialNumberForRow1,
        bound: [2, startY],
        type: 'text',
        text: '按照工程费用的7.0%估算',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'extra-total-row-1',
        pid: topPid,
        serialNumber: serialNumberForRow1,
        bound: [5, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue5 * 0.07),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'extra-total-row-1',
        pid: topPid,
        serialNumber: serialNumberForRow1,
        bound: [6, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue6 * 0.07),
        serviceType: 'extra-total',
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    });
    cellDataGroupRow.push({
        id: 'extra-total-row-1',
        pid: topPid,
        serialNumber: serialNumberForRow1,
        bound: [7, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue7 * 0.07),
        serviceType: 'extra-total',
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    });
    cellDataGroupRow.push({
        id: 'extra-total-row-1',
        pid: topPid,
        serialNumber: serialNumberForRow1,
        bound: [8, startY],
        type: 'text',
        // text: parsePriceForExcel(totalDeviceValue8 * 0.07),
        text: parsePriceForExcel(totalDeviceValue5 * 0.07),
        serviceType: 'extra-total',
        cellStyle: tableCellDefaultBlockStyle,
    });
    cellDataGroupRow.push({
        id: 'extra-total-row-1',
        pid: topPid,
        serialNumber: serialNumberForRow1,
        bound: [9, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue9 * 0.07),
        serviceType: 'extra-total',
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    });
    cellDataGroupRow.push({
        id: 'extra-total-row-1',
        pid: topPid,
        serialNumber: serialNumberForRow1,
        bound: [10, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue10 * 0.07),
        serviceType: 'extra-total',
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    });
    //勘查设计费用
    const serialNumberForRow2 = '二-1';
    startY++;
    cellDataGroupRow.push({
        id: 'otherExpens-1-0',
        pid: topPid,
        serialNumber: serialNumberForRow2,
        bound: [0, startY],
        type: 'text',
        text: '1',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-1-1',
        pid: topPid,
        serialNumber: serialNumberForRow2,
        bound: [1, startY],
        type: 'text',
        text: '勘查设计费',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-1-2',
        pid: topPid,
        serialNumber: serialNumberForRow2,
        bound: [2, startY],
        type: 'text',
        text: '按照工程费用的2.5%估算',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-1-5',
        pid: topPid,
        serialNumber: serialNumberForRow2,
        bound: [5, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue5 * 0.025),
        isDeviceTopic: false,
        serviceType: 'extra-total',
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-1-5',
        pid: topPid,
        serialNumber: serialNumberForRow2,
        bound: [6, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue6 * 0.025),
        isDeviceTopic: false,
        serviceType: 'extra-total',
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-1-5',
        pid: topPid,
        serialNumber: serialNumberForRow2,
        bound: [7, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue7 * 0.025),
        isDeviceTopic: false,
        serviceType: 'extra-total',
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-1-5',
        pid: topPid,
        serialNumber: serialNumberForRow2,
        bound: [8, startY],
        type: 'text',
        // text: parsePriceForExcel(totalDeviceValue8 * 0.025),
        text: parsePriceForExcel(totalDeviceValue5 * 0.025),
        isDeviceTopic: false,
        serviceType: 'extra-total',
        cellStyle: tableCellDefaultBlockStyle,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-1-5',
        pid: topPid,
        serialNumber: serialNumberForRow2,
        bound: [9, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue9 * 0.025),
        isDeviceTopic: false,
        serviceType: 'extra-total',
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-1-5',
        pid: topPid,
        serialNumber: serialNumberForRow2,
        bound: [10, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue10 * 0.025),
        isDeviceTopic: false,
        serviceType: 'extra-total',
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    //建设单位管理费
    const serialNumberForRow3 = '二-2';
    startY++;
    cellDataGroupRow.push({
        id: 'otherExpens-2-0',
        pid: topPid,
        serialNumber: serialNumberForRow3,
        bound: [0, startY],
        type: 'text',
        text: '2',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-2-1',
        pid: topPid,
        serialNumber: serialNumberForRow3,
        bound: [1, startY],
        type: 'text',
        text: '建设单位管理费',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-2-2',
        pid: topPid,
        serialNumber: serialNumberForRow3,
        bound: [2, startY],
        type: 'text',
        text: '按照工程费用的2.0%估算',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-2-5',
        pid: topPid,
        serialNumber: serialNumberForRow3,
        bound: [5, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue5 * 0.02),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-2-5',
        pid: topPid,
        serialNumber: serialNumberForRow3,
        bound: [6, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue6 * 0.02),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-2-5',
        pid: topPid,
        serialNumber: serialNumberForRow3,
        bound: [7, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue7 * 0.02),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-2-5',
        pid: topPid,
        serialNumber: serialNumberForRow3,
        bound: [8, startY],
        type: 'text',
        // text: parsePriceForExcel(totalDeviceValue8 * 0.02),
        text: parsePriceForExcel(totalDeviceValue5 * 0.02),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-2-5',
        pid: topPid,
        serialNumber: serialNumberForRow3,
        bound: [9, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue9 * 0.02),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-2-5',
        pid: topPid,
        serialNumber: serialNumberForRow3,
        bound: [10, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue10 * 0.02),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    //工程监理费
    const serialNumberForRow4 = '二-3';
    startY++;
    cellDataGroupRow.push({
        id: 'otherExpens-3-0',
        pid: topPid,
        serialNumber: serialNumberForRow4,
        bound: [0, startY],
        type: 'text',
        text: 3,
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-3-1',
        pid: topPid,
        serialNumber: serialNumberForRow4,
        bound: [1, startY],
        type: 'text',
        text: '工程监理费',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-4-2',
        pid: topPid,
        serialNumber: serialNumberForRow4,
        bound: [2, startY],
        type: 'text',
        text: '按照工程费用的2.0%估算',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-4-5',
        pid: topPid,
        serialNumber: serialNumberForRow4,
        bound: [5, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue5 * 0.025),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-4-5',
        pid: topPid,
        serialNumber: serialNumberForRow4,
        bound: [6, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue6 * 0.025),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-4-5',
        pid: topPid,
        serialNumber: serialNumberForRow4,
        bound: [7, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue7 * 0.025),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-4-5',
        pid: topPid,
        serialNumber: serialNumberForRow4,
        bound: [8, startY],
        type: 'text',
        // text: parsePriceForExcel(totalDeviceValue8 * 0.025),
        text: parsePriceForExcel(totalDeviceValue5 * 0.025),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-4-5',
        pid: topPid,
        serialNumber: serialNumberForRow4,
        bound: [9, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue9 * 0.025),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-4-5',
        pid: topPid,
        serialNumber: serialNumberForRow4,
        bound: [10, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue10 * 0.025),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    //招标费
    const serialNumberForRow5 = '二-4';
    startY++;
    cellDataGroupRow.push({
        id: 'otherExpens-5-0',
        pid: topPid,
        serialNumber: serialNumberForRow5,
        bound: [0, startY],
        type: 'text',
        text: 4,
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-5-1',
        pid: topPid,
        serialNumber: serialNumberForRow5,
        bound: [1, startY],
        type: 'text',
        text: '招标费',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-5-2',
        pid: topPid,
        serialNumber: serialNumberForRow5,
        bound: [2, startY],
        type: 'text',
        text: '按照工程费用的0.5%估算',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-5-5',
        pid: topPid,
        serialNumber: serialNumberForRow5,
        bound: [5, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue5 * 0.005),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-5-5',
        pid: topPid,
        serialNumber: serialNumberForRow5,
        bound: [6, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue6 * 0.005),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-5-5',
        pid: topPid,
        serialNumber: serialNumberForRow5,
        bound: [7, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue7 * 0.005),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-5-5',
        pid: topPid,
        serialNumber: serialNumberForRow5,
        bound: [8, startY],
        type: 'text',
        // text: parsePriceForExcel(totalDeviceValue8 * 0.005),
        text: parsePriceForExcel(totalDeviceValue5 * 0.005),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-5-5',
        pid: topPid,
        serialNumber: serialNumberForRow5,
        bound: [9, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue9 * 0.005),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-5-5',
        pid: topPid,
        serialNumber: serialNumberForRow5,
        bound: [10, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue10 * 0.005),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    //基本预备费
    const serialNumberForRow6 = '三';
    startY++;
    cellDataGroupRow.push({
        id: 'otherExpens-6-0',
        pid: topPid,
        serialNumber: serialNumberForRow6,
        bound: [0, startY],
        type: 'text',
        text: serialNumberForRow6,
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-7-1',
        pid: topPid,
        serialNumber: serialNumberForRow6,
        bound: [1, startY],
        type: 'text',
        text: '基本预备费',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-7-2',
        pid: topPid,
        serialNumber: serialNumberForRow6,
        bound: [2, startY],
        type: 'text',
        text: '按照工程费用的5.0%估算',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-7-5',
        pid: topPid,
        serialNumber: serialNumberForRow6,
        bound: [5, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue5 * 0.05),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-7-5',
        pid: topPid,
        serialNumber: serialNumberForRow6,
        bound: [6, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue6 * 0.05),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-7-5',
        pid: topPid,
        serialNumber: serialNumberForRow6,
        bound: [7, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue7 * 0.05),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-7-5',
        pid: topPid,
        serialNumber: serialNumberForRow6,
        bound: [8, startY],
        type: 'text',
        // text: parsePriceForExcel(totalDeviceValue8 * 0.05),
        text: parsePriceForExcel(totalDeviceValue5 * 0.05),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-7-5',
        pid: topPid,
        serialNumber: serialNumberForRow6,
        bound: [9, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue9 * 0.05),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    cellDataGroupRow.push({
        id: 'otherExpenses-7-5',
        pid: topPid,
        serialNumber: serialNumberForRow6,
        bound: [10, startY],
        type: 'text',
        text: parsePriceForExcel(totalDeviceValue10 * 0.05),
        serviceType: 'extra-total',
        isDeviceTopic: false,
        cellStyle: tableCellDefaultBlockStyle,
        editble: false,
        headLess: true,
    })
    // console.log("尾部小记的处理结果", cellDataGroupRow)
    return cellDataGroupRow;
}

const getCellOffsetTop = (cellRowIndex: number, yAxisList: YAxisType[]) => {
    let totalOffsetTopValue = 0;
    yAxisList.forEach((ele, index) => {
        if (index < cellRowIndex) {
            totalOffsetTopValue += ele.rowHeight;
        }
    })
    return totalOffsetTopValue;
}

const setTextTopicCheckByTreePath = (topicList: TopicType[]) => {
    // 首先，将扁平化的数据转换为树形结构

    // 然后，使用DFS来处理这个树
    function dfs(node) {
        console.log("dfs---->", node)
        if (!node.children || node.children.length === 0) {
            if (node.type === "text") {
                node.checked = true;
                return true;
            }
            return false;
        }

        let childResult = node.children.map(dfs);
        if (node.type === "text" || childResult.includes(true)) {
            node.checked = true;
            return true;
        }
        return false;
    }

    // 对树的每一个顶层节点进行处理
    for (let i = 0; i < topicList.length; i++) {
        dfs(topicList[i]);
    }
}


const convertCellStyleConfigToCellFormat = (styleType: InlineStyleType, styleValue: string | number) => {
    // console.log("convertCellStyleConfigToCellFormat--->", styleType, styleValue)
    let cellFormatAttr: CellStyleNameType = 'fc';
    let cellFormatValue: string | number = null;
    switch (styleType) {
        case 'inlineFontFamily':
            cellFormatAttr = 'ff';
            switch (styleValue) {
                case '宋体':
                    cellFormatValue = '宋体（Song）';
                    break;
                case '微软雅黑':
                    cellFormatValue = '微软雅黑';
                    break;
                case '黑体':
                    cellFormatValue = '黑体（ST Heiti）';
                    break;
                case '仿宋':
                    cellFormatValue = '仿宋（ST FangSong）';
                    break;
                case 'Times New Roman':
                    cellFormatValue = 'Times New Roman';
                    break;
                case '仿宋_GB2312':
                    cellFormatValue = '仿宋_GB2312';
                    break;
                case '方正仿宋_GBK':
                    cellFormatValue = '方正仿宋_GBK';
                    break;
                case '方正小标宋_GBK':
                    cellFormatValue = '方正小标宋_GBK';
                    break;
                case '方正楷体_GBK':
                    cellFormatValue = '方正楷体_GBK';
                    break;
                case '方正楷体简体':
                    cellFormatValue = '方正楷体简体';
                    break;
                case '方正黑体_GBK':
                    cellFormatValue = '方正黑体_GBK';
                    break;
                case '等线体':
                    cellFormatValue = '等线体';
                    break;
                default:
                    cellFormatValue = '宋体（Song）';
                    break;
            }
            break;
        case 'inlineFontSize':
            cellFormatAttr = 'fs';
            const findFontSizeOption = fontSizeTemplateList.find(option => {
                return option.label == styleValue
            })
            if (findFontSizeOption) {
                cellFormatValue = findFontSizeOption.pt;
            }
            break;
        case 'inlineFontBold':
            cellFormatAttr = 'bl';
            cellFormatValue = styleValue ? 1 : 0
            break;
        case 'inlineFontItalic':
            cellFormatAttr = 'it';
            cellFormatValue = styleValue ? 1 : 0
            break;
        case 'inlineFontDeleteLine':
            cellFormatAttr = 'cl';
            cellFormatValue = styleValue ? 1 : 0
            break;
        case 'inlineFontUnderLine':
            cellFormatAttr = 'un';
            cellFormatValue = styleValue ? 1 : 0
            break;
        case 'inlineFontColor':
            cellFormatAttr = 'fc';
            cellFormatValue = styleValue ? styleValue : `#000000`;
            break;
        case 'inlineBackgroundColor':
            cellFormatAttr = 'bg';
            cellFormatValue = styleValue ? styleValue : null;
            break;
        case 'cellTextWrapMode':
            cellFormatAttr = 'tb';
            cellFormatValue = styleValue;
            break;
        case 'cellVerticalAlign':
            cellFormatAttr = 'vt';
            cellFormatValue = 0;
            switch (styleValue) {
                case 'top':
                    cellFormatValue = 1;
                    break;
                case 'center':
                    cellFormatValue = 0;
                    break;
                case 'bottom':
                    cellFormatValue = 2;
                    break;
                default:
                    break;
            }
            break;
        case 'cellHorizontalAlign':
            cellFormatAttr = 'ht';
            cellFormatValue = 0;
            switch (styleValue) {
                case 'left':
                    cellFormatValue = 1;
                    break;
                case 'center':
                    cellFormatValue = 0;
                    break;
                case 'right':
                    cellFormatValue = 2;
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
    return {
        cellFormatAttr,
        cellFormatValue
    }
}

const parsePriceForExcel = (value: any) => {
    let price = Number(Number(value).toFixed(6));
    if (!Number.isNaN(price)) {
        return Number(Number(value).toFixed(6))
    }
    return value;
}


const convertTableCellToExcelCell = (
    tableData: TableDataCell[],
    excelMergeCellBoundGroud: TypeMergeCellBound[],
    comDocConfig: DocConfig
): {
    excelCellDataList: ExcelCellData[],
    dataVerificationMap: any
} => {
    let cellDataList: ExcelCellData[] = [];
    const { cellConfigList, docBaseConfigInfo: { decimalNumber = 0.01 } } = comDocConfig;
    const decimalNumberCount = decimalNumber.toString().split('.')[1] ? decimalNumber.toString().split('.')[1].length : 0;
    let dataVerificationMap = {};
    tableData.push({ bound: [2, 4], type: 'text', text: '' });
    tableData.push({ bound: [3, 4], type: 'text', text: '' });
    tableData.push({ bound: [4, 4], type: 'text', text: '' });
    tableData.push({ bound: [11, 3], type: 'text', text: '' });
    tableData.push({ bound: [11, 4], type: 'text', text: '' });
    tableData.forEach(cell => {
        let cellText = cell.text;
        if (
            cell.bound[0] >= 2 && cell.bound[0] <= 10 &&
            //数量和单价列不用保留小数位数
            (cell.bound[0] !== 3 && cell.bound[0] !== 4)
        ) {
            if (!Number.isNaN(Number(cellText)) && Number(cellText) != 0) {
                cellText = filldecimalNumber(cellText, decimalNumberCount)
            }
        }
        if (cellText == '0') {
            cellText = '';
        }
        if (cell.isDeviceTopic) {
            // console.log("原始的cell---->设备单元格", cell)
            const columnIndex = cell.bound[0];
            if (
                columnIndex == 6 ||
                columnIndex == 7 ||
                columnIndex == 8
            ) {
                // dataVerificationMap[`${cell.bound[1]}_${cell.bound[0]}`] = {
                //     type: "dropdown",
                //     type2: null,
                //     value1: cell.dropDownValueList.toString(),
                //     checked: false,
                //     remote: false,
                //     prohibitInput: false,
                //     hintShow: false,
                //     hintText: ""
                // }
            } else if (columnIndex == 11) {
                // console.log("设定重点工程勾选------>", cell)
                // dataVerificationMap[`${cell.bound[1]}_${cell.bound[0]}`] = {
                //     type: "checkbox",
                //     type2: null,
                //     value1: "是",
                //     value2: "否",
                //     checked: cell.isChecked ? true : false,
                //     remote: false,
                //     prohibitInput: false,
                //     hintShow: false,
                //     hintText: ""
                // }
                if (cell.isChecked) {
                    cellText = '是'
                } else {
                    cellText = '否'
                }
            }
        }
        const findCellStyleConfig = cellConfigList.find(cellConfigItem => {
            return cellConfigItem.cellId == `${cell.id}-${cell.bound[0]}`
        })
        let cellBg = '#f7f8f9';
        const notAllowEditCellIndexList = [0, 5];
        let excelCell: ExcelCellData = {
            r: cell.bound[1],
            c: cell.bound[0],
            v: {
                v: cellText,
                ff: '宋体（Song）',
                fc: '#000000',
                vt: 0,
                ht: 0,
                fs: 12,
            },
            m: cellText,
            ct: {
                fa: 'General'
            },
        };
        if (notAllowEditCellIndexList.includes(cell.bound[0])) {
            excelCell.v.bg = cellBg;
        }
        if (cell.bound[1] < 4) {
            excelCell.v.bg = cellBg;
        }
        if (cell.text === '标桩') {
            console.log("cell------>", cell)
        }
        if(cell.id === '660a580a57a0600ec236fedc'){
            console.log("cell------>111", cell)
        }
        if (
            cell.type == 'text'
        ) {
            excelCell.v.bg = cellBg;
        }
        if (cell.bound[0] == 0 &&
            cell.bound[1] >= 4
        ) {
            // console.log("设置样式---->", cell)
            excelCell.v.ht = '1';
        }
        // if (cell.valueType == 'number') {
        //     // excelCell.ct.t = 'n';
        // };
        if (findCellStyleConfig) {
            // console.log("findCellStyleConfig--->", findCellStyleConfig)
            const { keys, values } = Object;
            const keyList = keys(findCellStyleConfig);
            const valueList = values(findCellStyleConfig);
            // console.log("keyList--->", keyList, valueList)
            keyList.forEach((key, index) => {
                const {
                    cellFormatAttr,
                    cellFormatValue
                    //@ts-ignore
                } = convertCellStyleConfigToCellFormat(key, valueList[index])
                if (cellFormatAttr !== null && cellFormatValue !== null) {
                    excelCell.v[cellFormatAttr] = cellFormatValue;
                }
            })
        }
        cellDataList.push(excelCell);
    })
    excelMergeCellBoundGroud.forEach(mergeBound => {
        for (let c = mergeBound.bounds[0][0]; c < mergeBound.bounds[1][0] + 1; c++) {
            for (let r = mergeBound.bounds[0][1]; r < mergeBound.bounds[1][1] + 1; r++) {
                const findExcelCell = cellDataList.find((cell) => {
                    return cell.r == r && cell.c == c;
                })
                if (findExcelCell) {
                    if (
                        findExcelCell.r == mergeBound.focusCellbound[1] &&
                        findExcelCell.c == mergeBound.focusCellbound[0]
                    ) {
                        //@ts-ignore
                        findExcelCell.v.mc = {
                            r: mergeBound.focusCellbound[1], //主单元格的行号
                            c: mergeBound.focusCellbound[0], //主单元格的列号
                            rs: (mergeBound.bounds[1][1] - mergeBound.bounds[0][1]) + 1,
                            cs: (mergeBound.bounds[1][0] - mergeBound.bounds[0][0]) + 1//合并单元格占的列数
                        }
                    } else {
                        findExcelCell.v.mc = {
                            r: mergeBound.focusCellbound[1], //主单元格的行号
                            c: mergeBound.focusCellbound[0], //主单元格的列号
                        }
                    }
                } else {
                    let emptyExcelCell: ExcelCellData = {
                        r: r,
                        c: c,
                        v: {
                            v: '',
                            ff: '宋体（Song）',
                            fc: '#000000',
                            vt: 0,
                            ht: 0
                        },
                        m: '',
                        ct: {
                            fa: '0.00',
                            t: 'n'
                        },
                    };
                    if (
                        r == mergeBound.focusCellbound[1] &&
                        c == mergeBound.focusCellbound[0]
                    ) {
                        emptyExcelCell.v.mc = {
                            r: mergeBound.focusCellbound[1], //主单元格的行号
                            c: mergeBound.focusCellbound[0], //主单元格的列号
                            rs: (mergeBound.bounds[1][1] - mergeBound.bounds[0][1]) + 1,
                            cs: (mergeBound.bounds[1][0] - mergeBound.bounds[0][0]) + 1//合并单元格占的列数
                        }
                    } else {
                        emptyExcelCell.v.mc = {
                            r: mergeBound.focusCellbound[1], //主单元格的行号
                            c: mergeBound.focusCellbound[0], //主单元格的列号
                        }
                    }
                    cellDataList.push(emptyExcelCell);
                }
            }
        }
    });
    const newCellDataList = cellDataList.map(cell => {
        if (cell.c === 0 && cell.r > 3) {
            let newCellText = cell.m;
            if (cell.m && isNumber(cell.m) && !cell.m.toString().includes('.')) {
                newCellText = `（${getChineseNumber(Number(cell.m))}）`;
            } else if (cell.m.toString().includes('.')) {
                // console.log("cell---->2", cell)
                newCellText = cell.m.toString().replace(/^\d+\./, '');
            }
            cell.m = newCellText;
            cell.v.m = newCellText;
            cell.v.v = newCellText;

        }
        return cell;
    })
    return {
        // excelCellDataList: cellDataList,
        excelCellDataList: newCellDataList,
        dataVerificationMap: dataVerificationMap
    };
}

const getTwoExcellCellListDiffInfo = (
    originCellDataList: ExcelCellData[],
    newCellDataList: ExcelCellData[]
): {
    needClearCellDataList: ExcelCellData[],
    needAddCellDataList: ExcelCellData[]
} => {
    const needClearCellDataList: ExcelCellData[] = [];
    const immutableCellDataList: ExcelCellData[] = [];
    const needAddCellDataList: ExcelCellData[] = [];
    originCellDataList.forEach(originCell => {
        const findExistInNewCellDataList = newCellDataList.find(newCell => {
            return (
                originCell.r == newCell.r &&
                originCell.c == newCell.c &&
                originCell.m == newCell.m
            )
        })
        //在新的cellDataList里面对不上了
        if (!findExistInNewCellDataList) {
            needClearCellDataList.push(originCell);
        } else {
            immutableCellDataList.push(originCell)
        }
    })
    newCellDataList.forEach(newCell => {
        const findExistInOriginCellDataList = immutableCellDataList.find(originCell => {
            return (
                originCell.r == newCell.r &&
                originCell.c == newCell.c &&
                originCell.m == newCell.m
            )
        })
        if (!findExistInOriginCellDataList) {
            needAddCellDataList.push(newCell)
        }
    })
    return {
        needClearCellDataList,
        needAddCellDataList
    }
}

/**
 * 根据原始单元格获取表格数据
 * @param tableData 
 * @param excelCells 
 * @returns 
 */
const findTableDataCellListByExcelCells = (
    tableData: TableDataCell[],
    excelCells: ExcelCellData[]
): TableDataCell[] => {
    let findTableDataCellList: TableDataCell[] = [];
    tableData.forEach(tableCell => {
        const findExcelCell = excelCells.find(excelCell => {
            return excelCell.r == tableCell.bound[1] && excelCell.c == tableCell.bound[0]
        })
        // console.log("findExcelCell--->", findExcelCell)
        if (findExcelCell) {
            if (findExcelCell.v.ff) {

            }
            if (findExcelCell.v.fc) {

            }
            if (findExcelCell.v.fs) {

            }
            if (findExcelCell.v.ht) {

            }
            if (findExcelCell.v.it) {

            }
            if (findExcelCell.v.tb) {

            }
            if (findExcelCell.v.un) {

            }
            if (findExcelCell.v.vt) {

            }
            findTableDataCellList.push(tableCell);
        }
    })
    return findTableDataCellList;
}


/**
 * 根据选区获取原始单元格
 * @param cellData 
 * @param selections 
 * @returns 
 */
const getCellsBySelection = (
    cellData: ExcelCellData[],
    selections: SheetSelection[]
): ExcelCellData[] => {
    let range = {
        column: selections[0]['column'],
        row: selections[0]['row']
    }
    let cellDataInSelection: ExcelCellData[] = [];
    cellDataInSelection = cellData.filter(cell => {
        return (
            cell.c >= range.column[0] &&
            cell.c <= range.column[1] &&
            cell.r >= range.row[0] &&
            cell.r <= range.row[1]
        )
    })
    return cellDataInSelection;
}

const getExcelCellMaxRowIndex = (excelCellDataList: ExcelCellData[]) => {
    const sortedList = excelCellDataList.sort((a, b) => {
        return a.r - b.r;
    })
    // console.log("sortedList--->", sortedList)
    return sortedList[sortedList.length - 1].r;
}

const generateSheetRangeGridCellData = (originCellDataList: ExcelCellData[]): ExcelCellData[][] => {
    const gridCellData: ExcelCellData[][] = [];
    originCellDataList.forEach(cell => {
        if (cell.r > 2) {
            if (gridCellData.length == 0) {
                gridCellData.push([cell]);
            } else {
                const currentCellGroup = gridCellData[gridCellData.length - 1];
                if (currentCellGroup[0].r == cell.r) {
                    gridCellData[gridCellData.length - 1].push(cell)
                } else {
                    gridCellData.push([cell]);
                }
            }
        }
    })
    return gridCellData;
}

const convertNewTableDataToGridTableData = (newTableData: TableDataCell[]): TableDataCell[][] => {
    const gridTableData: TableDataCell[][] = [];
    let maxRowIndex = 0;
    newTableData.forEach((cell, cellIndex) => {
        if (cell.bound[1] > maxRowIndex) {
            maxRowIndex = cell.bound[1]
        }
    })
    for (let i = 0; i < maxRowIndex + 1; i++) {
        gridTableData.push(new Array(11).fill(null))
    }
    newTableData.forEach((cell, cellIndex) => {
        if (
            gridTableData[cell.bound[1]] &&
            gridTableData[cell.bound[1]][cell.bound[0]] == null
        ) {
            gridTableData[cell.bound[1]][cell.bound[0]] = cell;
        }
    })
    return gridTableData;
}

const filldecimalNumber = (cellText: string, decimalPlaces: number): string => {
    let cellTextResult = Number(cellText).toFixed(decimalPlaces);
    cellTextResult = cellTextResult.toString();
    // 检查是否有小数点
    let dotIndex = cellTextResult.indexOf('.');
    if (dotIndex === -1) {
        // 没有小数点，直接添加小数点和零
        cellTextResult += '.' + '0'.repeat(decimalPlaces);
    } else {
        // 有小数点，检查小数位数
        let decimalPartLength = cellTextResult.length - dotIndex - 1;
        if (decimalPartLength < decimalPlaces) {
            // 小数位数不足，补零
            cellTextResult += '0'.repeat(decimalPlaces - decimalPartLength);
        }
    }
    return cellTextResult;
}

const processExcelCellDataList = (cellDataList: ExcelCellData[], comDocConfig: DocConfig): ExcelCellData[] => {
    const { docBaseConfigInfo: { decimalNumber = 0.01 } } = comDocConfig;
    const decimalNumberCount = decimalNumber.toString().split('.')[1] ? decimalNumber.toString().split('.')[1].length : 0;
    let processedCellDataList = [];
    let decimalNumberFormat = '0';
    for (let i = 0; i < decimalNumberCount; i++) {
        if (i == 0) {
            decimalNumberFormat += '.';
        }
        decimalNumberFormat += '0';
    }
    const decimalNumberFormatRule = {
        fa: decimalNumberFormat,
        t: 'n'
    };
    cellDataList.forEach(cellData => {
        const currentCellValueIsNumber = isNumber(Number(cellData.m)) && cellData.c >= 2 && cellData.c !== 3 && cellData.c !== 4;
        if (currentCellValueIsNumber) {
            cellData.ct = decimalNumberFormatRule;
            if (cellData.v && !isEmpty(cellData.v.ct)) {
                cellData.v.ct = decimalNumberFormatRule;
            } else {
                cellData.v = {
                    ...cellData.v,
                    ...{
                        ct: decimalNumberFormatRule
                    }
                }
            }
        } else {
            cellData.ct = {
                fa: 'General'
            };
            if (cellData.v && cellData.v.ct) {
                cellData.v.ct = {
                    fa: 'General'
                };
            }
        }
        processedCellDataList.push(cellData)
    })
    return processedCellDataList;
}

/**
 * 查询最新的cellStyle并且合并到TableDataCellList
 * @param docConfig 
 * @param tableDataCellList 
 */
const mergeCellStyleConfigForTableDataCellList = (
    docConfig: DocConfig,
    tableDataCellList: TableDataCell[]
) => {
    try {
        let _tableDataCellList: TableDataCell[] = deepCopy(tableDataCellList);
        const {
            cellConfigList = []
        } = docConfig;
        _tableDataCellList.forEach((tableDataCell, index) => {
            const findCellConfig = cellConfigList.find(ele => {
                return ele.colIndex == tableDataCell.bound[0] && ele.rowIndex == tableDataCell.bound[1];
            })
            if (findCellConfig) {
                tableDataCell.cellStyleConfig = findCellConfig;
            } else {
                tableDataCell.cellStyleConfig = defaultExcelCellStyleConfig;
            }
        })
        console.log("_tableDataCellList--->", _tableDataCellList)
        return _tableDataCellList;
    } catch (e) {
        return tableDataCellList;
    }
}

/**
 * 合并样式
 * @param originCellStyleConfigList 
 * @param fragmentCellStyleConfigList 
 * @returns 
 */
const replaceOrAddCellStyleConfigList = (
    originCellStyleConfigList: CellStyleConfig[],
    fragmentCellStyleConfigList: CellStyleConfig[]
): CellStyleConfig[] => {
    fragmentCellStyleConfigList.forEach(cellStyleConfig => {
        let findIndex = -1;
        originCellStyleConfigList.forEach((ele, index) => {
            if (ele.rowIndex == cellStyleConfig.rowIndex && ele.colIndex == cellStyleConfig.colIndex) {
                findIndex = index;
            }
        })
        if (findIndex > -1) {
            originCellStyleConfigList[findIndex] = deepCopy(cellStyleConfig);
        } else {
            originCellStyleConfigList.push(deepCopy(cellStyleConfig))
        }
    })
    return originCellStyleConfigList;
}

export {
    EnCode,
    xAxis,
    getXAxisData,
    getYAxisData,
    getTableDataCell,
    getMergeCellBoundInfo,
    convertCellGroupByRowToTopicObject1,
    convertCellGroupByRowToTopicObject2,
    addLastTotalRowListToTableData,
    getCellOffsetTop,
    setTextTopicCheckByTreePath,
    convertTableCellToExcelCell,
    findTableDataCellListByExcelCells,
    getCellsBySelection,
    convertCellStyleConfigToCellFormat,
    getTwoExcellCellListDiffInfo,
    getExcelCellMaxRowIndex,
    generateSheetRangeGridCellData,
    convertNewTableDataToGridTableData,
    processExcelCellDataList,
    mergeCellStyleConfigForTableDataCellList,
    replaceOrAddCellStyleConfigList,
}