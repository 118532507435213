import React, { useEffect, useState } from 'react';

import { Button } from 'antd';

import { BoldConfig } from '../../../constants';
import { useMatchMark, useToggle } from '../hooks';
import { ItemProps } from '../types';
import { isEmpty } from '../../../../../utils';
import OptionBarBtn from '../../../../../components/option-bar-btn/OptionBarBtn';

/**
 * 文字加粗工具项
 */
export const BoldItem = React.memo(function BoldItem({ editor }: ItemProps) {
  const format = 'bold';
  const [value, setValue] = useState(BoldConfig.default);
  const toggle: React.MouseEventHandler<HTMLElement> = useToggle(editor, format, value);
  const mark = (useMatchMark(editor, format) as boolean) ?? BoldConfig.default;

  useEffect(() => {
    setValue(mark);
  }, [mark]);

  // return (
  //   <Button type={value ? 'primary' : 'default'} onMouseDown={toggle}>
  //     加粗
  //   </Button>
  // );
  return (
    <OptionBarBtn
      icon={<i className='iconfont-1 ghzs-gray-jiacu' style={{ fontSize: 18, fontWeight: 'bold' }} />}
      size={'small'}
      onMouseDown={toggle}
      tooltipTitle={'粗体'}
      tooltipDesc={''}
      active={value}
    />
  )
});
