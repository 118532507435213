import { forwardRef, useRef, useImperativeHandle, useState, useContext, useEffect, ChangeEvent } from "react"
import CustomPopover from "./CustomPopover";
import { Button, Form, Input, Select } from "antd";
import { ColumnWidthConfigMap, DocConfig, DocInstance, LuckySheetSelectionRange, OfficeUnit, RowHeightConfigMap, SelectOption } from "../../utils/types";
import { useForm } from "antd/lib/form/Form";
import comDocContext from './../../context/DocContext';
import { convertPx2Lb, convertToPx, deepCopy, getChangeEventValue } from "../../utils";

interface Props {
    clientX: number
    clientY: number
    onApplayExcelGridConfig: Function
}


const ExcelGridSettingPopover = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openPopover,
        closePopover
    }))

    const {
        comDocInstance,
        _setComDocInstance,
        comDocConfig,
        _setComDocConfig,
        comFocusTopicNode,
        comExcelSelectionRange
    } = useContext(comDocContext);

    const {
        clientX,
        clientY,
        onApplayExcelGridConfig
    } = props;

    const gridSizeUnitSelect: SelectOption[] = [
        {
            label: '磅',
            value: 'lb'
        },
        {
            label: '英寸',
            value: 'inch'
        },
        {
            label: '厘米',
            value: 'cm'
        },
        {
            label: '毫米',
            value: 'mm'
        }
    ];

    const [tempSelectionRange, setTempSelectionRange] = useState<LuckySheetSelectionRange>(null);

    const [form] = useForm<{ tempRowHeight: number, tempColumnWidth: number }>()
    const [formData, setFormData] = useState<{ tempRowHeight: number, tempColumnWidth: number }>({
        tempRowHeight: 0,
        tempColumnWidth: 0,
    })

    const [tempDocConfig, setTempDocConfig] = useState<DocConfig>({})

    const customSelectRef = useRef(null);

    const rowHeightInputRef = useRef(null);
    const columnWidthInputRef = useRef(null);

    useEffect(() => {
        setTempDocConfig(comDocConfig);
    }, [comDocConfig]);

    useEffect(() => {
        // console.log("接收到最新的comExcelSelectionRange--->", comExcelSelectionRange);
        if (comExcelSelectionRange) {
            const {
                rowHeight,
                columnWidth
            } = comExcelSelectionRange;
            setFormData({
                tempRowHeight: rowHeight,
                tempColumnWidth: columnWidth
            })
            form.setFieldValue('tempRowHeight', convertPx2Lb(rowHeight));
            form.setFieldValue('tempColumnWidth', convertPx2Lb(columnWidth));
            setTempSelectionRange({ ...comExcelSelectionRange });
        }
    }, [comExcelSelectionRange])

    const openPopover = () => {
        customSelectRef.current.show();
        if (tempDocConfig && tempDocConfig.docExtraConfigInfo) {
            form.setFieldValue('rowHeight', tempDocConfig && tempDocConfig.docExtraConfigInfo.rowHeight)
            form.setFieldValue('columnWidth', tempDocConfig && tempDocConfig.docExtraConfigInfo.columnWidth)
        }
    };

    const closePopover = () => {
        customSelectRef.current._handleClose();
    }

    const onColumnWidthUnitChange = (e: OfficeUnit) => {
        let _tempDocConfig = tempDocConfig;
        _tempDocConfig.docExtraConfigInfo.columnWidthUnit = e;
        setTempDocConfig({ ..._tempDocConfig });
    };

    const onRowHeightUnitChange = (e: OfficeUnit) => {
        let _tempDocConfig = tempDocConfig;
        _tempDocConfig.docExtraConfigInfo.rowHeightUnit = e;
        setTempDocConfig({ ..._tempDocConfig });
    };

    const columnWidthSelectAfter = (
        <Select
            value={tempDocConfig && tempDocConfig.docExtraConfigInfo && tempDocConfig.docExtraConfigInfo.columnWidthUnit}
            options={gridSizeUnitSelect}
            onChange={onColumnWidthUnitChange}
            disabled
            style={{ width: 72 }}
        />
    );

    const rowHeightSelectAfter = (
        <Select
            value={tempDocConfig && tempDocConfig.docExtraConfigInfo && tempDocConfig.docExtraConfigInfo.rowHeightUnit}
            options={gridSizeUnitSelect}
            onChange={onRowHeightUnitChange}
            style={{ width: 72 }}
        />
    );

    const handleSetFormData = (target: string, value: any) => {
        let _formData = formData;
        //@ts-ignore
        _formData[target] = value;
        setFormData(deepCopy(_formData));
    };

    const onTempColumnWidthTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        // let _tempDocConfig = tempDocConfig;
        // //@ts-ignore
        // _tempDocConfig.docExtraConfigInfo.columnWidth = getChangeEventValue(e);
        // setTempDocConfig({ ..._tempDocConfig });
        handleSetFormData('tempColumnWidth', getChangeEventValue(e))
    }

    const onTempRowHeightTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        // let _tempDocConfig = tempDocConfig;
        // //@ts-ignore
        // _tempDocConfig.docExtraConfigInfo.rowHeight = getChangeEventValue(e);
        // setTempDocConfig({ ..._tempDocConfig });
        handleSetFormData('tempRowHeight', getChangeEventValue(e))
    }

    const confirmApplayExcelGridConfig = () => {
        let _tempDocConfig = {...tempDocConfig};
        let columnWidthConfigCollect: ColumnWidthConfigMap = {};
        let rowHeightConfigCollect: RowHeightConfigMap = {};
        const {
            row,
            column
        } = tempSelectionRange;
        const rowIndex = row[0] || 0;
        const columnIndex = column[0] || 0;
        if (_tempDocConfig.docExtraConfigInfo && _tempDocConfig.docExtraConfigInfo.columnWidthConfigCollect) {
            columnWidthConfigCollect = _tempDocConfig.docExtraConfigInfo.columnWidthConfigCollect
        }
        if (_tempDocConfig.docExtraConfigInfo && _tempDocConfig.docExtraConfigInfo.rowHeightConfigCollect) {
            rowHeightConfigCollect = _tempDocConfig.docExtraConfigInfo.rowHeightConfigCollect
        }
        rowHeightConfigCollect[rowIndex] = {
            rowIndex: rowIndex,
            rowHeight: formData.tempRowHeight,
            rowHeightUnit: 'lb'
        }
        columnWidthConfigCollect[columnIndex] = {
            columnIndex: rowIndex,
            columnWidth: formData.tempColumnWidth,
            columnWidthUnit: 'lb'
        }
        _tempDocConfig.docExtraConfigInfo.rowHeightConfigCollect = rowHeightConfigCollect;
        _tempDocConfig.docExtraConfigInfo.columnWidthConfigCollect = columnWidthConfigCollect;
        console.log("_tempDocConfig---->", _tempDocConfig)
        _tempDocConfig.updateComponentName = 'EditPageNav';
        _setComDocConfig({..._tempDocConfig});
        closePopover();
    }

    return (
        <CustomPopover
            ref={customSelectRef}
            clientX={clientX}
            clientY={clientY}
            contentRender={(props) => (
                <div className='flex-col excel-grid-setting-popover'>
                    {
                        tempDocConfig && tempDocConfig.docExtraConfigInfo ?
                            <div className='excel-grid-setting-popover-content'>
                                <Form
                                    size={'middle'}
                                    form={form}
                                >
                                    <Form.Item
                                        name="tempColumnWidth"
                                        label="列宽"
                                    >
                                        <Input
                                            ref={columnWidthInputRef}
                                            value={tempDocConfig.docExtraConfigInfo.columnWidth}
                                            placeholder="请输入列宽"
                                            style={{ width: 175 }}
                                            onClick={() => columnWidthInputRef.current.focus()}
                                            // addonAfter={columnWidthSelectAfter}
                                            addonAfter={'磅'}
                                            type={'number'}
                                            onChange={onTempColumnWidthTextChange}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="tempRowHeight"
                                        label="行高"
                                    >
                                        <Input
                                            ref={rowHeightInputRef}
                                            value={tempDocConfig.docExtraConfigInfo.rowHeight}
                                            placeholder="请输入行高"
                                            style={{ width: 175 }}
                                            onClick={() => rowHeightInputRef.current.focus()}
                                            // addonAfter={rowHeightSelectAfter}
                                            addonAfter={'磅'}
                                            type={'number'}
                                            onChange={onTempRowHeightTextChange}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                            :
                            null
                    }
                    <div className='flex-row excel-grid-setting-popover-footer'>
                        <Button
                            size={'small'}
                            onClick={closePopover}
                        >
                            取消
                        </Button>
                        <Button
                            size={'small'}
                            type={"primary"}
                            style={{ marginLeft: 12 }}
                            onClick={confirmApplayExcelGridConfig}
                        >
                            确认
                        </Button>
                    </div>
                </div>
            )}
        />
    )
}


export default forwardRef(ExcelGridSettingPopover);