import React, { useEffect, useState } from 'react';

import { Button } from 'antd';

import { useMatchMark, useToggle } from '../hooks';
import { ItemProps } from '../types';
import OptionBarBtn from '../../../../../components/option-bar-btn/OptionBarBtn';

/**
 * 文字斜体设置工具项
 */
export const ItalicItem = React.memo(function ItalicItem({ editor }: ItemProps) {
  const format = 'italic';
  const [value, setValue] = useState(false);
  const toggle: React.MouseEventHandler<HTMLElement> = useToggle(editor, format, value);
  const mark = (useMatchMark(editor, format) as boolean) ?? false;
  useEffect(() => {
    setValue(mark);
  }, [mark]);
  // return (
  //   <Button type={value ? 'primary' : 'default'} onMouseDown={toggle}>
  //     倾斜
  //   </Button>
  // );
  return (
    <OptionBarBtn
      icon={<i className='iconfont-1 ghzs-gray-xieti1' style={{ fontSize: 18, fontWeight: '500' }} />}
      size={'small'}
      onMouseDown={toggle}
      tooltipTitle={'斜体'}
      tooltipDesc={''}
      active={value}
    />
  )
});
