import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Select } from 'antd';
import { Element as SlateElement, Transforms } from 'slate';

import { AlignConfig, AlignEnum, AlignOptions } from '../../../constants';
import { useMatchSlateItem } from '../hooks';
import { ItemProps } from '../types';
import OptionBarBtn from '../../../../../components/option-bar-btn/OptionBarBtn';
import CustomPopover from '../../../../../components/popover/CustomPopover';
import CustomSelect from '../../../../../components/select/CustomSelect';
import { SelectOption } from '../../../../../utils/types';

/**
 * 对齐工具项
 * - 段落对齐
 */
export const AlignItem = React.memo(function AlignItem({ editor, visible }: ItemProps) {
  const btnRef = useRef(null);
  const customPopoverRef = useRef(null);

  const [value, setValue] = useState(AlignConfig.default);
  const change = useCallback(
    (value: string) => {
      console.log("value---->", value)
      const curEditor = editor?.current?.editor;
      if (!curEditor) return;
      // @ts-ignore
      Transforms.setNodes<SlateElement>(curEditor, { align: value });
      setValue(value as AlignEnum);
    },
    [editor],
  );
  const slateItem = useMatchSlateItem(editor);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    // @ts-ignore
    setValue(slateItem?.align ?? (AlignConfig[slateItem?.type] || AlignConfig.default));
  }, [slateItem]);

  useEffect(() => {
    updatePosition();
  }, [visible])

  const updatePosition = () => {
    // console.log("updatePosition--->")
    if (btnRef.current) {
      const { top, left } = btnRef.current.getBoundingClientRect();
      // console.log("top--->", top)
      setPosition({ top, left });
    }
  };

  const handleClosePopover = () => {
    updatePosition();
    customPopoverRef.current._handleClose();
  }

  const openPopover = () => {
    updatePosition();
    customPopoverRef.current.show();
  }

  const getFormatIcon = (align) => {
    switch (align) {
      case 'left':
        return <div className='custom-option-bar-icon-container'>
          <i style={{ fontSize: 17 }} className='iconfont-1 ghzs-gray-juzuoduiqi' />
        </div>
        break;
      case 'center':
        return <div className='custom-option-bar-icon-container'>
          <i style={{ fontSize: 17 }} className='iconfont-1 ghzs-gray-juzhongduiqi' />
        </div>
        break;
      case 'right':
        return <div className='custom-option-bar-icon-container'>
          <i style={{ fontSize: 17 }} className='iconfont-1 ghzs-gray-juyouduiqi' />
        </div>
        break;
      case 'justify':
        return <div className='custom-option-bar-icon-container'>
          <i style={{ fontSize: 17 }} className='iconfont-1 ghzs-gray-liangduanduiqi' />
        </div>
        break;
      default:
        break;
    }
  }

  const addIcon = (options: SelectOption[]) => {
    return options.map(option => {
      option.icon = getFormatIcon(option.value)
      return option;
    })
  }
  
  const onSelectAlign = (e) => {
    console.log("e--->", e);
    change(e)

  }
  // return <Select value={`${value}`} onSelect={change} options={AlignOptions} />;
  return (
    <div
      ref={btnRef}
    >
      <OptionBarBtn
        icon={getFormatIcon(value)}
        size={'small'}
        tooltipTitle={"对齐方式"}
        tooltipDesc={'设置光标所在或选中段落的对齐方式'}
        selectEnable={true}
        onClickArrow={openPopover}
      />
      <CustomPopover
        ref={customPopoverRef}
        clientX={position.left}
        clientY={position.top + 32}
        contentRender={(props) =>
          <CustomSelect
            options={addIcon(AlignOptions)}
            value={`${value}`}
            onSelect={onSelectAlign}
            handleClose={handleClosePopover}
            selectTarget={'value'}
          />
        }
      />
    </div>
  )
});
