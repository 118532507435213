import React, { forwardRef } from 'react';
import {
    Input,
    InputProps
} from 'antd'


const AutoSelectInput = (props: InputProps, ref: any) => {
    const handleFocus = (event) => {
        event.target.select();
    };

    return (
        <Input
            {...props}
            onFocus={handleFocus}
        />
    )

}

export default forwardRef(AutoSelectInput);