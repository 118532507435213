import { useCallback } from 'react';

import { Color } from 'antd/es/color-picker';
import { Editor } from 'slate';

import { DocEditorRef } from '../../../types';

export const useChangeColor = (editor: DocEditorRef | undefined, format: string) =>
  useCallback(
    (value: Color) => {
      const curEditor = editor?.current?.editor;
      if (!curEditor) return;
      // Editor.addMark(curEditor, format, value.toHexString());
      Editor.addMark(curEditor, format, value);
    },
    [editor, format],
  );
