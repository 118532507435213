import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { Segmented, Spin, Tabs, Checkbox, Input, Select, Button, Tooltip } from 'antd';
import { BrowserRouter, Routes, Route, Link, Navigate, useNavigate } from 'react-router-dom'
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import Image from './../../../assets/image/image.svg';
import Image2 from './../../../assets/image/image-2.svg';
import Image3 from './../../../assets/image/image-3.svg';
import Image4 from './../../../assets/image/image-4.svg';
import Image5 from './../../../assets/image/image-5.svg';
import Image6 from './../../../assets/image/image-6.svg';
import { generateEcologicalBenefit, replaceLastCharWithPeriod } from '../word-editor-deprecated/WordEditorHelper';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getChangeEventValue, getCheckBoxChangeEventValue, isEmpty, isNonNegativeNumeric, toastShort } from '../../../utils';
import { TopicType, WordParagraphBlock } from '../../../utils/types';
import comDocContext from '../../../context/DocContext';
import { convertWordParagraphListToDocItemList } from '../word-editor/editor-doc-item-generate';

interface Props {
    insertDocItemByTargetTopicName: Function
}

const BenefitEvaluationContent1 = (props: Props) => {

    const {
        comDocInstance,
    } = useContext(comDocContext);

    const {
        insertDocItemByTargetTopicName
    } = props;

    const [forestArea, setForestArea] = useState('0');
    const [shrubArea, setShrubArea] = useState('0');
    const [grassArea, setGrassArea] = useState('0');
    const [wetlandArea, setWetlandArea] = useState('0');
    const [cultivatedArea, setCultivatedArea] = useState('0');
    const [cityArea, setCityArea] = useState('0');
    const [totalArea, setTotalArea] = useState('0');

    const [forestAreaPercent, setForestAreaPercent] = useState('');
    const [shrubAreaPercent, setShrubAreaPercent] = useState('');
    const [grassAreaPercent, setGrassAreaPercent] = useState('');
    const [wetlandAreaPercent, setWetlandAreaPercent] = useState('');
    const [cultivatedAreaPercent, setCultivatedAreaPercent] = useState('');
    const [cityAreaPercent, setCityAreaPercent] = useState('');

    const [sign, setSign] = useState('1');
    const [WH, setWH] = useState(false);  //水源涵养服务价值
    const [CO, setCO] = useState(false);  //固碳释氧服务价值
    const [CR, setCR] = useState(false);  //气候调节服务价值
    const [AC, setAC] = useState(false);  //空气净化服务价值
    const [WC, setWC] = useState(false);  //水质净化服务价值
    const [SC, setSC] = useState(false);  //固土保肥服务价值
    const [BD, setBD] = useState(false);  //生物多样性服务价值
    const [PN, setPN] = useState(false);  //维持养分循环服务价值
    const [generateTable, setGenerateTable] = useState(false);

    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputRef5 = useRef(null);
    const inputRef6 = useRef(null);
    const inputRef7 = useRef(null);

    const benefitEvaluation1ParagraphList: WordParagraphBlock[] = [];

    const switchGenerateTable = (e: CheckboxChangeEvent) => {
        setGenerateTable(getCheckBoxChangeEventValue(e))
    };

    const getTableColumnData = (tableData: number[][], columnIndex) => {
        let columnData: number[] = [];
        tableData.forEach(row => {
            columnData.push(row[columnIndex])
        })
        return columnData;
    }

    const getAreaName = (areaType) => {
        switch (areaType) {
            case 'forest':
                return '森林';
                break;
            case 'shrub':
                return '灌丛';
                break;
            case 'grass':
                return '草地';
                break;
            case 'wetland':
                return '湿地和水域';
                break;
            case 'cultivated':
                return '耕地';
                break;
            default:
                break;
        }
    }

    const checkConfimBtnDisabled = (): boolean => {
        let disabled = false;
        const _forestAreaPercent = Number(forestAreaPercent);
        const _shrubAreaPercent = Number(shrubAreaPercent);
        const _grassAreaPercent = Number(grassAreaPercent);
        const _wetlandAreaPercent = Number(wetlandAreaPercent);
        const _cultivatedAreaPercent = Number(cultivatedAreaPercent);
        const realTotalAreaPercent = _forestAreaPercent + _shrubAreaPercent + _grassAreaPercent + _wetlandAreaPercent;
        const _totalArea = Number(totalArea);
        if (realTotalAreaPercent == 0) {
            disabled = true;
        }
        if (_totalArea == 0) {
            disabled = true;
        }
        // const currentUseTotalArea = (Number(forestArea) + Number(shrubArea) + Number(grassArea) + Number(wetlandArea) + Number(cultivatedArea) + Number(cityArea));
        // if (currentUseTotalArea > _totalArea) {
        //     disabled = true;
        // }
        // if (_totalArea != currentUseTotalArea) {
        //     disabled = true;
        // }
        if (
            WH == false &&
            CO == false &&
            CR == false &&
            AC == false &&
            WC == false &&
            SC == false &&
            BD == false &&
            PN == false
        ) {
            disabled = true;
        }
        return disabled;
    }

    const handleGenerateEcologicalBenefit = () => {
        const _forestAreaPercent = Number(forestAreaPercent);
        const _shrubAreaPercent = Number(shrubAreaPercent);
        const _grassAreaPercent = Number(grassAreaPercent);
        const _wetlandAreaPercent = Number(wetlandAreaPercent);
        const _cultivatedAreaPercent = Number(cultivatedAreaPercent);

        const realTotalAreaPercent = _forestAreaPercent + _shrubAreaPercent + _grassAreaPercent + _wetlandAreaPercent;
        const _totalArea = Number(totalArea);
        if (realTotalAreaPercent == 0) {
            return toastShort('error', '请至少填入一项地类覆盖状况')
        }
        if (_totalArea == 0) {
            return toastShort('error', '保护地总面积不能为空')
        }
        const currentUseTotalArea = (Number(forestArea) + Number(shrubArea) + Number(grassArea) + Number(wetlandArea) + Number(cultivatedArea) + Number(cityArea));
        if (currentUseTotalArea > _totalArea) {
            return toastShort('error', '所有地类面积总和不得超出保护地总面积')
        }
        // console.log("_totalArea--->", _totalArea, currentUseTotalArea)
        if (_totalArea != currentUseTotalArea) {
            return toastShort('error', '其它地类的面积总和需要等于保护地总面积,请检查后重试！')
        }
        if (
            WH == false &&
            CO == false &&
            CR == false &&
            AC == false &&
            WC == false &&
            SC == false &&
            BD == false &&
            PN == false
        ) {
            return toastShort('error', '请至少填入一项生态系统服务指标')
        }
        const benefitResultCSV = generateEcologicalBenefit({
            sign: parseInt(sign),
            AT: Number(_totalArea),
            FP: _forestAreaPercent,
            HP: _shrubAreaPercent,
            GP: _grassAreaPercent,
            WP: _wetlandAreaPercent,
            CP: _cultivatedAreaPercent,
            WH,
            CO,
            CR,
            AC,
            WC,
            SC,
            BD,
            PN
        })
        let areaTypeList = [];
        let targetTypeList = [];
        if (_forestAreaPercent != 0) {
            areaTypeList.push('forest')
        }
        if (_shrubAreaPercent != 0) {
            areaTypeList.push('shrub')
        }
        if (_grassAreaPercent != 0) {
            areaTypeList.push('grass')
        }
        if (_wetlandAreaPercent != 0) {
            areaTypeList.push('wetland')
        }
        if (_cultivatedAreaPercent != 0) {
            areaTypeList.push('cultivated')
        }
        if (WH) {
            targetTypeList.push('WH')
        }
        if (CO) {
            targetTypeList.push('CO')
        }
        if (CR) {
            targetTypeList.push('CR')
        }
        if (AC) {
            targetTypeList.push('AC')
        }
        if (WC) {
            targetTypeList.push('WC')
        }
        if (SC) {
            targetTypeList.push('SC')
        }
        if (BD) {
            targetTypeList.push('BD')
        }
        if (PN) {
            targetTypeList.push('PN')
        }
        const {
            protectlandBaseInfo: {
                protectlandName,
            }
        } = comDocInstance;
        let totalParagraphDescText = '';
        let WHParagraphDescText = ''; //水源涵养服务价值描述文本
        let COParagraphDescText = ''; //固碳释氧服务价值描述文本
        let CRParagraphDescText = ''; //气候调节服务价值描述文本
        let ACParagraphDescText = ''; //空气净化服务价值描述文本
        let WCParagraphDescText = ''; //水质净化服务价值描述文本
        let SCParagraphDescText = ''; //固土保肥服务价值描述文本
        let BDParagraphDescText = ''; //生物多样性服务价值描述文本
        let PNParagraphDescText = ''; //维持养分循环价值描述文本
        const tableHeader1: string[] = ["生态系统服务"];
        const tableHeader2: string[] = ["总价值", "单位面积总价值"];
        //开始生成生态系统服务总价值描述
        for (let i = 0; i < benefitResultCSV[0].length; i++) {
            const currentColumnData = getTableColumnData(benefitResultCSV, i);
            const currentTargetTotalData = benefitResultCSV[0][i];
            const currentTargetUnitData = benefitResultCSV[1][i];
            if (i == benefitResultCSV[0].length - 1) {
                tableHeader1.push("保护地整体");
                let totalText = `通过测算，规划后${protectlandName}的生态服务功能总价值约为${currentColumnData[0]}万元，单位面积生态服务功能价值为${currentColumnData[1]}万元/(h㎡·a)。其中`;
                totalParagraphDescText = totalText + totalParagraphDescText;
                targetTypeList.forEach((targetType, targetTypeIndex) => {
                    switch (targetType) {
                        case 'WH':
                            if (!tableHeader2.includes('水源涵养价值')) {
                                tableHeader2.push("水源涵养价值")
                            }
                            if (!tableHeader2.includes('水源涵养单位面积价值')) {
                                tableHeader2.push("水源涵养单位面积价值")
                            }
                            WHParagraphDescText = `水源涵养指生态系统对降水的截留、吸收和贮存以及将地表水转为地表径流或地下水的作用。保护地的自然生态系统不仅具有较好的吸收降水功能，而且水分在土壤内的渗透，能对水体中的各种污染进行直接过滤和净化。可见，保护好保护区内的各种生态系统，可以增加有效水，减少无效水，起到蓄水和调节水量的作用，对涵养水源具有重要意义。通过测算，规划后${protectlandName}的水源涵养服务总价值约为${currentTargetTotalData}万元，单位面积水源涵养价值为${currentTargetUnitData}万元/(h㎡·a)。其中` + WHParagraphDescText;
                            break;
                        case 'CO':
                            if (!tableHeader2.includes('固碳释氧价值')) {
                                tableHeader2.push("固碳释氧价值")
                            }
                            if (!tableHeader2.includes('固碳释氧单位面积价值')) {
                                tableHeader2.push("固碳释氧单位面积价值")
                            }
                            COParagraphDescText = `固碳释氧指生态系统中的各种植被通过光合作用吸收二氧化碳，释放氧气，有效地维持大气中氧气和二氧化碳的比例平衡的功能。自然保护地中的植被可以看做是天然的“氧气制造工厂”和“二氧化碳净化工厂”，氧气是地球上绝大多数生物生存的基本物质条件，吸收二氧化碳能够有效降低大气中的温室气体，缓解全球变暖。通过测算，规划后${protectlandName}的固碳释氧服务总价值约为${currentTargetTotalData}万元，单位面积固碳释氧价值为${currentTargetUnitData}万元/(h㎡·a)。其中` + COParagraphDescText
                            break;
                        case 'CR':
                            if (!tableHeader2.includes('气候调节价值')) {
                                tableHeader2.push("气候调节价值")
                            }
                            if (!tableHeader2.includes('气候调节单位面积价值')) {
                                tableHeader2.push("气候调节单位面积价值")
                            }
                            CRParagraphDescText = `气候调节指生态系统调节区域气温、降水和风力等气象要素的能力，生态系统能够有效地维持区域气候的稳定，降低极端气象事件发生的概率，提高人与其他生物的环境舒适度。通过测算，规划后${protectlandName}的气候调节服务总价值约为${currentTargetTotalData}万元，单位面积气候调节价值为${currentTargetUnitData}万元/(h㎡·a)。其中` + CRParagraphDescText;
                            break;
                        case 'AC':
                            if (!tableHeader2.includes('空气净化价值')) {
                                tableHeader2.push("空气净化价值")
                            }
                            if (!tableHeader2.includes('空气净化单位面积价值')) {
                                tableHeader2.push("空气净化单位面积价值")
                            }
                            ACParagraphDescText = `空气净化指生态系统对二氧化硫、氟化物、氮氧化物、粉尘、重金属等大气污染物有吸收、过滤、阻隔和分解，以及降低噪音、提供负离子等功能。保护地分布有大面积的植被，其净化空气作用显著。此外，某些树叶的挥发性气体及根系的分泌物可以杀灭空气中的一些病源菌，对大气进行消毒，成为“天然的防疫员”。在大气污染和其它各种污染日益严重的现代社会里，生态系统改善大气质量的作用日益受到重视。通过测算，规划后${protectlandName}的空气净化服务总价值约为${currentTargetTotalData}万元，单位面积空气净化价值为${currentTargetUnitData}万元/(h㎡·a)。其中` + ACParagraphDescText;
                            break;
                        case 'WC':
                            if (!tableHeader2.includes('水质净化价值')) {
                                tableHeader2.push("水质净化价值")
                            }
                            if (!tableHeader2.includes('水质净化单位面积价值')) {
                                tableHeader2.push("水质净化单位面积价值")
                            }
                            WCParagraphDescText = `水质净化指生态系统通过生物化学反应对氮氧化物、硫氧化物、磷、COD、BOD等水体污染物吸收、过滤、阻隔和分解的作用。保护地中土壤、植被和微生物等的净化水体作用显著，在不超出生态系统承载力的情况下，生态系统能够有效地净化水体中的污染物，达到生物可利用的标准。通过测算，规划后${protectlandName}的水质净化服务总价值约为${currentTargetTotalData}万元，单位面积水质净化价值为${currentTargetUnitData}万元/(h㎡·a)。其中` + WCParagraphDescText
                            break;
                        case 'SC':
                            if (!tableHeader2.includes('固土保肥价值')) {
                                tableHeader2.push("固土保肥价值")
                            }
                            if (!tableHeader2.includes('固土保肥单位面积价值')) {
                                tableHeader2.push("固土保肥单位面积价值")
                            }
                            SCParagraphDescText = `土壤侵蚀是巨大的环境问题。它能使土壤肥力降低，土层变浅。山区土层浅薄，在强烈的雨水侵蚀下，可以在很短的时间内成为裸露的岩石地。如果覆盖有森林植被，就会减少土壤侵蚀和水土流失。保护地内森林面积大、覆盖率高、植被保存完好、林下枯枝落叶层厚、土壤渗透性强，对防止地表径流效果显著。通过测算，规划后${protectlandName}的固土保肥服务总价值约为${currentTargetTotalData}万元，单位面积固土保肥价值为${currentTargetUnitData}万元/(h㎡·a)。其中` + SCParagraphDescText;
                            break;
                        case 'BD':
                            if (!tableHeader2.includes('生物多样性价值')) {
                                tableHeader2.push("生物多样性价值")
                            }
                            if (!tableHeader2.includes('生物多样性单位面积价值')) {
                                tableHeader2.push("生物多样性单位面积价值")
                            }
                            BDParagraphDescText = `保护地内的野生动植物物种极为丰富，生态系统多样，是珍稀濒危物种重要栖息地和极为宝贵的“基因资源库”。近年来，我国生物多样性保护已从单一物种的保护转移到物种生境及生态系统的保护。保护生境是保护生物多样性最有效的方法。自然保护地是生物多样性就地保护的最佳场所，它的建立和有效管理是生物多样性保护的战略举措。通过测算，规划后${protectlandName}的生物多样性服务总价值约为${currentTargetTotalData}万元，单位面积生物多样性价值为${currentTargetUnitData}万元/(h㎡·a)。其中` + BDParagraphDescText;
                            break;
                        case 'PN':
                            if (!tableHeader2.includes('维持养分循环价值')) {
                                tableHeader2.push("维持养分循环价值")
                            }
                            if (!tableHeader2.includes('维持养分循环单位面积价值')) {
                                tableHeader2.push("维持养分循环单位面积价值")
                            }
                            PNParagraphDescText = `生态系统中植被的生长能够积累营养物质。植被在其生长过程中不断地从周围环境中吸收N、P、K等营养物质，并贮存在各器官中。植被积累营养物质的功能能够供给其他生物生存与繁殖，同时降低下游面源污染及水体富营养化，维持营养物质循环的平衡。通过测算，规划后${protectlandName}的维持养分循环服务总价值约为${currentTargetTotalData}万元，单位面积维持养分循环价值为${currentTargetUnitData}万元/(h㎡·a)。其中` + PNParagraphDescText;
                            break;
                    }
                })
            } else {
                const currentAreaType = areaTypeList[i];
                const currentAreaName = getAreaName(currentAreaType);
                tableHeader1.push(currentAreaName)
                totalParagraphDescText += `${currentAreaName}提供的生态服务功能总价值约为${currentTargetTotalData}万元，相应的单位面积生态服务功能价值为${currentTargetUnitData}万元/(h㎡·a)；`;
                targetTypeList.forEach((targetType, targetTypeIndex) => {
                    let endingSymbol = targetTypeIndex == targetTypeList.length - 1 ? '。' : '；';
                    const currentTargetTotalData = currentColumnData[targetTypeIndex + 2];
                    const currentTargetUnitData = currentColumnData[targetTypeIndex + 3];
                    switch (targetType) {
                        case 'WH':
                            WHParagraphDescText += `${currentAreaName}提供的水源涵养总价值约为${currentTargetTotalData}万元，相应的单位面积水源涵养价值为${currentTargetUnitData}万元/(h㎡·a)${endingSymbol}`
                            break;
                        case 'CO':
                            COParagraphDescText += `${currentAreaName}提供的固碳释氧总价值约为${currentTargetTotalData}万元，相应的单位面积固碳释氧价值为${currentTargetUnitData}万元/(h㎡·a)${endingSymbol}`
                            break;
                        case 'CR':
                            CRParagraphDescText += `${currentAreaName}提供的气候调节总价值约为${currentTargetTotalData}万元，相应的单位面积气候调节价值为${currentTargetUnitData}万元/(h㎡·a)${endingSymbol}`
                            break;
                        case 'AC':
                            ACParagraphDescText += `${currentAreaName}提供的空气净化总价值约为${currentTargetTotalData}万元，相应的单位面积空气净化价值为${currentTargetUnitData}万元/(h㎡·a)${endingSymbol}`
                            break;
                        case 'WC':
                            WCParagraphDescText += `${currentAreaName}提供的水质净化总价值约为${currentTargetTotalData}万元，相应的单位面积水质净化价值为${currentTargetUnitData}万元/(h㎡·a)${endingSymbol}`
                            break;
                        case 'SC':
                            SCParagraphDescText += `${currentAreaName}提供的固土保肥总价值约为${currentTargetTotalData}万元，相应的单位面积固土保肥价值为${currentTargetUnitData}万元/(h㎡·a)${endingSymbol}`
                            break;
                        case 'BD':
                            BDParagraphDescText += `${currentAreaName}提供的生物多样性总价值约为${currentTargetTotalData}万元，相应的单位面积生物多样性价值为${currentTargetUnitData}万元/(h㎡·a)${endingSymbol}`
                            break;
                        case 'PN':
                            PNParagraphDescText += `${currentAreaName}提供的维持养分循环总价值约为${currentTargetTotalData}万元，相应的单位面积维持养分循环价值为${currentTargetUnitData}万元/(h㎡·a)${endingSymbol}`
                            break;
                    }

                })
            }
        }
        let tableData: string[][] = [tableHeader1];
        tableHeader2.forEach((headerName2, headerName2Index) => {
            let rowData = [headerName2];
            tableHeader1.forEach((headerName1, headerName1Index) => {
                if (headerName1Index > 0) {
                    if (headerName1Index > 0) {
                        rowData.push(benefitResultCSV[headerName2Index][headerName1Index - 1] + '')
                    }

                }
            })
            tableData.push(rowData)
        })
        // console.log("totalParagraphDescText--->", totalParagraphDescText);
        // console.log("WHParagraphDescText--->", WHParagraphDescText);
        // console.log("COParagraphDescText--->", COParagraphDescText);
        // console.log("CRParagraphDescText--->", CRParagraphDescText);
        // console.log("ACParagraphDescText--->", ACParagraphDescText);
        // console.log("WCParagraphDescText--->", WCParagraphDescText);
        // console.log("SCParagraphDescText--->", SCParagraphDescText);
        // console.log("BDParagraphDescText--->", BDParagraphDescText);
        // console.log("PNParagraphDescText--->", PNParagraphDescText);
        let wordParagraphList: WordParagraphBlock[] = [];
        if (!isEmpty(totalParagraphDescText)) {
            wordParagraphList.push({
                type: 'text',
                paragraphType: 'custom_background_paragraph',
                text: replaceLastCharWithPeriod(totalParagraphDescText)
            })
        }
        if (!isEmpty(WHParagraphDescText)) {
            wordParagraphList.push({
                type: 'text',
                paragraphType: 'custom_background_paragraph',
                text: replaceLastCharWithPeriod(WHParagraphDescText)
            })
        }
        if (!isEmpty(COParagraphDescText)) {
            wordParagraphList.push({
                type: 'text',
                paragraphType: 'custom_background_paragraph',
                text: replaceLastCharWithPeriod(COParagraphDescText)
            })
        }
        if (!isEmpty(CRParagraphDescText)) {
            wordParagraphList.push({
                type: 'text',
                paragraphType: 'custom_background_paragraph',
                text: replaceLastCharWithPeriod(CRParagraphDescText)
            })
        }
        if (!isEmpty(ACParagraphDescText)) {
            wordParagraphList.push({
                type: 'text',
                paragraphType: 'custom_background_paragraph',
                text: replaceLastCharWithPeriod(ACParagraphDescText)
            })
        }
        if (!isEmpty(WCParagraphDescText)) {
            wordParagraphList.push({
                type: 'text',
                paragraphType: 'custom_background_paragraph',
                text: replaceLastCharWithPeriod(WCParagraphDescText)
            })
        }
        if (!isEmpty(SCParagraphDescText)) {
            wordParagraphList.push({
                type: 'text',
                paragraphType: 'custom_background_paragraph',
                text: replaceLastCharWithPeriod(SCParagraphDescText)
            })
        }
        if (!isEmpty(BDParagraphDescText)) {
            wordParagraphList.push({
                type: 'text',
                paragraphType: 'custom_background_paragraph',
                text: replaceLastCharWithPeriod(BDParagraphDescText)
            })
        }
        if (!isEmpty(PNParagraphDescText)) {
            wordParagraphList.push({
                type: 'text',
                paragraphType: 'custom_background_paragraph',
                text: replaceLastCharWithPeriod(PNParagraphDescText)
            })
        }
        if (generateTable) {
            wordParagraphList.push({
                type: 'text',
                paragraphType: 'custom_background_paragraph',
                paragraphStyle: 'table_name_paragraph',
                text: `表 ${protectlandName}生态效益`,
                tableData: tableData,
                tableName: `表  ${protectlandName}生态效益`,
                tableDesc: '单位：万元'
            })
            wordParagraphList.push({
                type: 'Table',
                paragraphType: 'custom_background_paragraph',
                text: '表格',
                tableData: tableData,
                tableName: `表  ${protectlandName}生态效益`,
                tableDesc: '单位：万元'
            })
        }
        const docItemList = convertWordParagraphListToDocItemList(wordParagraphList)
        // console.log("docItemList--->", docItemList)
        // return false;
        insertDocItemByTargetTopicName &&
            insertDocItemByTargetTopicName([{
                topicName: "生态效益",
                docItemList: docItemList
            }])
    }

    /**
     * 
     * @param total 
     * @param value 
     */
    const caclulateAreaPercent = (total: number, value: number) => {
        return (value / total * 100).toFixed(2);
    }

    useEffect(() => {

        const _forestArea = Number(forestArea);
        const _shrubArea = Number(shrubArea);
        const _grassArea = Number(grassArea);
        const _wetlandArea = Number(wetlandArea);
        const _cultivatedArea = Number(cultivatedArea);
        const _cityArea = Number(cityArea);

        const _totalArea = Number(totalArea);
        let currentUseTotalArea = Number(totalArea);

        //当前最新的数据计算得来的总数
        const _newTotalArea = _forestArea + _shrubArea + _grassArea + _wetlandArea + _cultivatedArea + _cityArea;

        if (_totalArea >= _newTotalArea) {
            //不用处理
        } else {
            currentUseTotalArea = _newTotalArea;
        }
        if (!isEmpty(totalArea) && _totalArea > 0) {
            setForestAreaPercent(caclulateAreaPercent(currentUseTotalArea, _forestArea));
            setShrubAreaPercent(caclulateAreaPercent(currentUseTotalArea, _shrubArea));
            setGrassAreaPercent(caclulateAreaPercent(currentUseTotalArea, _grassArea));
            setWetlandAreaPercent(caclulateAreaPercent(currentUseTotalArea, _wetlandArea));
            setCultivatedAreaPercent(caclulateAreaPercent(currentUseTotalArea, _cultivatedArea));
            setCityAreaPercent(caclulateAreaPercent(currentUseTotalArea, _cityArea));
        }
        // console.log("依赖收集---->")
        const {
            protectlandBaseInfo: {
                protectlandArea
            }
        } = comDocInstance;
        if (isEmpty(currentUseTotalArea) || currentUseTotalArea == 0) {
            setTotalArea(protectlandArea + '')
        } else {
            if (Number(currentUseTotalArea) > Number(protectlandArea)) {
                setTotalArea(protectlandArea + '')
                return toastShort('error', '所有地类面积总和不得超出保护地总面积')
            } else {
                setTotalArea(currentUseTotalArea.toString())
            }
        }
    }, [forestArea, shrubArea, grassArea, wetlandArea, cultivatedArea, cityArea])

    const onForestAreaTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = getChangeEventValue(e);
        value = Number(value) + '';
        if (!isNonNegativeNumeric(value)) {
            return toastShort('error', '请输入正确的数字')
        }
        setForestArea(value)
    }

    const onShrubAreaTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = getChangeEventValue(e);
        value = Number(value) + '';
        if (!isNonNegativeNumeric(value)) {
            setTotalArea(totalArea)
            return toastShort('error', '请输入正确的数字')
        }
        setShrubArea(value)
    }

    const onGrassAreaTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = getChangeEventValue(e);
        value = Number(value) + '';
        if (!isNonNegativeNumeric(value)) {
            setTotalArea(totalArea)
            return toastShort('error', '请输入正确的数字')
        }
        setGrassArea(value)
    }

    const onWetlandAreaTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = getChangeEventValue(e);
        value = Number(value) + '';
        if (!isNonNegativeNumeric(value)) {
            setTotalArea(totalArea)
            return toastShort('error', '请输入正确的数字')
        }
        setWetlandArea(value)
    }

    const onCultivatedAreaTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = getChangeEventValue(e);
        value = Number(value) + '';
        if (!isNonNegativeNumeric(value)) {
            setTotalArea(totalArea)
            return toastShort('error', '请输入正确的数字')
        }
        setCultivatedArea(value)
    }

    const onCityAreaTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = getChangeEventValue(e);
        value = Number(value) + '';
        if (!isNonNegativeNumeric(value)) {
            setTotalArea(totalArea)
            return toastShort('error', '请输入正确的数字')
        }
        setCityArea(value)
    }

    const onTotalAreaChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = getChangeEventValue(e);
        value = Number(value) + '';
        if (!isNonNegativeNumeric(value)) {
            setTotalArea(totalArea)
            return toastShort('error', '请输入正确的数字')
        }
        const _forestArea = Number(forestArea);
        const _shrubArea = Number(shrubArea);
        const _grassArea = Number(grassArea);
        const _wetlandArea = Number(wetlandArea);
        const _cultivatedArea = Number(cultivatedArea);
        const _cityArea = Number(cityArea);
        const realTotalArea = _forestArea + _shrubArea + _grassArea + _wetlandArea + _cultivatedArea + _cityArea;
        let currentUseTotalArea = Number(value);
        // if (currentUseTotalArea < realTotalArea) {
        //     return toastShort('error', '总面积不能小于已填写的各类地类总面积')
        // }
        setForestAreaPercent(caclulateAreaPercent(currentUseTotalArea, _forestArea));
        setShrubAreaPercent(caclulateAreaPercent(currentUseTotalArea, _shrubArea));
        setGrassAreaPercent(caclulateAreaPercent(currentUseTotalArea, _grassArea));
        setWetlandAreaPercent(caclulateAreaPercent(currentUseTotalArea, _wetlandArea));
        setCultivatedAreaPercent(caclulateAreaPercent(currentUseTotalArea, _cultivatedArea));
        setCityAreaPercent(caclulateAreaPercent(currentUseTotalArea, _cityArea));
        setTotalArea(value)
    }


    const onCheckedAllChange = (e: CheckboxChangeEvent) => {
        const isCheckedAll = getCheckBoxChangeEventValue(e);
        setWH(isCheckedAll);
        setCO(isCheckedAll);
        setCR(isCheckedAll);
        setAC(isCheckedAll);
        setWC(isCheckedAll);
        setSC(isCheckedAll);
        setBD(isCheckedAll);
        setPN(isCheckedAll);
    }

    const onSignTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let _sign = getChangeEventValue(e);
        if (_sign.includes('.')) {
            return toastShort('error', '请勿输入小数点')
        }
        if (Number(_sign) > 6) {
            return toastShort('error', '最多保留到小数点后6位')
        }
        setSign(_sign)
    };

    const onWHChange = (e: CheckboxChangeEvent) => {
        setWH(getCheckBoxChangeEventValue(e))
    }

    const onCOChange = (e: CheckboxChangeEvent) => {
        setCO(getCheckBoxChangeEventValue(e))
    }

    const onCRChange = (e: CheckboxChangeEvent) => {
        setCR(getCheckBoxChangeEventValue(e))
    }

    const onACChange = (e: CheckboxChangeEvent) => {
        setAC(getCheckBoxChangeEventValue(e))
    }

    const onWCChange = (e: CheckboxChangeEvent) => {
        setWC(getCheckBoxChangeEventValue(e))
    }

    const onSCChange = (e: CheckboxChangeEvent) => {
        setSC(getCheckBoxChangeEventValue(e))
    }

    const onBDChange = (e: CheckboxChangeEvent) => {
        setBD(getCheckBoxChangeEventValue(e))
    }

    const onPNChange = (e: CheckboxChangeEvent) => {
        setPN(getCheckBoxChangeEventValue(e))
    }

    const onInputFocus = (index) => {
        switch (index) {
            case 1:
                inputRef1.current.select();
                break;
            case 2:
                inputRef2.current.select();
                break;
            case 3:
                inputRef3.current.select();
                break;
            case 4:
                inputRef4.current.select();
                break;
            case 5:
                inputRef5.current.select();
                break;
            case 6:
                inputRef6.current.select();
                break;
            case 7:
                inputRef7.current.select();
                break;
            default:
                break;
        }
    }

    return (
        <div className='word-editor-plugins-content benefit-evaluation-content'>
            <div className='edit-benefit-header'>
                <div className='edit-benefit-title'>
                    保护地地表覆盖:面积（公顷）
                    <Tooltip title={'请至少填写一项地类覆盖情况，系统会根据填写的地表覆盖状况信息自动计算比例，并输出对应的报告文本。'}>
                        <QuestionCircleOutlined style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
                <div className='edit-benefit-title'>比例（%）</div>
            </div>
            <div className='edit-benefit-header'>
                <Input
                    ref={inputRef1}
                    placeholder='请输入'
                    value={forestArea}
                    style={{ width: 110, textAlign: 'center' }}
                    type={'number'}
                    onChange={onForestAreaTextChange}
                    onFocus={() => onInputFocus(1)}
                ></Input>
                <div className='edit-benefit-header-center'>
                    <div className='edit-benefit-header-font'>
                        <img className='edit-benefit-header-font-img' src={Image} />
                    </div>
                    <div className='edit-benefit-header-text'>森林</div>
                </div>
                <Input
                    placeholder='自动计算'
                    value={forestAreaPercent}
                    style={{ width: 110, textAlign: 'center' }}
                    disabled
                ></Input>
            </div>
            <div className='edit-benefit-header'>
                <Input
                    ref={inputRef2}
                    placeholder='请输入'
                    value={shrubArea}
                    onChange={onShrubAreaTextChange}
                    type={'number'}
                    onFocus={() => onInputFocus(2)}
                    style={{ width: 110, textAlign: 'center' }}
                ></Input>
                <div className='edit-benefit-header-center'>
                    <div className='edit-benefit-header-font'>
                        <img className='edit-benefit-header-font-img' src={Image2} />
                    </div>
                    <div className='edit-benefit-header-text'>灌丛</div>
                </div>
                <Input
                    placeholder='自动计算'
                    value={shrubAreaPercent}
                    style={{ width: 110, textAlign: 'center' }}
                    disabled
                ></Input>
            </div>
            <div className='edit-benefit-header'>
                <Input
                    ref={inputRef3}
                    placeholder='请输入'
                    value={grassArea}
                    onChange={onGrassAreaTextChange}
                    type={'number'}
                    onFocus={() => onInputFocus(3)}
                    style={{ width: 110, textAlign: 'center' }}
                ></Input>
                <div className='edit-benefit-header-center'>
                    <div className='edit-benefit-header-font'>
                        <img className='edit-benefit-header-font-img' src={Image3} />
                    </div>
                    <div className='edit-benefit-header-text'>草地</div>
                </div>
                <Input

                    placeholder='自动计算'
                    value={grassAreaPercent}
                    style={{ width: 110, textAlign: 'center' }}
                    disabled
                ></Input>
            </div>
            <div className='edit-benefit-header'>
                <Input
                    ref={inputRef5}
                    placeholder='请输入'
                    value={wetlandArea}
                    onChange={onWetlandAreaTextChange}
                    type={'number'}
                    onFocus={() => onInputFocus(5)}
                    style={{ width: 110, textAlign: 'center' }}
                ></Input>
                <div className='edit-benefit-header-center'>
                    <div className='edit-benefit-header-font'>
                        <img className='edit-benefit-header-font-img' src={Image4} />
                    </div>
                    <div className='edit-benefit-header-text'>水域和湿地</div>
                </div>
                <Input
                    placeholder='自动计算'
                    value={wetlandAreaPercent}
                    style={{ width: 110, textAlign: 'center' }}
                    disabled
                ></Input>
            </div>
            <div className='edit-benefit-header'>
                <Input
                    ref={inputRef6}
                    placeholder='请输入'
                    value={cultivatedArea}
                    type={'number'}
                    onChange={onCultivatedAreaTextChange}
                    onFocus={() => onInputFocus(6)}
                    style={{ width: 110, textAlign: 'center' }}
                ></Input>
                <div className='edit-benefit-header-center'>
                    <div className='edit-benefit-header-font'>
                        <img className='edit-benefit-header-font-img' src={Image5} />
                    </div>
                    <div className='edit-benefit-header-text'>耕地</div>
                </div>
                <Input
                    placeholder='自动计算'
                    value={cultivatedAreaPercent}
                    style={{ width: 110, textAlign: 'center' }}

                    disabled
                ></Input>
            </div>
            <div className='edit-benefit-header'>
                <Input
                    ref={inputRef7}
                    placeholder='请输入'
                    style={{ width: 110, textAlign: 'center' }}
                    value={cityArea}
                    type={'number'}
                    onFocus={() => onInputFocus(7)}
                    onChange={onCityAreaTextChange}
                ></Input>
                <div className='edit-benefit-header-center'>
                    <div className='edit-benefit-header-font'>
                        <img className='edit-benefit-header-font-img' src={Image6} />
                    </div>
                    <div className='edit-benefit-header-text'>城市</div>
                </div>
                <Input
                    placeholder='自动计算'
                    value={cityAreaPercent}
                    style={{ width: 110, textAlign: 'center' }}
                    disabled
                ></Input>
            </div>
            <div className='edit-benefit-header'>
                <Input
                    placeholder='请输入'
                    style={{ width: 110, textAlign: 'center' }}
                    value={totalArea}
                    type={'number'}
                    disabled
                    onChange={onTotalAreaChange}
                ></Input>
                <div className='edit-benefit-header-center'>
                    {/* <div className='edit-benefit-header-font'>
                        <img className='edit-benefit-header-font-img' src={Image6} />
                    </div> */}
                    <div className='edit-benefit-header-text' style={{ whiteSpace: 'pre-wrap' }}>{'保护地\n总面积'}</div>
                </div>
                <Input
                    placeholder='自动计算'
                    style={{ width: 110, textAlign: 'center' }}
                    value={'100'}
                    disabled
                ></Input>
            </div>
            {/* <div className='edit-benefit-header'>
                <div style={{ fontSize: 14 }} className='edit-benefit-header-text'>保护地总面积(公顷)</div>
                <Input placeholder='0~100' style={{ width: 110, textAlign: 'center' }}></Input>
            </div> */}
            <div className='edit-benefit-line'></div>
            <div className='edit-benefit-header'>
                <div className='edit-benefit-title'>生态系统服务指标</div>
                <Checkbox onChange={onCheckedAllChange}>全选</Checkbox>
            </div>
            <div className='edit-benefit-bnt-more'>
                <div
                    className='edit-benefit-bnt-item'
                // onClick={switchWHChecked}
                >
                    <Checkbox
                        className='edit-benefit-bnt-checkbox'
                        checked={AC}
                        onChange={onACChange}
                    >
                        空气净化
                    </Checkbox>
                </div>
                <div
                    className='edit-benefit-bnt-item'
                // onClick={switchCOChecked}
                >
                    <Checkbox
                        className='edit-benefit-bnt-checkbox'
                        checked={WH}
                        onChange={onWHChange}
                    >
                        水源涵养
                    </Checkbox>
                </div>
                <div
                    className='edit-benefit-bnt-item'
                // onClick={switchCRChecked}
                >
                    <Checkbox
                        key={'CO'}
                        className='edit-benefit-bnt-checkbox'
                        checked={CO}
                        onChange={onCOChange}
                    >
                        固碳释氧
                    </Checkbox>

                </div>
                <div
                    className='edit-benefit-bnt-item'
                // onClick={switchACChecked}
                >
                    <Checkbox
                        key={'CR'}
                        className='edit-benefit-bnt-checkbox'
                        checked={CR}
                        onChange={onCRChange}
                    >
                        气候调节
                    </Checkbox>
                </div>
                <div
                    className='edit-benefit-bnt-item'
                // onClick={switchWCChecked}
                >
                    <Checkbox
                        className='edit-benefit-bnt-checkbox'
                        checked={WC}
                        onChange={onWCChange}
                    >
                        水质净化
                    </Checkbox>
                </div>
                <div
                    className='edit-benefit-bnt-item'
                // onClick={switchSCChecked}
                >
                    <Checkbox
                        className='edit-benefit-bnt-checkbox'
                        checked={SC}
                        onChange={onSCChange}
                    >
                        固土保肥
                    </Checkbox>
                </div>
                <div
                    className='edit-benefit-bnt-item'
                // onClick={switchBDChecked}
                >
                    <Checkbox
                        className='edit-benefit-bnt-checkbox'
                        checked={PN}
                        onChange={onPNChange}
                    >
                        维持养分营养
                    </Checkbox>
                </div>
                <div
                    className='edit-benefit-bnt-item'
                // onClick={switchPNChecked}
                >
                    <Checkbox
                        className='edit-benefit-bnt-checkbox'
                        checked={BD}
                        onChange={onBDChange}
                    >
                        生物多样性保护
                    </Checkbox>
                </div>
            </div>
            <div className='edit-benefit-header'>
                <div>
                    小数点后保留位数
                    <Input
                        placeholder='1'
                        value={sign}
                        onChange={onSignTextChange}
                        style={{ width: 32, textAlign: 'center', height: 24, margin: '0 6px' }}
                    ></Input>
                    位
                </div>
                <Checkbox
                    checked={generateTable}
                    onChange={switchGenerateTable}
                >生成表格</Checkbox>
            </div>
            <Button
                type={'primary'}
                style={{ width: '100%', marginTop: 12 }}
                onClick={handleGenerateEcologicalBenefit}
                disabled={checkConfimBtnDisabled()}
                size={'middle'}
            >确定</Button>
        </div>
    )
}

export default BenefitEvaluationContent1;