import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import ContextMenuWrapper from "../../../components/context-menu/ContextMenuWrapper";
import { RightOutlined, ApartmentOutlined, ProfileOutlined } from '@ant-design/icons';
import { Select } from "antd";
import SelectWidthToolip from "../../../components/select/SelectWidthToolip";
import { fontSizeTemplateList, inlineStyleTemplateList, ctrlKeyName, deepCopy, defaultExcelCellStyleConfig } from "../../../utils";
import OptionBarBtn from "../../../components/option-bar-btn/OptionBarBtn";
import { CellStyleConfig, DocConfig } from "../../../utils/types";

interface Props {
    onWordEditorContextMenuClose?: Function
}

const WordEditorContextMenu = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        showContextMenu,
        hideContextMenu
    }))

    const {
        onWordEditorContextMenuClose
    } = props;

    const [fontFamilyOptions, setFontFamilyOptions] = useState([...inlineStyleTemplateList]);
    const [fontSizeOptions, setFontSizeOption] = useState([...fontSizeTemplateList]);

    const contextMenuWrapperRef = useRef(null);

    const showContextMenu = (
        e: any,
        containerRect: any,
    ) => {
        const { clientX, clientY, nativeEvent: { offsetX, offsetY } } = e;
        const layoutEnvent = {
            clientX,
            clientY
        }
        const contentRect = e.target.getBoundingClientRect();
        const containerRectTop = containerRect.top;
        const containerRectLeft = containerRect.left;
        const contentReactTop = contentRect.top;
        const contentReactLeft = contentRect.left;
        layoutEnvent.clientX = offsetX + 42;
        layoutEnvent.clientY = contentReactTop - containerRectTop + offsetY;
        contextMenuWrapperRef.current.className = 'word-editor-context-menu-container show';
        const x = layoutEnvent.clientX - 0;
        const y = layoutEnvent.clientY + 24;
        contextMenuWrapperRef.current.style.top = y + 'px';
        contextMenuWrapperRef.current.style.left = x + 'px'
    }

    const hideContextMenu = () => {
        contextMenuWrapperRef.current.className = 'word-editor-context-menu-container';
        onWordEditorContextMenuClose && onWordEditorContextMenuClose();
    }

    const onClickContextMenuContainer = (e) => {
        // console.log("触发----->111")
        // e.preventDefault();
        e.stopPropagation();

    }

    const decreaseFontSize = () => {

    }

    const increaseFontSize = () => {

    }

    const [currentCellStyleConfig, setCurrentCellStyleConfig] = useState<CellStyleConfig>(deepCopy(defaultExcelCellStyleConfig));

    return (
        <div
            ref={contextMenuWrapperRef}
            className="word-editor-context-menu-container"
        >
            <div
                onMouseDown={onClickContextMenuContainer}
                onClick={onClickContextMenuContainer}
            >
                <div className="context-menu-outline word-edit-context-menu-inline-style"

                >
                    {/* <SelectWidthToolip
                        options={fontFamilyOptions}
                    /> */}
                    <div className="word-edit-context-menu-iconfont-operation">
                        <Select
                            options={fontFamilyOptions}
                            size={'small'}
                            style={{ width: 76, marginRight: 2 }}
                            // onMouseDown={onClickContextMenuContainer}
                            // onClick={onClickContextMenuContainer}
                            // onBlur={onClickContextMenuContainer}
                            // onFocus={onClickContextMenuContainer}
                            placeholder={'字体'}
                        >
                        </Select>
                        <Select
                            options={fontFamilyOptions}
                            size={'small'}
                            style={{ width: 76, marginRight: 2 }}
                            // onMouseDown={onClickContextMenuContainer}
                            // onClick={onClickContextMenuContainer}
                            // onBlur={onClickContextMenuContainer}
                            // onFocus={onClickContextMenuContainer}
                            placeholder={'字号'}
                        >
                        </Select>
                        <div className="iconfont-operation-right">
                            <OptionBarBtn
                                icon={<i className='iconfont ghzs-color-a-zu737' style={{ fontSize: 17 }} />}
                                size={'small'}
                                // tooltipTitle={`增加字号(Shift + ${ctrlKeyName} + .)`}
                                tooltipTitle={`增加字号`}
                                tooltipDesc={'增加字体的字号'}
                                onClick={decreaseFontSize}
                            />
                            <OptionBarBtn
                                icon={<i className='iconfont ghzs-color-a-mengbanzu71' style={{ fontSize: 17 }} />}
                                size={'small'}
                                // tooltipTitle={`减少字号(Shift + ${ctrlKeyName} + ,)`}
                                tooltipTitle={`减少字号`}
                                tooltipDesc={'减少字体的字号'}
                                onClick={increaseFontSize}
                            />
                            <OptionBarBtn
                                icon={(
                                    <div className='custom-option-bar-icon-container'>
                                        <i style={{ fontSize: 15, marginTop: '-2px' }} className='iconfont ghzs-color-hanggao' />
                                        <div
                                            className='custom-option-bar-icon-bgline cell-background-line'
                                            style={{ backgroundColor: currentCellStyleConfig.inlineBackgroundColor ? currentCellStyleConfig.inlineBackgroundColor : '#000' }}
                                        ></div>
                                    </div>
                                )}
                                size={'small'}
                                tooltipTitle={"行高"}
                                tooltipDesc={'点击箭头选择文字的行高'}
                                selectEnable
                            />
                        </div>
                    </div>
                    <div className="word-edit-context-menu-iconfont-operation">
                        <OptionBarBtn
                            icon={<i className='iconfont-1 ghzs-gray-jiacu' style={{ fontSize: 14, fontWeight: 'bold' }} />}
                            size={'small'}
                            // onClick={onInlineFontBoldSwitch}
                            tooltipTitle={'粗体'}
                            // tooltipDesc={'⌥ ⌘ B'}
                        // active={checkInlineTypeActive('inlineFontBold')}
                        />
                        <OptionBarBtn
                            icon={<i className='iconfont-1 ghzs-gray-xieti1' style={{ fontSize: 14, fontWeight: '500' }} />}
                            size={'small'}
                            // onClick={onInlineFontItalicSwitch}
                            tooltipTitle={'斜体'}
                            // tooltipDesc={'⌥ ⌘ I'}
                        // active={checkInlineTypeActive('inlineFontItalic')}
                        />
                        <OptionBarBtn
                            icon={(
                                <div className='custom-option-bar-icon-container'>
                                    <i className='iconfont ghzs-color-a-zu736' style={{ fontSize: 16, fontWeight: 'bold' }} />
                                    <div
                                        className='custom-option-bar-icon-underline'
                                        style={{ backgroundColor: currentCellStyleConfig.inlineFontColor }}
                                    ></div>
                                </div>
                            )}
                            size={'small'}
                            // onClick={onInlineFontUnderLineSwitch}
                            tooltipTitle={'下划线'}
                            // tooltipDesc={'⌥ ⌘ U'}
                        // active={checkInlineTypeActive('inlineFontUnderLine')}
                        />
                        <OptionBarBtn
                            icon={<i style={{ fontSize: 19 }} className='iconfont-1 ghzs-gray-xiangpica' />}
                            size={'small'}
                            tooltipTitle={"格式擦"}
                            // tooltipDesc={'Shift-Command-C'}
                            selectEnable={true}
                        />
                        <OptionBarBtn
                            icon={(
                                <div className='custom-option-bar-icon-container'>
                                    <i style={{ fontSize: 17, marginTop: '-2px' }} className='iconfont ghzs-color-a-zu743' />
                                    <div
                                        className='custom-option-bar-icon-bgline'
                                    ></div>
                                </div>
                            )}
                            size={'small'}
                            tooltipTitle={"字体颜色"}
                            tooltipDesc={'设置文字的颜色'}
                            selectEnable={true}
                        />
                        <OptionBarBtn
                            icon={(
                                <div className='custom-option-bar-icon-container'>
                                    <i style={{ fontSize: 14 }} className='iconfont-1 ghzs-gray-juzhongduiqi' />
                                </div>
                            )}
                            size={'small'}
                            tooltipTitle={"对齐方式"}
                            tooltipDesc={'设置光标所在或选中段落的对齐方式'}
                            selectEnable={true}
                        />
                        <OptionBarBtn
                            icon={<i className='iconfont-1 ghzs-gray-geshishua' style={{ fontSize: 16, fontWeight: '500' }} />}
                            size={'small'}
                            // onClick={onInlineFontItalicSwitch}
                            tooltipTitle={'格式刷'}
                            tooltipDesc={'⌥ ⌘ F'}
                        // active={checkInlineTypeActive('inlineFontItalic')}
                        />
                    </div>
                </div>
                <div className="context-menu-outline word-edit-context-menu-box"
                >
                    <div className="word-edit-context-menu-item">
                        <div className="flex-row">
                            <i className="iconfont-1 ghzs-gray-shezhimulujibie"></i>
                            <div className="word-edit-context-menu-item-text">设置目录级别(A)</div>
                        </div>
                        <RightOutlined />
                    </div>
                    <div className="word-edit-context-menu-item">
                        <div className="flex-row">
                            <i className="iconfont-1 ghzs-gray-fuzhi"></i>
                            <div className="word-edit-context-menu-item-text">复制(C)</div>
                        </div>
                        <div>⌘ C</div>
                    </div>
                    <div className="word-edit-context-menu-item">
                        <div className="flex-row">
                            <i className="iconfont-1 ghzs-gray-jianqie"></i>
                            <div className="word-edit-context-menu-item-text">剪切(T)</div>
                        </div>
                        <div>⌘ X</div>
                    </div>
                    <div className="word-edit-context-menu-item">
                        <div className="flex-row">
                            <i className="iconfont-1 ghzs-gray-niantie"></i>
                            <div className="word-edit-context-menu-item-text">粘贴</div>
                        </div>
                        <div>⌘ V</div>
                    </div>
                    <div className="word-edit-context-menu-item">
                        <div className="flex-row">
                            <i className="iconfont-1 ghzs-gray-xuanzexingniantie"></i>
                            <div className="word-edit-context-menu-item-text">选择性粘贴(S)…</div>
                        </div>
                        <div>^ ⌘ V</div>
                    </div>
                    <div className="word-edit-context-menu-line"></div>
                    <div className="word-edit-context-menu-item">
                        <div className="flex-row">
                            <i className="iconfont-1 ghzs-gray-geshishua"></i>
                            <div className="word-edit-context-menu-item-text">格式刷(F)</div>
                        </div>
                    </div>
                    <div className="word-edit-context-menu-line"></div>
                    <div className="word-edit-context-menu-item">
                        <div className="flex-row">
                            <i className="iconfont-1 ghzs-gray-ziti"></i>
                            <div className="word-edit-context-menu-item-text">字体(F)…</div>
                        </div>
                        <div>⌘ D</div>
                    </div>
                    <div className="word-edit-context-menu-item">
                        <div className="flex-row">
                            <i className="iconfont-1 ghzs-gray-duanla"></i>
                            <div className="word-edit-context-menu-item-text">段落(P)…</div>
                        </div>
                        <div>⌥ ⌘ M</div>
                    </div>
                    <div className="word-edit-context-menu-line"></div>
                    <div className="word-edit-context-menu-item">
                        <div className="flex-row">
                            <ProfileOutlined />
                            <div className="word-edit-context-menu-item-text">拉丁名填充</div>
                        </div>
                        <div>⌥ ⌘ M</div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default forwardRef(WordEditorContextMenu);