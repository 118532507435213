import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import CustomPopover from "./CustomPopover"
import { addKeyToList, commonErrorMsg, copyTextToClipboard, deepCopy, getChangeEventValue, getSingleTdTableHeight, toastShort } from "../../utils"
import systemApi from "../../api/system"
import useReSize from "../../hooks/useResize"
import { Alert, Button, Form, Input, Pagination, Space, Table, Tooltip } from "antd"
import { SpeciesInfoType } from "../../utils/types"
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons"
import { ColumnsType } from "antd/es/table"
import AutoSelectInput from "../override-antd-kit/AutoSelectInput"

interface Props {
    clientX: number
    clientY: number
}


const LatinNameQueryPopover = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openPopover
    }))

    const {
        clientX,
        clientY,
    } = props;

    const customSelectRef = useRef<any>(null);

    const openPopover = () => {
        customSelectRef.current.show();
        querySpeciesInfoList();
    };

    const tableContentRef = useRef<any>(null);
    const tableContainerSize = useReSize(tableContentRef)

    const [form] = Form.useForm<{ species_c: string; canorical_me: string; family_c: string; genus_c: string }>();
    const [queryParams, setQueryParams] = useState<{ species_c: string; canorical_me: string; family_c: string; genus_c: string }>({
        species_c: "",
        canorical_me: "",
        family_c: "",
        genus_c: ""
    });

    const [pageParams, setPageParams] = useState<{ page: number, size: number }>({
        page: 1,
        size: 20,
    });
    const [tableLoading, setTableLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [speciesInfoList, setSpeciesInfoList] = useState<SpeciesInfoType[]>([]);

    const delayTimer = useRef(null);

    const renderCellText = (cellText: string) => {
        return (
            // <Tooltip
            //     title={"点击文本即可复制至粘贴板"}
            // >
            <div
                className="link-text"
                style={{ color: '#303133' }}
                title={cellText}
                onClick={() => copyTextToClipboard(cellText || '', true)}
            >
                {cellText}
            </div>
            // </Tooltip>
        )
    }

    const columns: ColumnsType<SpeciesInfoType> = [
        {
            title: '中文名',
            dataIndex: 'species_c',
            key: 'species_c',
            width: 82,
            render: (value, record, index) => {
                return renderCellText(record['species_c'])
            }
        },
        {
            title: '拉丁名',
            dataIndex: 'canorical_me',
            key: 'canorical_me',
            width: 82,
            render: (value, record, index) => {
                return renderCellText(record['canorical_me'])
            }
        },
        {
            title: '科名',
            dataIndex: 'family_c',
            key: 'family_c',
            width: 68,
            render: (value, record, index) => {
                return renderCellText(record['family_c'])
            }
        },
        {
            title: '属名',
            dataIndex: 'genus_c',
            key: 'genus_c',
            width: 68,
            render: (value, record, index) => {
                return renderCellText(record['genus_c'])
            }
        },
    ];

    const onPaginationChange = (page: number, pageSize: number) => {
        let _pageParams = pageParams;
        _pageParams.size = pageSize;
        _pageParams.page = page;
        setPageParams(deepCopy(_pageParams));
    }

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current)
        }
    }, [])

    useEffect(() => {
        querySpeciesInfoList();
    }, [pageParams])


    const querySpeciesInfoList = () => {
        setTableLoading(true)
        let params = {
            page: pageParams.page,
            size: pageParams.size,
            likeFiled: ['species_c', 'canorical_me', 'family_c', 'genus_c'],
            params: queryParams
        }
        systemApi.getSpeciesInfoList(params)
            .then(res => {
                console.log("获取到的物种信息列表", res)
                setTableLoading(false);
                //@ts-ignore
                setTotalCount(res.data.pager.total)
                //@ts-ignore
                setSpeciesInfoList(addKeyToList(res.data.list));
            })
            .catch(err => {
                setTableLoading(false);
                toastShort("error", commonErrorMsg)
            })
    }

    const onSpeciesNameTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let _queryParams = queryParams;
        _queryParams.species_c = getChangeEventValue(e);
        setQueryParams(_queryParams)
    }

    const onGenusCTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let _queryParams = queryParams;
        _queryParams.genus_c = getChangeEventValue(e);
        setQueryParams(_queryParams)
    }

    const onCanoricalMeTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let _queryParams = queryParams;
        _queryParams.canorical_me = getChangeEventValue(e);
        setQueryParams(_queryParams)
    }


    const onFamilyCTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let _queryParams = queryParams;
        _queryParams.family_c = getChangeEventValue(e);
        setQueryParams(_queryParams)
    }

    const handleSearch = () => {
        // alert('2')
        let _pageParams = pageParams;
        _pageParams.page = 1;
        setPageParams(deepCopy(_pageParams));
    }

    const handleResetForm = () => {
        let _queryParams = {
            species_c: "",
            canorical_me: "",
            family_c: "",
            genus_c: ""
        };
        setQueryParams(_queryParams)
        delayTimer.current = setTimeout(() => {
            handleSearch();
        }, 100);
    }

    return (
        <CustomPopover
            ref={customSelectRef}
            clientX={clientX}
            clientY={clientY}
            contentRender={(props) => (
                <div className="flex-col latin-name-query-popover">
                    <Form
                        size={'small'}
                        className='edit-page-input-box'
                        labelAlign='right'
                        labelCol={{ span: 10 }}
                        form={form}
                    >
                        <Space
                            style={{ marginBottom: -6 }}
                        >
                            <Form.Item
                                label="中文名"
                                name="spcies_c"
                            >
                                <AutoSelectInput
                                    placeholder='请输入'
                                    style={{ width: '96px', fontSize: '14px' }}
                                    value={queryParams.species_c}
                                    onChange={onSpeciesNameTextChange}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="属名"
                                style={{ marginLeft: -5 }}
                                name="genus_c"
                            >
                                <AutoSelectInput
                                    placeholder='请输入'
                                    style={{ width: '96px', fontSize: '14px' }}
                                    value={queryParams.genus_c}
                                    onChange={onGenusCTextChange}
                                    allowClear
                                />
                            </Form.Item>
                        </Space>
                        <Space
                            style={{ marginBottom: -6 }}
                        >
                            <Form.Item
                                label="拉丁名"
                                name="canorical_me"
                            >
                                <AutoSelectInput
                                    placeholder='请输入'
                                    style={{ width: '96px', fontSize: '14px' }}
                                    value={queryParams.canorical_me}
                                    onChange={onCanoricalMeTextChange}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="科名"
                                style={{ marginLeft: -5 }}
                                name="family_c"
                            >
                                <AutoSelectInput
                                    placeholder='请输入'
                                    style={{ width: '96px', fontSize: '14px' }}
                                    value={queryParams.family_c}
                                    onChange={onFamilyCTextChange}
                                    allowClear
                                />
                            </Form.Item>
                        </Space>
                        <div
                            className='flex-row'
                            style={{ marginBottom: 12, width: '100%', alignItems: 'center', justifyContent: 'space-between', paddingRight: 12 }}
                        >
                            <Alert
                                type={'warning'}
                                description={'注：点击文本复制到粘贴板'}
                                style={{ padding: `1px 3px`, fontSize: 13 }}
                            />
                            <div className="flex-row">
                                <Button
                                    type='default'
                                    icon={<ReloadOutlined />}
                                    style={{ marginRight: 12 }}
                                    size={"small"}
                                    onClick={handleResetForm}
                                >重置</Button>
                                <Button
                                    type="primary"
                                    icon={<SearchOutlined />}
                                    size={"small"}
                                    onClick={handleSearch}
                                >查询</Button>
                            </div>
                        </div>
                    </Form>
                    <div
                        ref={tableContentRef}
                        className='word-editor-plugin-table-content'
                    >
                        <Table
                            className='edit-page-table'
                            pagination={false}
                            size='small'
                            dataSource={speciesInfoList}
                            columns={columns}
                            loading={tableLoading}
                            scroll={{ y: getSingleTdTableHeight(tableContainerSize.height), x: 'max-content' }}
                        />
                    </div>
                    <div className='flex-row word-editor-plugin-table-footer'>
                        <Pagination
                            total={totalCount}
                            pageSize={pageParams.size}
                            current={pageParams.page}
                            size={'small'}
                            showSizeChanger={false}
                            showQuickJumper={false}
                            showTotal={(total) => `共${total} 项`}
                            locale={{
                                items_per_page: '/页',
                                jump_to: '跳转到',
                                page: '页'
                            }}
                            onChange={onPaginationChange}
                        />
                    </div>
                </div>
            )}
        />
    )
}


export default forwardRef(LatinNameQueryPopover)
