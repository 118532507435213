import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import OptionBarBtn from "../../../components/option-bar-btn/OptionBarBtn";
import CustomPopover from "../../../components/popover/CustomPopover";
import CustomColorPicker from "../../../components/color-picker/CustomColorPicker";
import { ctrlKeyName, deepCopy, defaultExcelCellStyleConfig, findExcelStyleByCell, fontSizeTemplateList, handleStopPropagation, mergeCellConfigList, toastShort } from "../../../utils";
import { CellStyleConfig, DocConfig, InlineStyleType } from "../../../utils/types";
import { TableDataCell } from "../../../types";
import { isEmpty } from "lodash";

interface Props {
    currentCellStyleConfig?: CellStyleConfig
    onDispathCellStyle: Function
    focusTableCellList: TableDataCell[]
    updateCurrentCellStyleConfig: Function
}

const ExcelCellStyleOptionBar = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
    }))

    const {
        currentCellStyleConfig,
        updateCurrentCellStyleConfig,
        onDispathCellStyle,
        focusTableCellList
    } = props;

    const inlineFontColorSheetRef = useRef(null);
    const inlineBackgroundColorSheetRef = useRef(null);

    const tempFocusTableCellList = useRef<TableDataCell[]>([]);

    const tempDocConfig = useRef<DocConfig>(null);

    const [currentInlineFontColor, setCurrentInlineFontColor] = useState('#000000');
    const [currentInlineBackgroundColor, setCurrentInlineBackgroundColor] = useState('');

    useEffect(() => {
        setCurrentInlineFontColor(localStorage.getItem('inlineFontColor-excel') ? localStorage.getItem('inlineFontColor-excel') : '#000000');
        setCurrentInlineBackgroundColor(localStorage.getItem('inlineBackgroundColor-excel') ? localStorage.getItem('inlineBackgroundColor-excel') : '');
    }, []);

    useEffect(() => {
        tempFocusTableCellList.current = focusTableCellList;
    }, [focusTableCellList])

    const decreaseFontSize = () => {
        let findIndex = -1;
        fontSizeTemplateList.forEach((option, index) => {
            if (currentCellStyleConfig.inlineFontSize == option.label) {
                findIndex = index;
            }
        })
        if (findIndex > -1) {
            if (findIndex == fontSizeTemplateList.length - 1) {
                return toastShort('warning', '已经是最小字号')
            }
            onInlineFontSizeChange(fontSizeTemplateList[findIndex + 1].label)
        } else {
            toastShort('warning', '当前状态无法减少字号，请刷新页面后重试')
        }
    }

    const increaseFontSize = () => {
        let findIndex = -1;
        fontSizeTemplateList.forEach((option, index) => {
            if (currentCellStyleConfig.inlineFontSize == option.label) {
                findIndex = index;
            }
        })
        if (findIndex > -1) {
            if (findIndex == 0) {
                return toastShort('warning', '已经是最大字号')
            }
            console.log("findIndex---->", findIndex)
            onInlineFontSizeChange(fontSizeTemplateList[findIndex - 1].label)
        } else {
            toastShort('warning', '当前状态无法增加字号，请刷新页面后重试')
        }
    }

    const onInlineFontSizeChange = (e) => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.inlineFontSize = e;
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        dispathCellStyle('inlineFontSize', e);
    }

    //打开单元格背景色选择器
    const showCellBackgroundColorSheet = () => {
        inlineBackgroundColorSheetRef.current.show();
    }

    //打开文本颜色选择器
    const showInlineFontColorSheet = () => {
        inlineFontColorSheetRef.current.show();
    }

    const dispathCellStyle = (styleType: InlineStyleType, styleValue: number | string) => {
        console.log("dispathCellStyle--->", styleType, styleValue);
        onDispathCellStyle && onDispathCellStyle({ styleType, styleValue });
    }


    const onToggleInlineFontSuperScript = (type: 'super' | 'sub') => {
        toastShort('warning', '暂不支持')
    }

    //切换文本颜色
    const toggleInlineFontColor = () => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.inlineFontColor = currentInlineFontColor;
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        dispathCellStyle('inlineFontColor', currentInlineFontColor)
    }

    const _onPickerInlineFontColor = (e) => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.inlineFontColor = e;
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        setCurrentInlineFontColor(e);
        localStorage.setItem('inlineFontColor-excel', e);
        dispathCellStyle('inlineFontColor', e)
    }

    //切换单元格背景色
    const toggleInlineBackgroundColor = () => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.inlineBackgroundColor = currentInlineBackgroundColor;
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        dispathCellStyle('inlineBackgroundColor', currentInlineBackgroundColor)
    }

    const _onPickerInlineBackgroundColor = (e) => {
        let tempInlineStyle = currentCellStyleConfig;
        tempInlineStyle.inlineBackgroundColor = e;
        handleSetCurrentCellStyleConfig(tempInlineStyle);
        setCurrentInlineBackgroundColor(e);
        localStorage.setItem('inlineBackgroundColor-excel', e);
        dispathCellStyle('inlineBackgroundColor', e)
    }

    const handleSetCurrentCellStyleConfig = (newCellStyleConfig: CellStyleConfig) => {
        updateCurrentCellStyleConfig && updateCurrentCellStyleConfig(newCellStyleConfig);
    }

    return (
        <div
            className='edit-page-option-row-item flex-col'
            style={{ width: '96px', paddingLeft: '10px' }}
            onMouseDown={handleStopPropagation}
        >
            <div className='edit-page-option-row-half text-edit-row-1 flex-row'>
                <OptionBarBtn
                    icon={<i className='iconfont ghzs-color-a-mengbanzu71' style={{ fontSize: 20 }} />}
                    size={'small'}
                    tooltipTitle={`减少字号(Shift + ${ctrlKeyName} + ,)`}
                    tooltipDesc={'减少字体的字号'}

                    onClick={decreaseFontSize}
                />
                <OptionBarBtn
                    icon={(
                        <div className='custom-option-bar-icon-container'>
                            <i style={{ fontSize: 21, marginTop: '-2px' }} className='iconfont ghzs-color-a-bianzu31' />
                            <div
                                className='custom-option-bar-icon-bgline cell-background-line'
                                style={{ backgroundColor: currentInlineBackgroundColor }}
                            ></div>
                        </div>
                    )}
                    size={'small'}
                    onClick={toggleInlineBackgroundColor}
                    onClickArrow={showCellBackgroundColorSheet}
                    tooltipTitle={"单元格背景色"}
                    tooltipDesc={'点击箭头选择文字的背景颜色'}
                    selectEnable
                />
            </div>
            <div className='edit-page-option-row-half text-edit-row-1 flex-row'>
                <OptionBarBtn
                    icon={<i className='iconfont ghzs-color-a-zu737' style={{ fontSize: 20 }} />}
                    size={'small'}
                    tooltipTitle={`增加字号(Shift + ${ctrlKeyName} + .)`}
                    tooltipDesc={'增加字体的字号'}
                    onClick={increaseFontSize}
                />
                <OptionBarBtn
                    icon={(
                        <div className='custom-option-bar-icon-container'>
                            <i style={{ fontSize: 20, marginTop: '-2px' }} className='iconfont ghzs-color-a-zu743' />
                            <div
                                className='custom-option-bar-icon-bgline'
                                style={{ backgroundColor: currentInlineFontColor }}
                            ></div>
                        </div>
                    )}
                    size={'small'}
                    tooltipTitle={"字体颜色"}
                    tooltipDesc={'设置文字的颜色'}
                    selectEnable={true}
                    onClick={toggleInlineFontColor}
                    onClickArrow={showInlineFontColorSheet}
                />
            </div>
            <CustomPopover
                ref={inlineBackgroundColorSheetRef}
                clientX={660}
                clientY={96}
                disableBackground
                //@ts-ignore
                contentRender={(props) =>
                    <CustomColorPicker
                        {...props}
                        mode={'light'}
                        onPickerColor={_onPickerInlineBackgroundColor}
                        screenName={'单元格背景色'}
                        screenTag={"cellBgColor"}
                    />
                }
            />
            <CustomPopover
                ref={inlineFontColorSheetRef}
                clientX={660}
                clientY={129}
                disableBackground
                //@ts-ignore
                contentRender={(props) =>
                    <CustomColorPicker
                        {...props}
                        mode={'dark'}
                        onPickerColor={_onPickerInlineFontColor}
                        screenName={'文字颜色'}
                        screenTag={"fontColor"}
                    />
                }
            />
        </div>
    )
}


export default forwardRef(ExcelCellStyleOptionBar);