import { ClickToFold, Close, FullScreen } from '@icon-park/react';
import './ServiceWindow.scss';
import { useEffect, useRef, useState } from 'react';
import emitter from '../../utils/emitter';
import useReSize from '../../hooks/useResize';
import store from './../../redux/store';
import LoadingWapper from '../loading/LoadingWapper';

const ServiceWindow = () => {

    const [isOpenServiceWindow, setIsOpenServiceWindow] = useState(false);
    const [isMiniScreen, setIsMiniScreen] = useState<boolean>(false);
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const [userInfo] = useState(store.getState().systemConfig.userInfo);

    const [htmlURL, setHtmlUrl] = useState('');

    const [loading, setLoading] = useState(true);

    // const baseUrl = 'http://192.168.31.155:5174/auth/login?from=iframe&';
    // const baseUrl = 'http://192.168.31.31:5173/auth/login?from=iframe&';
    const baseUrl = 'http://chat-web.map-cloud.cn/auth/login?from=iframe&';


    useEffect(() => {
        let _htmlURL = baseUrl;
        // _htmlURL += baseUrl;
        const {
            id,
            avatar,
            nickName,
            phone
        } = userInfo;
        _htmlURL += `&userId=${id}&avatar=${avatar}&nickName=${nickName}&mobile=${phone}`;
        setHtmlUrl(_htmlURL);
        emitter.sub('openServiceWindow', () => {
            setIsOpenServiceWindow(true)
            setIsMiniScreen(false);
            setIsFullScreen(false);
        })
        return () => {
        }
    }, []);

    const handleMiniServiceWindow = () => {
        setIsMiniScreen(!isMiniScreen);
    }

    const handleFullServiceWindow = () => {
        setIsFullScreen(!isFullScreen)
    }

    const handleCloseServiceWindow = () => {
        setIsOpenServiceWindow(false)
    }

    const onIframeLoad = () => {
        setLoading(false);
    }

    return (
        isOpenServiceWindow ?
            <div
                className="flex-col service-window"
                style={{
                    display: isMiniScreen ? 'none' : 'flex',
                    width: isFullScreen ? '88vw' : '500px',
                    height: isFullScreen ? '88vh' : '78vh'
                }}
            >
                <div className='flex-row service-window-header'>
                    <div>人工客服在线咨询窗口</div>
                    <div className='flex-row service-window-header-right'>
                        <div className='service-window-header-btn' onClick={handleMiniServiceWindow}>
                            <div className='min-size-icon'></div>
                        </div>
                        <div className='service-window-header-btn' onClick={handleFullServiceWindow}>
                            <FullScreen theme="outline" size="20" fill="#303133" />
                        </div>
                        <div className='service-window-header-btn' onClick={handleCloseServiceWindow}>
                            <Close theme="outline" size="20" fill="#303133" />
                        </div>
                    </div>
                </div>
                <div className='service-window-body'>
                    <LoadingWapper loading={loading}/>
                    {
                        htmlURL && isOpenServiceWindow ?
                            <iframe
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    border: 'none'
                                }}
                                src={htmlURL}
                                onLoad={onIframeLoad}
                            />
                            :
                            null
                    }

                </div>
            </div>
            : null
    )
}


export default ServiceWindow;