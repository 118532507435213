import React, { useCallback, useEffect } from 'react';

import { SlateElementProps } from '../types';
import {
  emitSlateItemClick,
  offEditorMouseUp,
  offSlateItemClick,
  offToolbarRefresh,
  onEditorMouseUp,
  onSlateItemClick,
  onToolbarRefresh,
} from '../utils';

export const useEmitSlateItemClick = (element: SlateElementProps['element']) => {
  return useCallback(
    (e) => {
      e.stopPropagation();
      emitSlateItemClick(element);
    },
    [element],
  ) as React.MouseEventHandler<HTMLParagraphElement>;
};

export const useOnSlateItemClick = (listener: (data: SlateElementProps['element']) => void) => {
  useEffect(() => {
    onSlateItemClick(listener);
    return () => {
      offSlateItemClick(listener);
    };
  }, [listener]);
};

export const useOnToolbarRefresh = (listener: () => void) => {
  useEffect(() => {
    onToolbarRefresh(listener);
    return () => {
      offToolbarRefresh(listener);
    };
  }, [listener]);
};

export const useEditorMouseUp = (listener: () => void) => {
  useEffect(() => {
    onEditorMouseUp(listener);
    return () => offEditorMouseUp(listener);
  }, [listener]);
};
