import { CSSProperties } from "react";
import { TypeMergeCellBound, TableDataCell } from "../types";
import { DocConfig, DocInstance, TopicType, TopicUnionRecord } from "./types"
import { deepCloneV2, defaultDocBaseConfigInfo, dfsRecursiveFromInner } from ".";
import { accDivide, accMultiply } from "./MathTools";
import { getChineseNumber } from "../statistical-analysis-temp/src/utils";

//是否是叶子结点
const checkIsLeafNode = (node: TopicType) => {
    return !node.children || node.children.length === 0;
}
//是否是设备节点
const checkIsDeviceTopic = (node: TopicType): boolean => {
    return node.topicType == 'device';
}

//筛选设备节点
const filterTreeByDeviceNode = (tree: TopicType[]) => {
    return tree
        .filter((node) => {
            if (node.children) {
                node.children = filterTreeByDeviceNode(node.children);
            }
            return checkIsLeafNode(node) ? checkIsDeviceTopic(node) : node.children.length > 0;
        })
        .map((node) => ({ ...node }));
}

const _addTreePropertyForList = (originList: Array<any>): any[] => {
    let resultList = originList.map(ele => {
        ele.title = ele.name ? ele.name : ele.topicName;
        ele.key = ele.id;
        ele.pid = ele.pid ? ele.pid : 0;
        return ele;
    })
    return resultList;
}

//数组转树结构数据
const generateTreeData = (originList: Array<any>): Array<any> => {
    originList = _addTreePropertyForList(originList);
    const [map, treeData] = [{}, []];
    for (let i = 0; i < originList.length; i += 1) {
        map[originList[i].id] = i;
        originList[i].children = [];
    }
    for (let i = 0; i < originList.length; i += 1) {
        const node = originList[i];
        if (node.pid && originList[map[node.pid]]) {
            originList[map[node.pid]].children.push(node);
        } else {
            treeData.push(node);
        }
    }
    return treeData;
};

const deepCopy = (value: any) => {
    try {
        return JSON.parse(JSON.stringify(value));
    } catch (e) {
        return ''
    }
};


const dfsRecursive = (tree, callback, level = 0) => {
    for (const node of tree) {
        callback(node, level);
        if (node.children) {
            dfsRecursive(node.children, callback, level + 1);
        }
    }
};

const isEmpty = (value: any) => {
    try {
        if (value === null || value === undefined || value === "" || JSON.stringify(value) === '{}') {
            return true
        };
        return false;
    } catch (e) {
        return true;
    }
};


const parsePrice = (value: any) => {
    let price = Number(Number(value).toFixed(6));
    if (!Number.isNaN(price)) {
        return Number(Number(value).toFixed(6))
    }
    return value;
};


const tableCellDefaultBlockStyle: CSSProperties = {
};

const adaptDocMoneyCardinalNumberName = (comDocConfig: DocConfig): string => {
    try {
        if (comDocConfig && comDocConfig.docBaseConfigInfo && comDocConfig.docBaseConfigInfo.docMoneyCardinalNumber) {
            let docMoneyCardinalNumberName = '';
            const docMoneyCardinalNumber = comDocConfig.docBaseConfigInfo.docMoneyCardinalNumber;
            switch (docMoneyCardinalNumber) {
                case 1:
                    docMoneyCardinalNumberName = '万元'
                    break;
                case 10:
                    docMoneyCardinalNumberName = '千元'
                    break;
                case 10000:
                    docMoneyCardinalNumberName = '元'
                    break;
                default:
                    break;
            }
            return docMoneyCardinalNumberName;
        }
        return '未知'
    } catch (e) {
        return '未知'
    }
};


const getCellGroupByColumnTotalValue = (cellList: TableDataCell[]): number => {
    let totalValue = 0;
    cellList.forEach(ele => {
        if (ele.text) {
            totalValue += Number(ele.text)
        }
    })
    return totalValue;
}


const resetCalculatedValueForList = (tableDataByCol: TableDataCell[]): TableDataCell[] => {
    return tableDataByCol = tableDataByCol.map(item => {
        //如果不是设施设备且有pid
        if (!item.isDeviceTopic && item.pid && !item.id.includes('-')) {
            item.text = '';
        }
        return item;
    })
}

const getExtraTotalCellList = (tableData: TableDataCell[], colIndex) => {
    return tableData.filter(item => {
        return item.bound[0] == colIndex && item.serviceType == 'extra-total'
    })
}


const getTotalMoney = (list: TableDataCell[]) => {
    let total = 0;
    list.forEach(ele => {
        if (ele.text && ele.isDeviceTopic) {
            // console.log("eleesssss->", ele)
            total = total + Number(ele.text);
        }
    })
    return parsePrice(total);
}

const getExtraTotalMoney = (list: TableDataCell[]) => {
    let total = 0;
    list.forEach(ele => {
        if (ele.serialNumber == '二' || ele.serialNumber == '三') {
            total = total + Number(ele.text);
        }
    })
    return parsePrice(total);
}

const calculateTableData = (tableData: TableDataCell[]): TableDataCell[] => {
    let tempTableData = tableData;
    const investmentCompositionMoneyColIndex = 5;  //投资金额的下标
    const buildingInstallationColIndex = 6;       //建安工程的下标
    const deviceColIndex = 7;  //设施设备下标
    const otherColIndex = 8;   //其他投资构成的下标
    const nearFutureMoneyRateColIndex = 9;  //近期投资比例的下标
    const mediumAndLongTermMoneyRateColIndex = 10; //中长期投资比例的下标
    //投资金额
    const investmentCompositionMoneyColList = resetCalculatedValueForList(tempTableData.filter(cell => {
        return cell.bound[0] == investmentCompositionMoneyColIndex && cell.pid;
    }))
    const investmentCompositionMoneyExtraTotalList = getExtraTotalCellList(tempTableData, investmentCompositionMoneyColIndex);
    const buildingInstallationExtraTotalList = getExtraTotalCellList(tempTableData, buildingInstallationColIndex);
    const deviceExtraTotalList = getExtraTotalCellList(tempTableData, deviceColIndex);
    const otherExtraTotalList = getExtraTotalCellList(tempTableData, otherColIndex);
    const nearFutureMoneyRateExtraTotalList = getExtraTotalCellList(tempTableData, nearFutureMoneyRateColIndex);
    const mediumAndLongTermMoneyRateTotalList = getExtraTotalCellList(tempTableData, mediumAndLongTermMoneyRateColIndex);
    dfsRecursiveFromInner(generateTreeData(investmentCompositionMoneyColList), (node: TableDataCell) => {
        //从列表中获取到当前节点的数据
        const findNewTextValueFromList = investmentCompositionMoneyColList.find(item => {
            return item.id == node.id;
        }).text;
        for (let i = 0; i < investmentCompositionMoneyColList.length; i++) {
            if (investmentCompositionMoneyColList[i].id == node.pid) {
                investmentCompositionMoneyColList[i].text = parsePrice(Number(investmentCompositionMoneyColList[i].text) + Number(findNewTextValueFromList));
                break;
            }
        }
    })
    //建安工程
    const buildingInstallationColList = resetCalculatedValueForList(tempTableData.filter(cell => {
        return cell.bound[0] == buildingInstallationColIndex && cell.pid;
    }))
    dfsRecursiveFromInner(generateTreeData(buildingInstallationColList), (node: TableDataCell) => {
        //从列表中获取到当前节点的数据
        const findNewTextValueFromList = buildingInstallationColList.find(item => {
            return item.id == node.id;
        }).text;
        for (let i = 0; i < buildingInstallationColList.length; i++) {
            if (buildingInstallationColList[i].id == node.pid) {
                buildingInstallationColList[i].text = parsePrice(Number(buildingInstallationColList[i].text) + Number(findNewTextValueFromList));
                break;
            }
        }
    })
    //设施设备
    const deviceColList = resetCalculatedValueForList(tempTableData.filter(cell => {
        return cell.bound[0] == deviceColIndex && cell.pid;
    }))
    dfsRecursiveFromInner(generateTreeData(deviceColList), (node: TableDataCell) => {
        //从列表中获取到当前节点的数据
        const findNewTextValueFromList = deviceColList.find(item => {
            return item.id == node.id;
        }).text;
        for (let i = 0; i < deviceColList.length; i++) {
            if (deviceColList[i].id == node.pid) {
                deviceColList[i].text = parsePrice(Number(deviceColList[i].text) + Number(findNewTextValueFromList));
                break;
            }
        }
    })
    //建安工程
    const otherColList = resetCalculatedValueForList(tempTableData.filter(cell => {
        return cell.bound[0] == otherColIndex && cell.pid;
    }));
    dfsRecursiveFromInner(generateTreeData(otherColList), (node: TableDataCell) => {
        //从列表中获取到当前节点的数据
        const findNewTextValueFromList = otherColList.find(item => {
            return item.id == node.id;
        }).text;
        for (let i = 0; i < otherColList.length; i++) {
            if (otherColList[i].id == node.pid) {
                otherColList[i].text = parsePrice(Number(otherColList[i].text) + Number(findNewTextValueFromList));
                break;
            }
        }
    })
    //近期投资比例
    const nearFutureMoneyRateColList = resetCalculatedValueForList(tempTableData.filter(cell => {
        return cell.bound[0] == nearFutureMoneyRateColIndex && cell.pid;
    }))
    dfsRecursiveFromInner(generateTreeData(nearFutureMoneyRateColList), (node: TableDataCell) => {
        //从列表中获取到当前节点的数据
        const findNewTextValueFromList = nearFutureMoneyRateColList.find(item => {
            return item.id == node.id;
        }).text;
        for (let i = 0; i < nearFutureMoneyRateColList.length; i++) {
            if (nearFutureMoneyRateColList[i].id == node.pid) {
                nearFutureMoneyRateColList[i].text = parsePrice(Number(nearFutureMoneyRateColList[i].text) + Number(findNewTextValueFromList));
                break;
            }
        }
    })
    //中长期投资比例
    const mediumAndLongTermMoneyRateColList = resetCalculatedValueForList(tempTableData.filter(cell => {
        return cell.bound[0] == mediumAndLongTermMoneyRateColIndex && cell.pid;
    }))
    dfsRecursiveFromInner(generateTreeData(mediumAndLongTermMoneyRateColList), (node: TableDataCell) => {
        //从列表中获取到当前节点的数据
        const findNewTextValueFromList = mediumAndLongTermMoneyRateColList.find(item => {
            return item.id == node.id;
        }).text;
        for (let i = 0; i < mediumAndLongTermMoneyRateColList.length; i++) {
            if (mediumAndLongTermMoneyRateColList[i].id == node.pid) {
                mediumAndLongTermMoneyRateColList[i].text = parsePrice(Number(mediumAndLongTermMoneyRateColList[i].text) + Number(findNewTextValueFromList));
                break;
            }
        }
    })
    console.log("investmentCompositionMoneyColList--->", investmentCompositionMoneyColList)
    const totalInvestmentCompositionMoney = getTotalMoney(investmentCompositionMoneyColList);
    const totalBuildingInstallationMoney = getTotalMoney(buildingInstallationColList);
    const totalDeviceMoney = getTotalMoney(deviceColList);
    const totalOtherMoney = getTotalMoney(otherColList);
    const totalNearFutureMoney = getTotalMoney(nearFutureMoneyRateColList);
    const totalMediumAndLongTermMoney = getTotalMoney(mediumAndLongTermMoneyRateColList);
    tableData.forEach(cell => {
        if (cell.bound.toString() == '5,4') {
            cell.text = parsePrice(totalInvestmentCompositionMoney);
        }
        if (cell.bound.toString() == '6,4') {
            cell.text = parsePrice(totalBuildingInstallationMoney);
        }
        if (cell.bound.toString() == '7,4') {
            cell.text = parsePrice(totalDeviceMoney);
        }
        if (cell.bound.toString() == '8,4') {
            cell.text = parsePrice(totalOtherMoney);
        }
        if (cell.bound.toString() == '9,4') {
            cell.text = parsePrice(totalNearFutureMoney);
        }
        if (cell.bound.toString() == '10,4') {
            cell.text = parsePrice(totalMediumAndLongTermMoney);
        }
    })
    const extraTotalInvestmentCompositionMoney = getExtraTotalMoney(investmentCompositionMoneyExtraTotalList);
    const extraTotalBuildingInstallationMoney = getExtraTotalMoney(buildingInstallationExtraTotalList);
    const extraTotalDeviceMoney = getExtraTotalMoney(deviceExtraTotalList);
    const extraTotalOtherMoney = getExtraTotalMoney(otherExtraTotalList);
    const extraTotalNearFutureMoney = getExtraTotalMoney(nearFutureMoneyRateExtraTotalList);
    const extraTotalMediumAndLongTermMoney = getExtraTotalMoney(mediumAndLongTermMoneyRateTotalList);
    console.log("investmentCompositionMoneyExtraTotalList--->", investmentCompositionMoneyExtraTotalList)
    let investmentCompositionMoneyExtraTotal = 0;  //其它费用等等的项目总金额
    investmentCompositionMoneyExtraTotalList.forEach((ele, index) => {
        if (index > 0) {
            investmentCompositionMoneyExtraTotal += ele.text;
        }
    })
    //总投资金额计算
    tableData.forEach(cell => {
        if (cell.bound.toString() == '5,3') {
            // cell.text = parsePrice(totalInvestmentCompositionMoney + extraTotalInvestmentCompositionMoney);
            // cell.text = parsePrice(totalInvestmentCompositionMoney * 1.12);
            cell.text = parsePrice(totalInvestmentCompositionMoney + investmentCompositionMoneyExtraTotal);
            // cell.text = parsePrice(totalInvestmentCompositionMoney);
        }
        if (cell.bound.toString() == '6,3') {
            //TODO 这里要好好检查
            // cell.text = parsePrice(totalBuildingInstallationMoney + extraTotalBuildingInstallationMoney);
            // cell.text = parsePrice(totalBuildingInstallationMoney * 1.12);
            cell.text = parsePrice(totalBuildingInstallationMoney);
            // cell.text = parsePrice(totalBuildingInstallationMoney );
        }
        if (cell.bound.toString() == '7,3') {
            //TODO 这里要好好检查
            // cell.text = parsePrice(totalDeviceMoney + extraTotalDeviceMoney)
            cell.text = parsePrice(totalDeviceMoney)
            // cell.text = parsePrice(totalDeviceMoney)
        }
        if (cell.bound.toString() == '8,3') {
            //TODO 这里要好好检查
            // cell.text = parsePrice(totalOtherMoney + extraTotalOtherMoney);
            cell.text = parsePrice(totalOtherMoney);
            // cell.text = parsePrice(totalOtherMoney + extraTotalInvestmentCompositionMoney);
        }
        if (cell.bound.toString() == '9,3') {
            //TODO 这里要好好检查
            // cell.text = parsePrice(totalNearFutureMoney + extraTotalNearFutureMoney)
            cell.text = parsePrice(totalNearFutureMoney)
            // cell.text = parsePrice(totalNearFutureMoney)
        }
        if (cell.bound.toString() == '10,3') {
            //TODO 这里要好好检查
            // cell.text = parsePrice(totalMediumAndLongTermMoney + extraTotalMediumAndLongTermMoney)
            cell.text = parsePrice(totalMediumAndLongTermMoney)
            // cell.text = parsePrice(totalMediumAndLongTermMoney)
        }
    })
    return tableData;
}

const addLastTotalRowListToTableData = (
    cellDataGroupRow: TableDataCell[],
    tempComDocConfig: DocConfig,
    startY: number,
    topPid: string
) => {
    const { docBaseConfigInfo } = tempComDocConfig;
    const {
        otherExpensesItemList = defaultDocBaseConfigInfo.otherExpensesItemList,
        basicReserveExpensesList = defaultDocBaseConfigInfo.basicReserveExpensesList
    } = docBaseConfigInfo;
    cellDataGroupRow = deepCopy(cellDataGroupRow);
    let cellGroupByColumn5: TableDataCell[] = [];  //投资金额
    let cellGroupByColumn6: TableDataCell[] = [];  //建安工程
    let cellGroupByColumn7: TableDataCell[] = [];  //设备
    let cellGroupByColumn8: TableDataCell[] = [];  //其他
    let cellGroupByColumn9: TableDataCell[] = [];  //近期
    let cellGroupByColumn10: TableDataCell[] = []; //中长期
    //
    cellDataGroupRow.forEach(cell => {
        if (cell.bound[0] == 5 && cell.isDeviceTopic) {
            cellGroupByColumn5.push(cell)
        }
        if (cell.bound[0] == 6 && cell.isDeviceTopic) {
            cellGroupByColumn6.push(cell)
        }
        if (cell.bound[0] == 7 && cell.isDeviceTopic) {
            cellGroupByColumn7.push(cell)
        }
        if (cell.bound[0] == 8 && cell.isDeviceTopic) {
            cellGroupByColumn8.push(cell)
        }
        if (cell.bound[0] == 9 && cell.isDeviceTopic) {
            cellGroupByColumn9.push(cell)
        }
        if (cell.bound[0] == 10 && cell.isDeviceTopic) {
            cellGroupByColumn10.push(cell)
        }
    })
    const totalDeviceValue5 = getCellGroupByColumnTotalValue(cellGroupByColumn5);
    const totalDeviceValue6 = getCellGroupByColumnTotalValue(cellGroupByColumn6);
    const totalDeviceValue7 = getCellGroupByColumnTotalValue(cellGroupByColumn7);
    const totalDeviceValue8 = getCellGroupByColumnTotalValue(cellGroupByColumn8);
    const totalDeviceValue9 = getCellGroupByColumnTotalValue(cellGroupByColumn9);
    const totalDeviceValue10 = getCellGroupByColumnTotalValue(cellGroupByColumn10);
    let serialNumberForRow = 2;
    let serialNumberForRowChinese = getChineseNumber(serialNumberForRow);
    let realUseOtherExpensesItemList = otherExpensesItemList.filter(item => {
        return !isEmpty(item.name) && !isEmpty(item.value)
    })
    let realUseBaseExpensesItemList = basicReserveExpensesList.filter(item => {
        return !isEmpty(item.name) && !isEmpty(item.value)
    })
    let totalOfOtherExpensesValue = 0;
    realUseOtherExpensesItemList.forEach((item, index) => {
        item.value = Number(item.value);
        totalOfOtherExpensesValue += Number(item.value);
    })
    //
    let totalOfBaseExpensesValue = 0;
    realUseBaseExpensesItemList.forEach((item, index) => {
        item.value = Number(item.value);
        totalOfBaseExpensesValue += Number(item.value);
    })
    if (totalOfOtherExpensesValue) {
        realUseOtherExpensesItemList.unshift({
            name: "其他费用",
            value: totalOfOtherExpensesValue,
        })
    }
    let serialNumberForRowTag = serialNumberForRow + '-expenses-';
    realUseOtherExpensesItemList.forEach((item, index) => {
        const tempSerialNumberForRowTag = serialNumberForRowTag + index;
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [0, startY],
            type: 'text',
            text: index == 0 ? serialNumberForRowChinese : index,
            isDeviceTopic: false,
            editble: false,
        })
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [1, startY],
            type: 'text',
            text: item.name,
            isDeviceTopic: false,
            editble: false,
        })
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [2, startY],
            type: 'text',
            text: `按照工程费用的${item.value.toFixed(1)}%估算`,
            isDeviceTopic: false,
            editble: false,
        })
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [5, startY],
            type: 'text',
            text: parsePrice(totalDeviceValue5 * item.value / 100),
            serviceType: 'extra-total',
            isDeviceTopic: false,
            editble: false,
            headLess: true,
        })
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [6, startY],
            type: 'text',
            text: 0,
            serviceType: 'extra-total',
            editble: false,
            headLess: true,
        });
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [7, startY],
            type: 'text',
            text: 0,
            serviceType: 'extra-total',
            editble: false,
            headLess: true,
        });
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [8, startY],
            type: 'text',
            text: parsePrice(totalDeviceValue5 * item.value / 100),
            serviceType: 'extra-total',
        });
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [9, startY],
            type: 'text',
            text: parsePrice(totalDeviceValue5 * item.value / 100 * 0.6),
            serviceType: 'extra-total',
            editble: false,
            headLess: false,
        });
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [10, startY],
            type: 'text',
            text: parsePrice(totalDeviceValue5 * item.value / 100 * 0.4),
            serviceType: 'extra-total',
            editble: false,
            headLess: false,
        });
        startY++;
    })
    console.log("totalDeviceValue5--->", totalDeviceValue5)
    if (realUseOtherExpensesItemList.length) {
        serialNumberForRow++;
    }
    serialNumberForRowChinese = getChineseNumber(serialNumberForRow);
    serialNumberForRowTag = serialNumberForRow + '-expenses-';
    realUseBaseExpensesItemList.forEach((item, index) => {
        const tempSerialNumberForRowTag = serialNumberForRowTag + index;
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [0, startY],
            type: 'text',
            text: index == 0 ? serialNumberForRowChinese : index,
            isDeviceTopic: false,
            editble: false,
        })
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [1, startY],
            type: 'text',
            text: item.name,
            isDeviceTopic: false,
            editble: false,
        })
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [2, startY],
            type: 'text',
            text: `按照工程费用的${item.value.toFixed(1)}%估算`,
            isDeviceTopic: false,
            editble: false,
        })
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [5, startY],
            type: 'text',
            text: parsePrice(totalDeviceValue5 * item.value / 100),
            serviceType: 'extra-total',
            isDeviceTopic: false,
            editble: false,
            headLess: true,
        })
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [6, startY],
            type: 'text',
            text: 0,
            serviceType: 'extra-total',
            editble: false,
            headLess: true,
        });
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [7, startY],
            type: 'text',
            text: 0,
            serviceType: 'extra-total',
            editble: false,
            headLess: true,
        });
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [8, startY],
            type: 'text',
            text: parsePrice(totalDeviceValue5 * item.value / 100),
            serviceType: 'extra-total',
        });
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [9, startY],
            type: 'text',
            text: parsePrice(totalDeviceValue5 * item.value / 100 * 0.6),
            serviceType: 'extra-total',
            editble: false,
            headLess: false,
        });
        cellDataGroupRow.push({
            id: 'extra-total-row-1',
            pid: topPid,
            serialNumber: tempSerialNumberForRowTag,
            bound: [10, startY],
            type: 'text',
            text: parsePrice(totalDeviceValue5 * item.value / 100 * 0.4),
            serviceType: 'extra-total',
            editble: false,
            headLess: false,
        });
        startY++;
    })
    return cellDataGroupRow;
}

//
const generateTableContentData = (
    rowDataList: TopicType[],
    deviceTreeData: TopicType[],
    tempComDocConfig: DocConfig
) => {
    let cellDataGroupRow: TableDataCell[] = [];
    let startY = 4;
    let serialNumberList = [];
    // const topPid = deviceTreeData[0].pid;
    const topPid = '0';
    const docMoneyCardinalNumber = tempComDocConfig.docBaseConfigInfo && tempComDocConfig.docBaseConfigInfo && tempComDocConfig.docBaseConfigInfo.docMoneyCardinalNumber;
    const serialNumberForEngineeringConstructionRow = '一';
    cellDataGroupRow.push({
        id: 'engineering-construction-cost-0',
        pid: topPid,
        serialNumber: serialNumberForEngineeringConstructionRow,
        bound: [0, startY],
        type: 'text',
        text: serialNumberForEngineeringConstructionRow,
        isDeviceTopic: false,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'engineering-construction-cost-1',
        pid: topPid,
        serialNumber: serialNumberForEngineeringConstructionRow,
        bound: [1, startY],
        type: 'text',
        text: '工程建设费用',
        isDeviceTopic: false,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'engineering-construction-cost-2',
        pid: topPid,
        serialNumber: serialNumberForEngineeringConstructionRow,
        bound: [5, startY],
        type: 'text',
        text: '',
        isDeviceTopic: false,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'engineering-construction-cost-3',
        pid: topPid,
        serialNumber: serialNumberForEngineeringConstructionRow,
        bound: [6, startY],
        type: 'text',
        text: '',
        isDeviceTopic: false,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'engineering-construction-cost-4',
        pid: topPid,
        serialNumber: serialNumberForEngineeringConstructionRow,
        bound: [7, startY],
        type: 'text',
        text: '',
        isDeviceTopic: false,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'engineering-construction-cost-5',
        pid: topPid,
        serialNumber: serialNumberForEngineeringConstructionRow,
        bound: [8, startY],
        type: 'text',
        text: '',
        isDeviceTopic: false,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'engineering-construction-cost-6',
        pid: topPid,
        serialNumber: serialNumberForEngineeringConstructionRow,
        bound: [9, startY],
        type: 'text',
        text: '',
        isDeviceTopic: false,
        editble: false,
    })
    cellDataGroupRow.push({
        id: 'engineering-construction-cost-7',
        pid: topPid,
        serialNumber: serialNumberForEngineeringConstructionRow,
        bound: [10, startY],
        type: 'text',
        text: '',
        isDeviceTopic: false,
        editble: false,
    })
    startY++;
    rowDataList.forEach((node, index) => {
        //生成表格序号
        if (serialNumberList.length > node.treeLevel) {
            serialNumberList = serialNumberList.splice(0, node.treeLevel + 1)
            serialNumberList[serialNumberList.length - 1]++;
        } else if (serialNumberList.length == node.treeLevel) {
            if (isEmpty(serialNumberList[node.treeLevel])) {
                serialNumberList[node.treeLevel] = 1;
            } else {
                serialNumberList[node.treeLevel]++;
            }
        } else {
            serialNumberList.push(1)
        }
        if (node.unitPrice) {
            node._unitPrice = (Number(node.unitPrice) * docMoneyCardinalNumber).toString();
        }
        if (node._unitPrice) {
            //@ts-ignore
            node.investmentCompositionMoney = (node._unitPrice * node.count).toString();
        }
        if (
            node.topicType == 'device'
            // && isEmpty(node.nearFutureMoneyRateOfValue) && isEmpty(node.mediumAndLongTermMoneyRateOfValue)
        ) {
            // const _investmentCompositionMoney: any = Number(node.unitPrice) * Number(node.count);
            const _investmentCompositionMoney: any = Number(node._unitPrice) * Number(node.count);
            // console.log("_investmentCompositionMoney--->", _investmentCompositionMoney)
            if (!isEmpty(node.nearFutureMoneyRate)) {
                node.nearFutureMoneyRateOfValue = parsePrice(Number(_investmentCompositionMoney) * (Number(node.nearFutureMoneyRate) / 100));
            } else {
                node.nearFutureMoneyRateOfValue = '';
            }
            if (!isEmpty(node.mediumAndLongTermMoneyRate)) {
                node.mediumAndLongTermMoneyRateOfValue = parsePrice(Number(_investmentCompositionMoney) * (Number(node.mediumAndLongTermMoneyRate) / 100));
            } else {
                node.mediumAndLongTermMoneyRateOfValue = '';
            }
        }
        let _nearFutureMoneyRateOfValue = node.nearFutureMoneyRateOfValue;
        let _mediumAndLongTermMoneyRateOfValue = node.mediumAndLongTermMoneyRateOfValue;
        const serialNumber = serialNumberList.join('.');
        const isDeviceTopic = node.topicType === 'device';
        //序号
        let point1: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [0, startY],
            type: 'text',
            text: serialNumber ? serialNumber : '',
            isDeviceTopic,
            editble: false,
        };
        //标题
        let point2: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [1, startY],
            type: 'input',
            text: node.topicName ? node.topicName : '',
            isDeviceTopic,
            isExpand: true,
            editble: true,
        };
        //单位
        let point3: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [2, startY],
            type: isDeviceTopic ? 'input' : 'text',
            text: node.unit ? node.unit : '',
            serviceType: 'unit',
            valueType: 'string',
            isDeviceTopic,
            editble: true,
        }
        //数量
        let point4: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [3, startY],
            type: isDeviceTopic ? 'input' : 'text',
            serviceType: 'count',
            valueType: 'number',
            text: node.count ? node.count : '',
            isDeviceTopic,
            editble: true,
        }
        //单价
        let point5: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [4, startY],
            type: isDeviceTopic ? 'input' : 'text',
            serviceType: 'unitPrice',
            text: node.unitPrice ? node._unitPrice : '',
            valueType: 'number',
            isDeviceTopic,
            editble: true,
        }
        //总价
        let point6: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [5, startY],
            type: 'text',
            serviceType: 'investmentCompositionMoney',
            text: node.topicType == 'device' && node.investmentCompositionMoney ? parsePrice(node.investmentCompositionMoney) : '',
            valueType: 'number',
            isDeviceTopic,
            editble: false,
        }
        //建安工程
        let point7: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [6, startY],
            type: node.topicType == 'device' ? 'switch' : 'text',
            text: node.topicType == 'device' && node.investmentCompositionMoney && node.investmentCompositionType == 'buildingInstallation' ?
                parsePrice(node.investmentCompositionMoney)
                :
                '',
            valueType: 'number',
            isDeviceTopic,
            editble: false,
            dropDownValueList: [parsePrice(node.investmentCompositionMoney) + '', '']
        }
        //设备
        let point8: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [7, startY],
            type: node.topicType == 'device' ? 'switch' : 'text',
            text: node.topicType == 'device' && node.investmentCompositionMoney && node.investmentCompositionType == 'device' ?
                parsePrice(node.investmentCompositionMoney)
                :
                '',
            valueType: 'number',
            isDeviceTopic,
            editble: false,
            dropDownValueList: [parsePrice(node.investmentCompositionMoney) + '', '']
        }
        //其他
        let point9: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [8, startY],
            type: node.topicType == 'device' ? 'switch' : 'text',
            text: node.topicType == 'device' && node.investmentCompositionMoney && node.investmentCompositionType == 'other' ?
                parsePrice(node.investmentCompositionMoney)
                :
                '',
            valueType: 'number',
            isDeviceTopic,
            editble: false,
            dropDownValueList: [parsePrice(node.investmentCompositionMoney) + '', '']
        }
        //近期
        let point10: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [9, startY],
            type: node.topicType == 'device' ? 'input' : 'text',
            text: _nearFutureMoneyRateOfValue,
            valueType: 'number',
            serviceType: 'nearFutureMoneyRate',
            isDeviceTopic,
            editble: true,
        }
        //中长期
        let point11: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [10, startY],
            type: node.topicType == 'device' ? 'input' : 'text',
            // text: node.mediumAndLongTermMoneyRateOfValue,
            text: _mediumAndLongTermMoneyRateOfValue,
            valueType: 'number',
            serviceType: 'mediumAndLongTermMoneyRate',
            isDeviceTopic,
            editble: true,
        }
        //重点工程勾选
        let point12: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [11, startY],
            type: node.topicType == 'device' ? 'select' : 'text',
            text: '',
            isDeviceTopic,
            //这里控制重点工程是否勾选
            isChecked: node.topicType == 'device' && node.isMainProjectChcked,
            editble: false,
        }
        let point13: TableDataCell = {
            id: node.id,
            pid: node.pid,
            serialNumber: serialNumber,
            bound: [12, startY],
            type: 'input',
            text: node.remark && node.remark != null ? node.remark : '',
            isDeviceTopic,
            editble: true,
        }
        cellDataGroupRow.push(point1);
        cellDataGroupRow.push(point2);
        cellDataGroupRow.push(point3);
        cellDataGroupRow.push(point4);
        cellDataGroupRow.push(point5);
        cellDataGroupRow.push(point6);
        cellDataGroupRow.push(point7);
        cellDataGroupRow.push(point8);
        cellDataGroupRow.push(point9);
        cellDataGroupRow.push(point10);
        cellDataGroupRow.push(point11);
        cellDataGroupRow.push(point12);
        cellDataGroupRow.push(point13);
        startY++;
    })
    cellDataGroupRow = addLastTotalRowListToTableData(cellDataGroupRow, tempComDocConfig, startY, topPid);
    return cellDataGroupRow;
}

const initExcelBuninessContentForWorker = (
    tempComDocInstance: DocInstance,
    tempDocConfig: DocConfig,
    initMergeCellBoundGroup: TypeMergeCellBound[],
    initTableHeader: any
): {
    newTableData: TableDataCell[],
    newMergeCellBoundGroud: TypeMergeCellBound[],
    topicUnionRecordList: TopicUnionRecord[]
} => {
    // tempComDocInstance = deepCloneV2(tempComDocInstance);
    //生成表格原始数据
    let _initTableHeader = deepCopy(initTableHeader);
    console.log("_initTableHeader--->", _initTableHeader)
    let topicUnionRecordList: TopicUnionRecord[] = [];
    let usedTopicList = tempComDocInstance.topicList.filter(topic => {
        return topic.topicType === 'text' || (topic.topicType === 'device' && topic.checked)
    })
    usedTopicList = deepCloneV2(usedTopicList);
    let newTableData: TableDataCell[] = [];
    let newMergeCellBoundGroud: TypeMergeCellBound[] = initMergeCellBoundGroup;
    let deviceTreeData = generateTreeData(usedTopicList);
    let needToDeleteTopicList = [];
    let needToReplaceTopicList = [];
    //处理表格合并
    dfsRecursive(deviceTreeData, (topic: TopicType) => {
        if (
            topic.topicType == 'text' &&
            topic.children &&
            topic.children.length == 1 &&
            topic.children[0].topicName == topic.topicName
        ) {
            topicUnionRecordList.push({
                textTopicId: topic.id,
                textTopic: deepCloneV2(topic),
                deviceTopicId: topic.children[0].id,
                deviceTopic: deepCloneV2(topic.children),
            })
            let newTextTopic: TopicType = {
                id: topic.id,
                pid: topic.pid,
            };
            const tempDeviceTopic = deepCloneV2(topic.children[0]);
            delete tempDeviceTopic.id;
            delete tempDeviceTopic.pid;
            newTextTopic = {
                ...newTextTopic,
                ...tempDeviceTopic,
            }
            needToReplaceTopicList.push(deepCloneV2(newTextTopic));
            needToDeleteTopicList.push(deepCloneV2(topic.children[0]));
        }
    })
    needToDeleteTopicList.forEach(topic => {
        const findIndex = usedTopicList.findIndex(ele => ele.id == topic.id);
        usedTopicList.splice(findIndex, 1);
    })
    const realUsedTopicList = [];
    usedTopicList.forEach(topic => {
        const findTopic = needToReplaceTopicList.find(ele => ele.id == topic.id);
        if (findTopic) {
            realUsedTopicList.push(deepCloneV2(findTopic));
        } else {
            realUsedTopicList.push(topic);
        }
    })
    const {
        name,
        protectlandBaseInfo: {
            protectlandName
        }
    } = tempComDocInstance;
    deviceTreeData = filterTreeByDeviceNode(generateTreeData(realUsedTopicList));
    console.log("deviceTreeData--->", deviceTreeData)
    if (deviceTreeData.length == 0) {
        let tableData = initTableHeader;
        newTableData = tableData;
    } else {
        let rowDataIndex = 0;
        let rowDataList = [];
        dfsRecursive(deviceTreeData.length == 1 ? deviceTreeData[0].children : deviceTreeData, (node: TopicType, level: number) => {
            node.treeLevel = level;
            node.rowIndex = rowDataIndex;
            rowDataList.push(node);
            rowDataIndex++;
        })
        const tableContentCellDataList = generateTableContentData(rowDataList, deviceTreeData[0].children, tempDocConfig);
        _initTableHeader[0].text = `${protectlandName}建设工程投资估算与安排表（单位：${adaptDocMoneyCardinalNumberName(tempDocConfig)})`
        newTableData = _initTableHeader.concat(tableContentCellDataList);
        newTableData = calculateTableData(newTableData);
        const uniqueSortNumberList: string[] = [];
        tableContentCellDataList.forEach(cell => {
            if (!uniqueSortNumberList.includes(cell.serialNumber)) {
                uniqueSortNumberList.push(cell.serialNumber);
            }
        })
        const expensesUniqueSortNumberList = uniqueSortNumberList.filter(sortNumber => {
            return sortNumber.includes('expenses')
        })
        let lastIndex = 0;
        newTableData.forEach(cell => {
            if (cell.bound[1] > lastIndex) {
                lastIndex = cell.bound[1];
            }
        })
        let tableTableRowMergeCellBoundGroupList: TypeMergeCellBound[] = [];
        expensesUniqueSortNumberList.forEach((sortNumber, index) => {
            tableTableRowMergeCellBoundGroupList.push({
                id: `sortNumber-${index}`,
                bounds: [[2, lastIndex - index], [4, lastIndex - index]],
                focusCellbound: [2, lastIndex - index],
                offsetX: 0,
                offsetY: 0
            })
        })
        newMergeCellBoundGroud = deepCopy(initMergeCellBoundGroup.concat(tableTableRowMergeCellBoundGroupList));
    }
    return {
        newTableData,
        newMergeCellBoundGroud,
        topicUnionRecordList
    }
}


export {
    initExcelBuninessContentForWorker
}