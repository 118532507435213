import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Tabs } from 'antd';
import CustomPopover from './CustomPopover';
import './custom-popover.scss';
import { Switch } from 'antd';
import { copyTextToClipboard, toastShort } from '../../utils';

interface Props {
    clientX: number
    clientY: number
}

const SymbolPopover = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openPopover
    }))

    const {
        clientX,
        clientY
    } = props;

    const customSelectRef = useRef<any>(null);

    const openPopover = () => {
        customSelectRef.current.show();
    };

    const onClickInsertToolsItem = (type: string) => {
        toastShort('warning', '表格界面不支持此操作');
        customSelectRef.current.handleClosePopover();
    }

    const symbolStr1 = `'。  ，  、  ＇  ： ∶   ；   ?    ‘ ’   “ ”  〝 〞  ˆ ˇ  ﹕  ︰   ﹔   ﹖  ﹑  •   ¨   …  .    ¸    ;   ´     ？   ！    ～    —    ｜    ‖    ＂   〃 ｀  @   ﹫   ﹏   ﹋    ﹌    ︴  々     ﹟    #    ﹩    $   ﹠ &   ﹪  %      *    ﹡   ﹢     ﹦    ﹤   ‐   ￣¯   ―   ﹨ ˆ ˜﹍ ﹎   +    =   ＿  _   -   ˇ ~  ﹉    ﹊   （ ）〈 〉  ‹ ›  ﹛ ﹜ 『 』 〖 〗 ［ ］ 《 》 〔 〕 { } 「 」 【 】  ︵      ︷     ︿     ︹    ︽    _    ﹁   ﹃ ︻ ︶ ︸ ﹀ ︺ ︾ ˉ ﹂ ﹄ ︼ ❝ ❞'`;

    const [symbolList1, setSymbolList1] = useState<string[]>([]);

    const symbolStr2 = `零 壹 贰 叁 肆 伍 陆 柒 捌 玖 拾 佰 仟 万 亿 吉 太 拍 艾 分 厘 毫 微 卍 卐 卄 巜 弍 弎 弐 朤 氺 曱 甴 囍 兀 々 〦 〧 〨 〩  ㏠ ㏡ ㏢ ㏣ ㏤ ㏥ ㏦ ㏧ ㏨ ㏩ ㏪ ㏫ ㏬ ㏭ ㏮ ㏯ ㏰ ㏱ ㏲ ㏳ ㏴ ㏵ ㏶ ㏷ ㏸ ㏹ ㏺ ㏻ ㏼ ㏽ ㏾ ㍙ ㍚ ㍛ ㍜ ㍝ ㍞ ㍟ ㍠ ㍡ ㍢ ㍣ ㍤ ㍥ ㍦ ㍧ ㍨ ㍩ ㍪ ㍫ ㍬ ㍭ ㍮ ㍯ ㍰ ㍘ ☰ ☲ ☱ ☴ ☵ ☶ ☳ ☷ `;

    const [symbolList2, setSymbolList2] = useState<string[]>([]);

    const symbolStr3 = `① ② ③ ④ ⑤ ⑥ ⑦ ⑧ ⑨ ⑩ ⑪ ⑫ ⑬ ⑭ ⑮ ⑯ ⑰ ⑱ ⑲ ⑳ ⓪ ❶ ❷ ❸ ❹ ❺ ❻ ❼ ❽ ❾ ❿ ⓫ ⓬ ⓭ ⓮ ⓯ ⓰ ⓱ ⓲ ⓳ ⓴ ⑴ ⑵ ⑶ ⑷ ⑸ ⑹ ⑺ ⑻ ⑼ ⑽ ⑾ ⑿ ⒀ ⒁ ⒂ ⒃ ⒄ ⒅ ⒆ ⒇ ⒈ ⒉ ⒊ ⒋ ⒌ ⒍ ⒎ ⒏ ⒐ ⒑ ⒒ ⒓ ⒔ ⒕ ⒖ ⒗ ⒘ ⒙ ⒚ ⒛ Ⅰ Ⅱ Ⅲ Ⅳ Ⅴ Ⅵ Ⅶ Ⅷ Ⅸ Ⅹ Ⅺ Ⅻ ⅰ ⅱ ⅲ ⅳ ⅴ ⅵ ⅶ ⅷ ⅸ ⅹ Ⓐ Ⓑ Ⓒ Ⓓ Ⓔ Ⓕ Ⓖ Ⓗ Ⓘ Ⓙ Ⓚ Ⓛ Ⓜ Ⓝ Ⓞ Ⓟ `;

    const [symbolList3, setSymbolList3] = useState<string[]>([]);

    const symbolStr4 = `¹ ² ³ ⁰ ⁱ ⁴ ⁵ ⁶ ⁷ ⁸ ⁹ ⁺ ⁻ ⁼ ⁽ ⁾ ⁿ ₀ ₁ ₂ ₃ ₄ ₅ ₆ ₇ ₈ ₉ ₊ ₋ ₌ ₍ ₎ ₐ ₑ ₒ ₓ ₔ`;

    const [symbolList4, setSymbolList4] = useState<string[]>([]);

    const symbolStr5 = `℃,℉,㎎,㎏,㎜,㎝,㎞,m,㎡,m³,hm²,㏄,㏎,㏑,㏒,㏕,°`;

    const [symbolList5, setSymbolList5] = useState<string[]>([]);

    useEffect(() => {
        // 标点符号
        let _symbolList1 = symbolStr1.replace(/ /g, '').split('');
        setSymbolList1(_symbolList1);
        // 中文字符
        let _symbolList2 = symbolStr2.replace(/ /g, '').split('');
        setSymbolList2(_symbolList2);
        // 序号编号
        let _symbolList3 = symbolStr3.replace(/ /g, '').split('');
        setSymbolList3(_symbolList3);
        // 上标/下标
        let _symbolList4 = symbolStr4.replace(/ /g, '').split('');
        setSymbolList4(_symbolList4);
        // 单位符号
        let _symbolList5 = symbolStr5.split(',');
        setSymbolList5(_symbolList5);
    }, [])

    const handleCopySymbol = (str: string) => {
        copyTextToClipboard(str);
        toastShort('success', '已复制到粘贴板！')
    }

    const renderTab = (type) => {
        switch (type) {
            case '标点符号':
                return (
                    <div className='flex-row symbol-content' style={{marginTop: -12}}>
                        {
                            symbolList1.map((str, index) => {
                                return (
                                    <div
                                        key={index + ''}
                                        className='flex-row symbol-content-item'
                                        onClick={() => handleCopySymbol(str)}
                                    >
                                        {str}
                                    </div>
                                )
                            })
                        }
                    </div>
                )
                break;
            case '中文字符':
                return (
                    <div className='flex-row symbol-content'>
                        {
                            symbolList2.map((str, index) => {
                                return (
                                    <div
                                        key={index + ''}
                                        className='flex-row symbol-content-item'
                                        onClick={() => handleCopySymbol(str)}
                                    >
                                        {str}
                                    </div>
                                )
                            })
                        }
                    </div>
                )
                break;
            case '序号编号':
                return (
                    <div className='flex-row symbol-content' style={{marginTop: -14}}>
                        {
                            symbolList3.map((str, index) => {
                                return (
                                    <div
                                        key={index + ''}
                                        className='flex-row symbol-content-item'
                                        onClick={() => handleCopySymbol(str)}
                                    >
                                        {str}
                                    </div>
                                )
                            })
                        }
                    </div>
                )
                break;
            case '上标/下标':
                return (
                    <div className='flex-row symbol-content'>
                        {
                            symbolList4.map((str, index) => {
                                return (
                                    <div
                                        key={index + ''}
                                        className='flex-row symbol-content-item'
                                        onClick={() => handleCopySymbol(str)}
                                    >
                                        {str}
                                    </div>
                                )
                            })
                        }
                    </div>
                )
                break;
            case '单位符号':
                return (
                    <div className='flex-row symbol-content'>
                        {
                            symbolList5.map((str, index) => {
                                return (
                                    <div
                                        key={index + ''}
                                        className='flex-row symbol-content-item'
                                        onClick={() => handleCopySymbol(str)}
                                    >
                                        {str}
                                    </div>
                                )
                            })
                        }
                    </div>
                )
                break;
            default:
                break;
        }
    }

    const tabList = [
        {
            key: '标点符号',
            label: (
                <div
                    className='flex-row topic-side-title-item'
                >
                    标点符号
                </div>
            ),
            children: renderTab('标点符号'),
        },
        {
            key: '中文字符',
            label: (
                <div
                    className='flex-row topic-side-title-item'
                >
                    中文字符
                </div>),
            children: renderTab('中文字符'),
        },
        {
            key: '序号编号',
            label: (
                <div
                    className='flex-row topic-side-title-item'
                >
                    序号编号
                </div>),
            children: renderTab('序号编号'),
        },
        {
            key: '上标/下标',
            label: (
                <div
                    className='flex-row topic-side-title-item'
                >
                    上标/下标
                </div>),
            children: renderTab('上标/下标'),
        },
        {
            key: '单位符号',
            label: (
                <div
                    className='flex-row topic-side-title-item'
                >
                    单位符号
                </div>),
            children: renderTab('单位符号'),
        },
    ]

    return (
        <CustomPopover
            ref={customSelectRef}
            clientX={clientX}
            clientY={clientY}
            contentRender={(props) => (
                <div className='symbol-popover'>
                    <div className='flex-row symbol-popover-tabs-container'>
                        <Tabs
                            defaultActiveKey="currentTopic"
                            items={tabList}
                            className='topic-side-tabs disable-drag'
                            size={'small'}
                        />
                    </div>
                </div>
            )}
        />
    )
}


export default forwardRef(SymbolPopover);