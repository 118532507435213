import React from 'react';

import { BoldConfig, FontFamilyConfig, FontSizeConfig } from '../../constants';
import { useEmitSlateItemClick } from '../../hooks';
import { SlateElementProps } from '../../types';
import { createClassNameMark } from '../../utils';

import Style from './main.module.scss';

const classNames = createClassNameMark(Style);
export const Heading4 = React.memo(function Heading4({ attributes, children, element, style }: SlateElementProps) {
  const onClick = useEmitSlateItemClick(element);
  return (
    <h4
      {...attributes}
      style={{
        ...style,
        fontSize: FontSizeConfig.h4,
        fontFamily: FontFamilyConfig.h4,
        fontWeight: BoldConfig.h4 ? 'bold' : undefined,
      }}
      data-doc-item-id={element.id}
      className={classNames('doc-heading4')}
      onClick={onClick}
    >
      {children}
    </h4>
  );
});
