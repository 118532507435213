import { Spin } from 'antd';
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { makeClassNameList } from '../../utils';

interface Props {
    loading?: boolean
    autoControl?: boolean
    style?: CSSProperties
    hideOverlay?: boolean
    title?: string
}

const LoadingWapper = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        showLoading,
        hideLoading
    }))

    const {
        loading,
        autoControl = true,
        style,
        hideOverlay = false,
        title = '努力加载中...',
    } = props;

    const loadingContainerRef = useRef(null);

    useEffect(() => {
        if (autoControl == false) {
            hideLoading();
        }
    }, []);

    const showLoading = () => {
        // console.log("开始showLoading---->", loadingContainerRef.current.className)
        loadingContainerRef.current.className = 'flex-col loading-wapper-container show';
    }

    const hideLoading = () => {
        // console.log("开始hideLoading---->", loadingContainerRef.current.className)
        loadingContainerRef.current.className = 'flex-col loading-wapper-container hide';
    }

    if (!loading && autoControl) {
        return <></>
    }
    return (
        <div
            ref={loadingContainerRef}
            style={style ? style : {}}
            className={
                makeClassNameList([
                    "flex-col loading-wapper-container",
                    hideOverlay && autoControl ? 'hide-overlay' : null
                ])
            }
        >
            <Spin />
            <p className='loading-title'>{title}</p>
        </div>
    )
}


export default forwardRef(LoadingWapper)