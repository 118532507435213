import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Tabs } from 'antd';
import CustomPopover from './CustomPopover';
import './custom-popover.scss';
import { Switch } from 'antd';
import { copyTextToClipboard, makeClassNameList, toastShort } from '../../utils';
import { SelectOptionType } from '../../types';

interface Props {
    clientX: number
    clientY: number
    value: any
    options: SelectOptionType[]
    onChange: Function
}

const SelectPopover = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openPopover
    }))

    const {
        clientX,
        clientY,
        value,
        options,
        onChange
    } = props;

    const delayTimer = useRef<any>(null);
    const customSelectRef = useRef<any>(null);

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current)
        }
    }, []);

    const openPopover = () => {
        customSelectRef.current.show();
    }

    const handleSelectItem = (item: SelectOptionType) => {
        customSelectRef.current.handleClosePopover();
        if(item.value == value){
            return;
        }
        delayTimer.current = setTimeout(() => {
            onChange && onChange(item.value);
        }, 200);
    }

    return (
        <CustomPopover
            ref={customSelectRef}
            clientX={clientX}
            clientY={clientY}
            contentRender={(props) => (
                <div className='select-popover'>
                    {
                        options.map((item, index) => {
                            return (
                                <div 
                                    className={
                                        item.value == value ?
                                        'flex-row select-popover-item active'
                                        :
                                        'flex-row select-popover-item'
                                    }
                                    key={index + ''}
                                    onClick={() => handleSelectItem(item)}
                                >
                                    <p>{item.label}</p>
                                </div>
                            )
                        })
                    }
                </div>
            )}
        />
    )
}


export default forwardRef(SelectPopover);