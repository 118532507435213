import React from 'react';
import './ParagraphStylePicker.scss';
import { HeadingItem } from '../../views/slate-editor/components/toolbar/components';

interface Props {
    docEditorRef: any
}

interface State {
}

export default class ParagraphStylePicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    };

    public readonly state: Readonly<State> = {
    };

    render() {
        const { docEditorRef } = this.props;
        return (
            <div className='paragraph-style-picker'>
               <div className='word-title-font-size-box'>
                    <div className='flex-row word-title-font-size-content-box'>
                        <HeadingItem editor={docEditorRef} level={0}/>
                        <HeadingItem editor={docEditorRef} level={1}/>
                        <HeadingItem editor={docEditorRef} level={2}/>
                        <HeadingItem editor={docEditorRef} level={3}/>
                        <HeadingItem editor={docEditorRef} level={4}/>
                        <HeadingItem editor={docEditorRef} level={5}/>
                    </div>
                    <div className='flex-col word-title-iconfont-box'>
                        <div className='word-title-iconfont-top'>
                            <div className='word-title-iconfont'><span className='iconfont-1 ghzs-gray-a-mengbanzu1 word-title-iconfont-size'></span></div>
                            <div className='word-title-iconfont'><span className='iconfont-1 ghzs-gray-a-mengbanzu2 word-title-iconfont-size'></span></div>
                        </div>
                        <div className='word-title-iconfont-more'><span className='iconfont-1 ghzs-gray-a-zu113 word-title-iconfont-size1'></span></div>
                    </div>
                </div> 
            </div>
        )
    }
}