import { Tooltip, Tree } from "antd";
import AutoSelectSearchInput from "../override-antd-kit/AutoSelectSearchInput";
import { TopicType } from "../../utils/types";
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { addTreePropertyForList, arrayTreeFilter, checkIsCustomTopicNode, deepCloneV2, deepCopy, dfsRecursive, expandedKeysFun, findTreePathByNodeId, generateTreeData, getChangeEventValue, isEmpty, makeClassNameList } from "../../utils";
import { DownOutlined } from "@ant-design/icons";


interface Props {
    initalTopicList: TopicType[]
    onSelectTopicNode: Function
}

const TopicTreeSelectPanel = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        handleSynchronizationSelectedNode
    }))

    const deviceTopicTreeRef = useRef(null);

    const [tempTopicList, setTempTopicList] = useState<TopicType[]>([]);

    const [deviceTopicTreeDataSearchValue, setDeviceTopicTreeDataSearchValue] = useState('');
    const [filteredForDeviceTopicTreeData, setFilteredForDeviceTopicTreeData] = useState<TopicType[]>([]);

    const [deviceTopicTreeSelectedNode, setDeviceTopicTreeSelectedNode] = useState<TopicType>(null);

    const [deviceTopicTreeHighLightNode, setDeviceTopicTreeHighLightNode] = useState<TopicType>(null);

    const [deviceTopicTreeExpandeKeys, setDeviceTopicTreeExpandeKeys] = useState<string[]>([]);

    const [filteredForDeviceTopicTreeExpandedKeys, setFilteredForDeviceTopicTreeExpandedKeys] = useState([]);

    const {
        initalTopicList
    } = props;

    useEffect(() => {
        setTempTopicList(deepCopy(initalTopicList))
    }, [])

    const handleSynchronizationSelectedNode = (node: TopicType) => {
        setDeviceTopicTreeDataSearchValue("");
        // console.log("handleSynchronizationSelectedNode--->", node)
        if (node) {
            let _deviceTopicTreeExpandeKeys = deviceTopicTreeExpandeKeys;
            const findTopic = deepCopy(tempTopicList.find(topic => {
                return topic.id === node.key;
            }))
            // console.log("findTopic--->", findTopic)
            const keyPathList: string[] = findTreePathByNodeId(deviceTopicTreeData, findTopic.id);
            keyPathList.forEach(keyPath => {
                if (!_deviceTopicTreeExpandeKeys.includes(keyPath)) {
                    _deviceTopicTreeExpandeKeys.push(keyPath);
                }
            })
            deviceTopicTreeRef.current.scrollTo({ key: findTopic.id });
            setTimeout(() => {
                deviceTopicTreeRef.current.scrollTo({ key: findTopic.id });
            }, 100);
            setDeviceTopicTreeExpandeKeys(deepCopy(_deviceTopicTreeExpandeKeys));
            setDeviceTopicTreeSelectedNode(findTopic);
            setDeviceTopicTreeHighLightNode(findTopic);
        } else {
            setDeviceTopicTreeSelectedNode(null);
            setDeviceTopicTreeHighLightNode(null);
        }

    }

    const filterFn = (data: { title: string }, filterText: string) => {
        if (!filterText) {
            return true;
        }
        return new RegExp(filterText, "i").test(data.title);
    };

    const onDeviceTopicTreeDataSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newSearchValue = getChangeEventValue(e);
        setDeviceTopicTreeDataSearchValue(newSearchValue);
        if (!isEmpty(newSearchValue)) {
            let tempFilteredTopicTree = arrayTreeFilter(
                deepCloneV2(deviceTopicTreeData),
                filterFn,
                newSearchValue
            );
            let tempFilteredTopicTreeExpandedKeys = expandedKeysFun(tempFilteredTopicTree);
            setFilteredForDeviceTopicTreeData(tempFilteredTopicTree);
            setFilteredForDeviceTopicTreeExpandedKeys(tempFilteredTopicTreeExpandedKeys);
        }
    }

    const onSelectDeviceTopicNode = (ids: string[]) => {
        try {
            const {
                onSelectTopicNode
            } = props;
            const findTopicNode = tempTopicList.find((ele) => {
                return ele.id == ids[0];
            });
            if (findTopicNode && findTopicNode.topicType == 'device') {
                setDeviceTopicTreeSelectedNode(findTopicNode);
                setDeviceTopicTreeHighLightNode(findTopicNode);
                let params = {
                    e: {
                        tagert: {
                            value: findTopicNode.topicName
                        }
                    },
                    option: {
                        value: findTopicNode.id
                    }
                };
                onSelectTopicNode && onSelectTopicNode(params);
            }
        } catch (e) {
            console.log("onSelectDeviceTopicNode---->err", e)
        }
    }

    const deviceTopicTreeData = useMemo(() => {
        let _tempTopicList: TopicType[] = [];
        tempTopicList.forEach(node => {
            if (node.topicType == 'device' && !checkIsCustomTopicNode(node)) {
                if (!_tempTopicList.find(ele => ele.id == node.id)) {
                    _tempTopicList.push(node);
                }
                const parentNode = tempTopicList.find(ele => ele.id == node.pid);
                if (parentNode && !_tempTopicList.find(ele => ele.id == parentNode.id)) {
                    _tempTopicList.push(parentNode);
                }
            }
        })
        let _tree = generateTreeData(addTreePropertyForList(_tempTopicList));
        return _tree;
    }, [tempTopicList]);

    const expandAllNode = () => {
        let _deviceTopicTreeExpandeKeys: string[] = [];
        let _filteredForDeviceTopicTreeExpandedKeys: string[] = [];
        dfsRecursive(deviceTopicTreeData, (node: TopicType) => {
            _deviceTopicTreeExpandeKeys.push(node.key)
        })
        dfsRecursive(filteredForDeviceTopicTreeData, (node: TopicType) => {
            _filteredForDeviceTopicTreeExpandedKeys.push(node.key)
        })
        setDeviceTopicTreeExpandeKeys([..._deviceTopicTreeExpandeKeys]);
        setFilteredForDeviceTopicTreeExpandedKeys([..._filteredForDeviceTopicTreeExpandedKeys])
    };

    const unExpandAllNode = () => {
        setDeviceTopicTreeExpandeKeys([]);
        setFilteredForDeviceTopicTreeExpandedKeys([])
    }

    const adaptNodeIcon = (node: TopicType) => {
        let nodeIcon = <i className='iconfont-1 ghzs-gray-shebei' style={{ fontWeight: '400' }} />;
        switch (node.topicType) {
            case 'device':
                switch (node.deviceType) {
                    case 'S':
                        nodeIcon = <i className='iconfont-1 ghzs-gray-sheshi' style={{ fontWeight: '400' }} />
                        break;
                    case 'X':
                        nodeIcon = <i className='iconfont-1 ghzs-gray-shebei' style={{ fontWeight: '400' }} />

                        break;
                }
                break;
            case 'text':
                nodeIcon = <i className='iconfont-1 ghzs-gray-wenben' style={{ fontWeight: '400' }} />
                break;
            default:
                break;
        }
        return (
            <div
                className='flex-row topic-tree-node-icon'
                style={{ width: 15, marginRight: 5 }}
            >
                {nodeIcon}
            </div>
        );
    }

    const formatSearchTextForDeviceTopicTree = (originStr: string) => {
        try {
            if (isEmpty(deviceTopicTreeDataSearchValue)) {
                return originStr;
            }
            let reg = new RegExp(deviceTopicTreeDataSearchValue);
            let str = originStr.split(reg);
            let txt = [];
            if (str.length === 0 || str.length === 1) {
                return originStr;
            }
            if (str.length > 0) {
                txt.push(<span key={"0-0"}>{str[0]}</span>);
            }
            txt.push(
                <span key={"0-1"} style={{ color: "#ff0000" }}>
                    {deviceTopicTreeDataSearchValue}
                </span>
            );
            if (str.length > 1) {
                txt.push(<span key={"0-2"}>{str[1]}</span>);
            }
            if (str.length >= 3) {
                for (let i = 2; i < str.length; i++) {
                    txt.push(
                        <span key={"0-3"} style={{ color: "#ff0000" }}>
                            {deviceTopicTreeDataSearchValue}
                        </span>
                    );
                    txt.push(<span key={"0-4"}>{str[i]}</span>);
                }
            }
            return txt;
        } catch (e) {
            return originStr;
        }
    }

    const onDeviceTopicTreeExpanded = (keys: string[]) => {
        if (deviceTopicTreeDataSearchValue) {
            setFilteredForDeviceTopicTreeExpandedKeys(keys)
        } else {
            setDeviceTopicTreeExpandeKeys(keys);
        }
    }

    const renderDeviceTopicTreeNode = (node: TopicType) => {
        return (
            <div
                className={makeClassNameList(["device-manage-tree-node", node.topicType == 'device' ? '' : 'disabled-text-node'])}
                //@ts-ignore
                topic-type={node.topicType}
                key={node.id}
            >
                <div
                    className="flex-row device-manage-tree-node-content"
                >
                    <div style={{ height: '100%', flex: 1 }} className="flex-row">
                        {adaptNodeIcon(node)}
                        <div
                            className="single-line-text"
                            style={{
                                maxWidth: 280,
                                // fontWeight: node.childDeviceNodeCount ? 900 : 400,
                                color: node.topicType == 'device' ? '#000' : '#303133'
                            }}
                        >
                            {formatSearchTextForDeviceTopicTree(node.title)}
                        </div>
                        {node.topicType == "text" && node.childDeviceNodeCount ? (
                            <Tooltip title={"本节选择项目总数量"}>
                                <div className="flex-row device-manage-tree-node-count">
                                    {node.childDeviceNodeCount}
                                </div>
                            </Tooltip>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={{ width: '100%' }}>
            <div className='flex-row search-header'>
                <AutoSelectSearchInput
                    placeholder='请输入项目名称'
                    size={'large'}
                    value={deviceTopicTreeDataSearchValue}
                    onChange={onDeviceTopicTreeDataSearchValueChange}
                    allowClear
                />
                <div className="flex-row expand-btn-group">
                    <Tooltip title={"展开所有级别大纲"}>
                        <div
                            className="flex-row expand-option-btn"
                            onClick={expandAllNode}
                        >
                            <img src={require('./../../assets/image/expand-icon.png')} />
                        </div>
                    </Tooltip>
                    <div style={{ width: 1, height: 24, backgroundColor: '#d9d9d9' }}></div>
                    <Tooltip title={"收起所有级别大纲"}>
                        <div
                            className="flex-row expand-option-btn"
                            onClick={unExpandAllNode}
                        >
                            <img src={require('./../../assets/image/flip-icon.png')} />
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div className='add-topic-modal-right-content'>
                <Tree
                    ref={deviceTopicTreeRef}
                    style={{ width: "100%", overflow: "hidden", marginLeft: -3 }}
                    showLine
                    autoExpandParent={false}
                    defaultExpandAll={false}
                    switcherIcon={<DownOutlined />}
                    checkable={false}
                    //@ts-ignore
                    titleRender={renderDeviceTopicTreeNode}
                    expandedKeys={deviceTopicTreeDataSearchValue ? filteredForDeviceTopicTreeExpandedKeys : deviceTopicTreeExpandeKeys}
                    onExpand={onDeviceTopicTreeExpanded}
                    //@ts-ignore
                    treeData={
                        isEmpty(deviceTopicTreeDataSearchValue)
                            ? deviceTopicTreeData
                            : filteredForDeviceTopicTreeData
                    }
                    selectedKeys={
                        deviceTopicTreeSelectedNode
                            ? [deviceTopicTreeSelectedNode.id]
                            : []
                    }
                    onSelect={onSelectDeviceTopicNode}
                    height={396}
                    blockNode
                    draggable={false}
                />
            </div>
        </div>
    )

}

export default forwardRef(TopicTreeSelectPanel);