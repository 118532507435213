import axios from 'axios'
import qs from 'qs'
import redux from './../redux/store'
import { useNavigate } from 'react-router-dom';
import { toastShort } from '../utils';
import { removeSystemConfig, setSystemConfig } from '../redux/system.slice';
import { _navigate } from '../utils/global';
axios.defaults.timeout = 20 * 1000;                     
axios.defaults.headers.post['Content-Type'] = 'application/json';   

declare namespace Service {
    interface ListResult {
        code?: number
        data?: {
            list?: Array<any>
            url?: string
            info?: any
            id?: string
            total?: number
        }
        msg?: string
    }
}

axios.interceptors.request.use((config) => {
    config.headers.Accept = "application/json";
    config.headers['Content-Type'] = "application/json";
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((res) => {
    if (res.data.code == 200) {
        return Promise.resolve(res);
    }
    return res;
}, (error) => {
    return Promise.reject(error);
});

const exportPostRequest = (url, param): Promise<Service.ListResult> => {
    return new Promise((resolve, reject) => {
        axios.post(url, param)
            .then(response => {
                if (response.data.code === 200 || response.data.errcode === 200) {
                    resolve(response.data);
                } else {
                    toastShort('error', '服务器错误：' + response.data.msg)
                    reject(response.data);
                }
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
};

const exportGetRequest = (url, param): Promise<Service.ListResult> => {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: param })
            .then(response => {
                if (response.data.ykk_code === 10000) {
                    resolve(response.data);
                } else {
                    toastShort('error', '服务器错误：' + response.data.msg)
                    reject(response.data);
                }
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export {
    exportPostRequest,
    exportGetRequest
}
