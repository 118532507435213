import React, { useCallback } from 'react';

import { Editor } from 'slate';

import { DocEditorRef } from '../../../types';
import { emitToolbarRefresh } from '../../../utils';

export const useToggle = (
  editor: DocEditorRef | undefined,
  format: string,
  mark: boolean,
): React.MouseEventHandler<HTMLElement> =>
  useCallback(
    (e) => {
      e.preventDefault();
      const curEditor = editor?.current?.editor;
      if (!curEditor) return;
      //   if (curEditor.selection && Range.isCollapsed(curEditor.selection)) return;
      if (mark) Editor.removeMark(curEditor, format);
      else Editor.addMark(curEditor, format, true);
      emitToolbarRefresh();
    },
    [editor, format, mark],
  );
