import { isNumber } from "lodash";
import { deepCloneV2, isEmpty, replaceUnitUpperCase } from "../../../utils";
import { DocInstance, WordParagraphBlock } from "../../../utils/types";
import { DocItem } from "../../slate-editor";
import { deleteTableDataEmptyColumn, removeAndAdjustColumn, replaceLastCharWithPeriod } from "../word-editor-deprecated/WordEditorHelper";
import { convertWordParagraphListToDocItemList } from "./editor-doc-item-generate";
import { DocItemGroup } from "./type";
import { generateSubTableSpeciesBook } from "./report-utils";


const presetWorkSheet = (originWorkSheet: string[][]) => {
    let newWorkSheet: string[][] = [];
    originWorkSheet.forEach(rowList => {
        let newRowList = [];
        rowList.forEach(cellValue => {
            const replacedCellValue = replaceUnitUpperCase(cellValue);
            newRowList.push(replacedCellValue)
        })
        newWorkSheet.push(newRowList)
    })
    return newWorkSheet;
}

const processWordParagraphBlockList = (wordParagraphBlockList: WordParagraphBlock[]): WordParagraphBlock[] => {
    return wordParagraphBlockList;
}

/**
 * 获取指定列的固定行区间的总值
 * @param tableData 
 * @param colIndex 
 * @param startRowIndex 
 * @param endRowIndex 
 * @returns 
 */
const getCellValueTotalByColGroup = (
    tableData: string[][],
    colIndex: number,
    startRowIndex: number,
    endRowIndex: number,
) => {
    let columnList = [];
    tableData.forEach((rowList, rowIndex) => {
        if (rowIndex >= startRowIndex && rowIndex <= endRowIndex) {
            columnList.push(rowList[colIndex])
        }
    })
    let totalValue = 0;
    columnList.forEach((value, colIndex) => {
        if (!Number.isNaN(value)) {
            totalValue += Number(value);
        }
    })
    return totalValue;
}

/**
 * 获取指定列的固定行区间的去重值
 * @param tableData 
 * @param colIndex 
 * @param startRowIndex 
 * @param endRowIndex 
 * @returns 
 */
const getCellValueTotalUniqueCountByColGroup = (
    tableData: string[][],
    colIndex: number,
    startRowIndex: number,
    endRowIndex: number,
) => {
    let columnList = [];
    tableData.forEach((rowList, rowIndex) => {
        if (rowIndex >= startRowIndex && rowIndex <= endRowIndex) {
            columnList.push(rowList[colIndex])
        }
    })
    let uniqueValueList = [];
    columnList.forEach((value, colIndex) => {
        if (!uniqueValueList.includes(value)) {
            uniqueValueList.push(value)
        }
    })
    uniqueValueList = uniqueValueList.filter(item => {
        return !isEmpty(item)
    })
    return uniqueValueList.length;
}

/**
 * 获取指定行的固定列区间的总值
 * @param tableData 
 * @param rowIndex 
 * @param startColIndex 
 * @param endColIndex 
 */
const getCellValueTotalByRowGroup = (
    tableData: string[][],
    rowIndex: number,
    startColIndex: number,
    endColIndex: number,
) => {
    let rowList = tableData[rowIndex];
    let totalValue = 0;
    rowList.forEach((value, colIndex) => {
        if (
            !Number.isNaN(value) &&
            colIndex >= startColIndex &&
            colIndex <= endColIndex
        ) {
            totalValue += Number(value);
        }
    })
    return totalValue;
}

const findCellValuColIndex = (rowList: string[], cellValue: string): number => {
    let colIndex = -1;
    for (let i = 0; i < rowList.length; i++) {
        if (rowList[i] == cellValue) {
            colIndex = i;
            break;
        }
    }
    return colIndex;
}

const getPercentRate = (value: any, totalValue: any): number => {
    let rate = ((Number(value) / Number(totalValue)) * 100).toFixed(2);
    return Number(rate);
}

const getPercent = (value1: number, value2: number) => {
    return ((Number(value1) / Number(value2)) * 100).toFixed(2)
}


export const processWorkSheet = (
    workSheet: string[][],
    subTableType: string,
    docInstance: DocInstance
): { docItemGroupList: DocItemGroup[], docItemList: DocItem[], additionalDocItemList: DocItem[] } => {
    let {
        protectlandBaseInfo: {
            protectlandName,
            protectlandExtraInfo
        }
    } = docInstance;
    const newWorkSheet = subTableType == '6' ? workSheet : presetWorkSheet(workSheet);
    let wordParagraphList: WordParagraphBlock[] = [];
    let tempDocItemGroupList: DocItemGroup[] = [];
    switch (subTableType) {
        case '1':
            let subTableData1: string[][] = [];
            newWorkSheet.forEach(rowList => {
                let tempRowList = [];
                rowList.forEach((cell, colIndex) => {
                    if (colIndex <= 7) {
                        tempRowList.push(cell)
                    }
                })
                subTableData1.push(tempRowList);
            })
            //开始处理正常逻辑
            let tempWorkSheet1 = [...newWorkSheet].slice(2, newWorkSheet.length);
            let workSheet1: string[][] = [];
            tempWorkSheet1.forEach(rowList => {
                let tempRowList = [];
                rowList.forEach((cell, colIndex) => {
                    if (colIndex <= 7) {
                        tempRowList.push(cell)
                    }
                })
                workSheet1.push(tempRowList);
            })
            let tempParagraph1_1: string = `${protectlandName}周边地区现有`;
            let tempParagraph1_2: string = `${protectlandName}周边的地方经济以`;
            tempParagraph1_1 += `${getCellValueTotalUniqueCountByColGroup(workSheet1, 0, 0, workSheet1.length)}个县、`;
            tempParagraph1_1 += `${getCellValueTotalUniqueCountByColGroup(workSheet1, 1, 0, workSheet1.length)}个村。`;
            tempParagraph1_1 += `${protectlandName}内总人口${getCellValueTotalByColGroup(workSheet1, 2, 0, workSheet1.length)}人，`;
            tempParagraph1_1 += `共计${getCellValueTotalByColGroup(workSheet1, 3, 0, workSheet1.length)}户，`;
            //第二段
            const industrialValue1 = getCellValueTotalByColGroup(workSheet1, 5, 0, workSheet1.length - 1);
            const industrialValue2 = getCellValueTotalByColGroup(workSheet1, 6, 0, workSheet1.length - 1);
            const industrialValue3 = getCellValueTotalByColGroup(workSheet1, 7, 0, workSheet1.length - 1);
            let arr = [industrialValue1, industrialValue2, industrialValue3];
            arr = arr.sort((a, b) => b - a);
            switch (arr[0]) {
                case industrialValue1:
                    tempParagraph1_2 += `${newWorkSheet[1][5]}为主，`;
                    break;
                case industrialValue2:
                    tempParagraph1_2 += `${newWorkSheet[1][6]}为主，`;
                    break;
                case industrialValue3:
                    tempParagraph1_2 += `${newWorkSheet[1][7]}为主，`;
                    break;
                default:
                    break;
            }
            tempParagraph1_2 += `${protectlandName}总产值${industrialValue1 + industrialValue2 + industrialValue3}万元，`;
            workSheet1.forEach((rowList, rowIndex) => {
                if (rowIndex == 0) {
                    tempParagraph1_1 += '其中';
                    tempParagraph1_2 += '其中';
                }
                tempParagraph1_1 += `${rowList[1]}现有居民${rowList[3]}户${rowList[2]}人`;
                tempParagraph1_2 += `${rowList[1]}总产值${getCellValueTotalByRowGroup(workSheet1, rowIndex, 5, 7)}万元，人均年收入${Number(rowList[4]).toFixed(1)}万元`;

                if (rowIndex < workSheet1.length - 1) {
                    tempParagraph1_1 += '；';
                    tempParagraph1_2 += '；';
                } else {
                    tempParagraph1_1 += '。';
                    tempParagraph1_2 += '。';
                }
            })
            let tempWordParahraphList1: WordParagraphBlock[] = [];
            tempWordParahraphList1.push({
                type: 'text',
                text: tempParagraph1_1,
                paragraphStyle: 'normal_paragraph',
                paragraphType: 'custom_background_paragraph'
            })
            tempWordParahraphList1.push({
                type: 'text',
                text: tempParagraph1_2,
                paragraphStyle: 'normal_paragraph',
                paragraphType: 'custom_background_paragraph'
            })
            tempDocItemGroupList.push({
                topicName: "人口与分布状况",
                wordParagraphList: [tempWordParahraphList1[0]]
            })
            tempDocItemGroupList.push({
                topicName: "社区经济",
                wordParagraphList: [tempWordParahraphList1[1]]
            })
            //
            tempWordParahraphList1.push({
                type: 'text',
                isSubTable: true,
                text: `附表  ${protectlandName}社区情况统计表`,
                paragraphStyle: 'table_name_paragraph',
                paragraphType: 'custom_background_paragraph'
            })
            tempWordParahraphList1.push({
                type: 'Table',
                text: '表格',
                tableName: `附表  ${protectlandName}社区情况统计表`,
                isSubTable: true,
                tableData: subTableData1,
                tableHeaderRowIndex: 1,
                tableRowSpanList: [
                    { rowIndex: 0, colIndex: 0, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 1, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 2, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 3, rowSpan: 2 },
                    { rowIndex: 0, colIndex: 4, rowSpan: 2 },
                ],
                tableColSpanList: [
                    { rowIndex: 0, colIndex: 5, colSpan: 3 },
                ]
            })
            // setResultParagraphList(processWordParagraphBlockList(tempWordParahraphList1));
            wordParagraphList = processWordParagraphBlockList(tempWordParahraphList1);
            break;
        case '2':
            let workSheet2 = [...newWorkSheet].slice(2, newWorkSheet.length);
            let tempWordParahraphList2: WordParagraphBlock[] = [];
            const totalLandUseArea = getCellValueTotalByColGroup(workSheet2, 1, 0, workSheet2.length - 1);
            let tempParagraph2_1: string = `${protectlandName}总面积${totalLandUseArea}公顷，其中`
            let tableRowSpanListForTuDi = [
                { rowIndex: 0, colIndex: 0, rowSpan: 2 },
                { rowIndex: 0, colIndex: 1, rowSpan: 2 },
                //
                { rowIndex: 0, colIndex: 32, rowSpan: 2 },
                { rowIndex: 0, colIndex: 33, rowSpan: 2 },
                { rowIndex: 0, colIndex: 34, rowSpan: 2 },
                { rowIndex: 0, colIndex: 35, rowSpan: 2 },
                { rowIndex: 0, colIndex: 36, rowSpan: 2 },
                { rowIndex: 0, colIndex: 37, rowSpan: 2 },
                { rowIndex: 0, colIndex: 38, rowSpan: 2 },
                { rowIndex: 0, colIndex: 39, rowSpan: 2 },
                { rowIndex: 0, colIndex: 40, rowSpan: 2 },
                { rowIndex: 0, colIndex: 41, rowSpan: 2 },
                { rowIndex: 0, colIndex: 42, rowSpan: 2 },
                { rowIndex: 0, colIndex: 43, rowSpan: 2 },
                { rowIndex: 0, colIndex: 44, rowSpan: 2 },
                { rowIndex: 0, colIndex: 45, rowSpan: 2 },
                { rowIndex: 0, colIndex: 46, rowSpan: 2 },
                { rowIndex: 0, colIndex: 47, rowSpan: 2 },
                { rowIndex: 0, colIndex: 48, rowSpan: 2 },
                { rowIndex: 0, colIndex: 49, rowSpan: 2 },
                { rowIndex: 0, colIndex: 50, rowSpan: 2 },
            ]
            let tableColSpanListForTuDi = [
                { rowIndex: 0, colIndex: 2, colSpan: 5, originColSpan: 5, deleteCount: 0 },
                { rowIndex: 0, colIndex: 7, colSpan: 4, originColSpan: 4, deleteCount: 0 },
                { rowIndex: 0, colIndex: 11, colSpan: 8, originColSpan: 8, deleteCount: 0 },
                { rowIndex: 0, colIndex: 19, colSpan: 6, originColSpan: 6, deleteCount: 0 },
                { rowIndex: 0, colIndex: 25, colSpan: 7, originColSpan: 7, deleteCount: 0 },
            ]
            let tableDataForTuDi = [...workSheet];
            const checkTableColumnTotalValueIsEmpty = (tableData: string[][], colIndex: number, startRowIndex: number): boolean => {
                let columnTotalValue = 0;
                let columnIsEmpty = true;
                const tempColCellList = [];
                tableData.forEach((rowList, rowIndex) => {
                    if (rowIndex >= startRowIndex) {
                        const targetCellValue = rowList[colIndex];
                        if (!isEmpty(targetCellValue)) {
                            columnIsEmpty = false;
                        }
                        tempColCellList.push(targetCellValue);
                        columnTotalValue += Number(targetCellValue)
                    }
                })
                let isNeedToDelete = false;
                if (!columnIsEmpty) {
                    isNeedToDelete = false;
                } else {
                    if (isNumber(columnTotalValue) && Number(columnTotalValue) !== 0) {
                        isNeedToDelete = false;
                    } else {
                        isNeedToDelete = true;
                    }
                }
                return isNeedToDelete;
            }
            const tableDataForTuDiHandledResult = deleteTableDataEmptyColumn(
                tableDataForTuDi,
                tableColSpanListForTuDi,
                tableRowSpanListForTuDi,
                2,
                0,
                checkTableColumnTotalValueIsEmpty
            );
            let handledTableDataOfLandUseType = tableDataForTuDiHandledResult.tableData;
            let handledTableRowSpanInfoListOfLandUseType = tableDataForTuDiHandledResult.tableRowSpanInfoList;
            let handledTableColSpanInfoListOfLandUseType = tableDataForTuDiHandledResult.tableColSpanInfoList;
            const landUseTypeDistribution = [
                {
                    landType: "林地",
                    children: [
                        "乔木林地",
                        "竹林地",
                        "灌木林地",
                        "其他林地"
                    ],
                },
                {
                    landType: "草地",
                    children: [
                        "天然牧草地",
                        "人工牧草地",
                        "其他草地",
                    ],
                },
                {
                    landType: "湿地",
                    children: [
                        "森林沼泽",
                        "灌丛沼泽",
                        "沼泽草地",
                        "其他沼泽地",
                        "沿海滩涂",
                        "内陆滩涂",
                        "红树林地",
                    ],
                },
                {
                    landType: "园地",
                    children: [
                        "果园",
                        "茶园",
                        "橡胶园地",
                        "油料园地",
                        "其他园地",
                    ],
                },
                {
                    landType: "陆地水域",
                    children: [
                        "河流水面",
                        "湖泊水面",
                        "水库水面",
                        "坑塘水面",
                        "沟渠",
                        "冰川及常年积雪",
                    ],
                },
                {
                    landType: "耕地",
                    children: [],
                },
                {
                    landType: "居住用地",
                    children: [],
                },
                {
                    landType: "公共管理与公共服务用地",
                    children: [],
                },
                {
                    landType: "商业服务业用地",
                    children: [],
                },
                {
                    landType: "工矿用地",
                    children: [],
                },
                {
                    landType: "仓储用地",
                    children: [],
                },
                {
                    landType: "交通运输用地",
                    children: [],
                },
                {
                    landType: "公用设施用地",
                    children: [],
                },
                {
                    landType: "绿地与开敞空间用地",
                    children: [],
                },
                {
                    landType: "特殊用地",
                    children: [],
                },
                {
                    landType: "留白用地",
                    children: [],
                },
                {
                    landType: "其他土地",
                    children: [],
                },
                {
                    landType: "渔业用海",
                    children: [],
                },
                {
                    landType: "工矿通信用海",
                    children: [],
                },
                {
                    landType: "交通运输用海",
                    children: [],
                },
                {
                    landType: "游憩用海",
                    children: [],
                },
                {
                    landType: "特殊用海",
                    children: [],
                },
                {
                    landType: "其他海域",
                    children: [],
                },
            ]
            let landUseSubTypeParagraphPlainTextList: string[] = [];
            landUseTypeDistribution.forEach((landUseType => {
                const landUseTypeFindIndex = findCellValuColIndex(handledTableDataOfLandUseType[0], landUseType.landType);
                if (landUseTypeFindIndex > -1) {
                    let landUseSubTypeFindIndexList: { landUseSubType: string; findIndex: number }[] = [];
                    landUseType.children.forEach((lanUseSubTypeName => {
                        const tempLandUseSubTypeFindIndex = findCellValuColIndex(handledTableDataOfLandUseType[1], lanUseSubTypeName);
                        if (tempLandUseSubTypeFindIndex > -1) {
                            landUseSubTypeFindIndexList.push({
                                landUseSubType: lanUseSubTypeName,
                                findIndex: tempLandUseSubTypeFindIndex
                            })
                        }
                    }))
                    const landUseTypeArea = getCellValueTotalByColGroup(handledTableDataOfLandUseType, landUseTypeFindIndex, 2, handledTableDataOfLandUseType.length - 1);
                    tempParagraph2_1 += `其中${landUseType.landType}${landUseTypeArea}公顷，占总面积的${getPercentRate(landUseTypeArea, totalLandUseArea)}%；`;

                    if (landUseSubTypeFindIndexList.length == 1) {
                        handledTableDataOfLandUseType = removeAndAdjustColumn(handledTableDataOfLandUseType, landUseTypeFindIndex, handledTableRowSpanInfoListOfLandUseType, handledTableColSpanInfoListOfLandUseType);
                        const deletedColIndex = landUseSubTypeFindIndexList[0].findIndex - 1;
                        handledTableDataOfLandUseType[0][deletedColIndex] = landUseType.landType;
                        handledTableDataOfLandUseType[1][deletedColIndex] = "";
                        handledTableRowSpanInfoListOfLandUseType.push({
                            rowIndex: 0,
                            colIndex: deletedColIndex,
                            rowSpan: 2,
                        })
                    }
                }
            }))
            landUseTypeDistribution.forEach((landUseType => {
                const landUseTypeFindIndex = findCellValuColIndex(handledTableDataOfLandUseType[0], landUseType.landType);
                if (landUseTypeFindIndex > -1) {
                    const landUseTypeArea = getCellValueTotalByColGroup(handledTableDataOfLandUseType, landUseTypeFindIndex, 2, handledTableDataOfLandUseType.length - 1);
                    let landUseSubTypeFindIndexList: { landUseSubType: string; findIndex: number }[] = [];
                    landUseType.children.forEach((lanUseSubTypeName => {
                        const tempLandUseSubTypeFindIndex = findCellValuColIndex(handledTableDataOfLandUseType[1], lanUseSubTypeName);
                        if (tempLandUseSubTypeFindIndex > -1) {
                            landUseSubTypeFindIndexList.push({
                                landUseSubType: lanUseSubTypeName,
                                findIndex: tempLandUseSubTypeFindIndex
                            })
                        }
                    }))
                    if (landUseSubTypeFindIndexList.length > 1) {
                        let tempParagraphSlice1 = `在${landUseType.landType}中，`;
                        landUseSubTypeFindIndexList.forEach(landUseSubTypeInfo => {
                            let tempLandUseSubTypeArea = getCellValueTotalByColGroup(handledTableDataOfLandUseType, landUseSubTypeInfo.findIndex, 2, handledTableDataOfLandUseType.length - 1);
                            tempParagraphSlice1 += `${landUseSubTypeInfo.landUseSubType}林地面积${tempLandUseSubTypeArea}公顷，占林地面积的${getPercentRate(tempLandUseSubTypeArea, landUseTypeArea)}%；`
                        })
                        tempParagraphSlice1 = replaceLastCharWithPeriod(tempParagraphSlice1);
                        landUseSubTypeParagraphPlainTextList.push(tempParagraphSlice1);
                    }
                }
            }))
            tempParagraph2_1 = replaceLastCharWithPeriod(tempParagraph2_1);
            tempWordParahraphList2.push({
                type: 'text',
                text: tempParagraph2_1,
                paragraphStyle: 'normal_paragraph',
                paragraphType: 'custom_background_paragraph'
            })
            landUseSubTypeParagraphPlainTextList.forEach(str => {
                tempWordParahraphList2.push({
                    type: 'text',
                    text: str,
                    paragraphStyle: 'normal_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
            })
            tempDocItemGroupList.push({
                topicName: "土地利用状况",
                wordParagraphList: deepCloneV2(tempWordParahraphList2)
            })
            //
            tempWordParahraphList2.push({
                type: 'text',
                isSubTable: true,
                text: `附表  ${protectlandName}土地利用现状表`,
                paragraphStyle: 'table_name_paragraph',
                paragraphType: 'custom_background_paragraph'
            })
            tempWordParahraphList2.push({
                type: 'Table',
                text: "表格",
                isSubTable: true,
                tableName: `附表  ${protectlandName}土地利用现状表`,
                tableData: handledTableDataOfLandUseType,
                tableHeaderRowIndex: 2,
                tableRowSpanList: handledTableRowSpanInfoListOfLandUseType,
                tableColSpanList: handledTableColSpanInfoListOfLandUseType
            });
            wordParagraphList = processWordParagraphBlockList(tempWordParahraphList2);
            break;
        case '3':
            let totalArea = 0;
            let workSheet3 = [...newWorkSheet].slice(1, newWorkSheet.length);
            workSheet3.forEach(rowList => {
                if (rowList[1]) {
                    totalArea += Number(rowList[1])
                }
            })
            let subTableData2: string[][] = [];
            workSheet.forEach((rowList, rowIndex) => {
                let tempRowList: string[] = [];
                rowList.forEach((cell, cellIndex) => {
                    if (rowIndex > 0 && cellIndex == 2) {
                        let newCell = cell;
                        if (!Number.isNaN(Number(rowList[1]))) {
                            newCell = (Number(rowList[1]) / totalArea * 100).toFixed(2)
                        };
                        tempRowList.push(newCell);
                    } else {
                        tempRowList.push(cell)
                    }
                })
                subTableData2.push(tempRowList);
            })
            let tempParagraphList3: string[] = [
                `按照功能区划原则和依据，在实地调查与充分论证的基础上，根据保护对象的数量、空间分布特点，结合环境条件及区内居民生活方式等情况，采取自然区划为主的区划法，将该${protectlandName}内部按照功能性差异划分为`,
                `${protectlandName}总面积${getCellValueTotalByColGroup(workSheet3, 1, 0, workSheet3.length - 1).toFixed(2)}公顷。其中，`
            ];
            workSheet3.forEach((rowList, rowIndex) => {
                if (rowIndex < workSheet3.length - 1) {
                    tempParagraphList3[0] += `${rowList[0]}、`;
                    tempParagraphList3[1] += `${rowList[0]}面积${rowList[1]}公顷，占${protectlandName}总面积的${getPercent(Number(rowList[1]), getCellValueTotalByColGroup(workSheet3, 1, 0, workSheet3.length - 1))}%；`;
                } else {
                    tempParagraphList3[0] += `${rowList[0]}${workSheet3.length}个功能区。`;
                    tempParagraphList3[1] += `${rowList[0]}面积${rowList[1]}公顷，占${protectlandName}总面积的${getPercent(Number(rowList[1]), getCellValueTotalByColGroup(workSheet3, 1, 0, workSheet3.length - 1))}%。`;
                }
                tempParagraphList3.push(`（${rowIndex + 1}）${rowList[0]}`);
                let lastParagraph3 = `${protectlandName}${rowList[0]}面积${rowList[1]}公顷，占总面积的${getPercent(Number(rowList[1]), getCellValueTotalByColGroup(workSheet3, 1, 0, workSheet3.length - 1))}%`;
                if (rowList[3]) {
                    lastParagraph3 += `，${rowList[0]}范围为${rowList[3]}`;
                }
                if (rowList[4]) {
                    lastParagraph3 += `，分布有${rowList[4]}`;
                }
                lastParagraph3 += `。`;
                tempParagraphList3.push(lastParagraph3);
            })
            let tempWordParahraphList3: WordParagraphBlock[] = [];
            tempParagraphList3.forEach(str => {
                tempWordParahraphList3.push({
                    type: 'text',
                    text: str,
                    paragraphStyle: 'normal_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
            })
            tempDocItemGroupList.push({
                topicName: "功能区划",
                wordParagraphList: deepCloneV2(tempWordParahraphList3)
            })
            tempWordParahraphList3.push({
                type: 'text',
                isSubTable: true,
                text: `附表  ${protectlandName}功能区划表`,
                paragraphStyle: 'table_name_paragraph',
                paragraphType: 'custom_background_paragraph'
            })
            tempWordParahraphList3.push({
                type: 'Table',
                isSubTable: true,
                tableData: subTableData2,
                tableHeaderRowIndex: 0,
                tableName: `附表  ${protectlandName}功能区划表`,
            })
            wordParagraphList = processWordParagraphBlockList(tempWordParahraphList3);
            break;
        case '4':
            let workSheet4 = [...newWorkSheet].splice(2, newWorkSheet.length - 1);
            const totalPersonNum = Number(workSheet4[0][1]);
            let tempParagraph4_1 = `目前，${protectlandName}共有在职工${totalPersonNum}人，包括`;
            workSheet4.forEach((rowList, rowIndex) => {
                if (rowIndex > 0) {
                    if (rowIndex < workSheet4.length - 1) {
                        tempParagraph4_1 += `${rowList[0]}${rowList[1]}人，`
                    } else {
                        tempParagraph4_1 += `${rowList[0]}${rowList[1]}人。`
                    }
                }
            })
            if (workSheet4[0][2] && workSheet4[0][2] != '0') {
                tempParagraph4_1 += `按照文化结构划分，${protectlandName}硕士以上人员${workSheet4[0][2]}人，`;
            }
            if (workSheet4[0][3] && workSheet4[0][3] != '0') {
                tempParagraph4_1 += `本科人员${workSheet4[0][3]}人，`;
            }
            if (workSheet4[0][4] && workSheet4[0][4] != '0') {
                tempParagraph4_1 += `专科人员${workSheet4[0][4]}人，`;
            }
            if (workSheet4[0][5] && workSheet4[0][5] != '0') {
                tempParagraph4_1 += `中专或高中${workSheet4[0][5]}人，`;
            }
            if (workSheet4[0][6] && workSheet4[0][6] != '0') {
                tempParagraph4_1 += `初中及以下${workSheet4[0][6]}人；`;
            }
            if (workSheet4[0][8] && workSheet4[0][8] != '0') {
                tempParagraph4_1 += `按照职称结构划分，${protectlandName}高级职工${workSheet4[0][8]}人，`;
            }
            if (workSheet4[0][9] && workSheet4[0][9] != '0') {
                tempParagraph4_1 += `中级职工${workSheet4[0][9]}人，`;
            }
            if (workSheet4[0][10] && workSheet4[0][10] != '0') {
                tempParagraph4_1 += `助工${workSheet4[0][10]}人，`;
            }
            if (workSheet4[0][11] && workSheet4[0][11] != '0') {
                tempParagraph4_1 += `技术员${workSheet4[0][11]}人，`;
            }
            if (workSheet4[0][13] && workSheet4[0][13] != '0') {
                tempParagraph4_1 += `${protectlandName}正式职工${workSheet4[0][13]}人，`;
            }
            if (workSheet4[0][14] && workSheet4[0][14] != '0') {
                tempParagraph4_1 += `临时工${workSheet4[0][14]}人，`;
            }
            if (workSheet4[0][15] && workSheet4[0][15] != '0') {
                tempParagraph4_1 += `退休人员${workSheet4[0][15]}人。`;
            }
            let tempWordParahraphList4: WordParagraphBlock[] = [];
            let tableRowSpanListForRenYuan = [
                { rowIndex: 0, colIndex: 0, rowSpan: 2 },
                { rowIndex: 0, colIndex: 15, rowSpan: 2 },
            ];
            let tableColSpanListForRenYuan = [
                { rowIndex: 0, colIndex: 1, colSpan: 6 },
                { rowIndex: 0, colIndex: 7, colSpan: 5 },
                { rowIndex: 0, colIndex: 12, colSpan: 3 },
            ];
            const workSheet4DeleteResult = deleteTableDataEmptyColumn(newWorkSheet, tableColSpanListForRenYuan, tableRowSpanListForRenYuan, 2, 1)
            tempWordParahraphList4.push({
                type: 'text',
                text: replaceLastCharWithPeriod(tempParagraph4_1),
                paragraphType: 'custom_background_paragraph',
                paragraphStyle: 'normal_paragraph'
            })
            tempDocItemGroupList.push({
                topicName: "管理队伍",
                wordParagraphList: deepCloneV2(tempWordParahraphList4)
            })
            //
            tempWordParahraphList4.push({
                type: 'text',
                isSubTable: true,
                text: `附表  ${protectlandName}管理局（处）人员现状统计表`,
                paragraphStyle: 'table_name_paragraph',
                paragraphType: 'custom_background_paragraph'
            })
            tempWordParahraphList4.push({
                type: 'Table',
                isSubTable: true,
                tableName: `附表  ${protectlandName}管理局（处）人员现状统计表`,
                tableData: workSheet4DeleteResult.tableData,
                tableHeaderRowIndex: 1,
                tableRowSpanList: workSheet4DeleteResult.tableRowSpanInfoList,
                tableColSpanList: workSheet4DeleteResult.tableColSpanInfoList
            })
            wordParagraphList = processWordParagraphBlockList(tempWordParahraphList4);
            break;
        case '5':
            let workSheet5 = [...newWorkSheet].slice(1, newWorkSheet.length - 1);
            workSheet5 = presetWorkSheet(workSheet5)
            let tempParagraphList5: string[] = [
                `${protectlandName}地理位置较为偏远，社区的社会事业发展较为缓慢，社会服务水平较低，大型服务设施主要位于乡镇中心。近些年，在国家的大力发展支持下，${protectlandName}社区内学校、交通、医疗、通讯及文化等基础设施建设有所改善加强，社区及周边群众生活条件得到明显改善。`,
                `${protectlandName}当前的基础设施有`
            ]
            workSheet5.forEach((rowList, rowIndex) => {
                if (rowIndex < workSheet5.length - 1) {
                    tempParagraphList5[1] += `${rowList[0]}${rowList[1]}，`
                } else {
                    tempParagraphList5[1] += `${rowList[0]}${rowList[1]}。`
                }
            })
            let tempWordParahraphList5: WordParagraphBlock[] = [];
            tempParagraphList5.forEach(str => {
                tempWordParahraphList5.push({
                    type: 'text',
                    text: str,
                    paragraphStyle: 'normal_paragraph',
                    paragraphType: 'custom_background_paragraph'
                })
            })
            tempDocItemGroupList.push({
                topicName: "公共基础设施",
                wordParagraphList: deepCloneV2(tempWordParahraphList5)
            })
            //
            tempWordParahraphList5.push({
                type: 'text',
                isSubTable: true,
                text: `附表  ${protectlandName}基础设施现状统计表`,
                paragraphStyle: 'table_name_paragraph',
                paragraphType: 'custom_background_paragraph'
            })
            tempWordParahraphList5.push({
                type: 'Table',
                isSubTable: true,
                tableData: workSheet,
                tableHeaderRowIndex: 0,
                tableName: `附表  ${protectlandName}基础设施现状统计表`,
            });
            wordParagraphList = processWordParagraphBlockList(tempWordParahraphList5);
            break;
        case '6':
            //这里要开始生成物种名录等表格文件。而不是原始导入文件
            console.log("newWorkSheet--->", newWorkSheet)
            //@ts-ignore
            if(newWorkSheet && newWorkSheet.length && newWorkSheet[0].canorical_me){
                //@ts-ignore
                const speciesParagrahList = generateSubTableSpeciesBook(newWorkSheet, { protectlandName });
                // console.log("speciesParagrahList--->", speciesParagrahList)
                let plantParagraphList = speciesParagrahList.filter(ele => !ele.isSubTable).filter(ele => ele.text?.includes("植物") || ele.tableName?.includes("植物"));
                let animalParagraphList = speciesParagrahList.filter(ele => !ele.isSubTable).filter(ele => ele.text?.includes("动物") || ele.tableName?.includes("动物"));
                tempDocItemGroupList.push({
                    topicName: "野生植物资源",
                    wordParagraphList: plantParagraphList,
                })
                tempDocItemGroupList.push({
                    topicName: "野生动物资源",
                    wordParagraphList: animalParagraphList
                })
                wordParagraphList = processWordParagraphBlockList(speciesParagrahList);
            }
            break;
        default:
            break;
    }

    const normalParagraphList = wordParagraphList.filter(ele => {
        return !ele.isSubTable;
    })
    const subTableParagraphList = wordParagraphList.filter(ele => {
        return ele.isSubTable;
    })
    const additionalDocItemList = convertWordParagraphListToDocItemList(subTableParagraphList);
    additionalDocItemList.forEach((item, index) => {
        item.id = `-ADDITIONAL-INSET_SUB_TABLE_${subTableType}_${index}`
    })
    let docItemGroupList = tempDocItemGroupList.map(group => {
        let newGroup = group;
        newGroup.docItemList = convertWordParagraphListToDocItemList(group.wordParagraphList)
        return newGroup;
    })
    let docItemList = convertWordParagraphListToDocItemList(normalParagraphList);
    return {
        docItemGroupList,
        docItemList,
        additionalDocItemList
    }
}