import React, { ReactNode, Suspense, lazy, useEffect } from 'react';
import './App.css';
import './assets/font/normal-font/iconfont'
import type { FC } from 'react';
import locale from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AliveScope } from 'react-activation';
import EditPage from './views/edit-page/EditPage';
import { message } from 'antd';
import 'antd/dist/reset.css';
import './assets/style/com.scss';
import './assets/style/reset.scss';
import { Provider } from "react-redux";
import { ConfigProvider } from 'antd';
import store from './redux/store';
import { saasConfig } from './config/theme-config';
const DocGroupPage = lazy(() => import('./views/home-page/doc-group/DocGroupPage'));
const RecentlyOpened = lazy(() => import('./views/home-page/recently-opened/RecentlyOpened'));
const AllProjects = lazy(() => import('./views/home-page/all-projects/AllProjects'));
const ShareFiles = lazy(() => import('./views/home-page/share-files/ShareFiles'));
const RecylingBin = lazy(() => import('./views/home-page/recycling-bin/RecylingBin'));
const StatisticalAnalysis = lazy(() => import('./views/statistical-analysis/StatisticalAnalysis'));
const LoginYunNan = lazy(() => import('./views/login/LoginYunNan'));
const HomePage = lazy(() => import('./views/home-page/HomePage'));
const HelpCenter = lazy(() => import('./views/help-center/HelpCenter'));
const Register = lazy(() => import('./views/register/Register'));
const MainLayout = lazy(() => import('./layout/MainLayout'));
import FontStyleYunNan from './views/sass-components/FontStyleYunNan';
import FontStyleQingYuan from './views/sass-components/FontStyleQingYuan';
import ServiceWindow from './components/float-kit/ServiceWindow';
import { isDevelopment } from './utils/environment';
import WelComePage from './guide/WelComePage';
import GuideOverlay from './guide/GuideOverlay';
const ModelEssay = lazy(() => import('./views/home-page/model-essay/ModelEssay'));
const LoginV3 = lazy(() => import('./views/login/LoginV3'));
const ToolKits = lazy(() => import('./views/tool-kits/ToolKits'));

const App: FC = () => {

  useEffect(() => {
    message.config({
      top: 27,
    });
    document.title = saasConfig.systemNameFirst + saasConfig.systemNameSecond;
    localStorage.removeItem('confirmedToChangeSystemParagraph');
    //
    // const inputs = document.querySelectorAll('.ant-input');

    // const selectText = (event) => {
    //   event.target.select();
    // };

    // inputs.forEach(input => {
    //   input.addEventListener('focus', selectText);
    // });

    // // 清理函数，移除事件监听器
    // return () => {
    //   inputs.forEach(input => {
    //     input.removeEventListener('focus', selectText);
    //   });
    // }
  }, [])

  const LoadingProgress: FC = () => {
    useEffect(() => {
      NProgress.start();
      return () => {
        NProgress.done();
      }
    })
    return (
      <div></div>
    )
  };

  const lazyLoadRouteEl = (chilren: ReactNode) => {
    return (<Suspense
      fallback={
        <LoadingProgress />
      }>
      {chilren}
    </Suspense>)
  }

  const dispatchLoginPage = () => {
    switch (saasConfig.systemType) {
      case 'QingYuanDefault':
      case 'HuaWeiTestServer':
        // return <LoginV2 />
        return <LoginV3 />
        break;
      case 'YunNanLinGuiYuan':
        return <LoginYunNan />
        break;
    }
  }

  const LoadableComponent = () => {
    if (isDevelopment()) {
      return lazyLoadRouteEl(<FontStyleYunNan />);
    } else {
      return lazyLoadRouteEl(<FontStyleQingYuan />);
    }
  };

  return (
    <div className="App app-outer-container">
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: saasConfig.colorConfig.colorPrimary,
              colorBgContainerDisabled: saasConfig.colorConfig.colorPrimary + '32',
              controlItemBgActiveDisabled: saasConfig.colorConfig.colorPrimary + '32',
            },
            components: {
              Button: {
                colorPrimary: saasConfig.colorConfig.colorPrimary,
                colorPrimaryBg: saasConfig.colorConfig.colorPrimary,
                colorBgContainerDisabled: saasConfig.colorConfig.colorPrimary + '32',
                controlItemBgActiveDisabled: saasConfig.colorConfig.colorPrimary + '32',
              }
            }
          }}
          locale={locale}
        >
          <AliveScope>
            <BrowserRouter>
              <LoadableComponent />
              <Routes>
                <Route path='/' element={lazyLoadRouteEl(<MainLayout />)}>
                  <Route path="home" element={lazyLoadRouteEl(<HomePage />)}>
                    <Route path="recently-opened" element={lazyLoadRouteEl(<RecentlyOpened />)}></Route>
                    <Route path="all-projects" element={lazyLoadRouteEl(<AllProjects />)}></Route>
                    <Route path="share-files" element={lazyLoadRouteEl(<ShareFiles />)}></Route>
                    <Route path="model-essay" element={lazyLoadRouteEl(<ModelEssay />)}></Route>
                    <Route path="welcome_page" element={lazyLoadRouteEl(<WelComePage />)}></Route>
                    <Route path="tool-kits" element={lazyLoadRouteEl(<ToolKits />)} />
                    <Route path="recycling-bin" element={lazyLoadRouteEl(<RecylingBin />)}></Route>
                    <Route path="doc-group" element={lazyLoadRouteEl(<DocGroupPage />)}></Route>
                  </Route>
                  <Route path="statistical-analysis" element={lazyLoadRouteEl(<StatisticalAnalysis />)}></Route>
                </Route>
                <Route path="/login" element={lazyLoadRouteEl(dispatchLoginPage())} />
                <Route path='/help-center' element={lazyLoadRouteEl(<HelpCenter />)} />
                <Route path="/register" element={lazyLoadRouteEl(<Register />)} />
                <Route path="/edit" element={<EditPage />} />
              </Routes>
            </BrowserRouter>
          </AliveScope>
          {/* <GuideOverlay/> */}
        </ConfigProvider>
      </Provider>
      <ServiceWindow />
    </div>
  );
}

export default App;
