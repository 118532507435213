import { deepCloneV2 } from "../../../utils";
import { SpeciesInfoType } from "../../../utils/types";
import { DocItem, DocItemType } from "../../slate-editor";
import { updateDocItemPlainText } from "./editor-doc-item-generate";

/**
 * 07-24最新版规划助手前台拉丁名填充
 * 1、检查该段落是否有相应的(latinName)类似结构，
 * 如果有，则需要删除，并且重置该段落样式配置
 * 2、执行填充
 */
export const fillSpeciesLatinName = (
    docItemList: DocItem[],
    speciesInfoList: SpeciesInfoType[],
    notFillFamilyName?: boolean   //不要合并科名
) => {
    //执行预检,暂时先重置样式吧，后续做更精准的合并
    let needToReplaceDocItemRecords: { index: number, newDocItem: DocItem }[] = [];
    docItemList.forEach((docItem, index) => {
        // console.log("docItem----->plainText", docItem.plainText, docItem);
        let needResetStyleMap: boolean = false;
        let newPlainText = docItem.plainText;
        if (
            docItem.type == DocItemType.CP ||
            docItem.type == DocItemType.P
        ) {
            speciesInfoList.forEach(speciesInfo => {
                // let familyLatinNameStr = ` (${speciesInfo.family}）`;
                // let speciesLatinNameStr = ` (${speciesInfo.canorical_me}）`;
                let familyLatinNameStr = `(${speciesInfo.family})`;
                let speciesLatinNameStr = `(${speciesInfo.canorical_me})`;
                // console.log("familyLatinNameStr--->", familyLatinNameStr);
                // console.log("speciesLatinNameStr--->", speciesLatinNameStr);
                if (docItem.plainText && docItem.plainText.includes(familyLatinNameStr)) {
                    needResetStyleMap = true;
                    newPlainText = newPlainText.replaceAll(familyLatinNameStr, '');
                    // docItem.plainText.replaceAll(familyLatinNameStr, '');
                }
                if (docItem.plainText && docItem.plainText.includes(speciesLatinNameStr)) {
                    needResetStyleMap = true;
                    newPlainText = newPlainText.replaceAll(speciesLatinNameStr, '');
                    // docItem.plainText.replaceAll(speciesLatinNameStr, '');
                }
            })
        }
        if (needResetStyleMap) {
            docItem.text = newPlainText;
            docItem.plainText = newPlainText;
            docItem.textStyleMap = {};
            needToReplaceDocItemRecords.push({
                index,
                newDocItem: deepCloneV2(docItem)
            })
        }
    })
    needToReplaceDocItemRecords.forEach(record => {
        docItemList[record.index] = record.newDocItem;
    })
    //开始填充科名
    //开始填充拉丁名
    let speciesAppearance = {};
    let genusAppearance = {};
    let textStyleMapIndex = 5000;
    const speciesOrderMap = new Map();
    speciesInfoList.forEach((item, index) => {
        let docItemIndex = -1;
        let docItemTextIndexOf = -1;
        const findIndex = docItemList.findIndex(docItem => docItem.plainText && docItem.plainText.indexOf(item.species_c) !== -1);
        if (findIndex != -1) {
            docItemIndex = findIndex;
            docItemTextIndexOf = docItemList[findIndex].plainText.indexOf(item.species_c);
        }
        speciesOrderMap.set(item.species_c, { info: item, docItemIndex, docItemTextIndexOf })
    });
    const speciesOrderList = Array.from(speciesOrderMap.values());
    speciesOrderList.sort((a, b) => {
        if (a.docItemIndex !== b.docItemIndex) {
            return a.docItemIndex - b.docItemIndex;
        } else {
            return a.docItemTextIndexOf - b.docItemTextIndexOf;
        }
    });
    const speciesInfoListSorted = speciesOrderList
        .map(ele => ele.info)
        .filter(ele => ele.genus && ele.canorical_me);
    docItemList.forEach(docItem => {
        if (!docItem.textStyleMap) {
            docItem.textStyleMap = {};
        }
        let needToUpdatePlainText = false;
        speciesInfoListSorted.forEach(({ genus, canorical_me, species_c }) => {
            // let regex = new RegExp(`${species_c}(\\s*\\(.*?\\))?(?!\\s*\\()`, "g");
            let regex = new RegExp(`${species_c}(\\s*\\(.*?\\))?(?!\\s*\\()(?!(科|属))`, "g");
            let match = null;
            while ((match = regex.exec(docItem.text)) !== null) {
                // 如果物种已经被处理过，跳过
                if (speciesAppearance[species_c]) continue;
                const isGenusFirstAppearance = genusAppearance[genus] === undefined;
                genusAppearance[genus] = false;
                speciesAppearance[species_c] = true;
                const placeholder = `$$[${textStyleMapIndex}]`;
                // console.log("失败---->123", genus, canorical_me, species_c)
                let latinName: string = isGenusFirstAppearance ? canorical_me : `${genus[0]}. ${canorical_me.split(" ")[1]}`;
                if (isGenusFirstAppearance) {
                    const latinNameList: string[] = latinName.split(' ');
                    if (latinNameList.length > 2) {
                        let beforeLatinName = '';
                        let afterLatinName = '';
                        latinNameList.forEach((latinNameSlice, latinNameSliceIndex) => {
                            if (latinNameSliceIndex < 2) {
                                beforeLatinName = beforeLatinName + latinNameSlice + ' ';
                            } else {
                                afterLatinName = afterLatinName + latinNameSlice + ' ';
                            }
                        })
                        docItem.textStyleMap[placeholder] = {
                            text: beforeLatinName,
                            italic: true,
                        };
                        textStyleMapIndex++;
                        const afterPlaceholder = `$$[${textStyleMapIndex}]`;
                        docItem.textStyleMap[afterPlaceholder] = {
                            text: afterLatinName,
                            italic: false,
                        };
                        let replacementText = `${species_c} (${placeholder}${afterPlaceholder})`;
                        docItem.text = docItem.text.substring(0, match.index) + replacementText + docItem.text.substring(match.index + match[0].length);
                    } else {
                        let replacementText = `${species_c} (${placeholder})`;
                        docItem.text = docItem.text.substring(0, match.index) + replacementText + docItem.text.substring(match.index + match[0].length);
                        docItem.textStyleMap[placeholder] = {
                            text: latinName,
                            // italic: isGenusFirstAppearance, 
                            italic: true,  //第一次出现，那么就全部使用斜体
                        };
                    }
                } else {
                    //第二次出现，那么就要区分了
                    let latinNameBefore = `${genus[0]}. `;
                    let latinNameAfter = `${canorical_me.split(" ")[1]}`
                    textStyleMapIndex++; // 索引递增
                    const afterPlaceholder = `$$[${textStyleMapIndex}]`;
                    docItem.textStyleMap[placeholder] = {
                        text: latinNameBefore,
                        // italic: isGenusFirstAppearance
                        italic: false
                    };
                    docItem.textStyleMap[afterPlaceholder] = {
                        text: latinNameAfter,
                        italic: true,
                    };
                    let replacementText = `${species_c} (${placeholder}${afterPlaceholder})`;
                    docItem.text = docItem.text.substring(0, match.index) + replacementText + docItem.text.substring(match.index + match[0].length);
                }
                needToUpdatePlainText = true;
                textStyleMapIndex++;
            }
        });
        if (needToUpdatePlainText) {
            docItem = updateDocItemPlainText(docItem)
        }
    });
    return docItemList;
}



export const fillSpeciesLatinNameV2 = (
    docItemList: DocItem[],
    speciesInfoList: SpeciesInfoType[],
    notFillFamilyName?: boolean   //不要合并科名
) => {
    //执行预检,暂时先重置样式吧，后续做更精准的合并
    let needToReplaceDocItemRecords: { index: number, newDocItem: DocItem }[] = [];
    // docItemList.forEach((docItem, index) => {
    //     // console.log("docItem----->plainText", docItem.plainText, docItem);
    //     let needResetStyleMap: boolean = false;
    //     let newPlainText = docItem.plainText;
    //     if (
    //         docItem.type == DocItemType.CP ||
    //         docItem.type == DocItemType.P
    //     ) {
    //         speciesInfoList.forEach(speciesInfo => {
    //             // let familyLatinNameStr = ` (${speciesInfo.family}）`;
    //             // let speciesLatinNameStr = ` (${speciesInfo.canorical_me}）`;
    //             let familyLatinNameStr = `(${speciesInfo.family})`;
    //             let speciesLatinNameStr = `(${speciesInfo.canorical_me})`;
    //             // console.log("familyLatinNameStr--->", familyLatinNameStr);
    //             // console.log("speciesLatinNameStr--->", speciesLatinNameStr);
    //             if (docItem.plainText && docItem.plainText.includes(familyLatinNameStr)) {
    //                 needResetStyleMap = true;
    //                 newPlainText = newPlainText.replaceAll(familyLatinNameStr, '');
    //                 // docItem.plainText.replaceAll(familyLatinNameStr, '');
    //             }
    //             if (docItem.plainText && docItem.plainText.includes(speciesLatinNameStr)) {
    //                 needResetStyleMap = true;
    //                 newPlainText = newPlainText.replaceAll(speciesLatinNameStr, '');
    //                 // docItem.plainText.replaceAll(speciesLatinNameStr, '');
    //             }
    //         })
    //     }
    //     if (needResetStyleMap) {
    //         docItem.text = newPlainText;
    //         docItem.plainText = newPlainText;
    //         docItem.textStyleMap = {};
    //         needToReplaceDocItemRecords.push({
    //             index,
    //             newDocItem: deepCloneV2(docItem)
    //         })
    //     }
    // })
    // needToReplaceDocItemRecords.forEach(record => {
    //     docItemList[record.index] = record.newDocItem;
    // })
    //开始填充科名
    //开始填充拉丁名
    let speciesAppearance = {};
    let genusAppearance = {};
    let textStyleMapIndex = 5000;
    const speciesOrderMap = new Map();
    speciesInfoList.forEach((item, index) => {
        let docItemIndex = -1;
        let docItemTextIndexOf = -1;
        const findIndex = docItemList.findIndex(docItem => docItem.plainText && docItem.plainText.indexOf(item.species_c) !== -1);
        if (findIndex != -1) {
            docItemIndex = findIndex;
            docItemTextIndexOf = docItemList[findIndex].plainText.indexOf(item.species_c);
        }
        speciesOrderMap.set(item.species_c, { info: item, docItemIndex, docItemTextIndexOf })
    });
    const speciesOrderList = Array.from(speciesOrderMap.values());
    speciesOrderList.sort((a, b) => {
        if (a.docItemIndex !== b.docItemIndex) {
            return a.docItemIndex - b.docItemIndex;
        } else {
            return a.docItemTextIndexOf - b.docItemTextIndexOf;
        }
    });
    const speciesInfoListSorted = speciesOrderList
        .map(ele => ele.info)
        .filter(ele => ele.genus && ele.canorical_me);
    docItemList.forEach(docItem => {
        if (!docItem.textStyleMap) {
            docItem.textStyleMap = {};
        }
        let needToUpdatePlainText = false;
        speciesInfoListSorted.forEach(({ genus, canorical_me, species_c }) => {
            // let regex = new RegExp(`${species_c}(\\s*\\(.*?\\))?(?!\\s*\\()`, "g");
            let regex = new RegExp(`${species_c}(\\s*\\(.*?\\))?(?!\\s*\\()(?!(科|属))`, "g");
            let match = null;
            while ((match = regex.exec(docItem.text)) !== null) {
                // 如果物种已经被处理过，跳过
                if (speciesAppearance[species_c]) continue;
                const isGenusFirstAppearance = genusAppearance[genus] === undefined;
                genusAppearance[genus] = false;
                speciesAppearance[species_c] = true;
                const placeholder = `$$[${textStyleMapIndex}]`;
                // console.log("失败---->123", genus, canorical_me, species_c)
                let latinName: string = isGenusFirstAppearance ? canorical_me : `${genus[0]}. ${canorical_me.split(" ")[1]}`;
                if (isGenusFirstAppearance) {
                    const latinNameList: string[] = latinName.split(' ');
                    if (latinNameList.length > 2) {
                        let beforeLatinName = '';
                        let afterLatinName = '';
                        latinNameList.forEach((latinNameSlice, latinNameSliceIndex) => {
                            if (latinNameSliceIndex < 2) {
                                beforeLatinName = beforeLatinName + latinNameSlice + ' ';
                            } else {
                                afterLatinName = afterLatinName + latinNameSlice + ' ';
                            }
                        })
                        docItem.textStyleMap[placeholder] = {
                            text: beforeLatinName,
                            italic: true,
                        };
                        textStyleMapIndex++;
                        const afterPlaceholder = `$$[${textStyleMapIndex}]`;
                        docItem.textStyleMap[afterPlaceholder] = {
                            text: afterLatinName,
                            italic: false,
                        };
                        let replacementText = `${species_c} (${placeholder}${afterPlaceholder})`;
                        docItem.text = docItem.text.substring(0, match.index) + replacementText + docItem.text.substring(match.index + match[0].length);
                    } else {
                        let replacementText = `${species_c} (${placeholder})`;
                        docItem.text = docItem.text.substring(0, match.index) + replacementText + docItem.text.substring(match.index + match[0].length);
                        docItem.textStyleMap[placeholder] = {
                            text: latinName,
                            // italic: isGenusFirstAppearance, 
                            italic: true,  //第一次出现，那么就全部使用斜体
                        };
                    }
                } else {
                    //第二次出现，那么就要区分了
                    let latinNameBefore = `${genus[0]}. `;
                    let latinNameAfter = `${canorical_me.split(" ")[1]}`
                    textStyleMapIndex++; // 索引递增
                    const afterPlaceholder = `$$[${textStyleMapIndex}]`;
                    docItem.textStyleMap[placeholder] = {
                        text: latinNameBefore,
                        // italic: isGenusFirstAppearance
                        italic: false
                    };
                    docItem.textStyleMap[afterPlaceholder] = {
                        text: latinNameAfter,
                        italic: true,
                    };
                    let replacementText = `${species_c} (${placeholder}${afterPlaceholder})`;
                    docItem.text = docItem.text.substring(0, match.index) + replacementText + docItem.text.substring(match.index + match[0].length);
                }
                needToUpdatePlainText = true;
                textStyleMapIndex++;
            }
        });
        if (needToUpdatePlainText) {
            docItem = updateDocItemPlainText(docItem)
        }
    });
    return docItemList;
}
