import './table.scss'
import React, { useEffect, useState } from 'react';
import { DocItemExternalData } from '../../types';
import { ColSpanInfo, RowSpanInfo } from '../../../../utils/types';
import { isNumber, replaceUnitUpperCase } from '../../../../utils';

interface Props {
    docItemExternalData: DocItemExternalData
}

const EditableTable = (props: Props) => {

    const {
        docItemExternalData
    } = props;

    const [currentTableName, setCurrentTableName] = useState<string>("");
    const [currentTableDesc, setCurrentTableDesc] = useState<string>("");
    const [currentTableData, setCurrentTableData] = useState<string[][]>([]);
    const [currentTableColSpanList, setCurrentTableColSpanList] = useState<ColSpanInfo[]>([]);
    const [currentTableRowSpanList, setCurrentTableRowSpanList] = useState<RowSpanInfo[]>([]);
    const [currentTableHeaderRowIndex, setCurrentTableHeaderRowIndex] = useState(0);

    useEffect(() => {
        if (docItemExternalData) {

            const {
                tableData = [],
                tableDesc = '',
                tableName = '',
                rowSpanInfoList = [],
                colSpanInfoList = [],
                tableHeaderEndRowIndex = 0,
            } = docItemExternalData;
            setCurrentTableName(tableName);
            setCurrentTableDesc(tableDesc);
            setCurrentTableData(tableData);
            setCurrentTableColSpanList(colSpanInfoList);
            setCurrentTableRowSpanList(rowSpanInfoList)
            setCurrentTableHeaderRowIndex(tableHeaderEndRowIndex);
        }

    }, [docItemExternalData])

    const findColSpanByIndex = (rowIndex: number, columnIndex: number) => {
        const findColSpanInfo = currentTableColSpanList.find(colSpanInfo => {
            return colSpanInfo.colIndex == columnIndex && rowIndex == colSpanInfo.rowIndex;
        })
        if (findColSpanInfo && findColSpanInfo.colSpan) {
            return findColSpanInfo;
        }
        return null;
    }

    const findRowSpanInfo = (rowIndex: number, columnIndex: number): RowSpanInfo => {
        const rowSpanInfo = currentTableRowSpanList.find(rowSpanItem => {
            return rowSpanItem.colIndex == columnIndex && rowSpanItem.rowIndex === rowIndex;
        })
        if (rowSpanInfo && rowSpanInfo.rowSpan) {
            return rowSpanInfo;
        }
        return null;
    }

    const findColSpanInfo = (rowIndex: number, columnIndex: number): ColSpanInfo => {
        const colSpanInfo = currentTableColSpanList.find(colSpanItem => {
            return colSpanItem.colIndex == columnIndex && colSpanItem.rowIndex == rowIndex;
        })
        if (colSpanInfo && colSpanInfo) {
            return colSpanInfo;
        }
        return null;
    }

    const checkTdNeedRander = (rowIndex: number, colIndex: number): boolean => {
        let needRander: boolean = true;
        //检查tableRowSpanList, 如果colIndex 相等，判断是否是rowIndex + colSpan区间内
        currentTableRowSpanList.forEach(tableRowSpanItem => {
            if (
                tableRowSpanItem.colIndex === colIndex &&
                rowIndex > tableRowSpanItem.rowIndex &&
                rowIndex < tableRowSpanItem.rowIndex + tableRowSpanItem.rowSpan
            ) {
                needRander = false;
            }
        })
        currentTableColSpanList.forEach(tableColSpanItem => {
            if (
                tableColSpanItem.rowIndex === rowIndex &&
                colIndex > tableColSpanItem.colIndex &&
                colIndex < tableColSpanItem.colIndex + tableColSpanItem.colSpan
            ) {
                needRander = false;
            }
        })

        return needRander;
    }


    if (currentTableData.length == 0) {
        return null
    }

    const getLatinColIndex = () => {
        return currentTableData[0].findIndex(cellText => cellText == '拉丁文名');
    }


    function isNumeric(str) {
        try {
            str = str + '';
            for (let char of str) {
                if (char < '0' || char > '9') {
                    return false;
                }
            }
            return str.length > 0;
        } catch (e) {
            return false;
        }
    }

    return (
        <div
            style={{
                width: '100%',
            }}
            contentEditable={false}
        >
            <table
                border={1}
                className='word-table'
                contentEditable={false}
            >
                <tbody>
                    {
                        currentTableData.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex + ''}>
                                    {
                                        row.map((cellValue, colIndex) => (
                                            <React.Fragment
                                                key={colIndex + ''}
                                            >
                                                {
                                                    checkTdNeedRander(rowIndex, colIndex) ?
                                                        <td
                                                            // key={colIndex + ''}
                                                            style={
                                                                {
                                                                    fontWeight: rowIndex <= currentTableHeaderRowIndex ? 'bold' : 'normal',
                                                                    textAlign: 'center',
                                                                    fontSize: '10.5pt',
                                                                    fontStyle: getLatinColIndex() > -1 && colIndex == getLatinColIndex() && rowIndex > currentTableHeaderRowIndex ? 'italic' : 'normal',
                                                                    fontFamily: getLatinColIndex() > -1 && colIndex == getLatinColIndex() && rowIndex > currentTableHeaderRowIndex ? 'TimesNewRoman' : 'SourceHanSerif',
                                                                    whiteSpace: isNumeric(cellValue) ? 'nowrap' : 'normal',
                                                                    wordBreak: isNumeric(cellValue) ? 'keep-all' : 'normal',
                                                                }
                                                            }
                                                            //纵向合并
                                                            rowSpan={findRowSpanInfo(rowIndex, colIndex) ? findRowSpanInfo(rowIndex, colIndex).rowSpan : 1}
                                                            //横向合并
                                                            colSpan={findColSpanInfo(rowIndex, colIndex) ? findColSpanInfo(rowIndex, colIndex).colSpan : 1}
                                                        >
                                                            {replaceUnitUpperCase(cellValue)}
                                                        </td>
                                                        :
                                                        null
                                                }
                                            </React.Fragment>

                                        ))}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}


export default EditableTable;