import React, { ReactNode } from 'react';
import { isEmpty, makeClassNameList } from '../../utils';
import { SelectOption } from '../../utils/types';


interface Props {
    options: SelectOption[]
    value: string
    onSelect: (value: string, option: SelectOption) => any
    handleClose: Function
    selectTarget?: 'label' | 'value'
    hideLable?: boolean
    maxHeight?: number
    width?: number
    customOptionRender?: (option: SelectOption, index: number) => ReactNode
    emptyText?: string
}

interface State {
}

export default class CustomSelect extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    };

    public readonly state: Readonly<State> = {
    };

    onSelectOption(value: string, option: SelectOption) {
        if (option && option.disable) {
            return
        }
        const { onSelect, handleClose } = this.props;
        onSelect && onSelect(value, option);
        handleClose && handleClose();
    };

    defaultOptionRender(option: SelectOption, index: number): ReactNode {
        const {
            hideLable = false
        } = this.props;
        return (
            <React.Fragment
                key={index + ''}
            >
                {
                    hideLable ?
                        option.icon
                        :
                        <>
                            {
                                option.icon ?
                                    <div className='flex-row custom-select-item-icon'>
                                        {option.icon}
                                    </div>
                                    :
                                    null
                            }
                            {option.label}
                        </>
                }
            </React.Fragment>
        )
    };

    render() {
        const {
            options,
            value,
            selectTarget = 'label',
            hideLable = false,
            maxHeight = 420,
            // width = `calc(100% - 8px)`,
            width = `100%`,
            emptyText = '暂无数据',
            customOptionRender
        } = this.props;
        return (
            <div
                className='flex-col custom-select-container'
                style={{ maxHeight: maxHeight, width: width }}
            >
                {
                    options && options.length ?
                        options.map((option, index) => {
                            return (
                                <div
                                    className={makeClassNameList([
                                        'flex-row custom-select-item',
                                        value == option.value ? 'active' : null,
                                        // isEmpty(value) && index == 0 ? 'custom-select-item-hover' : null,
                                        option.disable ? 'custom-select-item-disable' : null
                                    ])}
                                    // style={{ pointerEvents: option.disable ? 'none' : 'all' }}
                                    key={'select-item-' + index}
                                    onClick={() => this.onSelectOption(selectTarget == 'label' ? option.label : option.value, option)}
                                >
                                    {
                                        customOptionRender ?
                                            customOptionRender(option, index)
                                            :
                                            this.defaultOptionRender(option, index)
                                    }
                                </div>
                            )
                        })
                        :
                        <div
                            className={makeClassNameList([
                                'flex-row custom-select-item custom-select-item-empty',
                            ])}
                        >
                            {emptyText}
                        </div>
                }
            </div>
        )
    }
}