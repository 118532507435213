import React, { forwardRef } from 'react';
import OptionBarBtn from '../../../components/option-bar-btn/OptionBarBtn';
import { FormatEraseItem, FormatPainterItem } from '../../slate-editor/components/toolbar/components';
import emitter from '../../../utils/emitter';

interface Props {
    docEditorRef: any
}


const WordEditorClipboard = (props: Props, ref: any) => {
    
    const {
        docEditorRef
    } = props;

    const handleCopyTextContentFromWord = () => {
        emitter.emit('onWordCopy')
    };

    const handlePasteTextContentToWord = () => {
        emitter.emit('onWordPaste')
    };

    return (
        <div className='edit-page-option-row-item flex-col' style={{ width: '240px' }}>
            <div className='edit-page-option-row-half flex-row' style={{ height: '100%' }}>
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-fuzhi' />}
                    size={'large'}
                    name={'复制'}
                    // onClick={handleCopyCellText}
                    tooltipTitle={'复制'}
                    tooltipDesc={'复制所选内容，并将其放入系统粘贴板(仅限纯文本)'}
                    onClick={handleCopyTextContentFromWord}
                />
                <OptionBarBtn
                    icon={<i style={{ fontSize: '22px' }} className='iconfont-1 ghzs-gray-niantie' />}
                    size={'large'}
                    name={'粘贴'}
                    // onClick={handlePasteToCell}
                    tooltipTitle={'粘贴'}
                    tooltipDesc={'粘贴内容到指定位置(仅限纯文本)'}
                    onClick={handlePasteTextContentToWord}
                />
                {/* <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-geshishua' />}
                    size={'large'}
                    name={'格式刷'}
                    // onClick={handleCopyCellText}
                    tooltipTitle={'格式刷'}
                    tooltipDesc={'复制所选内容，并将其放入系统粘贴板'}
                /> */}
                <FormatPainterItem editor={docEditorRef}/>
                <FormatEraseItem editor={docEditorRef}/>
            </div>
        </div>
    )
}

export default forwardRef(WordEditorClipboard)