import React from 'react';

import { BoldConfig, FontFamilyConfig, FontSizeConfig } from '../../constants';
import { useEmitSlateItemClick } from '../../hooks';
import { SlateElementProps } from '../../types';
import { createClassNameMark } from '../../utils';

import Style from './main.module.scss';

const classNames = createClassNameMark(Style);
export const Paragraph = React.memo(function Paragraph({ attributes, children, element, style }: SlateElementProps) {
  const onClick = useEmitSlateItemClick(element);
  return (
    <p
      {...attributes}
      style={{
        ...style,
        textAlign: element.align || 'justify',
        fontSize: FontSizeConfig.p,
        fontFamily: FontFamilyConfig.p,
        fontWeight: BoldConfig.p ? 'bold' : undefined,
        lineHeight: 1.83 * Number(FontSizeConfig.p.replace('pt', '')) + 'pt',
      }}
      data-doc-item-id={element.id}
      className={classNames('doc-paragraph')}
      onClick={onClick}
    >
      {children}
    </p>
  );
});
