import React from 'react';

import { Editable } from 'slate-react';
//@ts-ignore
import formatPainterIcon from './../../assets/img/format-painter.cur';
import { ColorConfig, FontFamilyConfig } from '../../constants';
import { useFormatPainterActive } from '../../hooks';

export const EditableWrap = React.memo(function EditableWrap(props: Parameters<typeof Editable>[0]) {
  const activated = useFormatPainterActive();
  return (
    <Editable
      {...props}
      style={{
        color: ColorConfig.default,
        fontFamily: FontFamilyConfig.default,
        cursor: activated ? `url(${formatPainterIcon}), pointer` : undefined,
      }}
    >
      {props.children}
    </Editable>
  );
});

// export const EditableWrap = (props: Parameters<typeof Editable>[0]) => {
//   const activated = useFormatPainterActive();
//   return (
//     <Editable
//       {...props}
//       style={{
//         color: ColorConfig.default,
//         fontFamily: FontFamilyConfig.default,
//         cursor: activated ? `url(${formatPainterIcon}), pointer` : undefined,
//       }}
//     >
//       {props.children}
//     </Editable>
//   );
// };
