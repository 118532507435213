const createDistribute = () => {
  let state = new Map();

  const emit = (key: string, ...data: any) => {
    state.get(key)?.(...data);
  };

  const sub = (key: string, fun: Function) => {
    state.set(key, fun);
  };

  const off = (key: string) => {
    state.delete(key);
  };

  return {
    sub,
    emit,
    off,
  };
};

const emitter = createDistribute();

export default emitter;
