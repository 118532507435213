import React, { useCallback, useEffect, useMemo } from 'react';

import { Button } from 'antd';
import { Editor } from 'slate';

import { FontSizeConfig, FontSizeEnum } from '../../../constants';
import { useMatchMark, useMatchSlateItem } from '../hooks';
import { ItemProps } from '../types';
import OptionBarBtn from '../../../../../components/option-bar-btn/OptionBarBtn';

/**
 * 文字大小操作工具项
 */
export const FontSizeOperationItem = React.memo(function FontSizeOperationItem({
  editor,
  isIncrease = true,
}: ItemProps & { isIncrease: boolean }) {
  const format = 'fontSize';
  const fontSizeList = useMemo(() => {
    const data = Object.values(FontSizeEnum).sort((a, b) => parseFloat(a) - parseFloat(b));
    if (!isIncrease) data.reverse();
    return data;
  }, [isIncrease]);
  const slateItemType = useMatchSlateItem(editor)?.type || 'default';
  const mark = (useMatchMark(editor, format) as string) || FontSizeConfig[slateItemType];
  const toggle: React.MouseEventHandler<HTMLElement> = useCallback(() => {
    let index = fontSizeList.findIndex((item) => item === mark);
    if (index >= fontSizeList.length - 1) index = 0;
    else index += 1;
    const curEditor = editor?.current?.editor;
    if (!curEditor) return;
    Editor.addMark(curEditor, format, fontSizeList[index]);
  }, [editor, fontSizeList, mark]);
  useEffect(() => { }, [editor, mark]);
  // return <Button onMouseDown={toggle}>字号{{ true: '加大', false: '减小' }[`${isIncrease}`]}</Button>;
  return (
    <OptionBarBtn
      icon={<i className={isIncrease ? 'iconfont ghzs-color-a-zu737' : 'iconfont ghzs-color-a-mengbanzu71'}
        style={{ fontSize: 20 }} />}
      size={'small'}
      tooltipTitle={{ true: '加大字号', false: '减小字号' }[`${isIncrease}`]}
      onMouseDown={toggle}
    // tooltipDesc={'Shift-Command-(+)'}
    />
  )
});
