export const isValidStringKeyCode = (keyCode: number) => {
  // 空格和异常
  if (keyCode == 32 || keyCode == 229) return true;
  return [
    // 数字
    [47, 58],
    // 字母
    [64, 91],
    // 数字键盘
    [95, 108],
    // 数字键盘
    [108, 112],
    // 符号
    [185, 193],
    // 符号
    [218, 223],
  ].some(([start, end]) => keyCode > start && keyCode < end);
};
