import React, { useEffect, useRef, useState } from 'react';

import { Button, ColorPicker } from 'antd';

import { ColorConfig } from '../../../constants';
import { useChangeColor, useMatchMark } from '../hooks';
import { ItemProps } from '../types';
import OptionBarBtn from '../../../../../components/option-bar-btn/OptionBarBtn';
import CustomPopover from '../../../../../components/popover/CustomPopover';
import CustomColorPicker from '../../../../../components/color-picker/CustomColorPicker';

/**
 * 文字颜色工具项
 */
export const ColorItem = React.memo(function ColorItem({ editor, visible }: ItemProps) {
  const format = 'color';
  const btnRef = useRef(null);
  const [value, setValue] = useState<string>(ColorConfig.default);
  const changeColor = useChangeColor(editor, format);
  const mark = (useMatchMark(editor, format) as string) || ColorConfig.default;
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const colorPickerRef = useRef(null);
  useEffect(() => {
    setValue(mark);
  }, [mark]);

  useEffect(() => {
    if (btnRef.current) {
      const { top, left } = btnRef.current.getBoundingClientRect();
      // console.log("top, left--->", top, left)
      setPosition({ top, left });
    }
  }, [visible])

  const openColorPicker = () => {
    colorPickerRef.current.show();
  }

  const onPick = (e) => {
    changeColor(e);
  }

  // return (
  //   <ColorPicker value={`${value}`} onChange={changeColor}>
  //     <Button style={{ color: value }}>字体颜色</Button>
  //   </ColorPicker>
  // );
  return (
    <div ref={btnRef}>
      <OptionBarBtn
        icon={(
          <div className='custom-option-bar-icon-container'>
            <i style={{ fontSize: 20, marginTop: '-2px' }} className='iconfont ghzs-color-a-zu743' />
            <div
              className='custom-option-bar-icon-bgline'
              style={{backgroundColor: value}}
            ></div>
          </div>
        )}
        size={'small'}
        tooltipTitle={"字体颜色"}
        tooltipDesc={'设置文字的颜色'}
        selectEnable={true}
        onClickArrow={openColorPicker}
      />
      <CustomPopover
        ref={colorPickerRef}
        clientX={position.left}
        clientY={position.top + 36}
        disableBackground
        contentRender={(props) =>
          <CustomColorPicker
            {...props}
            onPickerColor={onPick}
            screenName={'文字颜色'}
            screenTag={"fontColor"}
          />
        }
      />
    </div>
  )
});
