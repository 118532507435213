import { ReactEditor, RenderElementProps } from 'slate-react';

import { BaseSlateItemType } from '../../../types';

export const withInput = (editor: ReactEditor) => {
  const { isInline, isVoid, markableVoid } = editor;
  const isInput = (element: RenderElementProps['element']) =>
    (element as unknown as { type: BaseSlateItemType }).type === BaseSlateItemType.Input;
  editor.isInline = (element) => isInput(element) || isInline(element);
  editor.isVoid = (element) => isInput(element) || isVoid(element);
  editor.markableVoid = (element) => isInput(element) || markableVoid(element);
  return editor;
};
