import React from 'react';

import { useEmitSlateItemClick } from '../../hooks';
import { SlateElementProps } from '../../types';
import { createClassNameMark } from '../../utils';

import Style from './main.module.scss';
import EditableTable from './EditableTable';

const classNames = createClassNameMark(Style);
export const SlateTable = React.memo(function SlateTable({ attributes, children, element }: SlateElementProps) {
  const { externalData } = element;
  const onClick = useEmitSlateItemClick(element);
  return (
    <div {...attributes} data-doc-item-id={element.id} className={classNames('doc-table')} onClick={onClick}>
      {
        externalData && externalData.tableData ?
          <EditableTable
            docItemExternalData={externalData}
          />
          :
          null
      }
    </div>
  );
});
