import React, { forwardRef, useContext, useEffect, useState } from 'react';
import OptionBarBtn from '../../../components/option-bar-btn/OptionBarBtn';
import comDocContext from '../../../context/DocContext';
import { copyTextToClipboard, deepCopy, defaultExcelCellStyleConfig, defaultExcelFormatBrushConfig, handleStopPropagation, toastShort } from '../../../utils';
import { TableDataCell } from '../../../types';
import { ExcelFormatBrush } from '../../../utils/types';

interface Props {
    focusTableCellList: TableDataCell[]
    _handleClearCellStyle: Function
}

interface State {
}

const ExcelEditorClipboard = (props: Props, ref: any) => {

    const {
        _pasteCellText,
        comExcelFormatBrushConfig,
        _setComExcelFormatBrushConfig
    } = useContext(comDocContext);

    const {
        focusTableCellList,
        _handleClearCellStyle
    } = props;

    const [tempExcelFormatBrushConfig, setTempExcelFormatBrushConfig] = useState<ExcelFormatBrush>(defaultExcelFormatBrushConfig);

    const [focusTableCell, setFocusTableCell] = useState<TableDataCell>(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // 当按下Esc键时执行的代码
                console.log('Esc键被按下');
                if (tempExcelFormatBrushConfig.enable) {
                    let _tempExcelFormatBrushConfig = tempExcelFormatBrushConfig;
                    _tempExcelFormatBrushConfig.enable = false;
                    _tempExcelFormatBrushConfig.tableDataCellList = [];
                    toastShort('info', '格式刷已关闭')
                    _setComExcelFormatBrushConfig({ ..._tempExcelFormatBrushConfig })
                }
            }
        };

        // 组件挂载时添加事件监听器
        document.addEventListener('keydown', handleKeyDown);

        // 组件卸载时移除事件监听器
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (focusTableCellList && focusTableCellList.length === 1) {
            setFocusTableCell(focusTableCellList[0])
            window.addEventListener('paste', onPasteListener);
            return () => {
                window.removeEventListener('paste', onPasteListener);
            }
        }
    }, [focusTableCellList])

    useEffect(() => {
        if (comExcelFormatBrushConfig && comExcelFormatBrushConfig.tableDataCellList && comExcelFormatBrushConfig.tableDataCellList.length) {
            if (comExcelFormatBrushConfig.enable) {
                toastShort('success', '格式刷已激活')
            } else {
                toastShort('info', '格式刷已关闭')
            }
            setTempExcelFormatBrushConfig({ ...comExcelFormatBrushConfig })
        }
    }, [comExcelFormatBrushConfig])

    const onPasteListener = (e) => {
        //@ts-ignore
        const clipdata = e.clipboardData || window.clipboardData;
        const pasteText = clipdata.getData("text/plain");
        handlePasteToCell(pasteText);
    }

    const handlePasteToCell = async (text?: string) => {
        if (focusTableCell && focusTableCell.bound) {
            if (text) {
                _pasteCellText(text)
            } else {
                try {
                    const clipboardContentText = await navigator.clipboard.readText();
                    console.log("clipboardContentText--->", clipboardContentText)
                    _pasteCellText(clipboardContentText)
                } catch (e) {
                    console.error("e--->", e)
                    toastShort('error', '当前非安全环境，无法访问粘贴板内容')
                }
            }
        } else {
            toastShort('warning', '没有选中的单元格')
        }
    }

    const handleCutCellText = () => {
        if (focusTableCell && focusTableCell.bound) {
            _pasteCellText('');
            copyTextToClipboard(focusTableCell.text)
            if (focusTableCell.editble) {
                toastShort('success', '已复制到粘贴板！')
            }
        } else {
            toastShort('warning', '没有选中的单元格')
        }
    }

    const handleCopyCellText = () => {
        if (focusTableCell && focusTableCell.bound) {
            copyTextToClipboard(focusTableCell.text);
            toastShort('success', '已复制到粘贴板！')
        } else {
            toastShort('warning', '没有选中的单元格')
        }
    }

    const handleClearCellStyle = () => {
        if (focusTableCellList && focusTableCellList.length) {
            _handleClearCellStyle && _handleClearCellStyle(focusTableCellList);
        } else {
            toastShort('warning', '没有选中的单元格')
        }
    }

    const handleSwitchExcleFormatBrushConfig = () => {
        console.log("focusTableCellList-->", focusTableCellList)
        let _tempExcelFormatBrushConfig = tempExcelFormatBrushConfig;
        _tempExcelFormatBrushConfig.enable = !_tempExcelFormatBrushConfig.enable;
        _tempExcelFormatBrushConfig.tableDataCellList = deepCopy(focusTableCellList);
        // setTempExcelFormatBrushConfig({..._tempExcelFormatBrushConfig});
        _setComExcelFormatBrushConfig({ ..._tempExcelFormatBrushConfig })
    }

    return (
        <div 
            className='edit-page-option-row-item flex-col' 
            style={{ width: '240px' }}
            onMouseDown={handleStopPropagation}
        >
            <div className='edit-page-option-row-half flex-row' style={{ height: '100%' }}>
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-fuzhi' />}
                    size={'large'}
                    name={'复制'}
                    onClick={handleCopyCellText}
                    tooltipTitle={'复制'}
                    tooltipDesc={'复制所选内容，并将其放入系统粘贴板'}
                />
                <OptionBarBtn
                    icon={<i style={{ fontSize: '22px' }} className='iconfont-1 ghzs-gray-niantie' />}
                    size={'large'}
                    name={'粘贴'}
                    onClick={handlePasteToCell}
                    tooltipTitle={'粘贴'}
                    tooltipDesc={'粘贴内容到指定位置'}
                />
                {/* <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-jianqie' />}
                    size={'large'}
                    name={'剪切'}
                    onClick={handleCutCellText}
                    tooltipTitle={'格式刷(⌘ + X)'}
                    tooltipDesc={'剪切所选内容，并将其放入系统粘贴板'}
                /> */}
                <OptionBarBtn
                    icon={<i className='iconfont-1 ghzs-gray-geshishua' />}
                    size={'large'}
                    name={'格式刷'}
                    onClick={handleSwitchExcleFormatBrushConfig}
                    active={tempExcelFormatBrushConfig && tempExcelFormatBrushConfig.enable}
                    tooltipTitle={'格式刷'}
                    tooltipDesc={'复制所选内容的格式，应用到不同位置的内容中\n此控件可持续使用，以便于将格式应用到多个位置的内容中（再次点击或ESC可退出格式刷）'}
                />
                <OptionBarBtn
                    icon={<i style={{ fontSize: '22px' }} className='iconfont-1 ghzs-gray-xiangpica' />}
                    size={'large'}
                    name={'格式擦'}
                    onClick={handleClearCellStyle}
                    tooltipTitle={'格式擦'}
                    tooltipDesc={'清除应用的单元格样式'}
                />
            </div>
        </div>
    )
}
export default forwardRef(ExcelEditorClipboard)