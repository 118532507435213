import { forwardRef, useContext, useEffect, useRef, useState } from "react"
import OptionBarBtn from "../../../components/option-bar-btn/OptionBarBtn";
import SelectPopover from "../../../components/popover/SelectPopover";
import useReSize from "../../../hooks/useResize";
import { SelectOptionType } from "../../../types";
import comDocContext from '../../../context/DocContext';
import { DocConfig } from "../../../utils/types";
import { adaptDocMoneyCardinalNumberName, deepCopy, isEmpty } from "../../../utils";
import { CityOne, Percentage } from "@icon-park/react";
import OtherExpensesPopover from "../../../components/popover/OtherExpensesPopover";

interface Props {
    handleRecommandMainProjectDevice: Function
}
const ExcelDocToolsOptionBar = (props: Props, ref: any) => {

    const {
        comFocusTableCellList,
        comDocConfig,
        _setComDocConfig,
        comDocInstance,
        _setComDocInstance,
        comCellStyleConfig,
        _setComCellStyleConfig
    } = useContext(comDocContext);

    const {
        handleRecommandMainProjectDevice
    } = props;

    const yuanBtnRef = useRef<any>(null);
    const containerRef = useRef<any>(null);
    const containerSize = useReSize(containerRef);
    const yuanPopoverRef = useRef<any>(null);
    const decimalNumberPopoverRef = useRef(null);
    const decimalNumberBtnRef = useRef(null);

    const otherExpensesPopoverRef = useRef(null);
    const otherExpensesBtnRef = useRef(null);

    const [yuanPopoverPosition, setYuanPopoverPosition] = useState({ clientX: 0, clientY: 0 });
    const [otherExpensesPosition, setOtherExpensesPosition] = useState({ clientX: 0, clientY: 0 });
    const [decimalNumberPopoverPosition, setDecimalNumberPopoverPosition] = useState({ clientX: 0, clientY: 0 });
    const [currentDocConfig, setCurrentDocConfig] = useState<DocConfig>({});

    const thousandYuanPopoverOptions: SelectOptionType[] = [
        {
            label: '万元',
            value: 1
        },
        {
            label: '千元',
            value: 10
        },
        {
            label: '元',
            value: 10000
        }
    ]

    const decimalNumberPopoverOptions: SelectOptionType[] = [
        {
            label: '0',
            value: 0,
        },
        {
            label: '0.1',
            value: 0.1,
        },
        {
            label: '0.01',
            value: 0.01,
        },
        {
            label: '0.001',
            value: 0.001,
        },
        {
            label: '0.0001',
            value: 0.0001,
        },
    ]

    useEffect(() => {
        setCurrentDocConfig(comDocConfig);
    }, [comDocConfig])

    useEffect(() => {
        const clientRect = yuanBtnRef.current.getBoundingClientRect();
        if (clientRect) {
            const { top, left } = clientRect;
            setYuanPopoverPosition({ clientX: left, clientY: top + 64 })
        }
        const clientRect1 = decimalNumberBtnRef.current.getBoundingClientRect();
        if (clientRect1) {
            const { top, left } = clientRect1;
            setDecimalNumberPopoverPosition({ clientX: left, clientY: top + 64 })
        }
        const clientRect2 = otherExpensesBtnRef.current.getBoundingClientRect();
        if (clientRect2) {
            const { top, left } = clientRect2;
            setOtherExpensesPosition({ clientX: left, clientY: top + 64 })
        }
    }, [containerSize])


    const handleOpenYuanPopover = () => {
        yuanPopoverRef.current.openPopover();
    }

    const onThousandYuanSelectChange = (e: number) => {
        let _tempDocConfig = currentDocConfig;
        _tempDocConfig.docBaseConfigInfo.docMoneyCardinalNumber = e;
        _tempDocConfig = deepCopy(_tempDocConfig);
        setCurrentDocConfig(_tempDocConfig);
        _tempDocConfig.updateComponentName = 'EditPageNav';
        _setComDocConfig(_tempDocConfig);
        let _tempComDocInstance = comDocInstance;
        _tempComDocInstance.updateComponentName = 'EditPageNav';
        _setComDocInstance(_tempComDocInstance);
    }

    const handleOpenDecimalNumberPopover = () => {
        decimalNumberPopoverRef.current.openPopover();
    }

    const onDecimalNumberSelectChange = (e: number) => {
        let _tempDocConfig = currentDocConfig;
        _tempDocConfig.docBaseConfigInfo.decimalNumber = e;
        _tempDocConfig = deepCopy(_tempDocConfig);
        setCurrentDocConfig(_tempDocConfig);
        _tempDocConfig.updateComponentName = 'EditPageNav';
        _setComDocConfig(_tempDocConfig);
        let _tempComDocInstance = comDocInstance;
        _tempComDocInstance.updateComponentName = 'EditPageNav';
        _setComDocInstance(_tempComDocInstance);
    }

    const handleOpenOtherExpensesPopover = () => {
        otherExpensesPopoverRef.current.openPopover();
    }

    return (
        <div
            ref={containerRef}
            className='edit-page-option-row-item flex-col'
            style={{ width: '112px' }}
        >
            <div className='edit-page-option-row-half flex-row' style={{ height: '100%' }}>
                {/* <OptionBarBtn
                    icon={<i style={{ fontSize: '22px' }} className='iconfont-1 ghzs-gray-shoucang' />}
                    size={'large'}
                    name={'收藏'}
                /> */}
                <div
                    ref={yuanBtnRef}
                    id="ExcelToolDocMoneyCardinalNumberId"
                >
                    <OptionBarBtn
                        icon={<i className='iconfont-1 ghzs-gray-wanyuan' />}
                        size={'large'}
                        name={adaptDocMoneyCardinalNumberName(comDocConfig)}
                        selectEnable
                        onClick={handleOpenYuanPopover}
                    />
                </div>
                <div
                    style={{ marginRight: 12 }}
                    ref={decimalNumberBtnRef}
                    id="ExcelToolDecimalNumberId"
                >
                    <OptionBarBtn
                        icon={
                            <div style={{ fontSize: 24, fontWeight: '600', transform: `scaleX(0.72)` }}>
                                {comDocConfig && comDocConfig.docBaseConfigInfo && !isEmpty(comDocConfig.docBaseConfigInfo.decimalNumber) ? comDocConfig.docBaseConfigInfo.decimalNumber : 0.01}
                            </div>
                        }
                        size={'large'}
                        name={'保留位数'}
                        onClick={handleOpenDecimalNumberPopover}
                        tooltipTitle={"保留位数"}
                        tooltipDesc="调整表格中数值的小数点保留位数"
                    />
                </div>
                <div
                    ref={otherExpensesBtnRef}
                    id="ExcelToolOtherExpensesId"
                >
                    <OptionBarBtn
                        icon={<Percentage style={{ marginBottom: -2 }} theme="outline" size="21" fill="#333" strokeWidth={4.5} />}
                        size={'large'}
                        name={'其它费用'}
                        onClick={handleOpenOtherExpensesPopover}
                        tooltipTitle={"费用占比设置"}
                        tooltipDesc="自由调整其它费用、工程预备费占比，自由添加其它费用细则"
                    />
                </div>
                <div className='edit-page-option-row-border' style={{ marginLeft: 12, marginRight: 12 }}></div>
                <div
                    style={{ marginLeft: 12 }}
                    id="ExcelToolMainProjectRecommandId"
                >
                    <OptionBarBtn
                        // icon={<i className='iconfont-1 ghzs-gray-zhongdianxiangmu' />}
                        icon={<CityOne theme="outline" size={22} />}
                        size={'large'}
                        name={'重点工程推荐'}
                        onClick={handleRecommandMainProjectDevice}
                        tooltipTitle={"重点工程推荐"}
                        tooltipDesc="系统根据保护地的信息智能推荐重点工程项目"
                    />
                </div>

            </div>
            <SelectPopover
                ref={yuanPopoverRef}
                clientX={yuanPopoverPosition.clientX}
                clientY={yuanPopoverPosition.clientY}
                value={comDocConfig && comDocConfig.docBaseConfigInfo && comDocConfig.docBaseConfigInfo.docMoneyCardinalNumber}
                options={thousandYuanPopoverOptions}
                onChange={onThousandYuanSelectChange}
            />
            <SelectPopover
                ref={decimalNumberPopoverRef}
                clientX={decimalNumberPopoverPosition.clientX}
                clientY={decimalNumberPopoverPosition.clientY}
                value={comDocConfig && comDocConfig.docBaseConfigInfo && !isEmpty(comDocConfig.docBaseConfigInfo.decimalNumber) ? comDocConfig.docBaseConfigInfo.decimalNumber : 0.01}
                options={decimalNumberPopoverOptions}
                onChange={onDecimalNumberSelectChange}
            />
            <OtherExpensesPopover
                ref={otherExpensesPopoverRef}
                clientX={otherExpensesPosition.clientX}
                clientY={otherExpensesPosition.clientY}
            />
        </div>
    )
}


export default forwardRef(ExcelDocToolsOptionBar);
