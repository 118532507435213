import React, { useCallback, useEffect, useState } from 'react';

import { Select } from 'antd';
import { Editor } from 'slate';

import { FontSizeConfig, FontSizeOptions } from '../../../constants';
import { useMatchMark, useMatchSlateItem } from '../hooks';
import { ItemProps } from '../types';
import SelectWidthToolip from '../../../../../components/select/SelectWidthToolip';

/**
 * 文字字体大小工具项
 */
export const FontSizeItem = React.memo(function FontSizeItem({ editor }: ItemProps) {
  const format = 'fontSize';
  const [value, setValue] = useState<string>(FontSizeConfig.default);
  const changeFont = useCallback(
    (value: string) => {
      const curEditor = editor?.current?.editor;
      if (!curEditor) return;
      Editor.addMark(curEditor, format, value);
    },
    [editor],
  );
  const slateItemType = useMatchSlateItem(editor)?.type || 'default';
  const mark = (useMatchMark(editor, format) as string) || FontSizeConfig[slateItemType];
  useEffect(() => {
    setValue(mark);
  }, [mark]);
  // return <Select value={`${value}`} options={FontSizeOptions} onChange={changeFont} />;
  return (
    <SelectWidthToolip
      size={'small'}
      value={`${value}`}
      options={FontSizeOptions}
      onChange={changeFont}
      tooltipTitle={'字号大小'}
      tooltipDesc={''}
      selectStyle={{ width: '68px' }}
      selectOptionStyle={{ width: '62px' }}
      selectClassName={'option-bar-select'}
      selectTarget={'value'}
    />
  )
});
