import { Fragment, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Modal } from 'antd'
import { CloudUploadOutlined, LoadingOutlined, LeftOutlined, ExclamationCircleOutlined, } from '@ant-design/icons';
import './EditorPageNav.scss';
import { deepCloneV2, filterTopicListToNetServe, formatTime, getCurrentDateNs, isEmpty, toastShort } from '../../../utils';
import { DocConfig, DocInstance, EditPageNavType, TopicType, UserType } from '../../../utils/types';
import redux from '../../../redux/store';
import comDocContext from '../../../context/DocContext';
import systemApi from '../../../api/system';
import useStateDebounce from '../../../hooks/useStateDebounce';
import DocInstanceSettingPopover from '../../../components/popover/DocInstanceSettingPopover';
import useReSize from "../../../hooks/useResize";
import emitter from '../../../utils/emitter';
import AddProjectModal from '../../home-page/modal/AddProjectModal';
import { DocSettingConfigParams, DocSettingMenuType } from '../../../types';
import UserServiceModal from '../../../components/user-center/UserServiceModal';
import { useNavigate } from 'react-router-dom';
import { setSystemConfig } from '../../../redux/system.slice';
import UserCenterModal from '../../../components/user-center/UserCenterModal';
import UserAvatar from '../../../components/user-center/UserAvatar';
import { HeadsetOne, Helpcenter } from '@icon-park/react';
import ArrowNavItem from './ArrowNavItem';
import { saasConfig } from '../../../config/theme-config';
import { isDevelopment } from '../../../utils/environment';
import FeedBackModal from '../../../components/user-center/FeedBackModal';
import { showGuide } from '../../../guide/guide-steps-config';

interface Props {
    onEditorNavChange?: Function
    onNavigateBack?: Function
}

interface SegmentedOptionType {
    label: string
    value: string
}

const EditorPageNav = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        handleSetDefaultActiveNavType
    }))

    const {
        comDocInstance,
        comDocConfig,
        _setBaseMode,
    } = useContext(comDocContext);

    const {
        onEditorNavChange,
        onNavigateBack
    } = props;

    const userCenterModalRef = useRef<any>(null);
    const addProjectModalRef = useRef<any>(null);
    const userServiceModalRef = useRef<any>(null);
    const feedBackModalRef = useRef<any>(null);
    const delayTimer = useRef<any>(null);
    const containerRef = useRef(null);
    const docInstanceSettingPopoverRef = useRef(null);
    const [docInstanceSettingPopoverPosition, setDocInstanceSettingPopoverPosition] = useState<{ clientX: number, clientY: number }>({ clientX: 0, clientY: 0 });

    const [navList, setNavList] = useState<SegmentedOptionType[]>([
        {
            label: '规划项目选择',
            value: 'topic'
        },
        {
            label: '投资估算表格',
            value: 'excel'
        },
        {
            label: '规划报告撰写',
            value: 'word'
        }
    ])
    const [activeNavType, setActiveNavType] = useState<EditPageNavType>('topic');
    const [saveLoading, setSaveLoading] = useState(false);
    const [userInfo, setuserInfo] = useState<UserType>(redux.getState().systemConfig.userInfo);
    const debouncedComDocInstance = useStateDebounce(comDocInstance, 1500);
    const lastCachedComDocInstance = useRef<DocInstance>(comDocConfig)
    const debouncedComDocConfig = useStateDebounce(comDocConfig, 2 * 1000);
    const [lastUpdateDate, setLastUpdateDate] = useState(null);
    const delayAllowUpdateDocTimer = useRef(null);
    //禁用上传到服务器
    // const [disableUpdateToServe] = useState(isDevelopment());
    const [disableUpdateToServe] = useState(false);
    //
    const containerSize = useReSize(containerRef);

    const handleSetDefaultActiveNavType = (type: EditPageNavType) => {
        setActiveNavType(type);
    }

    const updateDocTimes = useRef(null);

    useEffect(() => {
        emitter.sub('AutoSwitchNavType', (e: { newNavType: EditPageNavType }) => {
            const { newNavType } = e;
            setActiveNavType(newNavType);
            onEditorNavChange(newNavType);
        })
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current);
            delayAllowUpdateDocTimer.current && clearTimeout(delayAllowUpdateDocTimer.current);
        }
    }, [])

    useEffect(() => {
        const el = document.querySelector('div[id=luckysheet-input-box]');
        const markerEl = document.querySelector('div[id=luckysheet-input-box-index]');
        if (activeNavType == 'excel') {
            el && el.setAttribute('style', 'display: block');
            markerEl && markerEl.setAttribute('style', 'display: block');
        } else {
            el && el.setAttribute('style', 'display: none');
            markerEl && markerEl.setAttribute('style', 'display: none');
        }
    }, [activeNavType])

    useEffect(() => {
        // console.log("containerSize--->", containerSize)
        setDocInstanceSettingPopoverPosition({
            clientX: containerSize.width - 252,
            clientY: 52
        })
    }, [containerSize])

    const formatDateStr = (dateStr: string) => {
        const date = new Date(dateStr ? dateStr : null);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDate;
    }

    useEffect(() => {
        console.log("导航栏开始更新---->", comDocInstance)
        if (comDocInstance) {
            lastCachedComDocInstance.current = comDocInstance;
            if (isEmpty(lastUpdateDate)) {
                // console.log("更新时间---->", comDocInstance.updateDate)
                setLastUpdateDate(comDocInstance.updateDate ? formatDateStr(comDocInstance.updateDate) : formatTime(getCurrentDateNs(13)) + '本地存储');
            }
        }
        if (!debouncedComDocInstance) {
            return;
        }
        updateDocTimes.current++;
        // console.log("updateDocTimes--->", updateDocTimes.current)
        let tempComDocInstance: DocInstance = deepCloneV2(comDocInstance);
        if (updateDocTimes.current <= 2) {
            return;
        }
        if (disableUpdateToServe) {
            return toastShort('warning', '即时保存已关闭');
        }
        setSaveLoading(true);
        tempComDocInstance.docId = tempComDocInstance.id;
        if (tempComDocInstance && filterTopicListToNetServe(tempComDocInstance.topicList).length) {
            let tempTopicList = [];
            let { topicList } = tempComDocInstance;
            topicList.forEach(topic => {
                let tempTopic: TopicType = deepCloneV2(topic);
                if (
                    tempTopic.topicSlateDocItemList &&
                    tempTopic.topicSlateDocItemList.length &&
                    tempTopic.topicSlateDocItemList instanceof Array
                ) {
                    //@ts-ignore
                    tempTopic.topicSlateDocItemList = JSON.stringify(tempTopic.topicSlateDocItemList)
                }
                tempTopicList.push(tempTopic);
            })
            tempComDocInstance.topicList = deepCloneV2(tempTopicList);
            // return;
            systemApi.updateProjectTopicList(tempComDocInstance)
                .then(res => {
                    setSaveLoading(false);
                    setLastUpdateDate(getCurrentDateNs());
                })
                .catch(err => {
                    toastShort('error', '数据同步失败，请检查网络连接')
                })
        }
    }, [debouncedComDocInstance])

    useEffect(() => {
        if (disableUpdateToServe) {
            return toastShort('warning', '即时保存已关闭');
        }
        if (!debouncedComDocConfig || !debouncedComDocInstance) {
            return;
        }
        let tempDocConfig: DocConfig = comDocConfig;
        tempDocConfig.id = comDocInstance.id;
        tempDocConfig.docId = comDocInstance.id;
        systemApi.updateDocExtraConfigInfo(tempDocConfig)
            .then(res => {
            })
            .catch(err => {
                console.log("更新失败", err)
            })
    }, [debouncedComDocConfig])


    const [modal, contextHolder] = Modal.useModal();

    const handleCloseEditPage = () => {
        modal.confirm({
            title: '温馨提示',
            icon: <ExclamationCircleOutlined />,
            content: '确认离开此规划编制页面吗？请确保所有修改已保存',
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: comfrimToCloseEditPage,
        });
    }

    const comfrimToCloseEditPage = () => {
        onNavigateBack && onNavigateBack();
    }

    const handleSwitchEditorNav = (nav: SegmentedOptionType) => {
        //@ts-ignore
        setActiveNavType(nav.value);
        onEditorNavChange && onEditorNavChange(nav.value);
    };

    const onMouseDown = (e: any) => {
        e.stopPropagation();
    };

    const switchBarTrackLeft = useMemo<number>(() => {
        switch (activeNavType) {
            case 'topic':
                return 3;
                break;
            case 'excel':
                return 121;
                break;
            case 'word':
                return 119 * 2;
                break;
            default:
                break;
        }
    }, [activeNavType])

    const showSettingPopover = () => {
        docInstanceSettingPopoverRef.current.openPopover();
    }

    const navigate = useNavigate();

    const onDocSettingMenuSelect = (type: DocSettingMenuType, configParams?: DocSettingConfigParams) => {
        if (!configParams) {
            configParams = {};
        }
        const {
            isSaveLoginAccountCache
        } = configParams;
        switch (type) {
            case 'UserCenter':
                userCenterModalRef.current.openModal();
                break;
            case 'DocBaseInfo':
                addProjectModalRef.current.openModal(comDocInstance);
                break;
            case 'VersionList':
                _setBaseMode('VersionPreview')
                break;
            case 'ServiceModal':
                if (saasConfig.systemType !== 'HuaWeiTestServer') {
                    userServiceModalRef.current.openModal();
                } else {
                    emitter.emit("openServiceWindow");
                }
                break;
            case 'HelpCenter':
                // const href = window.location.origin + '/help-center';
                // window.open(href, '_blank')
                window.open('https://www.bilibili.com/video/BV1G58Ee3Emw/?spm_id_from=333.999.0.0&vd_source=1d95c27b8d944550edd8697b7198cdcc', '_blank')
                break;
            case 'FeedBack':
                feedBackModalRef.current.openModal();
                break;
            case 'LoginOut':
                modal.confirm({
                    title: '温馨提示',
                    icon: <ExclamationCircleOutlined />,
                    content: '确认退出登录？',
                    okText: '确认',
                    cancelText: '取消',
                    centered: true,
                    onOk: confirmToLoginOut,
                    okButtonProps: { loading: false }
                });
                if (!isSaveLoginAccountCache) {
                    localStorage.removeItem('account')
                }
                break;
            case 'UserGuide':
                setActiveNavType('topic');
                onEditorNavChange && onEditorNavChange('topic');
                showGuide('TopicManagePageV3');
                break;
            default:
                break;
        }
    }

    const confirmToLoginOut = () => {
        localStorage.removeItem('systemConfig');
        localStorage.removeItem('token');
        redux.dispatch(setSystemConfig(null))
        //退出
        navigate('/login')
    }

    const handleJumpToWordEditPage = () => {
        setActiveNavType('word');
        onEditorNavChange && onEditorNavChange('word');
    }

    return (
        <div
            ref={containerRef}
            className='flex-row edit-page-header'
            onMouseDown={onMouseDown}
            onMouseUp={onMouseDown}
            onClick={onMouseDown}
        >
            <div className='flex-row edit-page-header-side edit-page-header-left'>
                <div
                    className='flex-row header-nav-back-btn'
                    onClick={handleCloseEditPage}
                >
                    <LeftOutlined style={{ fontSize: 16, fontWeight: 900 }} />
                    退出
                </div>
                <div className='flex-col project-info'>
                    <div className='project-name'>{comDocInstance && comDocInstance.name}</div>
                    <div className='flex-row project-info-bottom'>
                        <div style={{ maxWidth: 300 }} className='project-info-text single-line-text'>{userInfo && userInfo.nickName ? userInfo.nickName : userInfo && userInfo.account}</div>
                        {/* <div className='project-info-border-transform'></div> */}
                        {/* <div className='project-info-text single-line-text'>创建者</div> */}
                        <div className='project-info-border'></div>
                        <div className='flex-row project-info-status'>
                            {
                                saveLoading ?
                                    <Fragment>
                                        <LoadingOutlined style={{ fontSize: 12, color: '#fff' }} spin />
                                        <div className='project-info-status-text'>保存中</div>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <CloudUploadOutlined style={{ fontSize: 12, color: '#fff' }} />
                                        <div className='project-info-status-text'> {lastUpdateDate && "保存于：" + formatTime(lastUpdateDate)}</div>
                                    </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex-row nav-arrow'>
                <div className='nav-arrow-angle-left'></div>
                <div className='nav-arrow-angle-right'></div>
                <div id='editPageNavId' className='flex-row nav-arrow-content'>
                    {
                        navList.map((item, index) => {
                            return (
                                <ArrowNavItem
                                    id={`docNavItem${index}`}
                                    key={'nav-item-' + index}
                                    isActive={activeNavType == item.value}
                                    title={item.label}
                                    onClick={() => handleSwitchEditorNav(item)}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <div className='flex-row edit-page-header-side edit-page-header-right'>
                <div
                    id='jumpToWordPageFakeBtnId'
                    onClick={handleJumpToWordEditPage}
                    style={{ width: 0, display: 'none' }}
                >
                    手动调整
                </div>
                {
                    saasConfig.systemType === 'QingYuanDefault' ?
                        <>
                            <div
                                className='flex-col nav-setting-btn'
                                onClick={() => onDocSettingMenuSelect('HelpCenter')}
                            >
                                <div
                                    className='flex-col nav-setting-btn-icon-container'
                                >
                                    <Helpcenter
                                        theme="outline"
                                        size="20"
                                        strokeWidth={3}
                                        fill="#ffffff"
                                    />
                                </div>
                                <div style={{ fontSize: 12, color: '#fff', marginTop: 1 }}>帮助</div>
                            </div>
                            <div
                                className='flex-col nav-setting-btn'
                                onClick={() => onDocSettingMenuSelect('ServiceModal')}
                            >
                                <div
                                    className='flex-col nav-setting-btn-icon-container'
                                >
                                    <HeadsetOne
                                        theme="outline"
                                        size="20"
                                        strokeWidth={3}
                                        fill="#ffffff"
                                    />
                                </div>
                                <div style={{ fontSize: 12, color: '#fff', marginTop: 1 }}>客服</div>
                            </div>
                            <div
                                className='flex-col nav-setting-btn'
                                onClick={() => onDocSettingMenuSelect('FeedBack')}
                            >
                                <div
                                    className='flex-col nav-setting-btn-icon-container'
                                >
                                    <img
                                        src={require('./../../../assets/image/feed-back-icon-light.png')}
                                        className='nav-setting-btn-icon'
                                    />
                                </div>
                                <div style={{ fontSize: 12, color: '#fff', marginTop: 1 }}>反馈</div>
                            </div>
                            <div
                                style={{
                                    height: 28,
                                    width: 2,
                                    backgroundColor: '#ffffff93',
                                    marginRight: 12
                                }}
                            >
                            </div>
                        </>
                        :
                        null
                }
                <UserAvatar
                    size={36}
                    style={{ marginRight: 5 }}
                    onClick={showSettingPopover}
                />
            </div>
            <DocInstanceSettingPopover
                ref={docInstanceSettingPopoverRef}
                clientX={docInstanceSettingPopoverPosition.clientX}
                clientY={docInstanceSettingPopoverPosition.clientY}
                onSelect={onDocSettingMenuSelect}
            />
            <UserCenterModal
                ref={userCenterModalRef}
            />
            <AddProjectModal
                ref={addProjectModalRef}
            />
            <UserServiceModal
                ref={userServiceModalRef}
            />
            <FeedBackModal
                ref={feedBackModalRef}
            />
            {contextHolder}
        </div>
    )
}

export default forwardRef(EditorPageNav)