import axios from 'axios'
import redux from './../redux/store';
import { toastShort } from '../utils';
import { removeSystemConfig, setSystemConfig } from '../redux/system.slice';
import { _navigate } from './../utils/global';
import { saasConfig } from '../config/theme-config';
axios.defaults.timeout = 20 * 1000;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.defaults.baseURL = saasConfig.nodeJsApiBaseUrl;
// axios.defaults.baseURL = 'http://192.168.31.153:3000';

axios.interceptors.request.use((config) => {
    config.headers.Accept = "application/json";
    config.headers['Content-Type'] = "application/json";
    if (localStorage.getItem('token')) {
        config.headers['authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }
    if (config.method === 'post') {
        delete config.data._id;
        config.data = JSON.stringify(config.data);
    }
    return config;
}, (error) => {
    console.log('错误的传参')
    return Promise.reject(error);
});

axios.interceptors.response.use((res) => {
    if (res.data.code == 200 || res.data.errcode == 200) {
        return Promise.resolve(res);
    } else if (res.data.code == 401) {
        toastShort("error", "授权状态已失效，请重新登陆！");
        localStorage.removeItem('systemConfig');
        redux.dispatch(removeSystemConfig({}))
        const originPath = window.location.origin;
        window.location.href = originPath + '/login'
    } else {
        toastShort('error', res.data.message)
    }
    return res;
}, (error) => {
    if (error.response.data.code == 401) {
        toastShort("error", "授权状态已失效，请重新登陆！");
        localStorage.removeItem('systemConfig');
        redux.dispatch(removeSystemConfig({}))
        const originPath = window.location.origin;
        window.location.href = originPath + '/login';
    } else {
        toastShort('error', error['response'].data.message ? error['response'].data.message : '操作失败，请稍后重试');
    }
    return Promise.reject(error);
});

declare namespace Service {
    interface ListResult {
        code: number
        data?: {
            list?: Array<any>
            pager?: {
                page: number
                size: number
                total: number
            }
            id?: string
        }
        msg: string
    }
}

const postRequest = (url, param): Promise<Service.ListResult> => {
    let requestUrl = url + "";
    if(requestUrl.includes('export-api')){
    }else {
        requestUrl = saasConfig.matchPath + url;
    }
    return new Promise((resolve, reject) => {
        axios.post(requestUrl, param)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
};


const getRequest = (url, param) => {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: param })
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export {
    postRequest,
    getRequest
}
