export enum FontSizeEnum {
  zero = '44.9pt',
  zeroSmall = '38.5pt',
  one = '27.8pt',
  oneSmall = '25.7pt',
  two = '23.5pt',
  twoSmall = '19.3pt',
  three = '17.1pt',
  threeSmall = '16pt',
  four = '15pt',
  fourSmall = '12.8pt',
  five = '11.2pt',
  fiveSmall = '10.5pt',
}
export const FontSizeOptions = [
  [FontSizeEnum.zero, '初号'],
  [FontSizeEnum.zeroSmall, '初小初号'],
  [FontSizeEnum.one, '一号'],
  [FontSizeEnum.oneSmall, '小一'],
  [FontSizeEnum.two, '二号'],
  [FontSizeEnum.twoSmall, '小二'],
  [FontSizeEnum.three, '三号'],
  [FontSizeEnum.threeSmall, '小三'],
  [FontSizeEnum.four, '四号'],
  [FontSizeEnum.fourSmall, '小四'],
  [FontSizeEnum.five, '五号'],
  [FontSizeEnum.fiveSmall, '小五'],
].map(([value, label]) => ({ value, label }));
