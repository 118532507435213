import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import OptionBarBtn from '../../../components/option-bar-btn/OptionBarBtn';
import { OptionBarActionType } from '../../../types';
import { Button, Divider, message } from 'antd';
import type { TourProps } from 'antd';
import SymbolIcon from '../../../components/icon/SymbolIcon';
import InsertToolsPopover from '../../../components/popover/InsertToolsPopover';
import comDocContext from '../../../context/DocContext';
import ExportPopover from '../../../components/popover/ExportPopover';
import useReSize from '../../../hooks/useResize';
import { handleStopPropagation } from '../../../utils';
import emitter from '../../../utils/emitter';

interface Props {
    type: 'word' | 'excel'
    getWordExportParams?: Function
    getExcelExportParams?: Function
    onClickEditorOptionBar?: (actionType: OptionBarActionType) => void
}

const EditorStateOptionBar = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({

    }))

    const {
        comDocInstance,
        _setComDocInstance,
        undoComDocInstance,
        redoComDocInsatnce,
    } = useContext(comDocContext);

    const {
        type,
        getWordExportParams,
        getExcelExportParams
    } = props;

    const containerRef = useRef(null);

    const containerSize = useReSize(containerRef);

    const [messageApi, contextHolder] = message.useMessage();

    const delayTimer = useRef<any>(null);
    const insertToolsPopoverRef = useRef<any>(null);

    const exportPopoverRef = useRef(null);

    const exportBtnRef = useRef(null);

    const [exportPopoverPosition, setExportPopoverPosition] = useState({ clientX: 0, clientY: 0 })


    useEffect(() => {
        if (type == 'excel') {
            emitter.sub('emitExportExcel', () => {
                handleOpenExportPopover();
            })
        }
        if (type == 'excel') {
            emitter.sub('emitHotSaveDocInstance', () => {
                handleSave();
            })
        }
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current)
        }
    }, [])

    useEffect(() => {
        const clientRect = exportBtnRef.current?.getBoundingClientRect();
        const { top, left } = clientRect;
        setExportPopoverPosition({ clientX: left - 25, clientY: top + 64 })
    }, [containerSize])

    const showInsertTools = () => {
        insertToolsPopoverRef.current.openPopover();
    }

    const _getExcelExportParams = () => {
        const params = getExcelExportParams();
        return params;
    }

    const _getWordExportParams = () => {
        const params = getWordExportParams();
        return params;
    }

    const handleSave = () => {
        messageApi.open({
            key: 'saveLoading',
            type: 'loading',
            content: '手动保存中...',
        });
        let _tempComDocInstance = comDocInstance;
        _tempComDocInstance.updateComponentName = 'EditPageNav';
        _setComDocInstance(_tempComDocInstance);
        delayTimer.current = setTimeout(() => {
            messageApi.open({
                key: 'saveLoading',
                type: 'success',
                content: '数据已保存!',
                duration: 1,
            });
        }, 800);
    }

    const handleUndoOptionHistory = () => {
        undoComDocInstance && undoComDocInstance();
    }

    const handleRedoOptionHistory = () => {
        redoComDocInsatnce && redoComDocInsatnce();
    }

    const handleOpenExportPopover = () => {
        exportPopoverRef.current.openPopover();
    }


    return (
        <div
            ref={containerRef}
            className='edit-page-option-row-item flex-col'
            style={{ width: '120px' }}
            onMouseDown={handleStopPropagation}
        >
            {/* {type + ''} */}
            <div className='edit-page-option-row-half flex-row' style={{ height: '100%' }}>
                {/* <div>
                    <OptionBarBtn
                        icon={<i style={{ fontSize: '26px' }} className='iconfont ghzs-color-zengjia' />}
                        // icon={<SymbolIcon iconName='ghzs-gray-fuzhi' style={{width: 18, height: 18, fill: '#ff0000'}}/>}
                        size={'large'}
                        name={'插入'}
                        onClick={showInsertTools}
                    />
                </div> */}
                <div>
                    <OptionBarBtn
                        icon={<i style={{ color: '#262626' }} className='iconfont-1 ghzs-gray-baocun' />}
                        size={'large'}
                        name={'保存'}
                        onClick={handleSave}
                    />
                </div>
                <div
                    ref={exportBtnRef}
                    className='DocExportBtnId'
                >
                    <OptionBarBtn
                        icon={
                            // <i style={{ fontSize: '21px' }} 
                            //     className={type == 'word' ? 'iconfont-1 ghzs-gray-daochuword' : 'iconfont-1 ghzs-gray-daochuexcel'}
                            // />
                            <>
                                {
                                    type == 'word' ?
                                        <img
                                            src={require('./../../../assets/image/export-word-file.png')}
                                            style={{ width: 36, height: 32 }}
                                        />
                                        :
                                        <img
                                            src={require('./../../../assets/image/export-excel-file.png')}
                                            style={{ width: 36, height: 32 }}
                                        />
                                }
                            </>

                        }
                        size={'large'}
                        name={'导出'}
                        onClick={handleOpenExportPopover}
                    />
                </div>
                {/* <div>
                    <OptionBarBtn
                        icon={<i style={{ color: '#262626' }} className='iconfont-1 ghzs-gray-chexiao' />}
                        size={'large'}
                        name={'撤销'}
                        onClick={handleUndoOptionHistory}
                    />
                </div>
                <div>
                    <OptionBarBtn
                        icon={<i style={{ color: '#262626' }} className='iconfont-1 ghzs-gray-zhongzuo' />}
                        size={'large'}
                        name={'重做'}
                        onClick={handleRedoOptionHistory}
                    />
                </div> */}
            </div>
            <InsertToolsPopover
                ref={insertToolsPopoverRef}
                clientX={12}
                clientY={112}
            />
            <ExportPopover
                ref={exportPopoverRef}
                clientX={exportPopoverPosition.clientX}
                clientY={exportPopoverPosition.clientY}
                comDocInstance={comDocInstance}
                type={type}
                exportExcel={_getExcelExportParams}
                exportWord={_getWordExportParams}
            />
            {contextHolder}
        </div>
    )
}



export default forwardRef(EditorStateOptionBar)