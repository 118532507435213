import React, { useContext, useState } from 'react';
// import './../../login/Login.scss';
// import './../EditPage.scss';
import { Segmented, Spin, Tabs, Checkbox, Button } from 'antd';
import { BrowserRouter, Routes, Route, Link, Navigate, useNavigate } from 'react-router-dom'
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { TopicType } from '../../../utils/types';
import { deepCopy, getCheckBoxChangeEventValue, getUuid, toastShort } from '../../../utils';
import comDocContext from '../../../context/DocContext';
import { generateBlockId } from '../word-editor-deprecated/WordEditorHelper';

interface Props {
    insertDocItemByTargetTopicName: Function
}
const BenefitEvaluationContent3 = (props: Props) => {

    const {
        insertDocItemByTargetTopicName
    } = props;

    const {
        comDocInstance,
    } = useContext(comDocContext);

    const [socialResultsTopicList, setSocialResultsTopicList] = useState<TopicType[]>([
        {
            id: 'social_results_topic_1',
            topicType: 'text',
            topicName: '提高环境保护意识，促进精神文明建设',
            checked: false,
            wordParagraphList: [
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_1',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]加强了与当地政府的联系，通过环境教育、资源管理计划的编制等工作，加深了社区居民对自然保护工作的理解，使其逐步认识到保护生态环境和[NAME]的重要性，从[NAME]建立之初的“要我保护”变为主动的为“我要保护”，有效地增强社区居民的保护意识及自觉性；由于社区居民的环境保护意识得到了显著的提高，资源破坏活动明显减少，入区从事林副业生产的人员有了大幅度的下降，加之村民也都积极主动的加入到[NAME]管理建设的工作中，使生态环境得到了有效的保护，使退化的生态资源得到了恢复，[NAME]内野生动植物资源得到了明显的增加，栖息环境及周边生态环境均得到明显的改善。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_1',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]内拥有丰富的生物资源和自然景观资源，不但能满足人们向往、回归大自然愿望，又是对人们进行自然保护宣传教育和科普教育的理想场所，唤起公众的自然保护意识，进一步推动自然保护事业的发展，[NAME]内的一草一木、一山一水及所有的保护设施，都是对人们进行环保教育的很好材料和课堂，有利于促进身心健康和精神文明建设，有利于激发人们热爱祖国，热爱大自然的真实情感。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_1',
                    paragraphType: 'custom_background_paragraph',
                    text: '建设环境友好型社会，促进生态文明通过[NAME]功能的发挥，将为经济社会发展做出更大贡献，确保本地生态安全，为建成[NAME]周边的环境友好型社会奠定坚实基础。随着[NAME]总体规划建设内容的完成，必将促进[NAME]及周边地区生态文明的发展。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_1',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]的建设，能提高全民环保意识，激发热爱自然的良好愿望。[NAME]内拥有丰富的生物资源和自然景观资源，不但能使人们领略大自然的无穷魅力，体味自然风光的无限情趣，而且[NAME]内的山山水水和建设的保护设施，都是对人们进行环保教育的最佳材料和课堂，都有利于促进身心健康，有利于激发人们热爱祖国、热爱家园、热爱自然的真实感情，有利于提高全民的环保意识，使全社会都来关心、支持[NAME]事业。'
                },
            ]
        },
        {
            id: 'social_results_topic_2',
            topicType: 'text',
            topicName: '促进人与自然和谐，践行可持续发展',
            checked: false,
            wordParagraphList: [
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_2',
                    paragraphType: 'custom_background_paragraph',
                    text: '通过[NAME]的建设，改善了人们的生产生活环境，为人们直接或间接地提供丰富的生物资源。同时，随着社会发展、物质文化生活的提高，人们愈来愈要求更多地接触大自然，获得娱乐和休养。[NAME]山清水秀的自然景观，可使人们在森林中敞开情怀，尽情地享受其原始美、自然美。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_2',
                    paragraphType: 'custom_background_paragraph',
                    text: '促进[NAME]及周边地区的共同发展，拉动区域社会经济发展，[NAME]建立后，通过社区共管项目的实施，向区内及周边居民传授实用技术，开展科学种植，促进社区经济的发展，减少人们对自然资源的依赖，有利于社会稳定，促进当地生态环境的保护。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_2',
                    paragraphType: 'custom_background_paragraph',
                    text: '通过社区共管和部门联合执法、会商、生态补偿等多方面政策措施的贯彻落实，和社区共管共荣体系的建设，形成一整套[NAME]与周边村镇基层组织、企事业单位和自然人等携手发展的模式，变原来的资源竞争、利害冲突关系为互助发展、患难与共关系；调动社会各方参与自然保护事业的责任感和积极性，把保护自然作为各方神圣职责并积极投身于其中，真正形成保护光荣，破坏自然可耻的社会风尚；这些使得[NAME]与社区发展之间的矛盾得到一定程度的缓解，减缓了强制保护造成的同当地社区的对立矛盾，通过把社区居民与[NAME]有机地结合在一起，使环境保护和社区经济都得到了有效的发展。最终能实现在保护环境。'
                },
            ]
        },
        {
            id: 'social_results_topic_3',
            topicType: 'text',
            topicName: '提升科研价值，创造科研机会',
            wordParagraphList: [
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_3',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]特殊的地理位置，多样的地貌土壤类型，复杂的植被类型，较为丰富的动、植物种类为生态学研究提供了理想的素材。通过监测自然生态环境的演替过程，可更准确地认识生态系统的发生、发展规律，认识生物物种之间的制约、依赖关系；为人类长期多功能、高效、科学利用资源和改善生存环境提供依据，为科技工作者提供一个理想的科研场所，增加了国际国内科研院所之间的科技交流，造就和吸引更多的科研人才从事[NAME]的保护事业'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_3',
                    text: '[NAME]展示了植物群落在自然界中丰富多彩的变化与存在、展示了人与自然界、人与生态系统之间的相互依存关系，为人类认识自然、保护生态系统提供良好的科学研究场所。为广大群众、青少年提供更好地了解自然、保护自然，并教育使其最终成为保护自然的参与者与实践者。向人们展示了环境科学、生物科学和其他科学的特点及相互间的发展关系。'
                }
            ]
        },
        {
            id: 'social_results_topic_4',
            topicType: 'text',
            topicName: '提供科学研究平台，吸引科研项目和资金',
            wordParagraphList: [
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_4',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]内，保存有丰富多样的生态系统和生物群落，以及多种珍稀濒危的物种资源，为进行各种相关的研究，提供了良好的基础平台，是进行生态学和生物学研究的天然实验室。随着[NAME]各项建设事业的发展，保护设施的进一步完善，对外交流的深入开展，[NAME]将成为国内外暖温带落叶阔叶林生态系统、珍稀植物研究、学校教学实习和科学普及与宣传教育的重要基地。将会吸引更多的中外专家、学者前来考察、交流、研究，从而进一步壮大科技队伍，提高科研层次与水准，使[NAME]发挥更大的社会效益。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_4',
                    text: '[NAME]丰富的野生资源，保护物种较为丰富，植被类型多样，是珍贵的物种基因库，是人类探索、研究、发掘物质资源重要的教学、科研基地。将成为各个大专院校、科研院所科学考察生态系统专科、生物标本采集的基地，通过国际、国内科研、技术合作及国外援华项目的开展，促进当地与国内外的交流与沟通，加速彼此间的信息交流，提高知名度。有利于吸收外部的人才、技术、资金和先进经验与成果，对繁荣科研与管理，促进技术推广，加速当地经济、文化等各方面有着重要意义。'
                }
            ]
        },
        {
            id: 'social_results_topic_5',
            topicType: 'text',
            topicName: '增加科研论著的产出数量',
            wordParagraphList: [
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_5',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]处于典型地理气候带上，不同自然地带都有其典型自然景观，是干扰敏感区，又是生物多样性较高的地区，保护的价值的是很高的；[NAME]内各类动植物物种十分丰富，并且有许多特有物种，这些都引起地理、生物学家的极大研究兴趣，对于研究生态系统的演变及其植物群落发生、发展规律和植被生长、环境适应性的研究具有重要意义，成为地学、生物学等基础学科的天然实验室。近十几年来[NAME]已成为群落生态学研究的热点，大量的生态学、林学和地理学论文以[NAME]作为研究对象，因此，[NAME]为推进科研研究、培养优秀科研人才做出了突出贡献，在未来会继续发挥其科研价值，增加其影响力。'
                }
            ]
        },
        {
            id: 'social_results_topic_6',
            topicType: 'text',
            topicName: '加强资源保护的意识和行动',
            wordParagraphList: [
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_6',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]的建设是一项公益性社会事业，通过[NAME]的规划实施将起到极大的宣传效应，密切[NAME]与人类的关系，提高人们的保护意识。通过[NAME]，向世人展示美丽的大自然景观、丰富的生物多样性和神奇的人文地理，这些不仅能使人们领略大自然的无穷魅力，体味自然风光的无限情趣，而且是对人们进行环保教育的最佳材料和天然课堂，有利于提高人们的环境保护意识。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_6',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]内拥有丰富的生物资源和自然景观资源，不但能使人们领略大自然的无穷魅力，体味自然风光的无限情趣，而且[NAME]内的山山水水和建设的保护设施，都是对人们进行环保教育的最佳材料和课堂，都有利于促进身心健康，有利于激发人们热爱祖国、热爱家园、热爱自然的真实感情，有利于提高全民的环保意识，使全社会都来关心、支持和投入到[NAME]事业中。'
                }
            ]
        },
        {
            id: 'social_results_topic_7',
            topicType: 'text',
            topicName: '提供教育实习基地',
            wordParagraphList: [
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_7',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]内，保存有丰富多样的生态系统和生物群落，以及多种珍稀濒危的物种资源，为进行各种相关的研究，提供了良好的基础平台，为开展科学研究、野外调查和文艺生野外绘画写真提供了天然场地，也成为北京市民和公众了解自然生态环境的提供了完美的教育基地。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_7',
                    paragraphType: 'custom_background_paragraph',
                    text: '通过规划实施，[NAME]将建设成为科学研究与教学实习场所、学生夏令营活动基地。在宣教方面，尤其在青少年一代人的教育方面必将发挥其重大作用和效益，可成为当地以及周边县市青少年保护自然生态环境的教育基地，[NAME]科研宣教体系的建立，可以有计划地安排教学实习、参观考察及青少年夏令营活动，利用[NAME]的宣教培训中心及陈列馆内设置的标本、模型、图片、录相等向人民普及生物学、自然地理等自然知识、并提供直接的感性教育，有利于提高人们对保护自然环境、保护珍稀濒危动植物的认识，使人们热爱自然、保护自然，进一步增强全社会的环保意识。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_7',
                    paragraphType: 'custom_background_paragraph',
                    text: '随着[NAME]各项建设事业的发展，保护设施的进一步完善，对外交流的深入开展，[NAME]将成为国内外生态系统、珍稀植物研究、学校教学实习和科学普及与宣传教育的重要基地。将会吸引更多的中外专家、学者前来考察、交流、研究，从而进一步壮大科技队伍，提高科研层次与水准，使[NAME]发挥更大的社会效益。'
                }
            ],
        },
        {
            id: 'social_results_topic_8',
            topicType: 'text',
            topicName: '提供环境教育活动的素材和场地',
            wordParagraphList: [
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_8',
                    paragraphType: 'custom_background_paragraph',
                    text: '随着自然保护事业与生态旅游的发展，专家、学者、新闻工作者和游客将纷至沓来，通过科考、探险、游憩、绘画、摄影、录像和宣传等活动，将使[NAME]的知名度更加扩大，高知名度带来的各种正面效益将不可估量。同时[NAME]向公众介绍、传播和展示自然保护事业所做工作及其所取得的物种保护成果，其社会影响力和感染力独特。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_8',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]是进行有关自然和自然保护宣传教育的场所。通过科考、实习、标本展示和各种介绍[NAME]的书籍、图片、画册、录像等向社会各界和前来参观的群众灌输自然和自然保护知识；[NAME]是向学生进行有关自然和自然保护宣传教育的自然博物馆和讲坛。通过向学生灌输自然和自然保护知识，培养下一代的环境保护意识。宣传保护生物资源尤其是保护珍稀濒危动植物资源的目的与意义；利用科普教育，提高人们对保护生态环境、保护生物资源和建立[NAME]的认识，增强人们热爱自然、保护自然的思想意识，并使之形成为优良的社会风尚。'
                }
            ],
        },
        {
            id: 'social_results_topic_9',
            topicType: 'text',
            topicName: '带动就业，增加周边居民收入',
            wordParagraphList: [
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_9',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]及周边社区由于经济基础薄弱、产业结构单一等多种原因，经济长期处于相对落后状态。[NAME]的的建设，提供了一定数量的劳务机会，为解决就业创造了有利条件，从而减轻再就业和发展生产的社会负担。[NAME]的建设和发展，将进一步带动[NAME]及周边地区交通、商业、服务业、旅游业、土特产品、农副产品等各业的发展，既可加强自身实力，又可为地方经济发展做出贡献。同时，[NAME]的建设有利于更新社区群众的思想观念，[NAME]开展的生态旅游和社区共建经营项目，也将带动第三产业的发展，增加周边社区居民的收入，提高所在地居民的物质文化生活水平，促进社会稳定，加速国民经济向前发展。此外，保护地的建设为社区群众提供更多的就业机会，优化产业结构，有利于社会安定和群众生活水平的提高，有利于促进[NAME]社区共管的良性循环，对促进区域经济发展具有重要意义。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_9',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]开展的生态旅游等项目，提供了一定数量的劳务机会，为解决就业创造了有利条件，从而减轻再就业和发展生产的社会负担。同时随着规划的执行，每年可安排200余名农村劳动力作为护林员参与[NAME]的森林管护。同时，[NAME]的其它工程项目建设也会提供农村劳动力就业机会，增加林区群众收入。'
                },
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_9',
                    paragraphType: 'custom_background_paragraph',
                    text: '促进[NAME]及周边地区的共同发展，拉动区域社会经济发展，[NAME]建立后，通过社区共管项目的实施，向区内及周边居民传授实用技术，开展科学种植，促进社区经济的发展，减少人们对自然资源的依赖，有利于社会稳定，促进当地生态环境的保护。'
                }
            ],
        },
        {
            id: 'social_results_topic_10',
            topicType: 'text',
            topicName: '提高人居环境质量，促进周边居民的身心健康',
            wordParagraphList: [
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_10',
                    paragraphType: 'custom_background_paragraph',
                    text: '通过对[NAME]进行充分的本底调查，了解了[NAME]中卫村庄和社区居民生产生活中存在的困难和不便，[NAME]社区管理处修建许多基础设施，如用水、用电、道路等，改善了当地居民生活条件，提高了村民生活质量。[NAME]的建设，减少外部的干扰并极大地降低龙了[NAME]及周边区域的污染水平，使得保护地能提供安全清洁水源、清新的空气，确保当地饮水安全和呼吸健康。自然保护地区及其周围环境内群峰起伏，峡谷幽深，气势雄伟，兼有众多的珍稀动植物、丰富的森林景观、人文景观及浓郁的民俗风情，让人们体验到大自然的美景，呼吸到[NAME]的清新空气，从而陶冶人们的情操，有益于人们的身心健康。'
                },
            ],
        },
        {
            id: 'social_results_topic_11',
            topicType: 'text',
            topicName: '提供休闲旅游度假场所，愉悦身心',
            wordParagraphList: [
                {
                    type: 'text',
                    textTopicId: 'social_results_topic_11',
                    paragraphType: 'custom_background_paragraph',
                    text: '[NAME]是人们进行生态科普旅游、观光的好去处。[NAME]有着丰富的动植物资源和山清水秀的自然景观，可使人们在森林中敞开情怀，尽情地享受其原始美、自然美。有利于促进身心健康和精神文明建设，有利于激发人们热爱祖国，热爱大自然的真实情感。当地居民可以在这里受到良好的生态文明教育和熏陶，是居民融入自然、亲近自然、了解自然、观赏动植物的最佳场所，可以满足市民休闲、娱乐和观光的需求。[NAME]具有自然观光、旅游、娱乐等美学方面的功能，除可创造直接的经济效益外，还具有重要的文化价值。在美化环境、调节气候、为居民提供休憩空间方面有着重要的社会效益。'
                },
            ],
        },
    ])

    const onTopicCheckedChange = (index: number, e: CheckboxChangeEvent) => {
        let _socialResultsTopicList = socialResultsTopicList;
        _socialResultsTopicList[index].checked = getCheckBoxChangeEventValue(e);
        setSocialResultsTopicList(deepCopy(_socialResultsTopicList));
    };

    const checkComfirmBtnDisabled = (): boolean => {
        let disabled = false;
        const checkedTopicList = socialResultsTopicList.filter(topic => {
            return topic.checked;
        })
        if (checkedTopicList.length == 0) {
            disabled  = true;
        }
        return disabled;
    }

    const handleInsertSocialResultsTopicList = () => {
        const checkedTopicList = socialResultsTopicList.filter(topic => {
            return topic.checked;
        })
        if (checkedTopicList.length == 0) {
            return toastShort("warning", "请勾选需要添加至报告社会效益的段落")
        }
        checkedTopicList.map(topic => {
            topic.wordParagraphList.forEach(wordParagraph => {
                wordParagraph.blockId = generateBlockId();
            })
        })
        insertDocItemByTargetTopicName &&
            insertDocItemByTargetTopicName([{
                topicName: "社会效益",
                topicListFragment: checkedTopicList
            }])
    }

    return (
        <div className='edit-page-tab-box word-editor-plugins-content benefit-evaluation-content'>
            <div className='edit-page-tab-item-box'>
                <div className='edit-page-tab-title'>促进生态文明建设</div>
                <div className='edit-page-tab-text'>
                    <Checkbox
                        checked={socialResultsTopicList[0].checked}
                        onChange={(e) => onTopicCheckedChange(0, e)}
                    >
                        提高环境保护意识，促进精神文明建设
                    </Checkbox>
                </div>
                <div className='edit-page-tab-text'>
                    <Checkbox
                        checked={socialResultsTopicList[1].checked}
                        onChange={(e) => onTopicCheckedChange(1, e)}
                    >促进人与自然和谐，践行可持续发展</Checkbox>
                </div>
            </div>
            <div className='edit-page-tab-item-box'>
                <div className='edit-page-tab-title'>促进科学研究</div>
                <div className='edit-page-tab-text'>
                    <Checkbox
                        checked={socialResultsTopicList[2].checked}
                        onChange={(e) => onTopicCheckedChange(2, e)}
                    >提升科研价值，创造科研机会</Checkbox>
                </div>
                <div className='edit-page-tab-text'>
                    <Checkbox
                        checked={socialResultsTopicList[3].checked}
                        onChange={(e) => onTopicCheckedChange(3, e)}
                    >提供科学研究平台，吸引科研项目和资金</Checkbox>
                </div>
                <div className='edit-page-tab-text'>
                    <Checkbox
                        checked={socialResultsTopicList[4].checked}
                        onChange={(e) => onTopicCheckedChange(4, e)}
                    >增加科研论著的产出数量</Checkbox>
                </div>
            </div>
            <div className='edit-page-tab-item-box'>
                <div className='edit-page-tab-title'>推进宣传教育</div>
                <div className='edit-page-tab-text'>
                    <Checkbox
                        checked={socialResultsTopicList[5].checked}
                        onChange={(e) => onTopicCheckedChange(5, e)}
                    >加强资源保护的意识和行动</Checkbox>
                </div>
                <div className='edit-page-tab-text'>
                    <Checkbox
                        checked={socialResultsTopicList[6].checked}
                        onChange={(e) => onTopicCheckedChange(6, e)}
                    >提供教育实习基地</Checkbox>
                </div>
                <div className='edit-page-tab-text'>
                    <Checkbox
                        checked={socialResultsTopicList[7].checked}
                        onChange={(e) => onTopicCheckedChange(7, e)}
                    >提供环境教育活动都素材和场地</Checkbox>
                </div>
            </div>
            <div className='edit-page-tab-item-box'>
                <div className='edit-page-tab-title'>提升周边居民生活</div>
                <div className='edit-page-tab-text'>
                    <Checkbox
                        checked={socialResultsTopicList[8].checked}
                        onChange={(e) => onTopicCheckedChange(8, e)}
                    >加带动就业，增加周边居民收入</Checkbox>
                </div>
                <div className='edit-page-tab-text'>
                    <Checkbox
                        checked={socialResultsTopicList[9].checked}
                        onChange={(e) => onTopicCheckedChange(9, e)}
                    >提高人居环境质量，促进周边居民的身心健康</Checkbox>
                </div>
            </div>
            <div className='edit-page-tab-item-box'>
                <div className='edit-page-tab-title'>提供休息旅游服务</div>
                <div className='edit-page-tab-text'>
                    <Checkbox
                        checked={socialResultsTopicList[10].checked}
                        onChange={(e) => onTopicCheckedChange(10, e)}
                    >提供旅游度假场所，愉悦身心</Checkbox>
                </div>
            </div>
            <Button
                type={'primary'}
                onClick={handleInsertSocialResultsTopicList}
                disabled={checkComfirmBtnDisabled()}
                size={'middle'}
            >确定</Button>
        </div>
    )
}

export default BenefitEvaluationContent3;