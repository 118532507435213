import { ReactEditor, RenderElementProps } from 'slate-react';

import { BaseSlateItemType } from '../../../types';

export const withTag = (editor: ReactEditor) => {
  const { isInline, isVoid, markableVoid } = editor;
  const isTag = (element: RenderElementProps['element']) =>
    (element as unknown as { type: BaseSlateItemType }).type === BaseSlateItemType.Tag;
  editor.isInline = (element) => isTag(element) || isInline(element);
  editor.isVoid = (element) => isTag(element) || isVoid(element);
  editor.markableVoid = (element) => isTag(element) || markableVoid(element);
  return editor;
};
