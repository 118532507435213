/**
 * doc 项数据类型
 */

import { CSSProperties, MutableRefObject } from "react";
import { ColSpanInfo, RowSpanInfo } from "../../../utils/types";
import { ReactEditor } from 'slate-react';
import { AlignEnum } from "../constants";

export enum DocItemType {
  /**
   * 一级标题
   */
  H1 = 'h1',
  /**
   * 二级标题
   */
  H2 = 'h2',
  /**
   * 三级标题
   */
  H3 = 'h3',
  /**
   * 四级标题
   */
  H4 = 'h4',
  /**
   * 五级标题
   */
  H5 = 'h5',
  /**
   * 六级标题
   */
  H6 = 'h6',
  /**
   * 段落
   */
  P = 'p',
  /**
   * 联合数据段落
   */
  CP = 'cp',
  /**
   * TABLE_NAME
   */
  TABLE_NAME = 'table-name',
  /**
   * TABLE_DESC
   */
  TABLE_DESC = 'table-desc',
  /**
   * 表格
   */
  TABLE = 'table',
  /**
   * 公式
   */
  LaTex = 'latex'
}

export interface DocItemLeaf {
    text: string
    style?: CSSProperties
}

export interface DocItemExternalData {
  [name: string]: unknown
  tableName?: string
  tableDesc?: string
  tableData?: string[][]
  rowSpanInfoList?: RowSpanInfo[],
  colSpanInfoList?: ColSpanInfo[],
  tableHeaderEndRowIndex?: number //default 0
  childTopicIdSortList?: string[]
  laTex?: string
}

/**
 * doc 项数据
 * - doc 数据基本单位
 */
export interface DocItem {
  id: string | number;
  type: DocItemType;
  text?: string;
  data?: {
    [name: string]: string | number | undefined;
  };
  children?: DocItemLeaf[]
  externalData?: DocItemExternalData;
  //新属性
  plainText?: string;
  style?: {
    [prop: string]: string;
    align?: AlignEnum
  };
  textStyleMap?: {
    [textIndex: string]: {
      text: string;
      [prop: string]: string | number | boolean;
    };
  };
  checkType?: 'highLight' | 'custom'
}

/**
 * doc编辑器属性
 */
export interface DocEditorProps {
  value: DocItem[];
  loading?: boolean;
  data?: {
    [name: string]: string | number;
  };
  onChange?: (items: DocItem[]) => void;
  onDocItemClick?: (item: DocItem, elements: NodeListOf<Element> | null) => void;
  onSlateInputDataChanged: (data: Record<string, string>) => void; 
  editableWrapStyle?: CSSProperties
}

/**
 * 分享的文档编辑器的数据
 */
export interface ShareDocEditorData {
  [name: string]: string | number;
}


export interface SlateEditorInputInfo {
  docItemId: string                 //当时应用到docItem的id
  textTopicId: string               //当前所属文本节点的id
  deviceTopicId: string             //当前输入框对应的deviceTopic
  docItemInputMarker?: string       //输入框的标记$[U]$[S]$[X]
  realInputMarker?: string          //输入框的标记$[U]$[S]$[X]
  inputValue?: string               //输入框的数据
}

/**
 * 文档编辑器实例
 */
export interface DocEditorInstance {
  editor: ReactEditor;
}


/**
 * 文档编辑器实例ref
 */
export type DocEditorRef = MutableRefObject<DocEditorInstance | null>;