import { defaultProjectSubTableList } from "../../../utils";
import { DocConfig, DocInstance } from "../../../utils/types";
import { DocItem } from "../../slate-editor"
import { processWorkSheet } from "./process-sub-table-data";
import { DocItemGroup } from "./type";

/**
 * 生成附表正文插入段落
 * @param scheduleType 
 * @param docInstance 
 * @param docConfig 
 * @returns 
 */
export const generateScheduleTextDocItemList = (
    scheduleType: "1" | "2" | "3" | "4" | "5" | "6",
    docInstance: DocInstance,
    docConfig: DocConfig
):  { docItemGroupList: DocItemGroup[], docItemList: DocItem[], additionalDocItemList?: DocItem[] } => {
    let scheduleTextDocItemResult:{ docItemGroupList: DocItemGroup[], docItemList: DocItem[],additionalDocItemList: DocItem[] } = {
        docItemGroupList: [],
        docItemList: [],
        additionalDocItemList: []
    };
    let {
        topicList,
        protectlandBaseInfo: {
            protectlandExtraInfo
        }
    } = docInstance;
    const {
        projectSubTableList = defaultProjectSubTableList,
        supplementaryInformation,
        protectedNaturalEnvironment
    } = protectlandExtraInfo;
    let find = projectSubTableList.find(ele => ele.type == scheduleType);
    if (find && find.workSheetData.length) {
        const { docItemList, docItemGroupList, additionalDocItemList } = processWorkSheet(find.workSheetData, scheduleType, docInstance);
        scheduleTextDocItemResult.docItemList = docItemList;
        scheduleTextDocItemResult.docItemGroupList = docItemGroupList;
        scheduleTextDocItemResult.additionalDocItemList = additionalDocItemList;
    }
    return scheduleTextDocItemResult;
}

/**
 * 生成初始化的时候，所有的附表段落
 * @param docInstance 
 * @param docConfig 
 * @returns 
 */
export const generateScheduleTableAdditionalDocItemList = (
    docInstance: DocInstance,
    docConfig: DocConfig
): DocItem[] => {
    let allAdditionalDocItemList = [];
    for(let i = 1; i < 7; i ++){
        //@ts-ignore
        const { additionalDocItemList } =  generateScheduleTextDocItemList(`${i}`, docInstance, docConfig);
        allAdditionalDocItemList = allAdditionalDocItemList.concat(additionalDocItemList);
    }
    return allAdditionalDocItemList;
}
