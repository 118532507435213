import React, { useRef } from 'react';
import DocEditorContext from './DocEditorContext';

const DocEditorProvider = ({ children }) => {
  const docEditorRef = useRef();

  return (
    <DocEditorContext.Provider
      value={docEditorRef}
    >
      {children}
    </DocEditorContext.Provider>
  );
};

export default DocEditorProvider;