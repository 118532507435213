import "./AddBackgrounParagraphModal.scss";
import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useContext,
  ChangeEvent,
  useRef,
  useEffect,
} from "react";
import { Modal, Input, Switch, Alert } from "antd";
import { TopicNodeActionType, TopicType } from "../../../../utils/types";
import comDocContext from "./../../../../context/DocContext";
import {
  getChangeEventValue,
  getTopicMaxBackgroundIndex,
  isEmpty,
  toastShort,
} from "../../../../utils";
import systemApi from "../../../../api/system";

interface Props {
  onFinish?: (pagraphText: string) => void;
}

const AddTopicMoal = (props: Props, ref: any) => {
  useImperativeHandle(ref, () => ({
    openModal: openModal,
  }));

  const editableRef = useRef<any>(null);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    comDocInstance,
    _setComDocInstance,
    currentFocusedDocItem
  } = useContext(comDocContext);

  const [currentTopicNode, setCurrentTopicNode] = useState<TopicType>(null);

  const [pagraphText, setPagraphText] = useState<string>("");

  const [isCollect, setIsCollect] = useState(false);

  const [isShareOpen, setIsShareOpen] = useState(false);

  const { onFinish } = props;

  const openModal = (textTopic: TopicType) => {
    setIsModalOpen(true);
    setCurrentTopicNode(textTopic);
    setPagraphText("");
    setIsCollect(false);
    setIsShareOpen(false);
  };

  const handleConfirm = () => {
    if (isEmpty(pagraphText)) {
      return toastShort("error", "请先输入大纲段落文本");
    }
    // console.log("comDocInstance--->", comDocInstance);
    // return false;
    const {
      protectlandBaseInfo: {
        id,
        // type,
        // subType,
        startYear,
        endYear,
        protectlandTypeId,
        protectlandArea,
        protectlandAreaUnit,
        protectlandLevel,
        protectlandName,
        protectlandProvince,
      },
      protectland: {
        type,
        subType
      }
    } = comDocInstance;
    let createModelEssayParams = {
      topicName: currentTopicNode.topicName,
      protectlandTypeId: protectlandTypeId,
      protectlandTypeName: type,
      protectlandSubTypeName: subType,
      backgroundParagraphText: pagraphText,
      originProvince: protectlandProvince,
      orginStartYear: startYear,
      originYearRange: endYear,
      originProtectlandArea: protectlandArea,
      originProtectlandAreaUnit: protectlandAreaUnit,
      originProtectlandLevel: protectlandLevel,
      isCollect: isCollect ? 1 : 0,
      isShareOpen: isShareOpen ? 1 : 0
    }
    // console.log("这是创建参数----->", createModelEssayParams)
    setSubmitLoading(true);
    systemApi.createModelEssay(createModelEssayParams)
      .then(res => {
        // console.log("res---->", res)
        setSubmitLoading(false);
        setIsModalOpen(false);
        onFinish && onFinish(pagraphText);
      })
      .catch(err => {
        console.log("err--->", err)
        setSubmitLoading(false);
      })
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onParagraphTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPagraphText(getChangeEventValue(e));
  };

  const onIsCollectChange = (e: boolean) => {
    // console.log("onIsCollectChange--->", e);
    setIsCollect(e);
  };

  const onIsShareOpenChange = (e: boolean) => {
    setIsShareOpen(e);
  }

  return (
    <Modal
      title={"新建我的范文"}
      open={isModalOpen}
      onOk={handleConfirm}
      onCancel={handleCancel}
      okText={"确 认"}
      cancelText={"取 消"}
      width={720}
      maskClosable={false}
      confirmLoading={submitLoading}
      centered
    >
      <div className="custom-modal-container">
        <div style={{ marginBottom: 8, width: '100%' }}>
          <div className="add-paragraph-form-name" style={{ marginBottom: 5 }}>目标章节：</div>
          <Input value={currentTopicNode && currentTopicNode.topicName} disabled />
        </div>
        <div className="add-paragraph-form-name" style={{ marginBottom: 5 }}>范文内容：</div>
        <div className="add-paragraph-block">
          <Input.TextArea
            placeholder={"粘贴背景文本到此处"}
            rows={19}
            value={pagraphText}
            onChange={onParagraphTextChange}
            allowClear
            maxLength={8000}
            showCount
          />
          {/* <div 
            contentEditable={true}
            ref={editableRef}
            onInput={}
          >
            <span>adldhkshsdkhdkdshdkhsdkdshdskhsdk</span><span style={{border: '1px solid #000'}}>ssss</span><span> 1</span>
          </div>   */}
        </div>
        <Alert
          type={'warning'}
          style={{ paddingTop: 5, paddingBottom: 3, paddingLeft: 8, paddingRight: 5 }}
          description={<span><span>注：</span><span style={{ fontWeight: 700 }}>收藏范文</span>开启时，范文将一并保存至您的收藏范文库中，<span style={{ fontWeight: 700 }}>公开共享</span>选项开启时，此段范文保存后可被其他用户引用，您也可在用户共享栏目中发掘其他用户共享的优质范文。</span>}
          closable
        />
        <div className="flex-row add-paragraph-form-item">
          <div style={{ flex: 1 }} className="flex-row ">
            <div className="add-paragraph-form-name">收藏范文：</div>
            <Switch
              value={isCollect}
              onChange={onIsCollectChange}
            />
          </div>
          <div className="flex-row" style={{ flex: 1 }}>
            <div className="add-paragraph-form-name">公开共享：</div>
            <Switch
              value={isShareOpen}
              onChange={onIsShareOpenChange}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(AddTopicMoal);
